import "twin.macro";

import { FormProvider, useForm } from "react-hook-form";

import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
} from "@mui/material";

import _ from "lodash";

import { ResourceError } from "@features/errors";
import { useItemQuery, useUpdateItemMutation } from "@features/items";
import saveVariablePricing from "@features/items/data/saveVariablePricing";
import { TFormVariablePricing } from "@features/items/maps";
import { LoadingButton } from "@features/ui";
import { useMutateError } from "@services/api";

import { useQuote } from "../context/QuoteContext";
import QuoteTransferForm from "./QuoteTransferForm";

type TFieldValues = {
  transferOrderType: boolean;
  transferCost: boolean;
  transferMoq: boolean;
  selectedTierId: string;
  cost: string;
  price: string;
  moq: string;
  transferVariablePricing: boolean;
  variablePricingPrices: Record<string, string>;
};

const createPricingBody = (
  maxQuantity: number | null,
  cost: string,
  price: string,
  id?: string
): TFormVariablePricing => ({
  ...(id && { id }),
  maxQuantity: maxQuantity,
  cost,
  price,
});

const AwardedQuoteModal = ({ handleClose }: { handleClose: () => void }) => {
  const setError = useMutateError();
  const updateItemMutation = useUpdateItemMutation();
  const { quote } = useQuote();
  const {
    data: item,
    isLoading,
    error,
  } = useItemQuery(quote.requestForQuote.item.id);

  const formFunctions = useForm<TFieldValues>({
    defaultValues: {
      transferOrderType: true,
      transferCost: true,
      transferMoq: true,
      selectedTierId: "",
      cost: "",
      price: "",
      moq: quote.moq.toString(),
      transferVariablePricing: true,
      variablePricingPrices: Object.fromEntries(
        quote.pricingTierQuotes.map((ptq) => [ptq.id, ptq.cost])
      ),
    },
  });

  const { handleSubmit } = formFunctions;

  const handleTransfer = async (data: TFieldValues) => {
    if (!item) return;
    try {
      const promises: Promise<any>[] = [];
      const finalOrderType = data.transferOrderType
        ? quote.requestForQuote.orderType
        : item.orderType;
      const attributes = {
        id: item.id,
        // unchanged, but required
        territories: item.territories,
        channels: item.channels,
        groups: item.groups,

        orderType: finalOrderType,
        // transfer cost and moq
        ...(data.transferCost && {
          cost: data.cost,
          price: data.price,
        }),
        ...(data.transferMoq && {
          minimumOrderQuantity: data.moq,
        }),
      };
      promises.push(updateItemMutation.mutateAsync(attributes));

      if (data.transferVariablePricing && finalOrderType === "on-demand") {
        const ptqs = _.sortBy(quote.pricingTierQuotes, "pricingTierQty");
        const updateVps: TFormVariablePricing[] = item.variablePricing.map(
          (vp, i) => {
            const ptq = ptqs[i];
            if (!ptq) {
              return { id: vp.id, shouldDelete: true };
            }
            const maxQuantity = ptqs[i + 1]?.pricingTierQty ?? null;
            return createPricingBody(
              maxQuantity,
              ptq.cost,
              data.variablePricingPrices[ptq.id],
              vp.id
            );
          }
        );

        // Append new pricing tiers if there are more ptqs than existing variable pricing
        for (let i = item.variablePricing.length; i < ptqs.length; i++) {
          const ptq = ptqs[i];
          const maxQuantity = ptqs[i + 1]?.pricingTierQty ?? null;
          updateVps.push(
            createPricingBody(
              maxQuantity,
              ptq.cost,
              data.variablePricingPrices[ptq.id]
            )
          );
        }
        promises.push(saveVariablePricing(item.id, updateVps, true));
      }

      await Promise.all(promises);
      handleClose();
    } catch (e) {
      setError(e as Error);
    }
  };

  return (
    <Dialog open fullWidth maxWidth="sm">
      <DialogTitle tw="pb-2">Transfer Quote Info to Item</DialogTitle>
      <DialogContent>
        {error && <ResourceError error={error} />}
        {item && (
          <FormProvider {...formFunctions}>
            <p tw="text-sm text-neutral-500">
              You have successfully awarded the quote for{" "}
              <span tw="text-neutral-700">{item.name}</span> to{" "}
              <span tw="text-neutral-700">{quote.supplierName}</span>. Please
              review the following and determine if you would like to update the
              item with any information from the awarded quote.
            </p>
            <Divider tw="my-4" />
            <QuoteTransferForm item={item} />
          </FormProvider>
        )}
        {isLoading && <CircularProgress />}
      </DialogContent>
      <DialogActions>
        <Button
          variant="text"
          onClick={handleClose}
          disabled={updateItemMutation.isPending}
        >
          Cancel
        </Button>
        <LoadingButton
          variant="contained"
          onClick={handleSubmit(handleTransfer)}
          loading={updateItemMutation.isPending}
        >
          Update Item Info
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

export default AwardedQuoteModal;
