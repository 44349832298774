import fp from "lodash/fp";

import { WarehouseID } from "@models/Address";
import { Item } from "@models/Item";
import { OrderType } from "@models/Order";
import { defaultFormValues } from "@utils/forms";

import { optionsFromVariants, variantHash, variantName } from "./helpers";
import { FormVariant } from "./types";

const optionFields = fp.pick(["id", "name"]);

export type TFormVariablePricing = {
  id?: string;
  cost?: string;
  price?: string;
  maxQuantity?: string | number | null;
  shouldDelete?: boolean;
};

export type ItemFormData = {
  id: string;
  status: "draft" | "complete";
  isActive: boolean;
  isBundle: boolean;
  isCustomizable: boolean;
  customizationDescription: string;
  name: string;
  description: string;
  cost: string;
  price: string;
  sku: string;
  orderableStartDate: string;
  orderableEndDate: string;
  visibleStartDate: string;
  visibleEndDate: string;
  supplierId: string;
  territories: any[];
  channels: any[];
  groups: Record<string, string>;
  orderType: OrderType;
  leadTimeInDays: string;
  unitOfMeasure: "ea" | "pack";
  warehouse: WarehouseID | undefined;
  packSize: string;
  countryOfOriginId: string;
  htsCode: string;
  specification: { name: string; value: string }[];
  minimumOrderQuantity: string;
  reorderThreshold: string;
  variablePricing: TFormVariablePricing[];
  variants: Record<string, FormVariant>;
  variantOptions: Record<string, { id: string; name: string }[]>;
  usesVariablePricing: boolean;
};

export const defaultItemFormValues: ItemFormData = {
  id: "",
  status: "draft",
  isActive: true,
  isBundle: false,
  isCustomizable: false,
  customizationDescription: "",
  name: "",
  description: "",
  cost: "",
  price: "",
  sku: "",
  orderableStartDate: "",
  orderableEndDate: "",
  visibleStartDate: "",
  visibleEndDate: "",
  supplierId: "",
  territories: [],
  channels: [],
  groups: {},
  orderType: "on-demand",
  leadTimeInDays: "",
  unitOfMeasure: "ea",
  warehouse: undefined,
  packSize: "",
  countryOfOriginId: "",
  htsCode: "",
  specification: [],
  minimumOrderQuantity: "",
  reorderThreshold: "",
  variablePricing: [],
  variants: {},
  variantOptions: {},
  usesVariablePricing: false,
};

export const formDataFromItem: (data: Item) => ItemFormData = fp.flow(
  fp.pick(Object.keys(defaultItemFormValues)),
  fp.defaultTo(defaultItemFormValues),
  (data: Item) => ({
    ...data,
    cost: Number(data.cost).toFixed(2),
    price: Number(data.price).toFixed(2),
    territories: data.territories.map(optionFields),
    channels: data.channels.map(optionFields),
    groups: Object.fromEntries(
      data.groups.map((group) => [group.groupCategory.id, group.id])
    ),
    variablePricing: data.variablePricing.map(
      fp.pick(["id", "cost", "price", "maxQuantity"])
    ),
    usesVariablePricing: data.variablePricing.length > 0,
    specification: Object.entries(data.specification ?? {}).map(
      ([key, value]) => ({
        name: key,
        value,
      })
    ),
    variantOptions: optionsFromVariants(data.variants),
    variants: data.variants.reduce<Record<string, FormVariant>>((acc, v) => {
      acc[variantHash(v)] = {
        id: v.id,
        name: variantName(v),
        hash: variantHash(v),
        imageId: v.image?.id ?? null,
        upcharge: Number(v.upcharge).toFixed(2),
        externalWarehouseId: v.externalWarehouseId,
        cachedWarehouseQty: v.cachedWarehouseQty ?? 0,
        quantityOnHand: v.quantityOnHand ?? 0,
        variantSku: v.variantSku,
        orderPosition: v.orderPosition,
        isActive: v.isActive,
        isDefault: v.isDefault,
        reorderThreshold: v.reorderThreshold,
      };
      return acc;
    }, {}),
  }),
  defaultFormValues
  // inspect
);
