export const mapCategories = (categories) => {
  const mappedCategories = categories
    .map((cat) => ({
      id: cat.id,
      name: cat.name,
      type: cat.type,
      priorityLevel: cat["priority-level"],
      isRequired: cat["is-required-on-item"],
      limitsAvailability: cat["limits-availability"],
    }))
    .sort((a, b) =>
      a.type > b.type
        ? -1
        : a.type === b.type
          ? a.priorityLevel > b.priorityLevel
            ? 1
            : -1
          : 1
    );
  return mappedCategories;
};

export const mapGroups = (groups) => {
  const mappedGroups = groups
    .map((group) => ({
      id: group.id,
      name: group.name,
      groupCategoryId: group["group-category"].id,
      groupCategoryName: group["group-category"].name,
    }))
    .sort((a, b) => (a.name < b.name ? -1 : a.name > b.name ? 1 : 0));
  return mappedGroups;
};
