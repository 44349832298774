import "twin.macro";

import { useState } from "react";
import { CSVReader } from "react-papaparse";
import { useSelector } from "react-redux";

import { Close, GetApp } from "@mui/icons-material";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  LinearProgress,
} from "@mui/material";

import { useQueryClient } from "@tanstack/react-query";
import _ from "lodash";

import { orderSetsKeyFactory, useCurrentOrderSet } from "@features/ordering";
import { Country } from "@models/Country";
import asyncPool from "@utility/asyncPool";
import { csvWhitespaceRowRemover } from "@utility/csvWhitespaceRowRemover";
import { roundUp } from "@utility/utilityFunctions";
import { downloadAsCsv } from "@utils/csv";

import useCreateOrder from "../../data/mutations/useCreateOrder";
import useSetOrderVariantQty from "../../data/mutations/useSetOrderVariantQty";
import {
  addressColumns,
  createTempAddress,
  requiredAddressColumns,
  validateOrders,
} from "./helpers";

type ModalStatus =
  | "pendingFile"
  | "validating"
  | "uploading"
  | "complete"
  | "error";

const errorTitles = {
  pendingFile: "Could not upload file",
  validating: "The following errors need to be fixed before uploading",
  uploading: "The orders have been uploaded with the following error(s)",
  error: "An Error occured",
};

const BulkUploadOrdersModal = ({ onClose }: { onClose: () => void }) => {
  const queryClient = useQueryClient();
  const { orderSet, orderSetVariants } = useCurrentOrderSet();

  const createOrder = useCreateOrder();
  const setOrderVariantQty = useSetOrderVariantQty();

  const countries: Country[] = useSelector(
    (state: any) => state.currentUser.organization.countries
  );
  const territory = useSelector((state: any) =>
    state.currentUser.territories.find(
      (t) => t.id === state.currentUser.currentTerritoryId
    )
  );
  const [status, setStatus] = useState<ModalStatus>("pendingFile");
  const [errors, setErrors] = useState<string[]>([]);
  const [percentUploaded, setPercentUploaded] = useState(0);

  const columnSkuIds = orderSetVariants.map(
    (v) => v.variant.externalWarehouseId ?? v.variant.variantSku
  );
  const columns = [...addressColumns, ...columnSkuIds];

  const refetchOrderSet = () =>
    queryClient.invalidateQueries({
      queryKey: orderSetsKeyFactory.detail(orderSet.id).queryKey,
    });

  const uploadOrder = async (rowData) => {
    try {
      const address = await createTempAddress(rowData, territory.id);
      const order = await createOrder.mutateAsync({
        type: orderSet.type,
        attn: rowData.name,
        addressId: address.id,
      });

      const { errors } = await asyncPool(2, order.orderVariants, async (ov) => {
        const qty = Number(
          rowData[ov.variant.externalWarehouseId ?? ov.variant.variantSku]
        );
        if (qty === 0 || isNaN(qty)) return;
        return await setOrderVariantQty.mutateAsync({
          id: ov.id,
          qty: roundUp(qty, ov.qtyPerPack),
        });
      });

      return { order, errors };
    } catch (error: any) {
      throw new Error(`Error creating order ${rowData.name}: ${error.message}`);
    }
  };

  const handleUpload = async (data: any[]) => {
    try {
      setStatus("validating");
      const rows = _.map(data, "data");
      const cleanRows = csvWhitespaceRowRemover(rows, requiredAddressColumns);
      const { data: validData, errors: validationErrors } = validateOrders(
        cleanRows,
        columnSkuIds,
        countries,
        territory
      );
      if (validationErrors) {
        setErrors(validationErrors);
        return;
      }
      setStatus("uploading");
      const { results, errors: uploadErrors } = await asyncPool(
        3,
        validData,
        uploadOrder,
        () => setPercentUploaded((prev) => prev + 1 / rows.length)
      );
      const errors = [
        ...(uploadErrors ?? []),
        ...results.flatMap((r) => r.errors ?? []),
      ];
      if (errors.length > 0) {
        setErrors(errors.map((err) => err.message));
      } else {
        setStatus("complete");
      }
    } catch (error: any) {
      console.error(error);
      setErrors([error.message]);
      setStatus("error");
    } finally {
      refetchOrderSet();
    }
  };

  const handleClose = () => {
    if (status !== "pendingFile" && status !== "complete") {
      refetchOrderSet();
    }
    onClose();
  };

  const resetStatus = () => {
    setStatus("pendingFile");
    setErrors([]);
    setPercentUploaded(0);
  };

  return (
    <Dialog open fullWidth maxWidth="md">
      <DialogTitle tw="flex justify-between items-start">
        Upload orders
        <IconButton onClick={handleClose} edge="end">
          <Close />
        </IconButton>
      </DialogTitle>
      <DialogContent tw="space-y-6">
        {status === "pendingFile" && (
          <Button
            variant="outlined"
            startIcon={<GetApp />}
            onClick={() =>
              downloadAsCsv(
                [columns],
                `orders_upload_${orderSet.id}_template.csv`
              )
            }
          >
            Download CSV Template
          </Button>
        )}
        {errors.length === 0 && (
          <div tw="bg-neutral-100 rounded-xl p-4">
            {status === "pendingFile" && (
              <CSVReader onFileLoad={handleUpload} config={{ header: true }}>
                Upload CSV file
              </CSVReader>
            )}
            {status === "validating" && "Validating..."}
            {status === "uploading" && "Uploading..."}
            {status === "complete" && "Upload complete!"}
            {status === "uploading" && (
              <LinearProgress
                tw="mt-4"
                variant="determinate"
                value={percentUploaded * 100}
              />
            )}
          </div>
        )}
        {errors.length > 0 && (
          <div tw="">
            <h3 tw="text-2xl text-neutral-600 mb-3">{errorTitles[status]}</h3>
            <ul tw="font-mono py-3 px-6 rounded bg-neutral-100 text-neutral-800 space-y-2">
              {errors.map((err, i) => (
                <li key={i}>{err}</li>
              ))}
            </ul>
          </div>
        )}
      </DialogContent>
      <DialogActions>
        {(errors.length > 0 || status === "complete") && (
          <Button variant="outlined" onClick={resetStatus}>
            Upload new file
          </Button>
        )}
        {(status === "complete" ||
          (status === "uploading" && errors.length > 0)) && (
          <Button variant="contained" onClick={onClose}>
            Finish
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default BulkUploadOrdersModal;
