import "twin.macro";

import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router";

import { Button } from "@mui/material";

import { useFilterParams } from "@features/filters";
import { FaIcon, Title } from "@features/ui";
import {
  updateCurrentChannel,
  updateCurrentTerritory,
} from "@redux/slices/user/currentUserSlice";
import DocTitle from "@utility/DocTitle";
import permissions from "@utils/permissions";
import useRoleIs from "@utils/useRoleIs";

import useCheckoutOptions from "../hooks/useCheckoutOptions";
import { useCurrentOrderSet } from "./CurrentOrderSetContext";
import BudgetImpact from "./orderSetBudget/BudgetImpact";
import useCanEditOrderSet from "./useCanEditOrderSet";

const fromUrlMap = {
  "order-history": "/orders/history",
  approval: "/orders/approval",
};

const stageUrlMap = {
  overview: "../",
  checkout: "../overview",
  approval: "/orders/approval",
};

const OrderSetNavigation = ({
  stage,
}: {
  stage?: "overview" | "checkout" | "approval";
}) => {
  const dispatch = useDispatch();
  const [{ from }] = useFilterParams();
  const { orderSet } = useCurrentOrderSet();

  const { currentChannel, currentTerritory } = useSelector(
    (state: any) => state.currentUser
  );

  const { allowBudgetCheckout } = useCheckoutOptions();
  const usesApprovalFlow = useSelector(
    (state: any) => state.currentUser.organization.usesApprovalFlow
  );
  const canEdit = useCanEditOrderSet();
  const isApprover = useRoleIs()(permissions.orderReview) && usesApprovalFlow;

  if (canEdit && orderSet.status !== "draft" && usesApprovalFlow) {
    stage = "approval";
  }

  const addItemsUrl =
    orderSet.type === "pre-order"
      ? `/programs/${orderSet.program?.id}/order/${orderSet.orderWindow?.id}`
      : `/order-sets/${orderSet.type}`;

  // prioritize from param, then stage, then default to add items
  const backButtonUrl = fromUrlMap[from!] ?? stageUrlMap[stage!] ?? addItemsUrl;

  const title = {
    "on-demand": `On-Demand Order Set #${orderSet.id}`,
    "pre-order": `Pre-Order #${orderSet.id}`,
    inventory: `Inventory Order Set #${orderSet.id}`,
  }[orderSet.type];

  useEffect(() => {
    if (orderSet.channel && orderSet.channel?.id !== currentChannel) {
      dispatch(updateCurrentChannel({ channel: orderSet.channel.id }));
    }
    if (orderSet.territory.id !== currentTerritory) {
      dispatch(updateCurrentTerritory({ territory: orderSet.territory.id }));
    }
  }, [
    dispatch,
    currentChannel,
    currentTerritory,
    orderSet.territory.id,
    orderSet.channel,
  ]);

  return (
    <div tw="flex justify-between items-center">
      <DocTitle title={title} />
      <Title
        backUrl={backButtonUrl}
        override={
          stage === "overview" && !canEdit
            ? isApprover
              ? "/orders/approval"
              : "/orders/history"
            : undefined
        }
      >
        {title}
      </Title>
      <div tw="flex gap-2">
        {orderSet.status === "draft" && (
          <>
            <Button
              component={Link}
              variant="contained"
              color="secondary"
              to={addItemsUrl}
              startIcon={<FaIcon icon="plus" />}
              size="small"
              tw="whitespace-nowrap"
            >
              Add Items
            </Button>
            {!stage && allowBudgetCheckout && <BudgetImpact />}
          </>
        )}
        {stage && canEdit && (
          <Button
            component={Link}
            to="../"
            tw="whitespace-nowrap"
            variant="contained"
            color="secondary"
            startIcon={<FaIcon icon="pen" tw="text-sm!" />}
            size="small"
          >
            Edit Order
          </Button>
        )}
      </div>
    </div>
  );
};

export default OrderSetNavigation;
