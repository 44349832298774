import "twin.macro";

import { useDispatch } from "react-redux";
import { Link } from "react-router";

import { EmojiEvents } from "@mui/icons-material";
import { Chip } from "@mui/material";

import ReactQueryTable, {
  Column,
  PaginatedResourceProps,
} from "@components/Table/ReactQueryTable";
import SearchableText from "@components/Table/SearchableText";
import { ItemIdentifiers } from "@features/items";
import { QuotedItem } from "@models/QuotedItem";
import { setItemPreview } from "@redux/slices/items/itemSlice";
import { CldImage } from "@services/cloudinary";

const QuotedItemsTable = ({
  query,
  ...tableProps
}: PaginatedResourceProps & {
  rows: QuotedItem[];
  query?: string;
}) => {
  const dispatch = useDispatch();
  const columns: Column<QuotedItem>[] = [
    {
      id: "_preview",
      label: "Preview",
      sort: "id",
      to: (row) => `/rfqs/item/${row.id}`,
      render: (_, row) => (
        <div
          tw="flex-shrink-0 w-16 relative rounded-md cursor-pointer overflow-hidden
                after:(absolute inset-0 bg-neutral-900 opacity-0 transition-opacity)
                hover:after:opacity-20"
          role="button"
          tabIndex={-1}
          onClick={(e) => {
            e.stopPropagation();
            dispatch(setItemPreview({ id: row.id }));
          }}
        >
          <CldImage
            tw="w-full aspect-square object-contain 
                  border-2 border-neutral-200
                bg-white rounded-md"
            image={row.previewImage}
            size="thumbnail"
          />
        </div>
      ),
    },
    {
      id: "_item",
      label: "Item",
      sort: "name",
      to: (row) => `/rfqs/item/${row.id}`,
      render: (_, row) => (
        <>
          <ItemIdentifiers
            item={{
              sku: row.sku,
              defaultExternalWarehouseId: row.defaultExternalWarehouseId,
            }}
            query={query}
          />

          <div tw="inline-block text-base text-neutral-800 leading-tight mt-2">
            <SearchableText text={row.name} query={query} />
          </div>
        </>
      ),
    },
    {
      id: "_mostRecent",
      label: "Latest Round",
      render: (_, row) =>
        row.mostRecentRfq ? (
          <Chip
            tw="align-bottom"
            component={Link}
            variant="outlined"
            label={`Round ${row.mostRecentRfqRound}`}
            to={`/rfqs/${row.mostRecentRfq.id}`}
            clickable
          />
        ) : null,
    },
    {
      id: "_awarded",
      label: "Awarded",
      render: (_, row) =>
        row.awardedRfq ? (
          <Chip
            component={Link}
            variant="outlined"
            tw="border-purple-700 text-purple-700 font-medium"
            icon={<EmojiEvents tw="text-inherit! text-xl" />}
            label={row.awardedRfqSupplierName}
            to={`/rfqs/${row.awardedRfq.id}`}
            clickable
          />
        ) : null,
    },
  ];

  return <ReactQueryTable {...tableProps} columns={columns} />;
};

export default QuotedItemsTable;
