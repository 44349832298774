import "twin.macro";

import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";

import { Button, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

import { LuzmoDashboardComponent } from "@luzmo/react-embed";

import { Contained, OpaqueCard } from "@components/StyledComponents";
import Loading from "@components/Utility/Loading";
import { setError } from "@redux/slices/errorSlice";
import DocTitle from "@utility/DocTitle";

import { axiosGet } from "../api/axiosCalls";
import { useNoFetch } from "../hooks/useNoFetch";
import FourOhFour from "./FourOhFour";

const useStyles = makeStyles((theme) => ({
  ...theme.global,
}));

const dashMap = {
  orderactivity: {
    title: "Order Activity",
    desc: "Explore your team's order activity to understand who is ordering what products.",
    id: "dbb907bf-30d4-41f9-9222-00bbe67f709d",
  },
  preorderearlywarning: {
    title: "Pre-Order Early Warning",
    desc: "See how your Pre-Order Programs are rolling out in real time. Note that this dashboard looks at submitted orders on active Pre-Order windows only. Use this info to make adjustments and expand the reach of your promotional rollouts.",
    id: "df8895a5-c57b-45df-bb6c-b238e289c1b6",
  },
  inventorysnapshot: {
    title: "Inventory Snapshot",
    desc: "Explore your inventory by product to understand what you have on hand, and what the field is ordering. You can filter by Organization Category (ie, Brand), Product Category, or by overall inventory level to see what's on hand and what is being ordered.",
    id: "5840fad6-a4c3-4df3-9699-ce0c68d4e6ca",
  },
};

const Luzmo = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { slug } = useParams();
  const ref = useRef(null);

  const [isLoading, setIsLoading] = useState(true);
  const [auth, setAuth] = useState({ key: null, token: null });

  const { categories } = useSelector((state) => state.groupCategories);

  const primaryOrganizationCategory = categories.find(
    (c) => c.type === "organization" && c.priorityLevel === 1
  )?.name;
  const primaryItemCategory = categories.find(
    (c) => c.type === "item" && c.priorityLevel === 1
  )?.name;
  useNoFetch(dispatch);

  const getAuth = async () => {
    setIsLoading(true);

    let response = await axiosGet("/api/luzmo/get-auth-token", false);

    if (response.error) {
      console.error("Error fetching auth token:", response.error);
      dispatch(
        setError({ error: response.error, source: "Integrated Dashboards" })
      );
    } else {
      console.log("Auth token received:", response.data);
      setAuth({
        key: response.data["auth_key"],
        token: response.data["auth_token"],
      });
    }

    setIsLoading(false);
  };

  useEffect(() => {
    getAuth();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const dashboard = dashMap[slug];
  if (!dashboard) {
    console.error("Invalid dashboard slug:", slug);
    return <FourOhFour />;
  }

  return (
    <>
      <DocTitle title={`Analytics ${dashboard.title}`} />
      <Contained tw="space-y-6">
        <header className={classes.titleBar}>
          <Typography className={classes.titleText}>
            {dashboard.title}
          </Typography>
          <div className={classes.innerConfigDiv}>
            {primaryOrganizationCategory && (
              <Typography className={classes.bodyText}>
                <b>Primary Organization Category:</b>
                {` ${primaryOrganizationCategory}`}
              </Typography>
            )}
            {primaryItemCategory && (
              <Typography className={classes.bodyText}>
                <b>Primary Item Category:</b>
                {` ${primaryItemCategory}`}
              </Typography>
            )}
          </div>
        </header>
        <OpaqueCard>
          <Typography className={classes.bodyText}>{dashboard.desc}</Typography>
        </OpaqueCard>

        {isLoading && <Loading opacity="50%" />}
        {!isLoading && auth.key && auth.token && (
          <div tw="w-full -m-2">
            <LuzmoDashboardComponent
              ref={ref}
              tw="mx-auto"
              appServer="https://app.us.luzmo.com"
              authKey={auth.key}
              authToken={auth.token}
              dashboardId={dashboard.id}
              loaderSpinnerColor="rgb(0, 81, 126)"
              loaderSpinnerBackground="rgb(236 248 255)"
              itemsRendered={(e) => console.log("Dashboard items rendered:", e)}
              onError={(e) => console.error("Dashboard error:", e)}
            />
          </div>
        )}
        {!isLoading && !auth.key && !auth.token && (
          <div className={classes.fullWidthCenterColumn}>
            <Typography className={classes.titleText}>
              It looks like there was an error loading the integrated dashboard.
            </Typography>

            <Button
              className={classes.button}
              variant="contained"
              color="secondary"
              onClick={getAuth}
            >
              TRY AGAIN
            </Button>
          </div>
        )}
      </Contained>
    </>
  );
};

export default Luzmo;
