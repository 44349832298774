import "twin.macro";

import React, { memo, useState } from "react";

import { Button, CircularProgress, Switch, Typography } from "@mui/material";

import clsx from "clsx";
import PropTypes from "prop-types";

import SingleUserAutoComplete from "../../Utility/AutoCompleteFields/SingleUserAutoComplete";
import OptionTable from "../../Utility/OptionTable";

const UserPanel = ({
  classes,
  type,
  formData,
  currentUsers,
  setCurrentUsers,
  allUsers,
  setAllUsers,
  handleSubmit,
  promotion,
}) => {
  const [resetField, setResetField] = useState(false);

  const handleAddUser = (user) => {
    let selectedUser = currentUsers.find((u) => u.id === user.id);
    if (!selectedUser) setCurrentUsers(currentUsers.concat(user));
    setResetField(true);
  };

  const handleRemoveUser = (id) =>
    setCurrentUsers(currentUsers.filter((u) => u.id !== id));

  if (type === "edit" && !promotion) {
    return (
      <>
        <br />
        <br />
        <CircularProgress color="secondary" />
      </>
    );
  }

  return (
    <>
      {formData.redemptionType === "assignment" && (
        <Typography className={clsx(classes.bodyText, classes.settingsMargin)}>
          Users assigned to this promotions will be given the option to apply
          the promotion before submitting an order. You can assign a promotion
          to all users, or search and select specific users to assign a
          promotion to.
        </Typography>
      )}
      {formData.redemptionType === "code" && (
        <Typography className={clsx(classes.bodyText, classes.settingsMargin)}>
          Select which users will receive a promotion code for this promotion.
          They will receive the code via email, and can then use that code to
          apply the promotion to an order before submitting it. You can select
          all users to have the code emailed to everyone in your organization,
          or pick individual users.
        </Typography>
      )}
      <br />
      {type === "new" && (
        <div>
          <Typography className={classes.headerText}>
            {formData.redemptionType === "assignment"
              ? "Assign to All Users:"
              : "Send Code to All Users:"}
          </Typography>
          <Typography
            className={classes.bodyText}
            style={{ margin: "0 10px 0 10px" }}
            color="textSecondary"
          >
            No
          </Typography>
          <Switch
            checked={allUsers}
            onChange={() => {
              if (!allUsers) {
                setCurrentUsers([]);
              }
              setAllUsers(!allUsers);
            }}
            inputProps={{ "aria-label": "controlled" }}
          />
          <Typography
            className={classes.bodyText}
            style={{ margin: "0 10px 0 10px" }}
            color="textSecondary"
          >
            Yes
          </Typography>
        </div>
      )}
      {!allUsers && (
        <>
          <div className={classes.twoWide}>
            <SingleUserAutoComplete
              classes={classes}
              handleChange={handleAddUser}
              reset={resetField}
              setReset={setResetField}
              disabled={false}
            />
          </div>
          <br />
          <div className={classes.twoWide}>
            <OptionTable
              headCells={[
                { id: "id", label: "Id" },
                { id: "name", label: "Name" },
              ]}
              options={currentUsers}
              handleRemove={handleRemoveUser}
              isRemoveable
            />
          </div>
        </>
      )}
      {type === "edit" && (
        <>
          <br />
          <br />
          <Button
            className={clsx(classes.button, classes.settingsMargin)}
            variant="contained"
            color="secondary"
            onClick={handleSubmit}
            style={{ minWidth: "62px" }}
          >
            SAVE
          </Button>
        </>
      )}
    </>
  );
};

UserPanel.propTypes = {
  classes: PropTypes.object.isRequired,
  type: PropTypes.string,
  formData: PropTypes.object.isRequired,
  currentUsers: PropTypes.array.isRequired,
  setCurrentUsers: PropTypes.func.isRequired,
  allUsers: PropTypes.bool.isRequired,
  setAllUsers: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  promotion: PropTypes.object,
};

export default memo(UserPanel);
