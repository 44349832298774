import "twin.macro";

import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";

import useOrderableOrderWindowsQuery from "@features/orderWindows/useOrderableOrderWindowsQuery";
import { setError } from "@redux/slices/errorSlice";

import { DashboardGrid } from "../StyledComponents";
import DashCard from "./DashCard";
import WindowSelectionModal from "./WindowSelectionModal";

const dataMap = {
  reports: {
    ariaOwnsState: true,
    ariaOwnsText: "reports-dashboard",
    id: "reports-dashboard-parent",
    link: "/reports-dashboard",
    info: "Create and export various custom reports",
    icon: "https://res.cloudinary.com/brandhub/image/upload/v1617273999/prod/Icons/SELECT_Icons_Real-Time_Reporting_umtxqn.png",
  },
  "item-admin": {
    ariaOwnsState: true,
    ariaOwnsText: "item-admin",
    id: "item-admin-parent",
    link: "/admin/items",
    info: "Manage current items and create new items",
    icon: "https://res.cloudinary.com/brandhub/image/upload/v1617273999/prod/Icons/SELECT_Icons_Fast_Design_b1mgbt.png",
  },
  "program-admin": {
    ariaOwnsState: true,
    ariaOwnsText: "program-admin",
    id: "program-admin-parent",
    link: "/admin/programs",
    info: "Create new programs to help effectively manage marketing campaigns",
    icon: "https://res.cloudinary.com/brandhub/image/upload/v1617274000/prod/Icons/SELECT_Icons_Intelligence_Innovation_hfidfg.png",
  },
  "order-window-admin": {
    ariaOwnsState: true,
    ariaOwnsText: "order-window-admin",
    id: "order-window-admin-parent",
    link: "/admin/order-windows",
    info: "Create new pre-order windows with custom sets of items",
    icon: "https://res.cloudinary.com/brandhub/image/upload/v1617273999/prod/Icons/SELECT_Icons_Retail_-_On_Premise_fc9yxt.png",
  },
  "new-purchase-order": {
    ariaOwnsState: true,
    ariaOwnsText: "new-purchase-order",
    id: "new-purchase-order-parent",
    link: "/purchasing/purchase-order-rollup",
    info: "Create new purchase orders from pre-orders and on demand orders",
    icon: "https://res.cloudinary.com/brandhub/image/upload/v1617273998/prod/Icons/SELECT_Icons_Top_Products_-_Best_Sellers_hge1gt.png",
  },
  "purchase-order-history": {
    ariaOwnsState: true,
    ariaOwnsText: "purchase-order-history",
    id: "purchase-order-history-parent",
    link: "/purchasing/purchase-order-history",
    info: "View purchasing history",
    icon: "https://res.cloudinary.com/brandhub/image/upload/v1617274000/prod/Icons/SELECT_Icons_Analytics_hsy1lt.png",
  },
  "current-pre-orders": {
    ariaOwnsState: true,
    ariaOwnsText: "current-pre-orders",
    id: "current-pre-orders-parent",
    link: null,
    info: "Place pre-orders for active order windows, if there are multiple windows, click here to select which one to order from",
    icon: "https://res.cloudinary.com/brandhub/image/upload/v1617273999/prod/Icons/SELECT_Icons_Inventory_hqowaj.png",
  },
  "order-history": {
    ariaOwnsState: true,
    ariaOwnsText: "order-history",
    id: "order-history-parent",
    link: `/orders/history/?query=${JSON.stringify({
      groupOrderHistoryBy: "order",
    })}`,
    info: "View order history grouped by item or order & get tracking information",
    icon: "https://res.cloudinary.com/brandhub/image/upload/v1617274000/prod/Icons/SELECT_Icons_Analytics_hsy1lt.png",
  },
  "budget-admin": {
    ariaOwnsState: true,
    ariaOwnsText: "budget-admin",
    id: "budget-admin-parent",
    link: "/admin/budgets",
    info: "Create and manage budgets based on organization settings",
    icon: "https://res.cloudinary.com/brandhub/image/upload/v1617273999/prod/Icons/SELECT_Icons_Retail_-_On_Premise_fc9yxt.png",
  },
  "promotion-admin": {
    ariaOwnsState: true,
    ariaOwnsText: "promotion-admin",
    id: "promotion-admin-parent",
    link: "/admin/promotions",
    info: "Create and manage various promotions that can be applied while users are placing orders",
    icon: "https://res.cloudinary.com/brandhub/image/upload/v1617273999/prod/Icons/SELECT_Icons_Order_Management_m5zx9c.png",
  },
  "address-admin": {
    ariaOwnsState: true,
    ariaOwnsText: "address-admin",
    id: "address-admin-parent",
    link: "/admin/addresses",
    info: "Manage all addresses within your organization, print address reports, and create addresses for any user or territory",
    icon: "https://res.cloudinary.com/brandhub/image/upload/v1617273998/prod/Icons/SELECT_Icons_Social_Intranet_i82ihu.png",
  },
  programs: {
    ariaOwnsState: true,
    ariaOwnsText: "programs",
    id: "programs-parent",
    link: "/programs",
    info: "View active programs, learn about market strategies and goals",
    icon: "https://res.cloudinary.com/brandhub/image/upload/v1617273957/prod/Icons/SELECT_Icons_Design_wp1pmy.png",
  },
  "address-book": {
    ariaOwnsState: true,
    ariaOwnsText: "address-book",
    id: "address-book-parent",
    link: "/profile/addressBook",
    info: "View, create, and edit addresses for use while placing orders",
    icon: "https://res.cloudinary.com/brandhub/image/upload/v1617273998/prod/Icons/SELECT_Icons_Social_Intranet_i82ihu.png",
  },
  "favorite-addresses": {
    ariaOwnsState: true,
    ariaOwnsText: "favorite-addresses",
    id: "favorite-addresses-parent",
    link: "/profile/favoriteAddresses",
    info: "View, create, and edit addresses for use while placing orders",
    icon: "https://res.cloudinary.com/brandhub/image/upload/v1617273998/prod/Icons/SELECT_Icons_Success_vrcfia.png",
  },
  "inventory-order": {
    ariaOwnsState: true,
    ariaOwnsText: "inventory",
    id: "inventory-parent",
    link: "/order-sets/inventory",
    info: "Place orders for items that are currently available in our inventory",
    icon: "https://res.cloudinary.com/brandhub/image/upload/v1617273998/prod/Icons/SELECT_Icons_Top_Products_-_Best_Sellers_hge1gt.png",
  },
  "on-demand-order": {
    ariaOwnsState: true,
    ariaOwnsText: "on-demand",
    id: "on-demand-parent",
    link: "/order-sets/on-demand",
    info: "Place orders for items that will need to be produced for your order",
    icon: "https://res.cloudinary.com/brandhub/image/upload/v1617273999/prod/Icons/SELECT_Icons_Distribution_bvwczo.png",
  },
  "current-items": {
    ariaOwnsState: true,
    ariaOwnsText: "current-items",
    id: "current-items-parent",
    link: "/items",
    info: "View all items that are currently available for you to order, and items that will be available to order in the future",
    icon: "https://res.cloudinary.com/brandhub/image/upload/v1617273998/prod/Icons/SELECT_Icons_Total_Visibility_kym2yw.png",
  },
  "archived-items": {
    ariaOwnsState: true,
    ariaOwnsText: "archived-items",
    id: "archived-items-parent",
    link: "/items/archived",
    info: "View items that are no longer available to order",
    icon: "https://res.cloudinary.com/brandhub/image/upload/v1617273998/prod/Icons/SELECT_Icons_Total_Visibility_kym2yw.png",
  },
  budgets: {
    ariaOwnsState: true,
    ariaOwnsText: "budgets",
    id: "budgets-parent",
    link: "/budgets",
    info: "View budgets and budget history for your assigned budgets",
    icon: "https://res.cloudinary.com/brandhub/image/upload/v1617273999/prod/Icons/SELECT_Icons_Precise_Spend_Tracking_baw9z1.png",
  },
  help: {
    ariaOwnsState: true,
    ariaOwnsText: "help",
    id: "help-parent",
    link: "/help",
    info: "View helpful videos and documentation on Brandhub",
    icon: "https://res.cloudinary.com/brandhub/image/upload/v1617274000/prod/Icons/SELECT_Icons_Intelligence_Innovation_hfidfg.png",
  },
  "order-approvals": {
    ariaOwnsState: true,
    ariaOwnsText: "order-approvals",
    id: "order-approvals-parent",
    link: "/orders/approval",
    info: "Approve or deny orders",
    icon: "https://res.cloudinary.com/brandhub/image/upload/v1617273999/prod/Icons/SELECT_Icons_Production_zownaa.png",
  },
  "org-settings": {
    ariaOwnsState: true,
    ariaOwnsText: "org-settings",
    id: "org-settings-parent",
    link: "/admin/general",
    info: "Manage organizational level settings such as allowing credit card payments, estimated shipping percentage and usage of various order flows",
    icon: "https://res.cloudinary.com/brandhub/image/upload/v1617273999/prod/Icons/SELECT_Icons_Production_zownaa.png",
  },
  "dashboard-settings": {
    ariaOwnsState: true,
    ariaOwnsText: "dashboard-settings",
    id: "dashboard-settings-parent",
    link: "/admin/dashboard",
    info: "Manage dashboards for each user role in your organization",
    icon: "https://res.cloudinary.com/brandhub/image/upload/v1617273999/prod/Icons/SELECT_Icons_Production_zownaa.png",
  },
  "user-settings": {
    ariaOwnsState: true,
    ariaOwnsText: "user-settings",
    id: "user-settings-parent",
    link: "/admin/users",
    info: "Create, update, and view all users in your organization",
    icon: "https://res.cloudinary.com/brandhub/image/upload/v1617273999/prod/Icons/SELECT_Icons_Production_zownaa.png",
  },
  "territory-settings": {
    ariaOwnsState: true,
    ariaOwnsText: "territory-settings",
    id: "territory-settings-parent",
    link: "/admin/territories",
    info: "Create, update, and view all territories in your organization",
    icon: "https://res.cloudinary.com/brandhub/image/upload/v1617273999/prod/Icons/SELECT_Icons_Production_zownaa.png",
  },
  "channel-settings": {
    ariaOwnsState: true,
    ariaOwnsText: "channel-settings",
    id: "channel-settings-parent",
    link: "/admin/channels",
    info: "Create, update, and view all channels in your organization",
    icon: "https://res.cloudinary.com/brandhub/image/upload/v1617273999/prod/Icons/SELECT_Icons_Production_zownaa.png",
  },
  "item-settings": {
    ariaOwnsState: true,
    ariaOwnsText: "item-settings",
    id: "item-settings-parent",
    link: "/admin/items",
    info: "Manage what item categories and variants are available in your organization",
    icon: "https://res.cloudinary.com/brandhub/image/upload/v1617273999/prod/Icons/SELECT_Icons_Production_zownaa.png",
  },
  "supplier-settings": {
    ariaOwnsState: true,
    ariaOwnsText: "supplier-settings",
    id: "supplier-settings-parent",
    link: "/admin/suppliers",
    info: "Create and manage suppliers for your organization, and update which users are assigned to those suppliers",
    icon: "https://res.cloudinary.com/brandhub/image/upload/v1617273999/prod/Icons/SELECT_Icons_Production_zownaa.png",
  },
};

const DefaultDash = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [windowSelectionModalOpen, setWindowSelectionModalOpen] =
    useState(false);
  const [currentDashboard, setCurrentDashboard] = useState(null);

  const { isPending, data: orderWindows } = useOrderableOrderWindowsQuery();
  const {
    role,
    organization: { dashboards },
  } = useSelector((state) => state.currentUser);

  const handleWindowSelectionClose = () => setWindowSelectionModalOpen(false);

  const handleOrderWindowClick = (id) => {
    if (id) {
      navigate(`/pre-orders/${id}`);
    } else {
      navigate(`/pre-orders/${orderWindows[0].id}`);
    }
  };

  const handlePreOrderClick = () => {
    if (isPending || orderWindows.length === 0) {
      return dispatch(
        setError({
          error: "There are no active pre-orders at the moment",
          source: "Pre-Orders",
        })
      );
    }
    if (orderWindows.length === 1) {
      return handleOrderWindowClick(null);
    } else setWindowSelectionModalOpen(true);
  };

  const buildDashboard = () => {
    let activeDash = dashboards.find((d) => d.role === role);
    let activeOptions = activeDash?.options
      .filter((o) => o.isActive && dataMap[o.displayId])
      .sort((a, b) =>
        a.position < b.position ? -1 : a.position > b.position ? 1 : 0
      )
      .map((o) => ({
        ...dataMap[o.displayId],
        titleText: o.displayName,
      }));
    setCurrentDashboard(activeOptions);
  };

  useEffect(() => {
    if (dashboards && !currentDashboard) {
      buildDashboard();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dashboards, currentDashboard]);

  return (
    <>
      {windowSelectionModalOpen && (
        <WindowSelectionModal
          open={windowSelectionModalOpen}
          handleClose={handleWindowSelectionClose}
          windows={orderWindows}
          handleOrderWindowClick={handleOrderWindowClick}
        />
      )}
      {currentDashboard ? (
        <DashboardGrid>
          {currentDashboard.map((data, index) => (
            <DashCard
              key={index}
              data={data}
              onClick={
                data.id === "current-pre-orders-parent"
                  ? handlePreOrderClick
                  : null
              }
            />
          ))}
        </DashboardGrid>
      ) : (
        <h2>
          <br />
          No Dashboards are defined on this organization for the role "{role}"
        </h2>
      )}
    </>
  );
};

export default DefaultDash;
