import "twin.macro";

import { QuoteStatus as TQuoteStatus } from "@models/Quote";

const StatusChip = ({ label, ...props }) => (
  <div tw="flex items-center gap-2">
    <div tw="h-2 w-2 rounded-full" {...props} />
    <span>{label}</span>
  </div>
);

const QuoteStatus = ({ status }: { status: TQuoteStatus }) => {
  switch (status) {
    case "awarded":
      return <StatusChip label={`Awarded`} tw="bg-purple-400" />;
    case "complete":
      return <StatusChip label={`Complete`} tw="bg-green-400" />;
    case "accepted":
      return <StatusChip label={`Accepted`} tw="bg-blue-400" />;
    case "declined":
      return <StatusChip label={`Declined`} tw="bg-orange-400" />;
    case "canceled":
      return <StatusChip label={`Canceled`} tw="bg-red-400" />;
    case "sent":
      return <StatusChip label={`Pending`} tw="bg-blue-400" />;
  }
};

export default QuoteStatus;
