import { ResourceError } from "@features/errors";
import { useItemQuery } from "@features/items";

import { ItemContext } from "./ItemContext";
import { ItemModalSkeleton } from "./ItemModalSkeleton";

const ItemContextProvider = ({
  itemId,
  children,
}: {
  itemId: string;
  children: React.ReactNode;
}) => {
  const { data: item, error, isPending } = useItemQuery(itemId);

  if (error) {
    return <ResourceError error={error} />;
  }

  if (isPending) {
    return <ItemModalSkeleton />;
  }

  return (
    <ItemContext.Provider value={{ item }}>{children}</ItemContext.Provider>
  );
};

export default ItemContextProvider;
