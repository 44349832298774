import "twin.macro";

import { useState } from "react";
import { useSelector } from "react-redux";

import { Button } from "@mui/material";

import { DenyOrderSetsModal } from "@features/appovals";
import { LoadingButton } from "@features/ui";
import { useMutateError } from "@services/api";

import { useCurrentOrderSet } from "../CurrentOrderSetContext";
import { useApproveOrderSetsMutation, useDenyOrderSetsMutation } from "../data";
import useCanEditOrderSet from "../useCanEditOrderSet";

const OrderSetApprovalActions = () => {
  const setError = useMutateError();
  const usesApprovalFlow = useSelector(
    (state: any) => state.currentUser.organization.usesApprovalFlow
  );
  const approveOrderSets = useApproveOrderSetsMutation();
  const denyOrderSets = useDenyOrderSetsMutation();
  const { orderSet } = useCurrentOrderSet();
  const [denyModalOpen, setDenyModalOpen] = useState(false);
  // const lastLocation = useLastLocation();

  const onSuccess = () => {
    // if (lastLocation?.pathname === "/orders/approvals") {
    //   navigate(lastLocation);
    // }
  };

  const onError = (err) => setError(err, "Order Set Approval");

  const handleApprove = () => {
    approveOrderSets.mutate([orderSet.id], {
      onSuccess,
      onError,
    });
  };

  const handleDeny = async (reason: string) => {
    await denyOrderSets
      .mutateAsync({ orderSetIds: [orderSet.id], reason })
      .then(onSuccess);
  };

  const canEdit = useCanEditOrderSet();
  const isApprover = canEdit && orderSet.status !== "draft";

  if (!isApprover || !usesApprovalFlow) return null;

  return (
    <div tw="flex gap-2">
      <LoadingButton
        variant="contained"
        onClick={() => handleApprove()}
        loading={approveOrderSets.isPending}
      >
        Approve
      </LoadingButton>
      <Button variant="outlined" onClick={() => setDenyModalOpen(true)}>
        Deny
      </Button>
      {denyModalOpen && (
        <DenyOrderSetsModal
          singleId={orderSet.id}
          handleClose={() => setDenyModalOpen(false)}
          handleDeny={handleDeny}
        />
      )}
    </div>
  );
};

export default OrderSetApprovalActions;
