import _ from "lodash";

import { displayDate } from "@features/reports";
import { Quote } from "@models/Quote";
import { formatMoneyString } from "@utility/utilityFunctions";
import { CsvSchema } from "@utils/csv";

const quotesSchema: CsvSchema<Quote> = [
  { id: "id", label: "Quote ID" },
  { id: "requestForQuote.id", label: "Quote Request ID" },
  { id: "requestForQuote.sku", label: "Item SKU" },
  { id: "requestForQuote.itemName", label: "Item Name" },
  { id: "requestForQuote.orderType", label: "Order Type" },
  { id: "supplierName", label: "Supplier" },
  { id: "supplierQuoteNumber", label: "Supplier Quote Number" },
  { id: "status", label: "Status", f: _.startCase },
  { id: "updatedAt", label: "Last Action Date", f: displayDate },
  {
    id: "_lowestQuotedPrice",
    label: "Lowest Quoted Price",
    f: (_v, quote) =>
      quote.isComplete
        ? formatMoneyString(
            _(quote.pricingTierQuotes).map("cost").minBy(Number)
          )
        : "",
  },
  { id: "moq", label: "MOQ" },
];

export default quotesSchema;
