import React from "react";
import { Link, useParams } from "react-router";

import { useRfqQuery } from "./data";

const DynamicRFQCrumb = () => {
  const { id } = useParams();
  const { data: rfq } = useRfqQuery(id);
  if (!rfq) return null;
  return <Link to={`/rfqs/item/${rfq.item.id}`}>Item Quotes</Link>;
};

export default DynamicRFQCrumb;
