import tw from "twin.macro";

import { useBottomScrollListener } from "react-bottom-scroll-listener";
import { useDispatch, useSelector } from "react-redux";

import { FavoriteBorderRounded, FavoriteRounded } from "@mui/icons-material";
import {
  Checkbox,
  FormControlLabel,
  LinearProgress,
  Tooltip,
} from "@mui/material";

import { xor } from "lodash";

import { OpaqueCard } from "@components/StyledComponents";
import SearchableText from "@components/Table/SearchableText";
import { ResourceError } from "@features/errors";
import { ItemPreviewImageButton } from "@features/items";
import { setItemSelection } from "@redux/slices/items/itemSlice";
import { toggleFavoriteItem } from "@redux/slices/user/currentUserSlice";
import { formatMoneyString } from "@utility/utilityFunctions";

import { ItemCatalogProps } from "./ItemCatalogView";
import ItemIdentifiers from "./ItemIdentifiers";
import ItemOptionsCount from "./ItemOptionsCount";

const ItemCatalogGrid = ({
  query,
  showCheckbox,
  isRowDisabled,
  getCheckboxText,
  rowActions,
  rows,
  isLoading,
  isFetching,
  isPlaceholderData,
  hasNextPage,
  fetchNextPage,
  error,
  noResultsText,
}: ItemCatalogProps) => {
  const dispatch = useDispatch();
  const { selectedItems } = useSelector((state: any) => state.items);
  const { favoriteItemIds } = useSelector((state: any) => state.currentUser);
  const toggleSelection = (id) =>
    dispatch(setItemSelection({ items: xor(selectedItems, [id]) }));

  const fetchNext = () =>
    !isLoading && !isFetching && hasNextPage && fetchNextPage?.();
  const scrollRef = useBottomScrollListener(fetchNext, {
    offset: 500,
    debounceOptions: {
      leading: true,
      trailing: false,
    },
  }) as any;

  return (
    <div tw="relative overflow-hidden">
      {error && (
        <div tw="mb-4">
          <ResourceError error={error} />
        </div>
      )}
      <div
        ref={scrollRef}
        css={[
          tw`
          grid [grid-template-columns: repeat(auto-fill, minmax(15em, 1fr))] 
          gap-2 lg:gap-4 transition-opacity h-full overflow-y-auto
      `,
          isPlaceholderData && tw`opacity-50 pointer-events-none`,
        ]}
      >
        {rows.map((item) => (
          <OpaqueCard
            key={item.id}
            className="group"
            tw="p-3 overflow-visible flex flex-col justify-between"
          >
            <div tw="relative">
              {showCheckbox && (
                <FormControlLabel
                  css={{
                    "&": tw`absolute left-0 z-10 rounded-md -top-3 bg-white/90`,
                    ".MuiFormControlLabel-label": tw`pr-3 empty:pr-0`,
                  }}
                  control={
                    <Checkbox
                      tw="my-0"
                      checked={selectedItems.includes(item.id)}
                      onClick={(e) => e.stopPropagation()}
                      onChange={() => toggleSelection(item.id)}
                      disabled={isRowDisabled?.(item) ?? false}
                    />
                  }
                  label={getCheckboxText?.(item) ?? ""}
                />
              )}
              <ItemPreviewImageButton
                item={item}
                imageSize="medium"
                tw="w-full"
              />
              <div tw="mt-3 flex gap-2 items-center text-sm text-primary-600">
                <Tooltip title="Favorite Item">
                  <Checkbox
                    className="group"
                    tw="-m-2"
                    edge="start"
                    checked={favoriteItemIds.includes(+item.id)}
                    size="small"
                    icon={
                      <FavoriteBorderRounded tw="text-lg text-neutral-400 group-hover:text-neutral-700" />
                    }
                    checkedIcon={
                      <FavoriteRounded tw="text-lg text-primary-600" />
                    }
                    onClick={(e) => e.stopPropagation()}
                    onChange={() => dispatch(toggleFavoriteItem(item.id))}
                  />
                </Tooltip>
                <ItemIdentifiers item={item} query={query} />
              </div>
              <div tw="mt-2 font-medium text-neutral-800 leading-tight">
                <SearchableText text={item.name} query={query} />
              </div>
              {item.variants.length > 1 && <ItemOptionsCount item={item} />}
              <p tw="mt-3 text-neutral-600">
                <SearchableText
                  text={item.description ?? "---"}
                  query={query}
                  maxLength={150}
                />
              </p>
            </div>
            <div tw="flex justify-between items-center mt-3">
              <div tw="font-medium text-neutral-800">
                {formatMoneyString(item.price)}
              </div>
              {rowActions?.(item)}
            </div>
          </OpaqueCard>
        ))}

        {rows.length === 0 && !isLoading && (
          <div tw="text-xl text-neutral-500">
            {noResultsText ?? "No items found matching your search"}
          </div>
        )}
      </div>

      <div tw="h-1 w-full absolute left-0 bottom-0">
        {isFetching && !isLoading && <LinearProgress />}
      </div>
    </div>
  );
};

export default ItemCatalogGrid;
