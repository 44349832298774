import "twin.macro";

import { useState } from "react";
import { useFormContext, useWatch } from "react-hook-form";

import { Close } from "@mui/icons-material";
import { IconButton } from "@mui/material";

import { UniqueIdentifier } from "@dnd-kit/core";
import { v4 as uuidv4 } from "uuid";

import { StyledButton } from "@components/StyledComponents";
import { SortableList } from "@services/dndkit";
import { ControlledTextInput, TextInput } from "@utils/forms";

const EditableTextList = ({ listName }: { listName: string }) => {
  const { setValue, control } = useFormContext();
  const [editing, setEditing] = useState<null | UniqueIdentifier>(null);
  const [itemText, setItemText] = useState("");

  const items = useWatch({ name: listName });
  const setItems = (items) => setValue(listName, items, { shouldDirty: true });

  const handleRemove = (id: number | string) => {
    setEditing(null);
    setItems(items.filter((item) => item.id !== id));
  };

  const handleAddItem = (e) => {
    e.preventDefault();
    setItems([...items, { id: uuidv4(), value: itemText }]);
    setItemText("");
  };

  return (
    <>
      <SortableList
        onChange={setItems}
        items={items}
        renderItem={({ id, value }) => (
          <SortableList.Item
            id={id}
            className="group"
            tw="flex items-baseline rounded-lg px-2 hover:bg-neutral-50 transition-colors"
          >
            <div tw="flex w-8 flex-none justify-center items-center">
              {editing !== id && (
                <>
                  <SortableList.DragHandle tw="text-xl text-neutral-600 group-hover:block hidden" />
                  <span tw="text-xl group-hover:hidden block">&bull;</span>
                </>
              )}
            </div>
            <button tw="flex-grow text-left" onClick={() => setEditing(id)}>
              {editing === id ? (
                <ControlledTextInput
                  autoFocus
                  control={control}
                  name={`${listName}[${items.findIndex(
                    (g) => g.id === id
                  )}].value`}
                  onBlur={() => setEditing(null)}
                  tw="-ml-4"
                />
              ) : (
                <div tw="py-2">{value}</div>
              )}
            </button>
            <IconButton
              tw="group-hover:opacity-100 opacity-0"
              title="Remove"
              onClick={() => handleRemove(id)}
            >
              <Close tw="text-lg" />
            </IconButton>
          </SortableList.Item>
        )}
      />

      <form onSubmit={handleAddItem} tw="flex mt-3 gap-3">
        <div tw="flex-grow">
          <TextInput
            value={itemText}
            onChange={(e) => setItemText(e.target.value)}
          />
        </div>
        <StyledButton outlined type="submit">
          Add
        </StyledButton>
      </form>
    </>
  );
};

export default EditableTextList;
