import tw from "twin.macro";

import { Divider } from "@mui/material";

import _ from "lodash";

import { OpaqueCard, SectionTitle } from "@components/StyledComponents";
import ReactQueryTable from "@components/Table/ReactQueryTable";
import { RfqSummary } from "@features/rfqs";
import { useQuote } from "@features/quotes";
import { formatMoneyString, formatNumber } from "@utility/utilityFunctions";

const TwoCol = tw.div`grid gap-4 gap-y-2 lg:grid-cols-2`;

const LabelValuePair = ({ label, value }) => (
  <div tw="text-neutral-900 mb-2">
    <div tw="text-neutral-700 text-sm tracking-wide">{label}</div>
    <div css={[tw`text-lg`, !value && tw`opacity-40`]}>{value || "N/A"}</div>
  </div>
);

const CompletedQuote = () => {
  const { quote } = useQuote();
  const {
    supplierQuoteNumber,
    description,
    ideas,
    brandingArea,
    preProCost,
    colorOptions,
    decorationOptions,
    itemDimensions,
    moq,
    preProLeadTimeInDays,
    pricingBasedOn,
    pricingTierQuotes,
    costs,
  } = quote;

  const itemDetails = {
    "Item dimensions": itemDimensions,
    "Pricing based on": pricingBasedOn,
    "Branding area": brandingArea,
    "Color options": colorOptions,
    "Decoration options": decorationOptions,
  };

  return (
    <div
      tw="space-y-6"
      css={{
        th: tw`bg-neutral-100 text-neutral-700`,
      }}
    >
      <RfqSummary rfq={quote.requestForQuote} hidePricingTiers />
      <Divider />
      <TwoCol>
        <LabelValuePair
          label="Supplier quote number"
          value={supplierQuoteNumber}
        />
        <LabelValuePair label="MOQ" value={formatNumber(moq)} />
      </TwoCol>
      <div>
        <SectionTitle>Costs</SectionTitle>
        <OpaqueCard tw="p-0 border border-neutral-200">
          <ReactQueryTable
            rows={_.sortBy(pricingTierQuotes, "pricingTierQty")}
            columns={[
              {
                id: "pricingTierQty",
                label: "Quantity tier",
                render: formatNumber,
              },
              { id: "leadTimeInDays", label: "Lead time (days)" },
              {
                id: "cost",
                label: "Cost per Unit",
                align: "right",
                render: formatMoneyString,
              },
            ]}
          />
        </OpaqueCard>
      </div>

      {costs.length > 0 && (
        <>
          <OpaqueCard tw="p-0 border border-neutral-200">
            <ReactQueryTable
              rows={costs}
              columns={[
                {
                  id: "name",
                  label: "Set-up costs",
                },
                {
                  id: "amount",
                  label: "Total Cost",
                  align: "right",
                  render: formatMoneyString,
                },
              ]}
            />
          </OpaqueCard>
        </>
      )}

      <div>
        <SectionTitle>Item Details</SectionTitle>
        <TwoCol>
          {Object.entries(itemDetails).map(([key, val]) => (
            <LabelValuePair key={key} label={key} value={val} />
          ))}
        </TwoCol>
      </div>
      <div>
        <SectionTitle>Pre-Production</SectionTitle>
        <TwoCol>
          <LabelValuePair
            label="Pre pro. cost"
            value={preProCost && formatMoneyString(preProCost)}
          />
          <LabelValuePair
            label="Pre pro. lead time"
            value={preProLeadTimeInDays && `${preProLeadTimeInDays} days`}
          />
        </TwoCol>
      </div>
      <div>
        <SectionTitle>Notes</SectionTitle>
        <p tw="whitespace-pre-wrap text-neutral-600 mt-1">
          {description || "N/A"}
        </p>
      </div>
      <div>
        <SectionTitle>
          Ideas for Cost Savings, Enhancements or other ideas
        </SectionTitle>
        <p tw="whitespace-pre-wrap text-neutral-600 mt-1">{ideas || "N/A"}</p>
      </div>
    </div>
  );
};

export default CompletedQuote;
