import "twin.macro";

import { useState } from "react";
import { useSelector } from "react-redux";

import { ButtonGroup } from "@mui/material";

import format from "date-fns/format";
import subDays from "date-fns/subDays";
import PropTypes from "prop-types";

import { DatePicker } from "@utils/forms";
import { StyledTextInput } from "@utils/forms/ControlledInputs";

import { StyledButton } from "../StyledComponents";
import GroupAutoComplete from "../Utility/AutoCompleteFields/GroupAutoComplete";
import ProgramAutoComplete from "../Utility/AutoCompleteFields/ProgramAutoComplete";
import TerritoryAutoComplete from "../Utility/AutoCompleteFields/TerritoryAutoComplete";
import UserAutoComplete from "../Utility/AutoCompleteFields/UserAutoComplete";
import OrderTypeSelector from "../Utility/Selectors/OrderTypeSelector";
import StatusSelector from "../Utility/Selectors/StatusSelector";
import { FilterRow, MoreFiltersSection } from "./FilterComponents";

const FiltersOrderHistory = ({
  reset,
  setReset,
  handleFilters,
  sku,
  bindSku,
  orderId,
  bindOrderId,
  handleSearch,
}) => {
  const isLoading = useSelector((state) => state.globalState.isLoading);
  const categories = useSelector((state) => state.groupCategories.categories);
  const {
    groups,
    role,
    organization: { usesOnDemand, usesInventory },
  } = useSelector((state) => state.currentUser);
  const {
    dateRangeStart,
    dateRangeEnd,
    groupOrderHistoryBy,
    orderType,
    filterType,
    status: filterStatus,
  } = useSelector((state) => state.filters);

  const [type, setType] = useState(orderType || "");
  const [status, setStatus] = useState(filterStatus || "");

  const handleKeyUp = (evt) => {
    if (evt.keyCode === 13) {
      handleSearch();
    }
  };

  let userCategories =
    groups.length === 0
      ? categories
      : categories.map((cat) => {
          let currentGroups = groups.filter(
            (g) => g.groupCategoryId === cat.id
          );
          if (currentGroups.length > 0) {
            return {
              ...cat,
              groups: currentGroups,
            };
          } else return { ...cat, groups: [...cat.groups] };
        });

  return (
    <div tw="mt-3" onKeyUp={handleKeyUp}>
      <FilterRow>
        <ButtonGroup
          tw="relative"
          aria-label="order-history-grouping"
          disabled={isLoading}
          fullWidth
        >
          <label tw="text-sm absolute -top-5 text-neutral-500">
            Group History By
          </label>
          <StyledButton
            tw="w-full"
            cta={groupOrderHistoryBy === "order"}
            outlined={groupOrderHistoryBy !== "order"}
            onClick={() => {
              handleFilters("order", "groupOrderHistoryBy", "order-history");
            }}
          >
            ORDER
          </StyledButton>
          <StyledButton
            tw="w-full"
            cta={groupOrderHistoryBy !== "order"}
            outlined={groupOrderHistoryBy === "order"}
            onClick={() => {
              handleFilters("variant", "groupOrderHistoryBy", "order-history");
            }}
          >
            ITEM
          </StyledButton>
        </ButtonGroup>
        <DatePicker
          fullWidth
          color="secondary"
          disableToolbar
          variant="inline"
          format="MM/dd/yyyy"
          margin="normal"
          id="dateRangeStart"
          label="Submitted at Start"
          value={
            dateRangeStart || format(subDays(new Date(), 30), "MM/dd/yyyy")
          }
          onChange={(value) =>
            handleFilters(
              format(value, "MM/dd/yyyy"),
              "dateRangeStart",
              "order-history"
            )
          }
          disabled={isLoading}
        />

        <DatePicker
          fullWidth
          color="secondary"
          disableToolbar
          variant="inline"
          format="MM/dd/yyyy"
          margin="normal"
          id="dateRangeEnd"
          label="Submitted at End"
          value={dateRangeEnd || format(new Date(), "MM/dd/yyyy")}
          onChange={(value) =>
            handleFilters(
              format(value, "MM/dd/yyyy"),
              "dateRangeEnd",
              "order-history"
            )
          }
          disabled={isLoading}
        />
        <StyledTextInput
          name="orderId"
          type="text"
          label="Order Number"
          size="small"
          value={orderId}
          {...bindOrderId}
          disabled={isLoading}
        />

        <StyledTextInput
          name="itemNumber"
          type="text"
          label="Sku"
          size="small"
          value={sku}
          {...bindSku}
          disabled={isLoading}
        />

        <StyledButton cta onClick={handleSearch} disabled={isLoading}>
          {filterType.endsWith("report") ? "Generate report" : "Search"}
        </StyledButton>
      </FilterRow>
      <MoreFiltersSection>
        <FilterRow>
          {(usesOnDemand || usesInventory) && (
            <OrderTypeSelector
              handleOrderType={handleFilters}
              orderType={type}
              setType={setType}
              filterType={"order-history"}
            />
          )}

          <StatusSelector
            handleStatus={handleFilters}
            status={status}
            setStatus={setStatus}
            filterType={"order-history"}
            role={role}
          />

          {userCategories &&
            filterType !== "reports-franchise-order-history-report" &&
            userCategories.length > 0 &&
            userCategories.map((cat) => (
              <GroupAutoComplete
                key={cat.id}
                handleChange={handleFilters}
                reset={reset}
                setReset={setReset}
                filterType="order-history"
                categoryLabel={cat.name}
                options={cat.groups}
              />
            ))}

          <ProgramAutoComplete
            handleChange={handleFilters}
            reset={reset}
            setReset={setReset}
            filterType="order-history"
          />

          {role !== "orderer" && (
            <UserAutoComplete
              handleChange={handleFilters}
              reset={reset}
              setReset={setReset}
              filterType="order-history"
            />
          )}
          {["super", "admin"].includes(role) && (
            <TerritoryAutoComplete
              handleChange={handleFilters}
              reset={reset}
              setReset={setReset}
              filterType="order-history"
            />
          )}
        </FilterRow>
      </MoreFiltersSection>
    </div>
  );
};

FiltersOrderHistory.propTypes = {
  reset: PropTypes.bool.isRequired,
  setReset: PropTypes.func.isRequired,
  handleFilters: PropTypes.func.isRequired,
  sku: PropTypes.string.isRequired,
  bindSku: PropTypes.object.isRequired,
  orderId: PropTypes.string.isRequired,
  bindOrderId: PropTypes.object.isRequired,
  handleSearch: PropTypes.func.isRequired,
};

export default FiltersOrderHistory;
