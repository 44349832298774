import "twin.macro";

import { useSelector } from "react-redux";

import { Close } from "@mui/icons-material";

import { OpaqueCard } from "@components/StyledComponents";
import { usePromotionsQuery } from "@features/promotions";
import { LoadingButton } from "@features/ui";
import { Promotion as TPromotion } from "@models/Promotion";
import { formatUtcDate } from "@utility/utilityFunctions";

import { useCurrentOrderSet } from "../CurrentOrderSetContext";
import { useUpdateOrderSetPromotionsMutation } from "../data";

const Promotion = ({
  promo,
  applied,
}: {
  promo: TPromotion;
  applied: boolean;
}) => {
  const updateOrderSetPromotions = useUpdateOrderSetPromotionsMutation();

  const handleApply = () => {
    updateOrderSetPromotions.mutate(promo.id);
  };
  let details = "";
  if (promo.isOneTimeUse) details += "One Time Use, ";
  details += `Expires ${formatUtcDate(promo.expirationDate, "PP")}`;
  return (
    <div tw="py-2 flex justify-between items-center ">
      <div>
        <h3 tw="mb-0 text-lg text-purple-900">{promo.description}</h3>
        <p tw="text-xs text-neutral-600">{details}</p>
      </div>
      <div tw="flex gap-2 items-center">
        <LoadingButton
          variant="contained"
          size="small"
          onClick={handleApply}
          loading={updateOrderSetPromotions.isPending}
          endIcon={applied ? <Close /> : null}
        >
          {applied ? "Applied" : "Apply"}
        </LoadingButton>
      </div>
    </div>
  );
};

const useAvailablePromotions = () => {
  const userId = useSelector((state: any) => state.currentUser.id);
  const {
    orderSet: { type, promotionIds },
    orderSetVariants,
  } = useCurrentOrderSet();

  const { data = [] } = usePromotionsQuery({
    filter: {
      userIds: [userId],
      isActive: true,
      orderType: type,
    },
  });

  const availablePromotions = data.filter((promo) => {
    if (promotionIds.includes(+promo.id)) return true;

    // Don't show promotions that have already been used
    if (promo.isOneTimeUse && promo.hasRedeemed) return false;

    // Don't show promotions that are not applicable to the current order set
    if (promo.type === "item") {
      const orderSetItemIds = orderSetVariants.map(
        (osv) => osv.variant.item.id
      );
      if (promo.items.every((item) => !orderSetItemIds.includes(item.id)))
        return false;
    }
    return true;
  });

  return availablePromotions;
};

const OrderSetPromotions = () => {
  const {
    orderSet: { promotionIds },
  } = useCurrentOrderSet();

  const promotions = useAvailablePromotions();

  if (promotions.length === 0) {
    return null;
  }

  return (
    <div>
      <h2 tw="text-xl text-neutral-600 mb-2">Promotions</h2>
      <OpaqueCard tw="py-2 divide-y divide-purple-100 bg-purple-50 border border-purple-200">
        {promotions.map((promo) => (
          <Promotion
            promo={promo}
            key={promo.id}
            applied={promotionIds.includes(+promo.id)}
          />
        ))}
      </OpaqueCard>
    </div>
  );
};

export default OrderSetPromotions;
