import tw from "twin.macro";

import { useFormContext, useWatch } from "react-hook-form";

import { AddRounded, AddToPhotosRounded } from "@mui/icons-material";
import { Chip } from "@mui/material";

import { useVariantOptionsListQuery } from "@features/variantOptions";
import { VariantCategory } from "@models/VariantCategory";
import { SelectInput } from "@utils/forms";

type CategoryOptionsProps = {
  category: VariantCategory;
  savedVariantOptionIds: string[];
};

const dirty = { shouldDirty: true };

const CategoryOptions = ({
  category,
  savedVariantOptionIds,
}: CategoryOptionsProps) => {
  const { data: allVariantOptions } = useVariantOptionsListQuery({
    filter: { "variant-category-id": category.id },
  });

  const {
    setValue,
    resetField,
    formState: { dirtyFields },
  } = useFormContext();
  const formVariantKey = `variantOptions.${category.id}`;
  const variantOptionIds: string[] = useWatch({ name: formVariantKey });

  const handleAddNewVariantOption = (optionId: string) => {
    if (!allVariantOptions) return;
    let newOptions: string[] = [];
    if (optionId === "all") {
      newOptions = allVariantOptions.map(({ id }) => id);
    } else {
      newOptions = [...variantOptionIds, optionId];
    }

    setValue(formVariantKey, newOptions, dirty);
  };

  const handleRemoveNewVariantOption = (optionId: string) => {
    setValue(
      formVariantKey,
      variantOptionIds.filter((id) => id !== optionId),
      dirty
    );
  };

  const handleReset = () => resetField(formVariantKey);

  return (
    <div tw="my-2">
      <div tw="flex items-center mb-1 space-x-4">
        <h3 tw="font-medium text-neutral-700">{category.name}</h3>
        {variantOptionIds?.length > 0 && dirtyFields[formVariantKey] && (
          <button tw="text-sm text-primary-800" onClick={handleReset}>
            Reset
          </button>
        )}
      </div>
      <div tw="flex items-start justify-between gap-4">
        <div tw="flex flex-wrap gap-2">
          {allVariantOptions
            ?.filter((vo) => variantOptionIds.includes(vo.id))
            .map((vo) => (
              <Chip
                key={vo.id}
                size="small"
                label={vo.name}
                // Todo, on a completed item, only allow unsaved vos to be deleted
                onDelete={
                  savedVariantOptionIds.includes(vo.id)
                    ? undefined
                    : () => handleRemoveNewVariantOption(vo.id)
                }
              />
            ))}
        </div>
        <div tw="flex-none">
          {/* Todo: make this a popover menu. button smaller and blue */}
          <SelectInput
            size="small"
            multiple
            displayEmpty
            IconComponent={(props) => (
              <AddRounded tw="block text-lg mr-1" {...props} />
            )}
            styles={{
              "& .MuiInputBase-input": tw`text-primary-600`,
            }}
            renderValue={() => "Values "}
            disabled={!allVariantOptions}
            value={[]}
            options={[
              {
                id: "all",
                name: (
                  <b>
                    <AddToPhotosRounded tw="mr-2 text-primary-600 border-none!" />
                    Add All Options
                  </b>
                ),
              },
              ...(allVariantOptions ?? []),
            ]}
            onChange={(e) => handleAddNewVariantOption(e.target.value[0])}
            getOptionDisabled={(opt) => variantOptionIds.includes(opt.id)}
          />
        </div>
      </div>
    </div>
  );
};

export default CategoryOptions;
