import "twin.macro";

import { ControlledTextInput, intValidation } from "@utils/forms";

import Chip from "../components/Chip";
import { useFilterContext } from "../filterContext";

const Popper = ({ handleClose }) => {
  const { control } = useFilterContext();
  return (
    <ControlledTextInput
      autoFocus
      control={control}
      name="poId"
      label="Purchase Order #"
      tw="m-3 px-6"
      rules={intValidation}
      onKeyDown={(e) => e.code === "Enter" && handleClose?.()}
    />
  );
};

const FilterChip = (props) => {
  const { filterValues } = useFilterContext();
  const poId = filterValues["poId"] ?? "";

  return (
    <Chip {...props} showCloseButton={poId.length > 0}>
      {poId.length > 0 && (
        <>
          <span tw="text-neutral-600">PO ID is&nbsp;</span>
          <span tw="text-neutral-800 font-medium">{poId}</span>
        </>
      )}
      {poId.length === 0 && <span tw="text-neutral-600">Enter PO ID</span>}
    </Chip>
  );
};

const FilterPoId = {
  name: "poId",
  formControls: ["poId"],
  // alwaysShow: true,
  menuText: "PO #",
  Popper,
  Chip: FilterChip,
};

export default FilterPoId;
