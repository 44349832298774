import "twin.macro";

import { useState } from "react";
import { useForm } from "react-hook-form";

import { omit } from "lodash";

import { SectionTitle, StyledButton } from "@components/StyledComponents";
import { useOrderTypeOptions } from "@features/ordering";
import { RequestForQuote } from "@models/RequestForQuote";
import { useMutateError } from "@services/api";
import {
  ControlledDateInput,
  ControlledSelectInput,
  ControlledTextInput,
} from "@utils/forms";

import RfqAttachments from "../components/RfqAttachments";
import { useSendRfqMutation, useUpdateRfqMutation } from "../data";
import RfqQuantitiesInput from "./RfqQuantitiesInput";
import SupplierAutoComplete from "./SupplierAutocomplete";
import updatePricingTiers from "./updatePricingTiers";

const RequestForQuoteForm = ({ rfq }: { rfq: RequestForQuote }) => {
  const setMutateError = useMutateError();
  const [isLoading, setIsLoading] = useState(false);

  const sendMutation = useSendRfqMutation();
  const updateMutation = useUpdateRfqMutation();

  const { control, handleSubmit, getValues } = useForm({
    defaultValues: {
      note: rfq.note,
      pricingTiers: rfq.pricingTiers.map((tier) => tier.qty),
      suppliers: [],
      orderType: rfq.orderType,
      estimatedInMarketDate: rfq.estimatedInMarketDate,
      estimatedOrderDate: rfq.estimatedOrderDate,
      requestedDueDate: rfq.requestedDueDate,
    },
  });

  const orderTypeOptions = useOrderTypeOptions();

  const handleSaveRfq = async (data?: any) => {
    const values = data ?? getValues();
    await updateMutation
      .mutateAsync({
        ...omit(rfq, "pricingTiers"),
        ...omit(values, "suppliers", "pricingTiers"),
      })
      .catch(setMutateError);
  };

  const handleSaveTiers = async (data?: any) => {
    const values = data ?? getValues();
    const tierRes = await updatePricingTiers(
      rfq.id,
      rfq.pricingTiers,
      values.pricingTiers
    );
    if (tierRes.errors) setMutateError(tierRes.errors[0]);
    await handleSaveRfq(values);
  };

  const handleCompleteAndSend = async (data: any) => {
    setIsLoading(true);
    try {
      await handleSaveTiers(data);
      await sendMutation.mutateAsync({
        id: rfq.id,
        supplierIds: data.suppliers.map((s) => +s.id),
      });
    } catch (e) {
      console.error(e);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <form onSubmit={handleSubmit(handleCompleteAndSend)}>
      <div tw="flex flex-col gap-8">
        <div>
          <SectionTitle>Brief</SectionTitle>

          <ControlledTextInput
            name="note"
            multiline
            minRows={5}
            control={control}
            label={"Share quote instructions and notes with the suppliers *"}
            rules={{ required: true }}
            onBlur={() => handleSaveRfq()}
          />
        </div>
        <div>
          <SectionTitle>Info</SectionTitle>
          <div tw="space-y-4">
            <div tw="flex gap-6">
              <ControlledSelectInput
                label="Order Type *"
                options={orderTypeOptions}
                control={control}
                name="orderType"
              />
              <ControlledDateInput
                label="Quote Due By"
                control={control}
                name="requestedDueDate"
              />
            </div>

            <div tw="flex gap-6">
              <ControlledDateInput
                label="Est. Order Date"
                control={control}
                name="estimatedOrderDate"
              />
              <ControlledDateInput
                label="Est. In-Market Date"
                control={control}
                name="estimatedInMarketDate"
              />
            </div>
          </div>
        </div>

        <div>
          <SectionTitle>Pricing Tiers</SectionTitle>
          <RfqQuantitiesInput
            control={control}
            name="pricingTiers"
            placeholder="Quantity"
            helperText="Press ENTER to add a tier."
            label="Request Prices for the following item quantities *"
            onChange={() => handleSaveTiers()}
          />
        </div>

        <div>
          <SectionTitle>Attachments</SectionTitle>
          <RfqAttachments rfq={rfq} />
        </div>

        <div>
          <SectionTitle>Suppliers</SectionTitle>
          <SupplierAutoComplete
            control={control}
            name="suppliers"
            placeholder="Search for suppliers"
            label="Select Suppliers to send this quote request to"
          />
        </div>
        <StyledButton tw="self-start" cta loading={isLoading} type="submit">
          Complete and send
        </StyledButton>
      </div>
    </form>
  );
};

export default RequestForQuoteForm;
