import { axiosGet, axiosPatch, axiosPost } from "src/api/axiosCalls";

import { createSlice } from "@reduxjs/toolkit";

import { setError } from "../errorSlice";
import {
  setFailure as patchFailure,
  setIsLoading as patchLoading,
  patchSuccess,
} from "../patchOrderSlice";
import { buildOrderWindow, fetchOrderWindowPrograms } from "./helpers";
import { mapOrderWindow } from "./maps";

let initialState = {
  isLoading: false,
  isUpdateLoading: false,
  currentWindow: null,
  hasUpdated: false,
  error: null,
};

const orderWindowSlice = createSlice({
  name: "orderWindows",
  initialState,
  reducers: {
    setIsLoading(state) {
      state.isLoading = true;
      state.error = null;
    },
    setIsUpdateLoading(state) {
      state.isUpdateLoading = true;
    },
    getSingleOrderWindowSuccess(state, action) {
      const { orderWindow } = action.payload;
      state.currentWindow = orderWindow;
      state.isLoading = false;
      state.error = null;
    },

    createOrUpdateOrderWindowSuccess(state, action) {
      const { orderWindow } = action.payload;
      state.currentWindow = orderWindow;
      state.hasUpdated = true;
      state.isUpdateLoading = false;
      state.error = null;
    },
    setHasUpdated(state, action) {
      const { value } = action.payload;
      state.hasUpdated = value;
    },
    resetCurrentWindow(state) {
      state.currentWindow = null;
    },
    resetOrderWindows(state) {
      state.isLoading = false;
      state.isUpdateLoading = false;
      state.currentWindow = null;
      state.hasUpdated = false;
      state.error = null;
    },
    setFailure(state, action) {
      const { error } = action.payload;
      state.isLoading = false;
      state.isUpdateLoading = false;
      state.error = error;
    },
  },
});

export const {
  setIsLoading,
  setIsUpdateLoading,
  getSingleOrderWindowSuccess,
  createOrUpdateOrderWindowSuccess,
  setHasUpdated,
  resetCurrentWindow,
  resetOrderWindows,
  setFailure,
} = orderWindowSlice.actions;

export default orderWindowSlice.reducer;

export const fetchOrderWindow = (id) => async (dispatch) => {
  try {
    dispatch(setIsLoading());
    const response = await axiosGet(`/api/order-windows/${id}`);
    if (response.error) {
      throw response.error;
    }
    const programs = await fetchOrderWindowPrograms(id);
    const mappedData = mapOrderWindow(response.data, programs);
    dispatch(getSingleOrderWindowSuccess({ orderWindow: mappedData }));
  } catch (err) {
    dispatch(setFailure({ error: err.toString() }));
    dispatch(setError({ error: err.toString(), source: "Order Windows" }));
  }
};

export const createOrderWindow = (attributes) => async (dispatch) => {
  try {
    dispatch(patchLoading());
    dispatch(setIsUpdateLoading());
    const postData = buildOrderWindow(attributes);
    const response = await axiosPost("/api/order-windows", postData);
    if (response.error) {
      throw response.error;
    }
    const programs = await fetchOrderWindowPrograms(response.data.id);
    const mappedData = mapOrderWindow(response.data, programs);
    dispatch(createOrUpdateOrderWindowSuccess({ orderWindow: mappedData }));
    dispatch(patchSuccess());
  } catch (err) {
    dispatch(setFailure({ error: err.toString() }));
    dispatch(patchFailure());
    dispatch(
      setError({ error: err.toString(), source: "Order Window Create" })
    );
  }
};

export const updateOrderWindow = (attributes, id) => async (dispatch) => {
  try {
    dispatch(patchLoading());
    dispatch(setIsUpdateLoading());
    const patchData = buildOrderWindow(attributes);
    const response = await axiosPatch(`/api/order-windows/${id}`, patchData);
    if (response.error) {
      throw response.error;
    }
    const programs = await fetchOrderWindowPrograms(response.data.id);
    const mappedData = mapOrderWindow(response.data, programs);
    dispatch(createOrUpdateOrderWindowSuccess({ orderWindow: mappedData }));
    dispatch(patchSuccess());
  } catch (err) {
    dispatch(setFailure({ error: err.toString() }));
    dispatch(patchFailure());
    dispatch(
      setError({ error: err.toString(), source: "Order Window Update " })
    );
  }
};
