import { useSelector } from "react-redux";

import {
  Divider,
  FormControlLabel,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

import PropTypes from "prop-types";

import { useInput } from "../../../hooks/inputs/useInput";
import { useNumberOnlyInput } from "../../../hooks/inputs/useNumberOnlyInput";
import { OpaqueCard } from "../../StyledComponents";

const descMap = {
  reports: "Create and export various custom reports",
  "item-admin": "Manage current items and create new items",
  "program-admin":
    "Create new programs to help effectively manage marketing campaigns",
  "order-window-admin":
    "Create new pre-order windows with custom sets of items",
  "new-purchase-order":
    "Create new purchase orders from pre-orders and on demand orders",
  "purchase-order-history": "View purchasing history",
  "current-pre-orders": "Place pre-orders for active order windows",
  "order-history":
    "View order history grouped by item or order & get tracking information",
  "budget-admin": "Create and manage budgets based on organization settings",
  "promotion-admin":
    "Create and manage various promotions that can be applied while users are placing orders",
  "address-admin":
    "Manage all addresses within your organization, print address reports, and create addresses for any user or territory",
  programs: "View active programs, learn about market strategies and goals",
  "address-book":
    "View, create, and edit addresses for use while placing orders",
  "favorite-addresses":
    "View, create, and edit addresses for use while placing orders",
  "inventory-order":
    "Place orders for items that are currently available in our inventory, only available if your organization uses inventory ordering",
  "on-demand-order":
    "Place orders for items that will need to be produced for your order, only available if your organization uses on demand ordering",
  "current-items":
    "View all items that are currently available for you to order, and items that will be available to order in the future",
  "archived-items": "View items that are no longer available to order",
  budgets: "View budgets and budget history for your assigned budgets",
  help: "View helpful videos and documentation on Brandhub",
  "order-approvals":
    "Approve or deny orders, only available if your organization uses approval flows",
  "org-settings":
    "Manage organizational level settings such as allowing credit card payments, estimated shipping percentage and usage of various order flows",
  "dashboard-settings":
    "Manage dashboards for each user role in your organization",
  "user-settings": "Create, update, and view all users in your organization",
  "territory-settings":
    "Create, update, and view all territories in your organization",
  "channel-settings":
    "Create, update, and view all channels in your organization",
  "item-settings":
    "Manage what item categories and variants are available in your organization",
  "supplier-settings":
    "Create and manage suppliers for your organization, and update which users are assigned to those suppliers",
};

const useStyles = makeStyles((theme) => ({
  ...theme.global,
  detailPaper: {
    boxSizing: "border-box",
    padding: "20px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    textAlign: "center",
    border: `2px solid ${theme.palette.secondary.light}`,
    marginBottom: "15px",
  },
  inputRow: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    width: "100%",
    [theme.breakpoints.down("md")]: {
      flexDirection: "column",
    },
  },
  nameInput: {
    width: "50%",
    [theme.breakpoints.down("md")]: {
      width: "100%",
      marginBottom: "15px",
    },
  },
  positionInput: {
    width: "50%",
    marginLeft: "20px",
  },
  optionControl: {
    width: "50%",
    display: "flex",
    justifyContent: "space-between",
    [theme.breakpoints.down("md")]: {
      width: "100%",
      justifyContent: "space-around",
    },
  },
}));

const DashOption = ({ handleUpdateOption, option }) => {
  const classes = useStyles();

  const {
    organization: { usesOnDemand, usesInventory, usesApprovalFlow },
  } = useSelector((state) => state.currentUser);

  const { value: position, bind: bindPosition } = useNumberOnlyInput(
    `${option.position}`
  );
  const { value: displayName, bind: bindDisplayName } = useInput(
    option.displayName
  );

  return (
    <OpaqueCard>
      <div className={classes.inputRow}>
        <TextField
          className={classes.nameInput}
          size="small"
          variant="outlined"
          color="secondary"
          name="display-name"
          type="text"
          label="Display Name"
          {...bindDisplayName}
          disabled={
            (option.displayId === "inventory-order" && !usesInventory) ||
            (option.displayId === "on-demand-order" && !usesOnDemand) ||
            (option.displayId === "order-approvals" && !usesApprovalFlow)
          }
          onBlur={() =>
            handleUpdateOption(option.id, "displayName", displayName)
          }
        />
        <div className={classes.optionControl}>
          <TextField
            fullWidth
            className={classes.positionInput}
            size="small"
            variant="outlined"
            color="secondary"
            name="position"
            type="text"
            label="Position"
            {...bindPosition}
            disabled={
              (option.displayId === "inventory-order" && !usesInventory) ||
              (option.displayId === "on-demand-order" && !usesOnDemand) ||
              (option.displayId === "order-approvals" && !usesApprovalFlow)
            }
            onBlur={() => handleUpdateOption(option.id, "position", +position)}
          />
          <FormControlLabel
            style={{ marginLeft: "5px" }}
            control={
              <Switch
                checked={option.isActive}
                disabled={
                  (option.displayId === "inventory-order" && !usesInventory) ||
                  (option.displayId === "on-demand-order" && !usesOnDemand) ||
                  (option.displayId === "order-approvals" && !usesApprovalFlow)
                }
                onChange={() =>
                  handleUpdateOption(option.id, "isActive", !option.isActive)
                }
                name="active-switch"
              />
            }
            label={option.isActive ? "Visible" : "Not Visible"}
          />
        </div>
      </div>
      <br />
      <Divider className={classes.fullWidth} />
      <br />
      <Typography className={classes.bodyText} color="textSecondary">
        <b>Description: </b>
        {`${descMap[option.displayId]}`}
      </Typography>
    </OpaqueCard>
  );
};

DashOption.propTypes = {
  handleUpdateOption: PropTypes.func.isRequired,
  option: PropTypes.object.isRequired,
};

export default DashOption;
