import { useMemo } from "react";
import { useSelector } from "react-redux";

import _ from "lodash";

import { Item } from "@models/Item";
import { Organization } from "@models/Organization";

type AllocationValues = {
  userOnHand: number | null;
  allocationOnHand: number | null;
};

/**
 * Calculates available quantities for variants based on allocation settings
 * Quantity calculations vary by orderLimitType:
 *
 * "allocation-only":
 * - userOnHand = allocated quantity
 * - allocationOnHand = null
 *
 * "allocation-plus-global":
 * - userOnHand = allocated + unallocated quantity
 * - allocationOnHand = allocated quantity
 *
 * "allocation-only-or-global":
 * - userOnHand = allocation exists ? allocated quantity : unallocated quantity
 * - allocationOnHand = allocation exists ? allocated quantity : null
 *
 * When allocations are disabled:
 * - userOnHand = global available quantity
 * - allocationOnHand = null
 */

const useGetAllocationValues = (item: Item) => {
  const {
    id,
    currentTerritoryId,
    organization: { orderLimitType, allocationLocation },
  } = useSelector((state: any) => state.currentUser);

  return useMemo(() => {
    let totalUserOnHand = 0;
    if (item.orderType !== "inventory") {
      return {
        totalUserOnHand: null,
        variantAllocationLookup: null,
      };
    }
    const activeVariants = item.variants.filter((v) => v.isActive);
    const lookup = new Map<string, AllocationValues>();
    activeVariants.forEach((variant) => {
      if (allocationLocation) {
        const userAllocation = variant.variantAllocations?.find((allocation) =>
          allocationLocation === "user"
            ? allocation.user?.id === id
            : allocation.territory?.id === currentTerritoryId
        );
        const userAllocated = userAllocation?.availableToOrderQty ?? 0;
        const unallocated = variant.globalAvailableToOrderQty;
        let allocationValues: AllocationValues;
        switch (orderLimitType as Organization["orderLimitType"]) {
          case "allocation-only":
            allocationValues = {
              userOnHand: userAllocated,
              allocationOnHand: null,
            };
            break;
          case "allocation-plus-global":
            allocationValues = {
              userOnHand: userAllocated + unallocated,
              allocationOnHand: userAllocated,
            };
            break;
          case "allocation-only-or-global":
            allocationValues = {
              userOnHand: userAllocation ? userAllocated : unallocated,
              allocationOnHand: userAllocation ? userAllocated : null,
            };
            break;
          default:
            throw new Error(`Invalid order limit type "${orderLimitType}"`);
        }
        lookup.set(variant.id, allocationValues);
      } else {
        lookup.set(variant.id, {
          userOnHand: variant.globalAvailableToOrderQty,
          allocationOnHand: null,
        });
      }
    });

    for (const allocationValues of lookup.values()) {
      totalUserOnHand += allocationValues.userOnHand ?? 0;
    }
    return {
      totalUserOnHand,
      variantAllocationLookup: lookup,
      usesAllocations: !!allocationLocation,
    };
  }, [allocationLocation, id, currentTerritoryId, orderLimitType, item]);
};

export default useGetAllocationValues;
