import "twin.macro";

import { History } from "@mui/icons-material";
import { IconButton } from "@mui/material";

import { format } from "date-fns";

import { OpaqueCard } from "@components/StyledComponents";
import ReactQueryTable, {
  PaginatedResourceProps,
} from "@components/Table/ReactQueryTable";
import SearchableText from "@components/Table/SearchableText";
import { BudgetWithCalcs } from "@models/Budget";
import { formatMoneyString, utcDate } from "@utility/utilityFunctions";

type BudgetTableProps = PaginatedResourceProps & {
  rows: BudgetWithCalcs[];
  query?: string;
  onHistoryButtonClick?: (row: BudgetWithCalcs) => void;
  onRowClick?: (row: BudgetWithCalcs) => void;
};

const asDate = (date: string) => format(utcDate(date), "MM/dd/yyyy");

const BudgetTable = ({
  query,
  onHistoryButtonClick,
  ...tableProps
}: BudgetTableProps) => {
  return (
    <OpaqueCard tw="p-0 overflow-hidden relative">
      <ReactQueryTable
        columns={[
          { id: "id", label: "ID" },
          {
            id: "name",
            label: "Name",
            render: (name) => <SearchableText query={query} text={name} />,
          },
          { id: "startDate", label: "Start Date", render: asDate },
          { id: "expirationDate", label: "Expiration Date", render: asDate },
          {
            id: "totalAmount",
            label: "Budget Total",
            align: "right",
            render: formatMoneyString,
          },
          {
            id: "pendingSpend",
            label: "Total Pending",
            align: "right",
            render: formatMoneyString,
          },
          {
            id: "spend",
            label: "Total Spent",
            align: "right",
            render: formatMoneyString,
          },
          {
            id: "pendingBalance",
            label: "Pending Balance",
            align: "right",
            render: formatMoneyString,
          },
          {
            id: "currentLevel",
            label: "Balance",
            align: "right",
            render: formatMoneyString,
          },
          {
            id: "isActive",
            label: "Status",
            render: (b) => (b ? "Active" : "Inactive"),
          },
          ...(onHistoryButtonClick
            ? [
                {
                  id: "history",
                  label: "History",
                  render: (_, b) => (
                    <IconButton
                      onClick={(e) => {
                        e.stopPropagation();
                        onHistoryButtonClick(b);
                      }}
                    >
                      <History />
                    </IconButton>
                  ),
                },
              ]
            : []),
        ]}
        {...tableProps}
      />
    </OpaqueCard>
  );
};

export default BudgetTable;
