import { pdf } from "@react-pdf/renderer";

import ProgramItemPDF from "@components/PDF/ProgramItemPDF";
import { historicalOrderReport } from "@features/orderHistory";
import { purchaseOrderHistoryReport } from "@features/purchaseOrders";
import quotesSchema from "@features/quotes/reportQuotesSchema";
import { formatMoneyString, upCase } from "@utility/utilityFunctions";

import getAssignedToValue from "../budgets/getAssignedToValue";
import { variantName } from "../items/helpers";
import calculateOrderTotal from "../orderHistory/calculateOrderTotal";
import displayDate from "./utils/displayDate";
import schemaToCsvReport from "./utils/schemaToCsvReport";

const insertIf = (condition, value) => (condition ? [value] : []);

const reportDefinitions = (organization, categories, _role) => {
  const reports = {
    "order-history": schemaToCsvReport([
      { id: "id", label: "Order #" },
      { id: "type", label: "Order Type" },
      { id: "user.name", label: "Ordered By" },
      { id: "address.name", label: "Address Name" },
      { id: "address.streetAddress1", label: "Address Line 1" },
      { id: "address.streetAddress2", label: "Address Line 2" },
      { id: "attn", label: "Attn" },
      { id: "address.city", label: "City" },
      {
        id: "state",
        label: "State",
        f: (_, row) => row.address?.state?.code ?? row.address?.region,
      },
      { id: "address.zip", label: "Zip Code" },
      { id: "address.phoneNumber", label: "Phone Number" },
      { id: "address.country.name", label: "Country" },
      { id: "programName", label: "Program" },
      { id: "orderWindowName", label: "Order Window" },
      { id: "submittedAt", label: "Order Date", f: displayDate },
      { id: "approvedAt", label: "Approved Date", f: displayDate },
      {
        id: "expeditedDate",
        label: "Requested Date",
        f: displayDate,
      },
      { id: "totalQuantity", label: "Total Qty" },
      {
        id: "totalPromotionDiscount",
        label: "Promotion Discount",
        f: formatMoneyString,
      },
      { id: "totalPrice", label: "Extended Total", f: formatMoneyString },
      {
        id: "orderTotal",
        label: "Order Total",
        f: (_, order) =>
          formatMoneyString(calculateOrderTotal(order, organization)),
      },
      { id: "checkoutType", label: "Payment Type", f: upCase },
      ...insertIf(organization.budgetLocation, {
        id: "budgetNames",
        label: "Budget",
      }),
      { id: "territoryName", label: "Territory" },
      ...insertIf(organization.usesCostCenters, {
        id: "costCenterName",
        label: "Cost Center",
      }),
      { id: "status", label: "Status" },
    ]),

    "historical-orders": schemaToCsvReport(historicalOrderReport),

    "order-history-variant": schemaToCsvReport([
      { id: "order.id", label: "Order #" },
      { id: "poId", label: "PO #" },
      { id: "orderType", label: "Order Type" },
      { id: "variant.variantSku", label: "Sku" },
      { id: "variant.externalWarehouseId", label: "External ID" },
      { id: "variant.item.name", label: "Item name" },
      {
        id: "variant",
        label: "Variant",
        f: variantName,
      },
      { id: "orderUserName", label: "Ordered By" },
      ...categories.map((c) => ({
        id: c.id,
        label: c.name,
        f: (_, row) =>
          row.variant.item.groups.find(
            (group) => group.groupCategory.id === c.id
          )?.name,
      })),
      { id: "addressName", label: "Address Name" },
      { id: "streetAddress1", label: "Address Line 1" },
      { id: "streetAddress2", label: "Address Line 2" },
      { id: "orderAttn", label: "Attn" },
      { id: "city", label: "City" },
      {
        id: "state",
        label: "State",
        f: (_, row) => row.state ?? row.region,
      },
      { id: "zip", label: "Zip Code" },
      { id: "phoneNumber", label: "Phone Number" },
      { id: "country", label: "Country" },

      { id: "programName", label: "Program" },
      { id: "orderWindowName", label: "Order Window" },
      { id: "qty", label: "Total Qty" },
      { id: "price", label: "Price", f: formatMoneyString },
      { id: "totalPrice", label: "Extended Price", f: formatMoneyString },
      {
        id: "promotionDiscount",
        label: "Promotion Discount",
        f: formatMoneyString,
      },
      { id: "orderCheckoutType", label: "Payment Type", f: upCase },
      ...insertIf(organization.budgetLocation, {
        id: "budgetName",
        label: "Budget",
      }),
      { id: "territoryName", label: "Territory" },
      ...insertIf(organization.usesCostCenters, {
        id: "costCenterName",
        label: "Cost Center",
      }),
      { id: "orderSubmittedAt", label: "Order Date", f: displayDate },
      { id: "orderApprovedAt", label: "Approved Date", f: displayDate },
      { id: "actualShipDate", label: "Act. Ship Date", f: displayDate },
      {
        id: "orderExpeditedDate",
        label: "Requested Date",
        f: displayDate,
      },
      { id: "trackingData.carrier", label: "Carrier" },
      { id: "trackingData.number", label: "Tracking" },
      { id: "orderStatus", label: "Status" },
    ]),
    addresses: schemaToCsvReport([
      { id: "id", label: "ID" },
      { id: "userName", label: "User" },
      { id: "name", label: "Name" },
      { id: "streetAddress1", label: "Street Address One" },
      { id: "streetAddress2", label: "Street Address Two" },
      { id: "defaultAttn", label: "Attention" },
      { id: "city", label: "City" },
      { id: "state.code", label: "State" },
      { id: "region", label: "Region" },
      { id: "zip", label: "Zip Code" },
      { id: "phoneNumber", label: "Phone Number" },
      { id: "country.name", label: "Country" },
    ]),
    budgets: schemaToCsvReport([
      { id: "id", label: "ID" },
      { id: "name", label: "Name" },
      { id: "startDate", label: "Start Date", f: displayDate },
      { id: "expirationDate", label: "Expiration Date", f: displayDate },
      {
        id: "totalAmount",
        label: "Budget Total",
        f: formatMoneyString,
      },
      {
        id: "pendingSpend",
        label: "Total Pending",
        f: formatMoneyString,
      },
      {
        id: "spend",
        label: "Total Spent",
        f: formatMoneyString,
      },
      {
        id: "pendingBalance",
        label: "Pending Balance",
        f: formatMoneyString,
      },
      {
        id: "currentLevel",
        label: "Balance",
        f: formatMoneyString,
      },
      {
        id: "assignedTo",
        label: "Assigned To",
        f: (_, budget) =>
          getAssignedToValue(budget, organization.budgetLocation),
      },
      {
        id: "isActive",
        label: "Status",
        f: (b) => (b ? "Active" : "Inactive"),
      },
    ]),
    "purchase-order-rollup": schemaToCsvReport([
      { id: "variant.variantSku", label: "Sku" },
      { id: "variant.externalWarehouseId", label: "External ID" },
      { id: "variant.item.name", label: "Item Name" },
      { id: "variant", label: "Variant", f: variantName },
      {
        id: "supplierName",
        label: "Supplier",
      },
      {
        id: "territoryNames",
        label: "Territory",
        f: (t) => t.join(", "),
      },
      {
        id: "orderProgramName",
        label: "Program",
      },
      {
        id: "orderWindowName",
        label: "Order Window",
      },
      ...categories.map((c) => ({
        id: c.id,
        label: c.name,
        f: (_, row) =>
          row.variant.item.groups.find(
            (group) => group.groupCategory.id === c.id
          )?.name,
      })),
      {
        id: "totalOrdered",
        label: "Total Ordered",
      },
      {
        id: "minimumOrderQuantity",
        label: "MOQ",
      },
      {
        id: "cost",
        label: "Cost",
        align: "right",
        f: formatMoneyString,
      },
      {
        id: "estimatedTotal",
        label: "Total Cost",
        align: "right",
        f: formatMoneyString,
      },
      ...insertIf(organization.usesItemCustomization, {
        id: "customization",
        label: "Customization",
      }),
      {
        id: "inMarketDate",
        label: "In Market Date",
        f: displayDate,
      },
    ]),

    "order-set-approval": schemaToCsvReport([
      {
        id: "id",
        label: "Order Set #",
      },
      {
        id: "type",
        label: "Order Type",
      },
      {
        id: "user.name",
        label: "User",
        sort: "user-name",
      },
      {
        id: "programName",
        label: "Program",
        sort: "program-name",
      },
      { id: "territory.name", label: "Territory" },
      { id: "stateNames", label: "States" },
      ...insertIf(organization.budgetLocation, {
        id: "budgetName",
        label: "Budget",
      }),
      ...insertIf(organization.usesCostCenters, {
        id: "costCenterName",
        label: "Cost Center",
      }),
      {
        id: "totalPrice",
        label: "Total Price",
        f: formatMoneyString,
      },
      {
        id: "submittedAt",
        label: "Order Date",
        f: displayDate,
      },
      {
        id: "status",
        label: "Status",
        f: (status) => upCase(status, "-"),
      },
    ]),

    "order-set-variant-summary-approval": schemaToCsvReport([
      { id: "orderSet.id", label: "Set #" },
      { id: "variant.variantSku", label: "Sku" },
      { id: "variant.externalWarehouseId", label: "External ID" },
      { id: "variant.item.name", label: "Item Name" },
      {
        id: "variant",
        label: "Variant",
        f: variantName,
      },
      { id: "orderSetType", label: "Order Type" },
      { id: "orderWindowName", label: "Order Window" },
      { id: "userName", label: "Ordered By" },
      { id: "orderSetType", label: "Order Type" },
      { id: "programName", label: "Program" },
      { id: "territoryName", label: "Territory" },
      { id: "stateNames", label: "State(s)" },
      ...insertIf(organization.budgetLocation, {
        id: "budgetName",
        label: "Budget",
      }),
      ...insertIf(organization.usesCostCenters, {
        id: "costCenterName",
        label: "Cost Center",
      }),
      {
        id: "price",
        label: "Price",
        align: "right",
        f: formatMoneyString,
      },
      { id: "totalVariantQty", label: "Qty", align: "right" },
      {
        id: "totalPrice",
        label: "Total Price",
        align: "right",
        f: formatMoneyString,
      },
      {
        id: "orderSetSubmittedAt",
        label: "Order Date",
        f: displayDate,
      },
      {
        id: "orderSetStatus",
        label: "Status",
        f: (status) => status && upCase(status, "-"),
      },
    ]),
    "purchase-order-history": purchaseOrderHistoryReport(
      organization,
      categories
    ),
    "items-pdf": {
      fileExtension: "pdf",
      onDownload: (data) =>
        pdf(
          ProgramItemPDF({
            programName: "Item Catalog",
            items: data,
            orgLogo: organization.logoUrl,
            categories: categories,
          })
        ).toBlob(),
    },
    users: schemaToCsvReport([
      { id: "id", label: "ID" },
      { id: "name", label: "Name" },
      { id: "email", label: "Email" },
      { id: "role", label: "Role", f: (role) => upCase(role, "-") },
      {
        id: "territories",
        label: "Territories",
        f: (territories, user) =>
          territories
            .filter(
              (territory) => territory.organizationId === user.organizationId
            )
            .map((territory) => territory.name)
            .join(", "),
      },
    ]),
    quotes: schemaToCsvReport(quotesSchema),
  };

  return reports;
};

export default reportDefinitions;
