import React from "react";
import Helmet from "react-helmet";

type DocTitleProps = {
  title: String;
  children?: React.ReactNode;
};

const DocTitle: React.FC<DocTitleProps> = ({ title, children }) => {
  return (
    <Helmet>
      <title>{title} | brandhub</title>
      {children}
    </Helmet>
  );
};

export default DocTitle;
