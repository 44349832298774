import tw from "twin.macro";

import { ControllerProps, useFormContext, useWatch } from "react-hook-form";

import { HelperTextLabel, InputPlaceholder } from "@features/ui";
import { utcDate } from "@utility/utilityFunctions";
import {
  ControlledDateInput,
  ControlledTextInput,
  externalIdValidation,
} from "@utils/forms";

import BlockCard from "../../../ui/BlockCard";
import { useIsPublished, useValidateUniqueName } from "../../helpers";
import { FormVariant } from "../../types";

const TwoCol = tw.div`flex flex-col sm:flex-row gap-4`;

const MainBlock = () => {
  const { control, getValues, setFocus } = useFormContext();
  const reg = (name: string, rules?: ControllerProps["rules"]) => ({
    control,
    name,
    rules,
  });

  const visibleStartDate = useWatch({ name: "visibleStartDate" });
  const orderableStartDate = useWatch({ name: "orderableStartDate" });
  const isPublished = useIsPublished();

  const isSingleDefaultVariant = useWatch({
    name: "variants.default.isActive",
  });

  useValidateUniqueName();

  const handleSelectVariantExternalId = () => {
    // find first variant
    const variants = getValues("variants") as Record<string, FormVariant>;
    const firstVariant = Object.entries(variants).find(
      ([_key, v]) => !v.isDefault && v.orderPosition === 0
    );
    if (!firstVariant) return;
    setFocus(`variants.${firstVariant[0]}.externalWarehouseId`, {
      shouldSelect: true,
    });
  };

  return (
    <BlockCard tw="space-y-4">
      <ControlledTextInput label="Name" {...reg("name", { required: true })} />
      <ControlledTextInput
        multiline
        minRows={2}
        maxRows={8}
        label="Description"
        {...reg("description")}
      />
      <TwoCol>
        <ControlledTextInput disabled label="SKU" {...reg("sku")} />
        {isSingleDefaultVariant && (
          <ControlledTextInput
            label={
              <HelperTextLabel title="When provided, the External ID is used as the primary SKU for the item instead of the brandhub-generated SKU.">
                External ID
              </HelperTextLabel>
            }
            {...reg(
              "variants.default.externalWarehouseId",
              externalIdValidation
            )}
          />
        )}
        {!isSingleDefaultVariant && (
          <InputPlaceholder label="External ID">
            Manage{" "}
            <a
              href="#variants"
              tw="underline text-primary-800"
              onClick={handleSelectVariantExternalId}
            >
              Variant External IDs
            </a>
          </InputPlaceholder>
        )}
      </TwoCol>
      <TwoCol>
        <ControlledDateInput
          tw="w-full"
          label="Visible Start Date *"
          {...reg("visibleStartDate", { required: isPublished })}
        />
        <ControlledDateInput
          tw="w-full"
          label="Visible End Date (optional)"
          minDate={utcDate(visibleStartDate)}
          {...reg("visibleEndDate")}
        />
      </TwoCol>
      <p tw="text-neutral-500 mt-1! text-sm">
        When should the item be visible from the Item Catalog?
      </p>
      <TwoCol>
        <ControlledDateInput
          tw="w-full"
          label="Orderable Start Date *"
          {...reg("orderableStartDate", { required: isPublished })}
        />
        <ControlledDateInput
          tw="w-full"
          label="Orderable End Date (optional)"
          minDate={utcDate(orderableStartDate)}
          {...reg("orderableEndDate")}
        />
      </TwoCol>
      <p tw="text-neutral-500 mt-1! text-sm">
        When should the item be orderable?
      </p>
    </BlockCard>
  );
};

export default MainBlock;
