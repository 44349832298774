import "twin.macro";

import { useLocation, useRouteError } from "react-router";

import { Contained, StyledButton } from "@components/StyledComponents";
import DocTitle from "@utility/DocTitle";

import FourOhFour from "./FourOhFour";

type ErrorPageProps = {
  error?: Error;
};

const ErrorPage = ({ error: providedError }: ErrorPageProps) => {
  const routeError = useRouteError() as any;
  const location = useLocation();

  const error = providedError ?? routeError;

  error && console.error(error);

  if (error?.status === 404) return <FourOhFour />;

  return (
    <>
      <DocTitle title={"Error"} />
      <Contained>
        <h1 tw="text-6xl text-neutral-300 font-bold">error.</h1>
        <h2 tw="text-4xl text-neutral-600 font-bold">
          Something unexpected happened.
        </h2>
        <p tw="text-lg max-w-prose mt-2">
          If this error persists, please contact support and we'll get it
          resolved.
        </p>
        {error && (
          <pre tw="mt-2 p-3 rounded-lg bg-neutral-200 shadow-inner whitespace-pre-line">
            {error?.message ?? error}
          </pre>
        )}
        <StyledButton tw="mt-6" cta component="a" href={location.pathname}>
          Try again
        </StyledButton>
      </Contained>
    </>
  );
};

export default ErrorPage;
