import { sumBy } from "lodash";

import { PurchaseOrder } from "@models/PurchaseOrder";

export const calcTotalCostPurchaseOrder = (po: PurchaseOrder) => {
  const totalPurchaseOrderVariantCost = sumBy(
    po.purchaseOrderVariants,
    (pov) => parseFloat(pov.actualCost) * pov.qty
  );

  const totalTax = sumBy(po.shippingParameters, (sp) =>
    sumBy(sp.shippingParameterVariants, (spv) => parseFloat(spv.tax ?? "0.0"))
  );

  const totalFreight = po.totalFreightCost
    ? parseFloat(po.totalFreightCost)
    : 0;

  return totalPurchaseOrderVariantCost + totalTax + totalFreight;
};
