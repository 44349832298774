import "twin.macro";

import React from "react";

import { Typography } from "@mui/material";

import { StyledFormLabel } from "@utils/forms/ControlledInputs";

// This component is meant to be the same size/style as a disabled text input. Used for displaying instructional placeholder content.

const InputPlaceholder = ({
  label,
  children,
}: {
  label: React.ReactNode;
  children: React.ReactNode | React.ReactNode[];
}) => {
  return (
    <div tw="w-full inline-flex flex-col">
      <StyledFormLabel>{label}</StyledFormLabel>
      <div tw="px-2 py-2 border border-solid rounded shadow-sm bg-neutral-100 border-neutral-200 text-neutral-500">
        <Typography noWrap tw="text-base leading-[1.4375em]">
          {children}
        </Typography>
      </div>
    </div>
  );
};

export default InputPlaceholder;
