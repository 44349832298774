import React, { useEffect } from "react";
import { useDispatch } from "react-redux";

import { CircularProgress } from "@mui/material";

import { ResourceError } from "@features/errors";
import { clearOrderVariantErrors } from "@redux/slices/orders/orderSetSlice";

import {
  CurrentOrderSetContext,
  OrderSetContextType,
} from "./CurrentOrderSetContext";
import { useOrderSet } from "./useOrderSet";
import useOrderSetId from "./useOrderSetId";

const CurrentOrderSetContextProvider = ({
  children,
  fallback,
  onOrderSetDelete,
}: {
  children: React.ReactNode;
  fallback?: React.JSX.Element;
  onOrderSetDelete: OrderSetContextType["onOrderSetDelete"];
}) => {
  const dispatch = useDispatch();
  const orderSetId = useOrderSetId();
  const orderSetData = useOrderSet(orderSetId);

  useEffect(() => {
    // Clear order variant errors when the orders are fetched
    if (!orderSetData.ordersQuery?.isFetching) {
      console.log("Clearing order variant errors");
      dispatch(clearOrderVariantErrors());
    }
  }, [dispatch, orderSetData.ordersQuery?.isFetching]);

  if (orderSetData.isLoading || orderSetData.isPending) {
    return fallback || <CircularProgress />;
  }

  if (orderSetData.error) {
    return <ResourceError error={orderSetData.error} />;
  }

  return (
    <CurrentOrderSetContext.Provider
      value={{
        orderSetId,
        orderSet: orderSetData.orderSet,
        orders: orderSetData.orders,
        orderSetVariants: orderSetData.orderSetVariants,
        isFetching: Boolean(
          orderSetData.orderSetQuery?.isFetching ||
            orderSetData.ordersQuery?.isFetching
        ),
        onOrderSetDelete,
      }}
    >
      {children}
    </CurrentOrderSetContext.Provider>
  );
};

export default CurrentOrderSetContextProvider;
