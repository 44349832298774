/**
 * We have a reoccuring problem with CSV files where there is an extra row at the end of the file that is just whitespace.
 * This function will remove any bad rows of data that are missing any required columns.
 */
export function csvWhitespaceRowRemover(
  data: any[],
  requiredColumns: string[]
): any[] {
  return data.filter((row) => requiredColumns.some((col) => row[col]));
}
