import "twin.macro";

import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import { CheckCircle, RadioButtonUnchecked } from "@mui/icons-material";
import {
  Box,
  Button,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Menu,
  Radio,
} from "@mui/material";
import { DateCalendar } from "@mui/x-date-pickers";

import { addDays, addMonths, isSameDay } from "date-fns";
import { utcToZonedTime } from "date-fns-tz";

import { useCanEditOrder } from "@features/orders";
import { FaIcon } from "@features/ui";
import { Order } from "@models/Order";
import { formatUtcDate, utcDate } from "@utility/utilityFunctions";

import { useUpdateOrder } from "../data";
import { isDeliveryDateValid } from "./isDeliveryDateValid";

const DeliverySelectionDropdown = ({ order }: { order: Order }) => {
  const updateOrderMutation = useUpdateOrder();
  const isTitos = useSelector(
    (state: any) => state.currentUser.organization.name === "Tito's"
  );
  const [anchorEl, setAnchorEl] = useState<Element | null>(null);
  const [deliveryDate, setDeliveryDate] = useState<Date | null>(null);
  const [canDeliverEarly, setCanDeliverEarly] = useState(true);
  // Convert the current time to EST
  const nowEST = utcToZonedTime(new Date(), "America/New_York");
  const defaultExpeditedDeliveryDate = addDays(nowEST, 2);

  const canEdit = useCanEditOrder(order);

  const standardDeliveryText = isTitos
    ? "Ground Delivery"
    : "5-7 business days";

  const prettyDate = formatUtcDate(deliveryDate, "PP");

  const shouldDisableDate = (date: Date) => !isDeliveryDateValid(date, nowEST);

  const handleClose = () => {
    setAnchorEl(null);
    const updates: any = {};
    if (
      (!deliveryDate && !order.expeditedDate) || // both are null
      (deliveryDate &&
        ((order.expeditedDate &&
          isSameDay(deliveryDate, utcDate(order.expeditedDate))) || //both are the same date
          shouldDisableDate(deliveryDate))) // selected date is disabled
    ) {
      // ignore change
      setDeliveryDate(
        order.expeditedDate ? utcDate(order.expeditedDate) : null
      );
    } else {
      updates.expeditedDate = deliveryDate;
      updates.isExpedited = !!deliveryDate;
    }

    if (canDeliverEarly !== order.canDeliverEarly) {
      updates.canDeliverEarly = canDeliverEarly;
    }

    if (Object.keys(updates).length > 0) {
      updateOrderMutation.mutate({
        id: order.id,
        ...updates,
      });
    }
  };

  useEffect(() => {
    setDeliveryDate(order.expeditedDate ? utcDate(order.expeditedDate) : null);
    setCanDeliverEarly(order.canDeliverEarly);
  }, [order]);

  return (
    <div>
      <Button
        variant="text"
        tw="text-left px-4! -mx-4 -my-1"
        endIcon={<FaIcon icon="chevron-down" tw="text-sm! ml-3" />}
        size="small"
        onClick={(e) => setAnchorEl(e.target as any)}
        disabled={!canEdit}
      >
        {deliveryDate ? (
          <ListItemText
            primary={`RAD (${canDeliverEarly ? "by " : ""}${prettyDate})`}
            secondary={
              order.status !== "approved" && shouldDisableDate(deliveryDate) ? (
                <span tw="text-red-700">Not available</span>
              ) : (
                "Requested Arrival Date"
              )
            }
          />
        ) : (
          <ListItemText
            primary="Standard Delivery"
            secondary={standardDeliveryText}
          />
        )}
      </Button>
      {anchorEl && (
        <Menu
          open
          anchorEl={anchorEl}
          onClose={handleClose}
          slotProps={{
            paper: { sx: { maxWidth: "400px" } },
          }}
        >
          <ListItemButton
            onClick={() => setDeliveryDate(null)}
            selected={!deliveryDate}
          >
            <ListItemIcon tw="min-w-10">
              {deliveryDate && <RadioButtonUnchecked />}
              {!deliveryDate && <CheckCircle tw="text-primary-600" />}
            </ListItemIcon>
            <ListItemText
              primary="Standard Delivery"
              secondary={standardDeliveryText}
            />
          </ListItemButton>
          <Box
            component={deliveryDate ? ListItem : ListItemButton}
            tw="flex-col items-stretch -mb-2"
            onClick={() =>
              !deliveryDate &&
              setDeliveryDate(deliveryDate || defaultExpeditedDeliveryDate)
            }
          >
            <div tw="flex items-center">
              <ListItemIcon tw="min-w-10">
                {!deliveryDate && <RadioButtonUnchecked />}
                {deliveryDate && <CheckCircle tw="text-primary-600" />}
              </ListItemIcon>
              <ListItemText primary="RAD" secondary="Requested Arrival Date" />
            </div>
            {deliveryDate && (
              <>
                <div tw="bg-primary-50 text-primary-900 text-sm rounded-lg p-2 -mx-2">
                  Order before 2pm EST for next day delivery.
                </div>
                <div tw="text-neutral-600 text-sm mt-2">
                  Select a delivery date.
                </div>
                <DateCalendar
                  tw="bg-white rounded-lg"
                  value={deliveryDate}
                  onChange={(v) => setDeliveryDate(v)}
                  disablePast
                  minDate={nowEST}
                  maxDate={addMonths(nowEST, 3)}
                  shouldDisableDate={shouldDisableDate}
                />
              </>
            )}
          </Box>
          {deliveryDate && (
            <>
              <ListItemButton
                onClick={() => setCanDeliverEarly(true)}
                selected={canDeliverEarly}
                tw="items-start"
              >
                <ListItemIcon tw="min-w-10">
                  <Radio checked={canDeliverEarly} tw="-mx-2" />
                </ListItemIcon>
                <ListItemText
                  primary={
                    <span>
                      Order <b>May</b> Be Delivered Before RAD
                    </span>
                  }
                  secondary={`The warehouse will select a service level that will ensure the order is delivered by ${prettyDate}, at the latest`}
                />
              </ListItemButton>
              <ListItemButton
                onClick={() => setCanDeliverEarly(false)}
                selected={!canDeliverEarly}
                tw="items-start"
              >
                <ListItemIcon tw="min-w-10">
                  <Radio checked={!canDeliverEarly} tw="-mx-2" />
                </ListItemIcon>
                <ListItemText
                  primary={
                    <span>
                      Order <b>Cannot Be</b> Delivered Before RAD
                    </span>
                  }
                  secondary={`The warehouse will select a service level that will guarantee the order is delivered on ${prettyDate}`}
                />
              </ListItemButton>
            </>
          )}
        </Menu>
      )}
    </div>
  );
};

export default DeliverySelectionDropdown;
