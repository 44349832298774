import tw from "twin.macro";

import { TableCell, TableHead, TableRow } from "@mui/material";

import PropTypes from "prop-types";

const StandardTableHead = ({
  headCells,
  alignCells = [],
  lastCellRightAlign = false,
}) => (
  <TableHead
    css={{
      th: tw`py-3 text-xs tracking-wider uppercase whitespace-nowrap text-neutral-500`,
    }}
  >
    <TableRow>
      {headCells.map((headCell, i) => {
        const Component = headCell?.Component;
        const cellText = headCell.label ?? headCell;
        return (
          <TableCell
            key={i}
            align={
              headCell.align ||
              alignCells[i] ||
              (lastCellRightAlign && i === headCells.length - 1
                ? "right"
                : "left")
            }
          >
            {Component || cellText.toString()}
          </TableCell>
        );
      })}
    </TableRow>
  </TableHead>
);

StandardTableHead.propTypes = {
  headCells: PropTypes.array.isRequired,
  alignCells: PropTypes.array,
  lastCellRightAlign: PropTypes.bool,
};

export default StandardTableHead;
