import "twin.macro";

import { Contained } from "@components/StyledComponents";
import {
  OrderSetNavigation,
  OrderSetOverview as OrderSetOverviewContent,
} from "@features/ordering";

const OrderSetOverview = () => {
  return (
    <Contained tw="pb-6 max-w-screen-xl space-y-6">
      <OrderSetNavigation stage="overview" />
      <OrderSetOverviewContent />
    </Contained>
  );
};

export default OrderSetOverview;
