import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { useUserCostCentersQuery } from "@features/costCenters";
import { setError } from "@redux/slices/errorSlice";

const useDefaultCostCenter = ({ warn }) => {
  const dispatch = useDispatch();

  const { usesCostCenters } = useSelector(
    (state: any) => state.currentUser.organization
  );

  const { data: costCenters = [], isLoading: areCostCentersLoading } =
    useUserCostCentersQuery();

  const [costCenterId, setCostCenterId] = useState<string | null>(null);

  useEffect(() => {
    if (areCostCentersLoading || !usesCostCenters) return;
    if (costCenters[0]?.id) setCostCenterId(costCenters[0].id);
    else {
      warn &&
        dispatch(
          setError({
            error:
              "You currently do not have any Cost Centers assigned to you, you will need a Cost Center to start an order. Please contact your administrator.",
            source: "Order Create",
          })
        );
    }
  }, [areCostCentersLoading, usesCostCenters, costCenters, dispatch, warn]);
  return costCenterId;
};

export default useDefaultCostCenter;
