import { createSlice } from "@reduxjs/toolkit";

import { axiosPost } from "../../../api/axiosCalls";
import { setError } from "../errorSlice";
import {
  setFailure as patchFailure,
  setIsLoading as patchLoading,
  patchSuccess,
} from "../patchOrderSlice";
import { buildBulkBudgets } from "./helpers";

let initialState = {
  isLoading: false,
  isNextLoading: false,
  isUpdateLoading: false,
  nextLink: null,
  budgets: [],
  budgetsHistory: [],
  budgetReport: [],
  currentBudget: null,
  triggerCSVDownload: false,
  hasUpdated: false,
  error: null,
};

const budgetSlice = createSlice({
  name: "budgets",
  initialState,
  reducers: {
    setIsLoading(state) {
      state.isLoading = true;
      state.error = null;
    },
    setIsNextLoading(state) {
      state.isNextLoading = true;
    },
    setIsUpdateLoading(state) {
      state.isUpdateLoading = true;
    },
    getBudgetSuccess(state, action) {
      const { budget } = action.payload;
      state.currentBudget = budget;
      state.isLoading = false;
      state.error = null;
    },
    createOrUpdateBudgetSuccess(state, action) {
      const { budget } = action.payload;
      let currentBudget = state.budgets.find((b) => b.id === budget.id);
      state.budgets = !currentBudget
        ? state.budgets.concat(budget)
        : state.budgets.map((b) => {
            if (b.id === budget.id) return budget;
            else return b;
          });
      state.currentBudget = budget;
      state.isUpdateLoading = false;
      state.hasUpdated = true;
      state.error = null;
    },
    uploadBulkBudgetsSuccess(state) {
      state.isUpdateLoading = false;
      state.hasUpdated = true;
      state.error = null;
    },
    setHasUpdated(state, action) {
      const { value } = action.payload;
      state.hasUpdated = value;
    },
    setTriggerCSVFalse(state) {
      state.triggerCSVDownload = false;
    },
    resetCurrentBudget(state) {
      state.currentBudget = null;
    },
    setFailure(state, action) {
      const { error } = action.payload;
      state.isLoading = false;
      state.isNextLoading = false;
      state.isUpdateLoading = false;
      state.error = error;
    },
  },
});

export const {
  setIsLoading,
  setIsNextLoading,
  setIsUpdateLoading,
  getBudgetSuccess,
  createOrUpdateBudgetSuccess,
  uploadBulkBudgetsSuccess,
  setHasUpdated,
  setTriggerCSVFalse,
  resetCurrentBudget,
  setFailure,
} = budgetSlice.actions;

export default budgetSlice.reducer;

export const uploadBulkBudgets =
  (bulkBudgets, callback) => async (dispatch) => {
    try {
      dispatch(patchLoading());
      const body = buildBulkBudgets(bulkBudgets);
      const res = await axiosPost(`/api/budgets/import`, body);
      if (res.error) throw res.error;
      dispatch(uploadBulkBudgetsSuccess());
      dispatch(patchSuccess());
    } catch (err) {
      console.error(err);
      dispatch(setFailure({ error: err.toString() }));
      dispatch(
        setError({ error: err.toString(), source: "Upload Bulk Budgets" })
      );
      dispatch(patchFailure());
    } finally {
      callback?.();
    }
  };
