import "twin.macro";

import {
  CheckBoxOutlineBlankRounded,
  CheckBoxRounded,
} from "@mui/icons-material";

import { ControlledCheckboxInput } from "@utils/forms";

import { useFilterContext } from "../filterContext";
import Chip from "./Chip";

const InStockMenuItem = () => {
  const { control } = useFilterContext();
  return (
    <>
      <ControlledCheckboxInput
        tw="-my-2"
        name="inStock"
        label="In Stock Only"
        control={control}
        icon={<CheckBoxOutlineBlankRounded fontSize="small" />}
        checkedIcon={<CheckBoxRounded fontSize="small" />}
      />
    </>
  );
};

const InStockChip = (props) => {
  return (
    <Chip {...props}>
      <b>In Stock</b>
    </Chip>
  );
};

const InStockFilter = {
  Chip: InStockChip,
  MenuItem: InStockMenuItem,
};

export default InStockFilter;
