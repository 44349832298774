import tw from "twin.macro";

import { useDispatch, useSelector } from "react-redux";

import ViewListRoundedIcon from "@mui/icons-material/ViewListRounded";
import ViewModuleRoundedIcon from "@mui/icons-material/ViewModuleRounded";
import { IconButton, Tooltip } from "@mui/material";

import { setProductView } from "@redux/slices/globalStateSlice";

const ItemViewButtons = () => {
  const dispatch = useDispatch();
  const { productView } = useSelector((state: any) => state.globalState);
  return (
    <div tw="flex">
      <Tooltip title="View List">
        <IconButton onClick={() => dispatch(setProductView("table"))}>
          <ViewListRoundedIcon
            css={
              productView === "table"
                ? tw`text-primary-600`
                : tw`text-neutral-500`
            }
          />
        </IconButton>
      </Tooltip>
      <Tooltip title="View Grid">
        <IconButton edge="end" onClick={() => dispatch(setProductView("grid"))}>
          <ViewModuleRoundedIcon
            css={
              productView === "grid"
                ? tw`text-primary-600`
                : tw`text-neutral-500`
            }
          />
        </IconButton>
      </Tooltip>
    </div>
  );
};

export default ItemViewButtons;
