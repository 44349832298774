import "twin.macro";

import { useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";

import { Button } from "@mui/material";

import ItemCSVUploadModal from "@components/Settings/Items/ItemCSVUploadModal";
import ItemImageUploadModal from "@components/Settings/Items/ItemImageUploadModal";
import {
  FixedHeightScrollLastChild,
  OpaqueCard,
} from "@components/StyledComponents";
import Filters, { useFilterParams } from "@features/filters";
import {
  ItemAdminTable,
  useCreateItemMutation,
  usePaginatedItemsQuery,
} from "@features/items";
import { LoadingButton } from "@features/ui";
import DocTitle from "@utility/DocTitle";
import useRoleIs from "@utils/useRoleIs";

import BulkAllocationModal from "./BulkAllocationModal";

const AdminItems = () => {
  const navigate = useNavigate();
  const [filterParams] = useFilterParams();
  const [newItemLoading, setNewItemLoading] = useState(false);
  const [itemCSVUploadModalOpen, setItemCSVUploadModalOpen] = useState(false);
  const [itemImageUploadModalOpen, setItemImageUploadModalOpen] =
    useState(false);
  const [bulkAllocationModal, setBulkAllocationModal] = useState(false);
  const usesAllocations = useSelector(
    (state: any) => !!state.currentUser.organization.allocationLocation
  );

  const createItemMutation = useCreateItemMutation();

  const roleIs = useRoleIs();

  const { data: items, ...tableProps } = usePaginatedItemsQuery({
    filter: {
      query: filterParams.q,
      orderType: filterParams.orderType,
      favorites: filterParams.favorites,
      territoryIds: filterParams.territories,
      programIds: filterParams.programs,
      groupIds:
        filterParams.groups && Object.values(filterParams.groups).flat(),
      supplierIds: filterParams.suppliers,
      isActive:
        filterParams.activeStatus && filterParams.activeStatus !== "inactive",
      isDraft:
        filterParams.activeStatus && filterParams.activeStatus === "draft",
    },
    sort: filterParams.sort ?? "-id",
  });

  const handleCreateNewItem = () => {
    setNewItemLoading(true);
    createItemMutation.mutate(
      {},
      {
        onSuccess: (newItem) => navigate(`/admin/items/${newItem.id}`),
        onSettled: () => setNewItemLoading(false),
      }
    );
  };

  return (
    <>
      {itemCSVUploadModalOpen && (
        <ItemCSVUploadModal
          open={itemCSVUploadModalOpen}
          onClose={() => setItemCSVUploadModalOpen(false)}
        />
      )}
      {itemImageUploadModalOpen && (
        <ItemImageUploadModal
          open={itemImageUploadModalOpen}
          onClose={() => setItemImageUploadModalOpen(false)}
        />
      )}
      {bulkAllocationModal && (
        <BulkAllocationModal
          open={bulkAllocationModal}
          handleClose={() => setBulkAllocationModal(false)}
        />
      )}
      <DocTitle title={"Items Admin"} />
      <FixedHeightScrollLastChild>
        <div tw="flex flex-wrap justify-between items-center">
          <h2 tw="text-2xl text-neutral-600 font-bold mb-2">Item Admin</h2>
          <div tw="flex gap-2">
            <LoadingButton
              variant="contained"
              onClick={handleCreateNewItem}
              loading={newItemLoading}
            >
              New Item
            </LoadingButton>
            {roleIs("super") && (
              <>
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={() => setItemCSVUploadModalOpen(true)}
                >
                  Item CSV Upload
                </Button>
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={() => setItemImageUploadModalOpen(true)}
                >
                  Item Image Upload
                </Button>
                {usesAllocations && (
                  <Button
                    variant="contained"
                    color="secondary"
                    onClick={() => setBulkAllocationModal(true)}
                  >
                    Allocation Upload
                  </Button>
                )}
              </>
            )}
          </div>
        </div>
        <Filters
          searchTitle="Search Items"
          slots={[
            "favorites",
            "activeStatus",
            "orderType",
            "groups",
            "territories",
            "programs",
            "suppliers",
          ]}
        />
        <OpaqueCard tw="p-0 overflow-hidden relative">
          <ItemAdminTable
            items={items}
            query={filterParams.q}
            {...tableProps}
          />
        </OpaqueCard>
      </FixedHeightScrollLastChild>
    </>
  );
};

export default AdminItems;
