import { get } from "lodash";

import { CsvSchema } from "./CsvSchema";

export const csvDataFromSchema = (
  csvSchema: CsvSchema,
  reportData: Record<string, any>[]
) => {
  const head = csvSchema.map((line) => line.label);
  const rows = reportData.map((row) =>
    csvSchema.map(({ id, f }) => (f ? f(get(row, id), row) : get(row, id)))
  );
  return [head, ...rows];
};
