import "twin.macro";
import tw from "twin.macro";

import { useState } from "react";
import { useSelector } from "react-redux";

import ItemColumn from "@components/OrderHistory/ItemColumn";
import ReactQueryTable, {
  Column,
  PaginatedResourceProps,
} from "@components/Table/ReactQueryTable";
import SearchableText from "@components/Table/SearchableText";
import TrackingModal from "@components/Utility/Modals/TrackingModal";
import { OrderStatus } from "@features/orderHistory";
import { checkoutTypeLabel } from "@features/ordering";
import { OrderSetVariantSummary } from "@models";
import {
  formatMoneyString,
  formatUtcDate,
  upCase,
} from "@utility/utilityFunctions";

const insertIf = (cond, val) => (cond ? [val] : []);

const OrderSetVariantSummaryTable = ({
  query,
  ...tableProps
}: PaginatedResourceProps & {
  rows: OrderSetVariantSummary[];
  query?: string;
}) => {
  const [trackingId, setTrackingId] = useState<string | null>(null);
  const { budgetLocation } = useSelector(
    (state: any) => state.currentUser.organization
  );

  const columns: Column<OrderSetVariantSummary>[] = [
    {
      id: "item",
      label: "Item",
      sort: "variant-sku",
      to: (row) => `/order-sets/${row.orderSet.id}`,
      render: (_, row) => (
        <ItemColumn row={row} query={query} imgProps={{ style: tw`w-16` }} />
      ),
    },
    {
      id: "orderSet.id",
      label: "Set #",
      sort: "order-set-id",
      to: (row) => `/order-sets/${row.orderSet.id}/overview?from=approval`,
      render: (id) => (
        <span tw="block hover:underline font-semibold">
          <SearchableText text={id} query={query} />
        </span>
      ),
    },
    { id: "userName", label: "Ordered By", sort: "user-name" },
    {
      id: "orderSetType",
      label: "Order Type",
      sort: "order-set-type",
      render: (ot, row) => (
        <>
          {upCase(ot ?? "", "-")}
          {row.orderWindowName && (
            <div tw="text-neutral-500 text-xs">{row.orderWindowName}</div>
          )}
        </>
      ),
    },
    { id: "programName", label: "Program", sort: "program-name" },
    { id: "territoryName", label: "Territory", sort: "territory-name" },
    { id: "stateNames", label: "State(s)" },
    ...insertIf(budgetLocation, {
      id: "budgetName",
      label: "Budget",
      sort: "budget-name",
    }),
    {
      id: "orderSetCheckoutType",
      label: "Checkout Type",
      render: checkoutTypeLabel,
    },
    {
      id: "price",
      label: "Price",
      align: "right",
      render: formatMoneyString,
    },
    { id: "totalVariantQty", label: "Qty", align: "right" },
    {
      id: "totalPrice",
      label: "Total Price",
      align: "right",
      render: formatMoneyString,
    },
    {
      id: "orderSetSubmittedAt",
      label: "Order Date",
      sort: "order-set-submitted-at",
      render: (date) => (date ? formatUtcDate(date) : ""),
    },
    {
      id: "orderSetStatus",
      label: "Status",
      sort: "order-set-status",
      render: (status) => <OrderStatus status={status} />,
    },
  ];

  return (
    <>
      {trackingId && (
        <TrackingModal
          id={trackingId}
          open={true}
          handleClose={() => setTrackingId(null)}
        />
      )}
      <ReactQueryTable {...tableProps} columns={columns} />
    </>
  );
};

export default OrderSetVariantSummaryTable;
