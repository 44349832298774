import "twin.macro";

import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router";

import { Tab, Tabs } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

import { useQueryClient } from "@tanstack/react-query";
import clsx from "clsx";
import addDays from "date-fns/addDays";
import format from "date-fns/format";
import PropTypes from "prop-types";

import GeneralPanel from "@components/BrandHQ/OrderWindows/GeneralPanel";
import ProgramPanel from "@components/BrandHQ/OrderWindows/ProgramPanel";
import ReviewPanel from "@components/BrandHQ/OrderWindows/ReviewPanel";
import VisibilityPanel from "@components/BrandHQ/OrderWindows/VisibilityPanel";
import { Contained, StyledButton } from "@components/StyledComponents";
import Loading from "@components/Utility/Loading";
import SuccessModal from "@components/Utility/Modals/SuccessModal";
import { orderWindowsKeyFactory } from "@features/orderWindows";
import { Title } from "@features/ui";
import { setError } from "@redux/slices/errorSlice";
import {
  createOrderWindow,
  fetchOrderWindow,
  setHasUpdated,
  updateOrderWindow,
} from "@redux/slices/orderWindows/orderWindowSlice";
import DocTitle from "@utility/DocTitle";

/*
Handles create and update functionality for order windows. Each step is controlled by it's own panel, that
can be found at ../components/BrandHQ/OrderWindows. The visible panel is controlled by tabs at the top of
the view.
*/

const defaultForm = {
  name: "",
  openDate: format(new Date(), "MM/dd/yyyy"),
  closeDate: format(addDays(new Date(), 30), "MM/dd/yyyy"),
  reviewCloseDate: format(addDays(new Date(), 30), "MM/dd/yyyy"),
  inMarketDate: format(addDays(new Date(), 90), "MM/dd/yyyy"),
};

const keyMap = {
  name: "Name",
  openDate: "Open Date",
  closeDate: "Close Date",
  reviewCloseDate: "Review Close Date",
  inMarketDate: "In Market Date",
  territories: "Territory Assignment",
  programs: "Program Assignment",
  channel: "Channel Assignment",
};

const tabProps = (index) => {
  return {
    id: `scrollable-auto-tab-${index}`,
    "aria-controls": `scrollable-auto-tabpanel-${index}`,
  };
};

const TabPanel = ({ children, value, index, ...other }) => {
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      {value === index && <>{children}</>}
    </div>
  );
};

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

const useStyles = makeStyles((theme) => ({
  ...theme.global,
  twoWideContainer: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    [theme.breakpoints.down("md")]: {
      flexDirection: "column",
    },
  },
  twoWide: {
    width: "48%",
    [theme.breakpoints.down("md")]: {
      width: "100%",
    },
  },
  justifyCenter: {
    justifyContent: "center",
  },
  formWrapper: {
    width: "70%",
    maxWidth: "1250px",
    padding: "20px",
    boxSizing: "border-box",
    [theme.breakpoints.down("lg")]: {
      width: "85%",
    },
    [theme.breakpoints.down("md")]: {
      width: "100%",
    },
  },
  tabs: {
    display: "flex",
    justifyContent: "space-between",
  },
}));

const OrderWindowCreate = () => {
  const { type, id } = useParams();
  const classes = useStyles();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const tabActions = useRef();
  const queryClient = useQueryClient();

  const [activeWindow, setActiveWindow] = useState(null);
  const [formData, setFormData] = useState(defaultForm);
  const [channel, setChannel] = useState(null);
  const [territories, setTerritories] = useState([]);
  const [programs, setPrograms] = useState([]);
  const [successModal, setSuccessModal] = useState({
    open: false,
    message: null,
    options: [],
  });
  const [localError, setLocalError] = useState(null);
  const [tabIndex, setTabIndex] = useState(0);

  const { isLoading, isUpdateLoading, hasUpdated, currentWindow } = useSelector(
    (state) => state.orderWindows
  );

  const {
    organization: { usesChannels },
  } = useSelector((state) => state.currentUser);

  const handleTab = (_evt, value) => {
    setTabIndex(value);
  };

  const handleFormUpdate = (key, value) =>
    setFormData({ ...formData, [key]: value });

  const reset = () => {
    setFormData(defaultForm);
    setActiveWindow(null);
    setChannel(null);
    setTerritories([]);
    setPrograms([]);
    setLocalError(null);
    dispatch(setHasUpdated({ value: false }));
  };

  const verifyForm = () => {
    let keys = Object.keys(formData);
    let verified = [];
    let index = 0;

    while (index < keys.length) {
      if (formData[keys[index]].length === 0) verified.push(keys[index]);
      index += 1;
    }

    if (territories.length === 0) verified.push("territories");
    if (usesChannels && !channel) verified.push("channel");

    return verified.length > 0
      ? verified.map((field) => keyMap[field]).join(", ")
      : false;
  };

  const handleSubmit = () => {
    let notVerified = verifyForm();
    if (notVerified) {
      setLocalError(
        `You must complete form before submitting, the following fields are missing: ${notVerified}`
      );
    } else {
      setLocalError(null);
      if (type === "edit") {
        dispatch(
          updateOrderWindow(
            {
              name: formData.name,
              openDate: new Date(formData.openDate),
              closeDate: new Date(formData.closeDate),
              reviewCloseDate: new Date(formData.reviewCloseDate),
              inMarketDate: new Date(formData.inMarketDate),
              channelId: channel ? channel.id : null,
              territories: territories,
              programs: programs,
            },
            id
          )
        );
      } else {
        dispatch(
          createOrderWindow({
            name: formData.name,
            openDate: new Date(formData.openDate),
            closeDate: new Date(formData.closeDate),
            reviewCloseDate: new Date(formData.reviewCloseDate),
            inMarketDate: new Date(formData.inMarketDate),
            channelId: channel ? channel.id : null,
            territories: territories,
            programs: programs,
          })
        );
      }
    }
  };

  const handleSuccessClose = (arg) => {
    if (arg === "new") {
      reset();
      setSuccessModal({
        open: false,
        message: null,
        options: [],
      });
      setTabIndex(0);
    } else if (arg === "continue") {
      setSuccessModal({
        open: false,
        message: null,
        options: [],
      });
      dispatch(setHasUpdated({ value: false }));
    } else {
      reset();
      navigate("/admin/order-windows");
    }
  };

  useEffect(() => {
    if (
      type === "edit" &&
      !activeWindow &&
      currentWindow &&
      currentWindow.id &&
      currentWindow.id === id
    ) {
      setActiveWindow(currentWindow);
      setFormData({
        name: currentWindow.name,
        openDate: currentWindow.openDate,
        closeDate: currentWindow.closeDate,
        reviewCloseDate: currentWindow.reviewCloseDate,
        inMarketDate: currentWindow.inMarketDate,
      });
      setChannel(currentWindow.channel);
      setTerritories(currentWindow.territories);
      setPrograms(currentWindow.programs);
    }
  }, [activeWindow, currentWindow, id, type]);

  useEffect(() => {
    dispatch(setHasUpdated({ value: false }));
    // handleFilterDrawer(false);
    reset();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (localError) {
      dispatch(setError({ error: localError, source: "Order Window Create" }));
      setLocalError(null);
    }
  }, [localError, dispatch]);

  useEffect(() => {
    if (hasUpdated && !successModal.open) {
      queryClient.invalidateQueries({ queryKey: orderWindowsKeyFactory._def });
      setSuccessModal({
        open: true,
        message:
          type === "edit"
            ? "Order Window Updated Successfully!"
            : "Order Window Created Successfully",
        options:
          type === "edit"
            ? [
                { label: "CONTINUE EDITING", arg: "continue" },
                { label: "BACK TO ORDER WINDOWS", arg: "back" },
              ]
            : [
                { label: "CREATE NEW WINDOW", arg: "new" },
                { label: "BACK TO ORDER WINDOWS", arg: "back" },
              ],
      });
    }
  }, [hasUpdated, successModal, setSuccessModal, type, queryClient]);

  useEffect(() => {
    if (type === "edit" && id) {
      dispatch(fetchOrderWindow(id));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <DocTitle title={`${type === "edit" ? "Edit" : "Create"} Order Window`} />
      {successModal.open && (
        <SuccessModal {...successModal} handleClose={handleSuccessClose} />
      )}
      {((type === "edit" && (!activeWindow || activeWindow.id !== id)) ||
        isLoading ||
        isUpdateLoading) && <Loading opacity="75%" />}
      {((type === "edit" && activeWindow && activeWindow.id === id) ||
        type === "new") && (
        <Contained tw="flex flex-col items-center">
          <div tw="w-full">
            <Title backUrl="/admin/order-windows">
              {type === "edit"
                ? `Editing ${activeWindow.name}`
                : "New Order Window"}
            </Title>
            <Tabs
              tw="my-4"
              value={tabIndex}
              onChange={handleTab}
              indicatorColor="secondary"
              textColor="primary"
              variant="scrollable"
              scrollButtons="auto"
              aria-label="item create sections"
              classes={{ flexContainer: classes.tabs }}
              action={tabActions}
            >
              <Tab label="General" {...tabProps(0)} />
              <Tab label="Visibility" {...tabProps(1)} />
              <Tab label="Program Assignment" {...tabProps(2)} />
              <Tab label="Review" {...tabProps(4)} />
            </Tabs>
            <TabPanel
              className={clsx(
                classes.fullWidthCenterColumn,
                classes.justifyCenter
              )}
              value={tabIndex}
              index={0}
            >
              <GeneralPanel
                classes={classes}
                type={type}
                formData={formData}
                handleFormUpdate={handleFormUpdate}
                handleSubmit={handleSubmit}
                activeWindow={activeWindow}
              />
            </TabPanel>
            <TabPanel
              className={clsx(
                classes.fullWidthCenterColumn,
                classes.justifyCenter
              )}
              value={tabIndex}
              index={1}
            >
              <VisibilityPanel
                classes={classes}
                type={type}
                activeWindow={activeWindow}
                channel={channel}
                setChannel={setChannel}
                territories={territories}
                setTerritories={setTerritories}
                programs={programs}
                setPrograms={setPrograms}
                isUpdateLoading={isUpdateLoading}
                handleSubmit={handleSubmit}
              />
            </TabPanel>
            <TabPanel
              className={clsx(
                classes.fullWidthCenterColumn,
                classes.justifyCenter
              )}
              value={tabIndex}
              index={2}
            >
              <ProgramPanel
                classes={classes}
                type={type}
                programs={programs}
                setPrograms={setPrograms}
                territories={territories}
                handleSubmit={handleSubmit}
              />
            </TabPanel>
            <TabPanel
              className={clsx(
                classes.fullWidthCenterColumn,
                classes.justifyCenter
              )}
              value={tabIndex}
              index={3}
            >
              <ReviewPanel
                classes={classes}
                type={type}
                formData={formData}
                activeWindow={activeWindow}
                channel={channel}
                territories={territories}
                programs={programs}
                handleSubmit={handleSubmit}
              />
            </TabPanel>
            {(type === "edit" || tabIndex === 3) && (
              <div tw="flex justify-center my-8">
                <StyledButton
                  cta
                  onClick={handleSubmit}
                  style={{ minWidth: "62px" }}
                >
                  {type === "new" ? "SUBMIT" : "SAVE"}
                </StyledButton>
              </div>
            )}
          </div>
        </Contained>
      )}
    </>
  );
};

OrderWindowCreate.propTypes = {};

export default OrderWindowCreate;
