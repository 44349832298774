import "twin.macro";

import { useFormContext, useWatch } from "react-hook-form";

import { CircularProgress } from "@mui/material";

import { ResourceError } from "@features/errors";
import { useVariantCategoriesListQuery } from "@features/variantCategories";
import { Item } from "@models";
import { SelectInput } from "@utils/forms";

import { optionsFromVariants } from "../../../helpers";
import CategoryOptions from "./CategoryOptions";

const Options = ({ item }: { item: Item }) => {
  const { data: variantCategories, error } = useVariantCategoriesListQuery();

  const { setValue } = useFormContext();

  const variantOptions = useWatch({ name: "variantOptions" });
  const variantCategoryIds = Object.keys(variantOptions);

  const savedVariantOptions = optionsFromVariants(item.variants);

  const handleAddVariantCategory = (categoryId) => {
    setValue(`variantOptions.${categoryId}`, []);
  };

  if (!variantCategories) {
    return <CircularProgress />;
  }
  if (error) {
    return <ResourceError error={error} />;
  }

  return (
    <div>
      {variantCategoryIds.map((categoryId) => {
        const matchedCategory = variantCategories.find(
          ({ id }) => id === categoryId
        );
        return (
          matchedCategory && (
            <CategoryOptions
              key={categoryId}
              category={matchedCategory}
              savedVariantOptionIds={savedVariantOptions[categoryId] ?? []}
            />
          )
        );
      })}

      {variantCategories.length > variantCategoryIds.length && (
        <SelectInput
          tw="w-full max-w-sm"
          displayEmpty
          options={[{ id: "", name: "Add New Option" }, ...variantCategories]}
          value={""}
          onChange={(e) => handleAddVariantCategory(e.target.value)}
          getOptionDisabled={(opt) => variantCategoryIds.includes(opt.id)}
        />
      )}
    </div>
  );
};

export default Options;
