import { createQueryKeys } from "@lukemorales/query-key-factory";
import { keepPreviousData } from "@tanstack/react-query";

import { Variant } from "@models/Variant";
import client from "@services/api";
import { buildPaginatedQuery } from "@utils/reactQuery";

export const variantsKeyFactory = createQueryKeys("variants", {
  detail: (id: string) => ({
    queryKey: [id],
    queryFn: () =>
      client.get<Variant>(`variants/${id}`).then((res) => res.data),
  }),
  paginated: (params) => ({
    queryKey: [params],
    queryFn: () =>
      client.get<Variant[]>("variants", {
        params,
      }),
  }),
});

export const usePaginatedVariantsQuery = buildPaginatedQuery(
  variantsKeyFactory.paginated,
  {
    staleTime: 1000 * 60 * 5,
    placeholderData: keepPreviousData,
  }
);
