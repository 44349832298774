import "twin.macro";
import tw from "twin.macro";

import React from "react";

import Popover from "@mui/material/Popover";

import PropTypes from "prop-types";

const InfoPopover = ({ id, info, open, anchorEl, handlePopoverClose }) => {
  return (
    <>
      <Popover
        id={id}
        css={{
          "&": tw`pointer-events-none`,
          ".MuiPopover-paper": tw`mt-4 rounded-lg shadow-lg`,
        }}
        elevation={0}
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        onClose={handlePopoverClose}
        marginThreshold={25}
        disableScrollLock
        disableAutoFocus
      >
        <p tw="max-w-prose p-4 pointer-events-auto">{info}</p>
      </Popover>
    </>
  );
};

InfoPopover.propTypes = {
  id: PropTypes.string.isRequired,
  info: PropTypes.string.isRequired,
  open: PropTypes.bool.isRequired,
  anchorEl: PropTypes.any,
  handlePopoverClose: PropTypes.func.isRequired,
};

export default React.memo(InfoPopover);
