import { useMemo } from "react";

import { UseQueryResult, useQuery } from "@tanstack/react-query";
import _ from "lodash";

import { Order } from "@models/Order";
import { OrderSet } from "@models/OrderSet";
import { OrderSetVariant } from "@models/OrderSetVariant";

import { orderSetsKeyFactory } from "./data/orderSetQueries";

export type ExtendedOrderSetVariant = OrderSetVariant & {
  price?: string;
  totalQty?: number;
  totalPrice?: number;
};
// insures both orderVariants and orderSetVariants are sorted by the same key
const variantSortKeys = [
  "variant.item.name", // names are unique
  "variant.orderPosition", // respect order position for variants
];

// Declare the combine function outside of the hook
// so that its reference is stable and doesn't cause re-renders
function combineOrderSetAndOrdersQuery(
  orderSetQuery: UseQueryResult<OrderSet, Error>,
  ordersQuery: UseQueryResult<Order[], Error>
) {
  if (orderSetQuery.isPending || ordersQuery.isPending) {
    return { isPending: true as const };
  }

  if (orderSetQuery.isLoading || ordersQuery.isLoading) {
    return { isLoading: true as const };
  }

  if (orderSetQuery.error || ordersQuery.error) {
    return { error: (orderSetQuery.error || ordersQuery.error) as Error };
  }

  const orders = ordersQuery.data!;
  const orderSet = orderSetQuery.data!;
  const sortedOrdersData = _(orders)
    .map((order) => ({
      ...order,
      orderVariants: _.sortBy(order.orderVariants, ...variantSortKeys),
    }))
    .sortBy("address.state.code", "address.region", "address.id")
    .value();

  const lookup = _(orders)
    .flatMap((o) => o.orderVariants)
    .groupBy("variant.id")
    .mapValues((v) =>
      v.reduce(
        (a, b) => {
          a.price = b.price;
          a.qty += b.qty;
          return a;
        },
        { price: "0", qty: 0 }
      )
    )
    .value();

  const orderSetVariants: ExtendedOrderSetVariant[] = _(
    orderSet.orderSetVariants
  )
    .sortBy(...variantSortKeys)
    .map((osv, i) => ({
      ...osv,
      price: lookup[osv.variant.id]?.price ?? null,
      totalQty: lookup[osv.variant.id]?.qty ?? 0,
      totalPrice: sortedOrdersData.reduce(
        (totalPrice, order) =>
          totalPrice + +(order.orderVariants[i]?.totalPrice || 0),
        0
      ),
    }))
    .value();

  return {
    orderSet: orderSet,
    orders: sortedOrdersData,
    orderSetVariants,
    orderSetQuery,
    ordersQuery,
  };
}

export const useOrderSet = (orderSetId?: string | null) => {
  const orderSetQuery = useQuery({
    ...orderSetsKeyFactory.detail(orderSetId),
    enabled: !!orderSetId,
  });
  const ordersQuery = useQuery({
    ...orderSetsKeyFactory.detail(orderSetId)._ctx.orders,
    enabled: !!orderSetId,
  });

  return useMemo(
    () => combineOrderSetAndOrdersQuery(orderSetQuery, ordersQuery),
    [orderSetQuery, ordersQuery]
  );
};
