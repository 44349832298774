import { useNavigate } from "react-router";

import { useMutation, useQueryClient } from "@tanstack/react-query";

import client, { useMutateError } from "@services/api";
import { kebabCaseKeys } from "@utility/utilityFunctions";

import useOrderSetId from "../../useOrderSetId";
import { orderSetsKeyFactory } from "../orderSetQueries";

export const useStripeCheckoutMutation = () => {
  const setError = useMutateError();
  const queryClient = useQueryClient();
  const orderSetId = useOrderSetId();
  const navigate = useNavigate();

  return useMutation({
    mutationFn: ({
      amountInCents,
      paymentMethodId,
    }: {
      amountInCents: number;
      paymentMethodId: string | null;
    }) =>
      client.post<null>(
        "/stripe",
        kebabCaseKeys({
          amountInCents,
          paymentMethodId,
          orderSetId,
        }),
        { serializeBody: false }
      ),
    onSuccess: () => {
      // navigate first, otherwise the component re-renders first and its navigate clause gets triggered first
      navigate(`/order-sets/${orderSetId}/overview?action=submit`, {
        replace: true,
      });

      queryClient.setQueryData(
        orderSetsKeyFactory.detail(orderSetId).queryKey,
        (os) => os && { ...os, status: "approved", checkoutType: "stripe" }
      );
    },
    onError: (err: any) => {
      setError(new Error(err.data.message), "Stripe Checkout");
      queryClient.invalidateQueries({
        queryKey: orderSetsKeyFactory.detail(orderSetId).queryKey,
      });
    },
  });
};
