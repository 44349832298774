import "twin.macro";

import { useState } from "react";
import { useParams } from "react-router";

import { Button, CircularProgress, Tooltip } from "@mui/material";

import { capitalize } from "lodash";

import {
  Contained,
  OpaqueCard,
  SectionTitle,
} from "@components/StyledComponents";
import { RfqQuotesTable } from "@features/quotes";
import {
  CancelRfqButton,
  NewRfqButton,
  RequestForQuoteForm,
  RfqItemReference,
  RfqSummary,
} from "@features/rfqs";
import { useRfqQuery } from "@features/rfqs";
import CompareQuotesModal from "@features/rfqs/compareQuotes/CompareQuotesModal";
import { Title } from "@features/ui";
import DocTitle from "@utility/DocTitle";

import ErrorPage from "./ErrorPage";

const RequestForQuote = () => {
  const { id } = useParams();
  const { data, error, isLoading } = useRfqQuery(id);
  const [compareQuotesModalOpen, setCompareQuotesModalOpen] = useState(false);
  if (error) return <ErrorPage error={error} />;
  if (isLoading || !data) return <CircularProgress />;

  const completedQuotes = data.quotes.filter((quote) => quote.isComplete);
  const canCompareQuotes = completedQuotes && completedQuotes.length > 1;

  return (
    <>
      <Contained tw="pb-3 max-w-screen-xl">
        <DocTitle title={`Quote Request #${id}`} />
        <Title backUrl="/rfqs" tw="mb-6">
          Quote Request #{id} [{capitalize(data.status)}]
        </Title>

        <div tw="grid lg:grid-cols-[2fr 1fr] gap-6 max-w-screen-xl">
          <OpaqueCard tw="p-6 space-y-6">
            <div tw="flex flex-col gap-6">
              {data.status === "draft" && <RequestForQuoteForm rfq={data} />}
              {data.status !== "draft" && <RfqSummary rfq={data} />}
              {data.quotes.length > 0 && (
                <div>
                  <SectionTitle>Quotes</SectionTitle>
                  {/* <div tw="-mx-4"> */}
                  <RfqQuotesTable rows={data.quotes ?? []} error={null} />
                  {/* </div> */}
                </div>
              )}
            </div>
            <div tw="flex gap-4">
              {data.status !== "draft" && (
                <NewRfqButton
                  itemId={data.item.id}
                  label="Copy to New RFQ"
                  baseOffOf={data}
                />
              )}
              {data.status === "sent" && <CancelRfqButton rfq={data} />}
              {["sent", "awarded"].includes(data.status) && (
                <Tooltip
                  title={
                    !canCompareQuotes
                      ? "You must have at least 2 completed quotes to compare"
                      : ""
                  }
                >
                  <span tw="ml-auto">
                    <Button
                      variant="outlined"
                      onClick={() => setCompareQuotesModalOpen(true)}
                      disabled={!canCompareQuotes}
                    >
                      Compare Completed Quotes
                    </Button>
                  </span>
                </Tooltip>
              )}
            </div>
          </OpaqueCard>
          <div>
            <RfqItemReference rfq={data} />
            {data.status === "draft" && (
              <div tw="flex justify-end mt-6">
                <CancelRfqButton rfq={data} />
              </div>
            )}
          </div>
        </div>
      </Contained>
      {compareQuotesModalOpen && (
        <CompareQuotesModal
          quotes={completedQuotes}
          onClose={() => setCompareQuotesModalOpen(false)}
        />
      )}
    </>
  );
};

export default RequestForQuote;
