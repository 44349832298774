import { Link } from "react-router";

import { useOrderWindowQuery } from "@features/orderWindows";

export const CurrentOrderWindowLink = ({ orderWindowId }) => {
  const { data: orderWindow } = useOrderWindowQuery(orderWindowId as string);
  return (
    <Link to={`/pre-orders/${orderWindowId}`}>
      {orderWindow?.name || "Order Window"}
    </Link>
  );
};
