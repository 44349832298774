import "twin.macro";

import permissions from "@utils/permissions";
import useRoleIs from "@utils/useRoleIs";

import { DashboardGrid } from "../StyledComponents";
import DashCard, { CardData } from "./DashCard";

const HelpDash = () => {
  const roleIs = useRoleIs();

  const cardData: CardData[] = [
    {
      titleText: "brandhub\n Knowledge Base",
      ariaOwnsState: true,
      ariaOwnsText: "admin-quick-start",
      id: "admin-quick-start",
      link: null,
      info: "This is the best place to learn about all of brandhub's features.",
      icon: "https://res.cloudinary.com/brandhub/image/upload/v1617274000/prod/Icons/SELECT_Icons_Intelligence_Innovation_hfidfg.png",
      onClick: () => {
        const link = roleIs(permissions.externalRoles)
          ? "https://supplier-help.selectbrandhub.com"
          : "https://help.selectbrandhub.com/";
        window.open(link, "_blank");
      },
    },
    {
      titleText: "Make Help Request",
      ariaOwnsState: true,
      ariaOwnsText: "make-help-request",
      id: "make-help-request",
      link: null,
      info: "Can't find what you are looking for? Send us a message and we will get back to you as soon as possible.",
      icon: "https://res.cloudinary.com/brandhub/image/upload/v1617273999/prod/Icons/SELECT_Icons_Fast_Design_b1mgbt.png",
      onClick: () => {
        if (window.Beacon) {
          window.Beacon("open");
          window.Beacon("navigate", "/ask");
        }
      },
    },
  ];

  return (
    <DashboardGrid>
      {cardData.map((data, index) => (
        <DashCard key={index} data={data} />
      ))}
    </DashboardGrid>
  );
};

export default HelpDash;
