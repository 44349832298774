import tw from "twin.macro";

import { useState } from "react";
import { useFormContext } from "react-hook-form";

import { Close } from "@mui/icons-material";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
} from "@mui/material";

import { StyledButton } from "@components/StyledComponents";
import useConfirm from "@features/confirm";
import { CldImage } from "@services/cloudinary";

import { useItemImages } from "../../ItemImageContext";

const VariantImagesModal = ({ open, handleClose, variantSelection }) => {
  const confirm = useConfirm();
  const { handleUpload, images } = useItemImages();
  const { setValue, getValues } = useFormContext();
  const [selectedImageId, setSelectedImageId] = useState<null | string>(
    variantSelection.length === 1
      ? getValues(`variants.${variantSelection[0]}.imageId`)
      : null
  );

  const handleSave = async () => {
    if (
      variantSelection.length > 1 &&
      !selectedImageId &&
      !(await confirm(
        "Are you sure you want to remove images for all selected variants?"
      ))
    ) {
      return;
    }
    variantSelection.forEach((variantHash) => {
      setValue(`variants.${variantHash}.imageId`, selectedImageId, {
        shouldDirty: true,
      });
    });
    handleClose();
  };
  return (
    <Dialog open={open} onClose={handleClose} fullWidth maxWidth="sm">
      <DialogTitle tw="flex justify-between items-center">
        Variant Images
        <IconButton size="small" onClick={handleClose}>
          <Close />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <div tw="my-2 grid [grid-template-columns: repeat(auto-fill, minmax(8em, 1fr))] gap-4">
          {images.length === 0 && (
            <button
              tw="flex justify-center rounded py-2 bg-neutral-100 text-neutral-700 hover:bg-neutral-200"
              onClick={handleUpload}
            >
              Add Image
            </button>
          )}
          {images.map((image) => (
            <button
              key={image.id}
              css={[
                tw`flex items-center justify-center border-2 rounded aspect-square border-neutral-200 `,
                selectedImageId === image.id && tw`outline outline-primary-600`,
              ]}
              onClick={() =>
                setSelectedImageId(
                  selectedImageId === image.id ? null : image.id
                )
              }
            >
              <CldImage image={image} size="thumbnail" />
            </button>
          ))}
        </div>
      </DialogContent>
      <DialogActions tw="justify-between">
        <div tw="ml-2 text-neutral-600">
          Modifying {variantSelection.length} item
          {variantSelection.length === 1 ? "" : "s"}
        </div>
        <div tw="flex gap-2">
          <StyledButton outlined onClick={handleClose}>
            Cancel
          </StyledButton>
          <StyledButton outlined onClick={handleUpload}>
            Add Image
          </StyledButton>
          <StyledButton cta onClick={handleSave}>
            Done
          </StyledButton>
        </div>
      </DialogActions>
    </Dialog>
  );
};

export default VariantImagesModal;
