import "twin.macro";

import React, { useEffect, useState } from "react";

import { CloseRounded } from "@mui/icons-material";
import { IconButton } from "@mui/material";

import { useHelpScoutBeacon } from "@features/helpScout";
import { useBreakpoint } from "@utils/tailwind";

import "../../styles/navWrapper.css";
import Header from "./Header";
import Sidebar from "./Sidebar";

const NavWrapper = ({ children }) => {
  const [open, setOpen] = useState(true);
  const isMobile = useBreakpoint("md");

  // hook to display helpscout beacon
  useHelpScoutBeacon();

  useEffect(() => {
    setOpen(!isMobile);
  }, [isMobile]);
  return (
    <div className="main-grid" tw="max-h-screen overflow-hidden">
      <div
        className={`sidebar-area ${open && "sidebar-area--open"}`}
        tw="relative z-[200]"
      >
        {isMobile && open && (
          <div
            tw="fixed inset-0 bg-neutral-900/50"
            onClick={() => setOpen(false)}
          >
            <IconButton tw="fixed top-4 right-4 bg-neutral-800!">
              <CloseRounded tw="text-white" />
            </IconButton>
          </div>
        )}
        <Sidebar setOpen={setOpen} />
      </div>
      <div className="body">
        <div tw="relative">
          <Header open={open} setOpen={setOpen} />
          {children}
        </div>
      </div>
    </div>
  );
};

export default NavWrapper;
