import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router";

import { setInitialFilters } from "@redux/slices/filterSlice";
import {
  updateCurrentChannel,
  updateCurrentTerritory,
} from "@redux/slices/user/currentUserSlice";

/*
useInitialFetch handles making the first data call for views, based on the filter type and if there are
any active parameter filters. Calling setInitialFilters will trigger a fetch from the FilterDrawer based
on the filter type, and the passed in filters.
*/

export const useInitialFetch = (
  dispatch,
  filterType,
  defaultFilters,
  params,
  ignoreUserTerritory,
  ignoreUserChannel
) => {
  const { search } = useLocation();
  const hasLoadedCategories = useSelector(
    (state) => state.groupCategories.isLoading === false
  );
  const { role, currentChannelId, currentTerritoryId } = useSelector(
    (state) => state.currentUser
  );

  useEffect(() => {
    if (hasLoadedCategories || role === "supplier") {
      let filters = null;
      if (params && params.length > 0) {
        filters = JSON.parse(params[0][1]);
        if (
          filters.currentTerritoryId &&
          !ignoreUserTerritory &&
          currentTerritoryId !== filters.currentTerritoryId
        ) {
          dispatch(
            updateCurrentTerritory({ territory: filters.currentTerritoryId })
          );
        }
        if (
          filters.channelId &&
          !ignoreUserChannel &&
          currentChannelId !== filters.channelId
        ) {
          dispatch(updateCurrentChannel({ channel: filters.channelId }));
        }
      }
      dispatch(
        setInitialFilters({
          type: filterType,
          defaultFilters: { ...defaultFilters },
          paramFilters: filters,
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterType, hasLoadedCategories, search]);
};
