import "twin.macro";

import { useDispatch, useSelector } from "react-redux";

import { Alert } from "@mui/material";

import DefaultDash from "@components/Dashboard/DefaultDash";
import SupplierDash from "@components/Dashboard/SupplierDash";
import {
  Contained,
  DashboardGrid,
  OpaqueCard,
} from "@components/StyledComponents";
import ImageWrapper from "@components/Utility/ImageWrapper";
import DashboardTutorial from "@components/tutorial/DashboardTutorial";
import DocTitle from "@utility/DocTitle";
import permissions from "@utils/permissions";
import useRoleIs from "@utils/useRoleIs";

import { useNoFetch } from "../hooks/useNoFetch";

const Dashboard = () => {
  const dispatch = useDispatch();
  const roleIs = useRoleIs();

  const { firstName, hasViewedDashboard, organization } = useSelector(
    (state) => state.currentUser
  );

  const showSupplierDash = roleIs(permissions.supplier);

  useNoFetch(dispatch);

  return (
    <>
      <DocTitle title={"Dashboard"} />
      {!hasViewedDashboard && !roleIs("supplier") && <DashboardTutorial />}
      <Contained tw="flex flex-col gap-6">
        <DashboardGrid>
          <OpaqueCard tw="p-6 md:col-span-2" id="org-card">
            {organization.id && (
              <ImageWrapper
                imgUrl={organization.logoUrl}
                tw="my-3 max-w-[320px] max-h-[160px]"
                alt={`Organization logo`}
              />
            )}
            <h1 tw="font-medium text-neutral-700 text-xl pt-3">{`Welcome, ${firstName}!`}</h1>
            {!organization.id && (
              <Alert severity="warning" tw="mt-4">
                You aren't assigned to an organization. If this is a mistake,
                please contact support.
              </Alert>
            )}
          </OpaqueCard>
        </DashboardGrid>
        {!showSupplierDash && <DefaultDash />}
        {showSupplierDash && <SupplierDash />}
      </Contained>
      <br />
      <br />
    </>
  );
};
export default Dashboard;
