import "twin.macro";

import { Chip } from "@mui/material";

import { sortBy } from "lodash";

import { SectionTitle } from "@components/StyledComponents";
import { orderTypeLabel } from "@features/ordering";
import { PricingTierQuote } from "@models/Quote";
import { RequestForQuote } from "@models/RequestForQuote";
import { formatNumber, formatUtcDate } from "@utility/utilityFunctions";

const NA = <span tw="text-neutral-400 font-normal">not specified</span>;

const RfqSummary = ({
  rfq,
  pricingTierQuotes,
  hidePricingTiers,
}: {
  rfq: RequestForQuote;
  pricingTierQuotes?: PricingTierQuote[];
  hidePricingTiers?: boolean;
}) => {
  const tiers = pricingTierQuotes
    ? pricingTierQuotes.map((tier) => ({
        id: tier.id,
        qty: tier.pricingTierQty,
      }))
    : rfq.pricingTiers;

  return (
    <div tw="flex flex-col gap-6">
      <div>
        <SectionTitle>Brief</SectionTitle>
        <p tw="text-neutral-700 whitespace-pre-wrap">{rfq.note?.trim()}</p>
      </div>
      <div>
        <SectionTitle>Info</SectionTitle>
        <div tw="grid grid-cols-2 gap-x-6 gap-y-3 text-neutral-600 font-medium">
          <div>
            <span tw="font-normal">Order Type: </span>
            {orderTypeLabel(rfq.orderType)}
          </div>
          <div>
            <span tw="font-normal">Quote Due Date: </span>
            {formatUtcDate(rfq.requestedDueDate) || NA}
          </div>
          <div>
            <span tw="font-normal">Est. Order Date: </span>
            {formatUtcDate(rfq.estimatedOrderDate) || NA}
          </div>
          <div>
            <span tw="font-normal">Est. In-market Date: </span>
            {formatUtcDate(rfq.estimatedInMarketDate) || NA}
          </div>
        </div>
      </div>

      {!hidePricingTiers && (
        <div>
          <SectionTitle>Pricing Tiers</SectionTitle>
          <div tw="flex flex-wrap gap-2">
            {sortBy(tiers, "qty").map((tier) => (
              <Chip
                key={tier.id}
                label={formatNumber(tier.qty)}
                tw="bg-neutral-100 border-neutral-200 text-base"
              />
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default RfqSummary;
