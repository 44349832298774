import "twin.macro";

import { useSelector } from "react-redux";

import { Radio } from "@mui/material";

import { OrderSetStatus } from "@models/OrderSet";
import { ControlledRadioGroup } from "@utils/forms";

import { useFilterContext } from "../filterContext";
import Chip from "./Chip";

const useOrderSetStatuses = (): {
  [key in OrderSetStatus | "all"]?: string;
} => {
  const { approvalFlows, usesStripe, usesApprovalFlow } = useSelector(
    (state: any) => state.currentUser.organization
  );
  const twoRoundApproval = approvalFlows.length >= 2;
  return {
    all: "Any submitted",
    ...(usesApprovalFlow && { submitted: "Submitted" }),
    ...(twoRoundApproval && { "approval-review": "Approval Review" }),
    approved: "Approved",
    canceled: "Canceled",
    draft: "Draft",
    inactive: "Inactive",
    "partial-approval-error": "Approval Error",
    ...(usesStripe && { "missed-payment": "Missed Payment" }),
  };
};

const OrderSetStatusPopper = () => {
  const { control } = useFilterContext();

  const orderSetStatuses = useOrderSetStatuses();
  const orderSetStatusOptions = Object.keys(orderSetStatuses);

  return (
    <div tw="min-w-[200px]">
      <h4 tw="text-neutral-600 my-4 px-3">Order Set Status is</h4>

      <ControlledRadioGroup tw="w-full" name="orderSetStatus" control={control}>
        {orderSetStatusOptions.map((key) => (
          <label
            key={key}
            tw="flex items-center px-3 border-t border-neutral-200 text-sm hover:bg-neutral-100 cursor-pointer"
          >
            <Radio size="small" value={key} edge="start" />
            {orderSetStatuses[key]}
          </label>
        ))}
      </ControlledRadioGroup>
    </div>
  );
};

const OrderSetStatusFilterTile = (props) => {
  const orderSetStatuses = useOrderSetStatuses();
  const { filterValues } = useFilterContext();
  const OrderSetStatus = filterValues["orderSetStatus"];
  const OrderSetStatusText = OrderSetStatus && orderSetStatuses[OrderSetStatus];

  return (
    <Chip {...props}>
      <span>Status is&nbsp;</span>
      <b>{OrderSetStatusText}</b>
    </Chip>
  );
};

const OrderSetStatusFilter = {
  Chip: OrderSetStatusFilterTile,
  Popper: OrderSetStatusPopper,
};

export default OrderSetStatusFilter;
