import "twin.macro";

import { useState } from "react";
import { useBottomScrollListener } from "react-bottom-scroll-listener";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";

import { GetApp } from "@mui/icons-material";
import { Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

import CostCenterTable from "@components/BrandHQ/CostCenters/CostCenterTable";
import FilterPanel from "@components/Filtering/FilterPanel";
import {
  FixedHeightScrollLastChild,
  OpaqueCard,
  StyledButton,
  TableLoading,
} from "@components/StyledComponents";
import {
  fetchNextCostCenters,
  mapCostCenterReport,
} from "@redux/slices/costCenterSlice";
import { setError } from "@redux/slices/errorSlice";
import {
  resetStepperValue,
  setIsStepper,
  updateStepperValue,
} from "@redux/slices/globalStateSlice";
import DocTitle from "@utility/DocTitle";
import { csvDataFromSchema, downloadAsCsv } from "@utils/csv";

import { axiosGet } from "../api/axiosCalls";
import { useInitialFetch } from "../hooks/useInitialFetch";
import useUrlQuery from "../hooks/useUrlQuery";

const defaultFilters = {
  filterType: "cost-centers",
};

const useStyles = makeStyles((theme) => ({
  ...theme.global,
}));

const getCostCenterReport = async (dispatch) => {
  try {
    // Heads up, not sure cost-center view supports pagination yet :/
    dispatch(
      setIsStepper({
        stepBool: true,
        stepTitle: `Generating Cost Center Report`,
      })
    );
    let report = [];
    let url = "/api/cost-centers";
    let res = null;
    while (url) {
      res = await axiosGet(url);
      report = report.concat(res.data.map(mapCostCenterReport));
      url = res.data.nextLink;
      dispatch(
        updateStepperValue({ value: report.length / res.data.totalEntries })
      );
    }

    return report;
  } catch (error) {
    dispatch(setError({ error, source: "Cost Center Report" }));
  } finally {
    dispatch(resetStepperValue());
  }
};

const reportHeaders = [
  { label: "Id", id: "id" },
  { label: "Name", id: "name" },
  { label: "Total", id: "total" },
  { label: "Assigned to", id: "users" },
];

const CostCenterAdmin = () => {
  const params = useUrlQuery();

  const classes = useStyles();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [csvData, setCsvData] = useState([]);
  const [reportLoading, setReportLoading] = useState(false);

  const { costCenters, isLoading, nextLink } = useSelector(
    (state) => state.costCenters
  );

  const handleBottomScroll = () => {
    if (nextLink && !isLoading) {
      if (scrollRef.current.scrollTop !== 0) {
        dispatch(fetchNextCostCenters(nextLink));
      }
    }
  };

  const scrollRef = useBottomScrollListener(handleBottomScroll, {
    offset: 300,
    debounceOptions: {
      leading: true,
      trailing: false,
    },
  });

  const handleGetReport = async () => {
    setReportLoading(true);
    const report = await getCostCenterReport(dispatch);
    setCsvData(report);
    setReportLoading(false);
  };

  const handleCostCenterClick = (id, action) => {
    navigate(
      id
        ? `/admin/cost-centers/${action}/${id}`
        : `/admin/cost-centers/${action}`
    );
  };

  useInitialFetch(dispatch, "cost-centers", defaultFilters, params, true, true);

  return (
    <>
      <DocTitle title={"Cost Center Admin"} />
      <FixedHeightScrollLastChild>
        <header className={classes.titleBar}>
          <Typography className={classes.titleText}>
            Cost Center Admin
          </Typography>
          <div className={classes.innerConfigDiv}>
            {csvData.length === 0 && (
              <StyledButton
                outlined
                disabled={reportLoading}
                onClick={handleGetReport}
              >
                GENERATE REPORT
              </StyledButton>
            )}
            {csvData.length > 0 && (
              <StyledButton
                outlined
                startIcon={<GetApp />}
                onClick={() =>
                  downloadAsCsv(
                    csvDataFromSchema(reportHeaders, csvData),
                    "cost-centers.csv"
                  )
                }
              >
                EXPORT REPORT
              </StyledButton>
            )}
            <StyledButton
              cta
              onClick={() => handleCostCenterClick(null, "create")}
            >
              NEW COST CENTER
            </StyledButton>
          </div>
        </header>
        <FilterPanel />
        <OpaqueCard tw="relative overflow-hidden p-0">
          <CostCenterTable
            costCenters={costCenters}
            isLoading={isLoading}
            scrollRef={scrollRef}
            handleCostCenterClick={handleCostCenterClick}
          />
          <TableLoading isNextLoading={isLoading} />
        </OpaqueCard>
      </FixedHeightScrollLastChild>
    </>
  );
};

export default CostCenterAdmin;
