import tw from "twin.macro";

import { useFormContext } from "react-hook-form";

import { Radio } from "@mui/material";

import _ from "lodash";

import { SectionTitle } from "@components/StyledComponents";
import { FaIcon } from "@features/ui";
import { Item } from "@models/Item";
import { PricingTierQuote } from "@models/Quote";
import { formatMoneyString, formatNumber } from "@utility/utilityFunctions";
import {
  ControlledCheckboxInput,
  ControlledRadioGroup,
  ControlledTextInput,
  intValidation,
  moneyAdornment,
  moneyValidation,
} from "@utils/forms";

import { useQuote } from "../context/QuoteContext";
import VariablePricingTable from "./VariablePricingTable";

const LabelValuePair = ({ label, value }) => (
  <div tw="text-neutral-900 mb-2">
    <div tw="text-neutral-700 text-sm tracking-wide">{label}</div>
    <div css={[tw`text-lg`, !value && tw`opacity-40`]}>{value || "N/A"}</div>
  </div>
);

const QuoteTransferForm = ({ item }: { item: Item }) => {
  const { quote } = useQuote();
  const { control, setValue, watch } = useFormContext();

  const reg = (name, rules?) => ({
    name,
    control,
    rules,
  });

  const handleSelectPricingTier = (ptq: PricingTierQuote) => {
    setValue("cost", Number(ptq.cost).toFixed(2));
    setValue("price", Number(ptq.cost).toFixed(2));
  };

  const [
    transferCost,
    transferMoq,
    selectedTierId,
    transferOrderType,
    transferVariablePricing,
  ] = watch([
    "transferCost",
    "transferMoq",
    "selectedTierId",
    "transferOrderType",
    "transferVariablePricing",
  ]);

  const finalOrderType = transferOrderType
    ? quote.requestForQuote.orderType
    : item.orderType;

  return (
    <form tw="space-y-8" onSubmit={(e) => e.preventDefault()}>
      {quote.requestForQuote.orderType !== item.orderType && (
        <section tw="flex gap-4 items-end justify-between">
          <ControlledCheckboxInput
            {...reg("transferOrderType")}
            label={"Transfer Order Type"}
          />
          <div
            tw="flex gap-6 items-center"
            css={[!transferOrderType && tw`opacity-50`]}
          >
            <LabelValuePair label="Item Order Type" value={item.orderType} />
            <FaIcon icon={"arrow-right"} />
            <LabelValuePair
              label="Quote Order Type"
              value={quote.requestForQuote.orderType}
            />
          </div>
        </section>
      )}
      <section>
        <ControlledCheckboxInput
          {...reg("transferCost")}
          label={"Transfer Cost Information"}
        />
        {transferCost && (
          <div tw="mt-2 ml-8">
            <SectionTitle>Select a Tier to use as estimated cost</SectionTitle>

            <ControlledRadioGroup
              {...reg("selectedTierId", {
                required: "Select a quoted tier to use as estimated cost.",
              })}
              tw="w-full"
            >
              <div tw="space-y-1">
                {_.sortBy(quote.pricingTierQuotes, "pricingTierQty").map(
                  (ptq) => (
                    <label
                      key={ptq.id}
                      tw="py-2 px-3 rounded bg-neutral-100 flex cursor-pointer hover:bg-neutral-200 transition-colors"
                    >
                      <Radio
                        name="selectedTierId"
                        value={ptq.id}
                        tw="-m-2"
                        onChange={() => handleSelectPricingTier(ptq)}
                      />
                      <div tw="ml-4 flex-1 text-right">
                        {formatMoneyString(ptq.cost)}
                      </div>
                      <div tw="flex-[2] basis-auto text-right text-neutral-500">
                        Quantity tier {formatNumber(ptq.pricingTierQty)}
                      </div>
                    </label>
                  )
                )}
              </div>
            </ControlledRadioGroup>

            {selectedTierId && (
              <div tw="flex gap-4 mt-3">
                <ControlledTextInput
                  label="Cost"
                  {...reg("cost", { ...moneyValidation, required: true })}
                  helperText={`Previously ${formatMoneyString(item.cost)}`}
                  InputProps={moneyAdornment}
                  onFocus={(e) => e.target.select()}
                />
                <ControlledTextInput
                  label="Price"
                  {...reg("price", { ...moneyValidation, required: true })}
                  helperText={`Previously ${formatMoneyString(item.price)}`}
                  InputProps={moneyAdornment}
                  onFocus={(e) => e.target.select()}
                />
              </div>
            )}
          </div>
        )}
      </section>
      {finalOrderType === "on-demand" && (
        <section tw="space-y-2">
          <ControlledCheckboxInput
            {...reg("transferVariablePricing")}
            labelProps={{ sx: { alignItems: "flex-start" } }}
            label={
              <div tw="flex flex-col">
                <span>Set Quoted Tiers as Variable Pricing</span>
                <small tw="text-neutral-500">
                  On-demand only. This will override any existing variable
                  pricing
                </small>
              </div>
            }
          />
          {transferVariablePricing && (
            <div tw="ml-8">
              <VariablePricingTable />
            </div>
          )}
        </section>
      )}
      <section tw="grid grid-cols-2 gap-4 items-start">
        <ControlledCheckboxInput
          control={control}
          name="transferMoq"
          label="Transfer MOQ"
        />
        {transferMoq && (
          <div tw="flex items-baseline gap-4">
            <ControlledTextInput
              tw="inline-block max-w-64"
              label="MOQ"
              {...reg("moq", { ...intValidation, required: true })}
              helperText={`Previously ${formatNumber(item.minimumOrderQuantity)}`}
            />
          </div>
        )}
      </section>
    </form>
  );
};

export default QuoteTransferForm;
