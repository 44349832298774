import "twin.macro";

import { useEffect, useState } from "react";

import { ButtonProps } from "@mui/material";

import { filter } from "lodash";

import { LoadingButton } from "@features/ui";
import { Item, OrderSetVariant } from "@models";

const statusTexts = {
  inactive: "Inactive",
  outOfStock: "Out of Stock",
  inCart: "In Cart",
  addToCart: "Add to cart",
};

type Status = keyof typeof statusTexts;

const getStatus = (item: Item, orderSetVariants: OrderSetVariant[]): Status => {
  const orderSetVariantIds = orderSetVariants.map((osv) => osv.variant.id);

  if (filter(item.variants, "isActive").length === 0) return "inactive";

  const defaultVariant = item.variants.find((v) => v.isDefault);

  const allVariantsInCart =
    defaultVariant && defaultVariant.isActive
      ? orderSetVariantIds.includes(defaultVariant.id)
      : item.variants.every(
          (v) => v.isDefault || !v.isActive || orderSetVariantIds.includes(v.id)
        );

  if (allVariantsInCart) {
    return "inCart";
  }
  if (item.warehouse !== "shipstation" && item.onHand === 0) {
    return "outOfStock";
  }
  return "addToCart";
};

const AddToCartButton = ({
  item,
  orderSetVariants,
  addToOrderSet,
  ...props
}: {
  item: Item;
  orderSetVariants: OrderSetVariant[];
  addToOrderSet: (item: Item) => Promise<void>;
} & ButtonProps) => {
  const [loading, setLoading] = useState(false);
  const [status, setStatus] = useState<Status>(
    getStatus(item, orderSetVariants)
  );

  const handleAddToCart = async () => {
    setLoading(true);
    await addToOrderSet(item);
    setLoading(false);
  };

  useEffect(() => {
    if (loading) return;
    setStatus(getStatus(item, orderSetVariants));
  }, [loading, item, orderSetVariants]);

  return (
    <LoadingButton
      variant="outlined"
      loading={loading}
      disabled={status !== "addToCart"}
      onClick={handleAddToCart}
      tw="whitespace-nowrap"
      {...props}
    >
      {statusTexts[status]}
    </LoadingButton>
  );
};

export default AddToCartButton;
