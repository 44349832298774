import { cloneDeep } from "lodash";

const removeFromSwrCacheList = (mutate, requestPromise) =>
  mutate(requestPromise, {
    populateCache: (response, cache) => {
      if (!Array.isArray(cache)) {
        throw new Error(
          `Error removing entity from cache, expected cache to be from useSwrInfinite, instead found ${typeof cache}`
        );
      }
      let entityIndex = -1;
      const pageIndex = cache.findIndex((page) =>
        page.data.some((entity, index) => {
          if (entity.id === response.id) {
            entityIndex = index;
            return true;
          }
          return false;
        })
      );
      if (pageIndex === -1) return cache;

      const cacheCopy = cloneDeep(cache);
      cacheCopy[pageIndex].data.splice(entityIndex, 1);
      cacheCopy[0].meta.total_entries -= 1;
      return cacheCopy;
    },
    // Since the API already gives us the updated information,
    // we don't need to revalidate here.
    revalidate: false,
  });

export default removeFromSwrCacheList;
