import { Transformer } from "@services/api";

import { Address } from "./Address";
import { AsyncResource } from "./AsyncResource";
import { OrderVariant } from "./OrderVariant";
import { User } from "./User";

type DateString = string;

export type OrderType = "inventory" | "pre-order" | "on-demand";
export type OrderStatus =
  | "draft"
  | "submitted"
  | "approval-review"
  | "approved"
  | "canceled"
  | "error";

export type CheckoutType = null | "stripe" | "budget" | "default";

export type Order = {
  address: Address;
  addressExternalId?: string;
  approvedAt: DateString;
  attn?: string;
  budgetNames: string[];
  cancelationNote: string | null;
  cancelationType: "order" | "denial" | null;
  checkoutType: CheckoutType;
  costCenterName?: string;
  expeditedDate?: DateString;
  id: string;
  isExpedited: boolean;
  canDeliverEarly: boolean;
  notes?: string;
  orderVariants: OrderVariant[];
  organization: AsyncResource<"organization">;
  poIds: string[];
  programName?: string;
  status: OrderStatus;
  submittedAt: DateString;
  submittedBy: null | string;
  territoryId: number;
  territoryName: string;
  totalActualShippingCost: number;
  totalEstimatedShippingCost: number;
  totalActualTax: number | null;
  totalEstimatedTax: number;
  totalPrice: number;
  totalPromotionDiscount: number;
  totalQuantity: number;
  type: string;
  user: User;
  userExternalId?: string;
  isForEvent: boolean;
};

const orderTransformer: Transformer = {
  type: ["order", "orders"],
  deserialize: (attr) => ({
    ...attr,
    totalActualShippingCost: Number(attr.totalActualShippingCost),
    totalEstimatedShippingCost: Number(attr.totalEstimatedShippingCost),
    totalEstimatedTax: Number(attr.totalEstimatedTax),
    totalPrice: Number(attr.totalPrice),
    totalPromotionDiscount: Number(attr.totalPromotionDiscount),
  }),
};

export default orderTransformer;
