import React from "react";

import { Image } from "@models/Image";

type ItemImagesContextProps = {
  handleUpload: () => void;
  images: Image[];
  setImages: React.Dispatch<React.SetStateAction<Image[]>>;
};

export const ItemImagesContext = React.createContext<ItemImagesContextProps>(
  {} as any
);

export const useItemImages = () => React.useContext(ItemImagesContext);
