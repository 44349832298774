import "twin.macro";

import { useState } from "react";

import { EmojiEvents } from "@mui/icons-material";
import { Button } from "@mui/material";

import { StyledButton } from "@components/StyledComponents";
import useConfirm from "@features/confirm";
import { useQuote } from "@features/quotes";

import AwardedQuoteModal from "./AwardedQuoteModal";
import { useQuoteActionMutation } from "./data/quoteMutations";

const AwardQuoteButton = () => {
  const { quote } = useQuote();
  const confirm = useConfirm();
  const doQuoteAction = useQuoteActionMutation();
  const [open, setOpen] = useState(false);

  const handleAwardQuote = () => {
    doQuoteAction.mutate(
      { id: quote.id, action: "award" },
      { onSuccess: () => setOpen(true) }
    );
  };
  const handleUnawardQuote = async () => {
    if (!(await confirm("Are you sure you want to unaward this quote?")))
      return;
    doQuoteAction.mutate({ id: quote.id, action: "unaward" });
  };

  return (
    <div tw="flex gap-4 shrink-0">
      {open && <AwardedQuoteModal handleClose={() => setOpen(false)} />}

      {quote.status === "awarded" ? (
        <>
          <Button
            variant="text"
            color="secondary"
            onClick={() => setOpen(true)}
          >
            Transfer to Item
          </Button>
          <StyledButton
            startIcon={<EmojiEvents />}
            outlined
            loading={doQuoteAction.isPending}
            onClick={handleUnawardQuote}
          >
            Awarded
          </StyledButton>
        </>
      ) : (
        <StyledButton
          startIcon={<EmojiEvents />}
          cta
          loading={doQuoteAction.isPending}
          onClick={handleAwardQuote}
        >
          Award Quote
        </StyledButton>
      )}
    </div>
  );
};

export default AwardQuoteButton;
