import "twin.macro";

import { Tooltip } from "@mui/material";

import { Item } from "@models";

import { countVariantOptions } from "../helpers";

const ItemOptionsCount = ({ item }: { item: Item }) => {
  return (
    <div tw="flex gap-2 flex-wrap text-sm text-neutral-500 mt-2">
      Options:
      {Object.entries(countVariantOptions(item)).map(([key, value]) => (
        <Tooltip
          key={key}
          title={
            <ul>
              {value.map((v) => (
                <li key={v}>{v}</li>
              ))}
            </ul>
          }
        >
          <div tw="text-primary-800 bg-primary-50 rounded-full px-2">
            {value.length}
            <span tw="font-medium"> {key}</span>
          </div>
        </Tooltip>
      ))}
    </div>
  );
};

export default ItemOptionsCount;
