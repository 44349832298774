import "twin.macro";

import { memo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Paper, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

import useConfirm from "@features/confirm";
import { updatePurchaseOrderStatus } from "@redux/slices/purchaseOrders/purchaseOrderSlice";
import permissions from "@utils/permissions";
import useRoleIs from "@utils/useRoleIs";

import { StyledButton } from "../StyledComponents";
import CancelPurchaseOrderModal from "./CancelPurchaseOrderModal";
import DeclinePurchaseOrderModal from "./DeclinePurchaseOrderModal";
import PurchaseOrderSupplierForm from "./PurchaseOrderSupplierForm";

const useStyles = makeStyles((theme) => ({
  ...theme.global,
  paperWrapper: {
    boxSizing: "border-box",
    padding: "20px",
    marginTop: "15px",
    border: `2px solid ${theme.palette.secondary.light}`,
    textAlign: "center",
  },
  buttonRow: {
    display: "flex",
    width: "100%",
    justifyContent: "center",
  },
}));

const PurchaseOrderControl = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const confirm = useConfirm();

  const roleIs = useRoleIs();

  const [cancelModalOpen, setCancelModalOpen] = useState(false);
  const [declineModalOpen, setDeclineModalOpen] = useState(false);

  const { currentPurchaseOrder } = useSelector((state) => state.purchaseOrder);
  const {
    organization: { hasPurchasingAgents },
  } = useSelector((state) => state.currentUser);

  const isSupplierRole = roleIs(permissions.supplier);

  const handleUpdateStatus = (action, body) => {
    dispatch(updatePurchaseOrderStatus(currentPurchaseOrder.id, action, body));
  };

  const handleDecline = async (reason) => {
    handleUpdateStatus("decline", {
      "decline-reason": reason,
    });
    setDeclineModalOpen(false);
  };
  const handleCancel = async (reason) => {
    handleUpdateStatus("cancel", {
      "cancelation-type": "order",
      "cancelation-note": reason,
    });
    setDeclineModalOpen(false);
  };

  return (
    <section tw="mt-6 flex flex-col items-center">
      {cancelModalOpen && (
        <CancelPurchaseOrderModal
          handleClose={() => setCancelModalOpen(false)}
          handleCancel={handleCancel}
        />
      )}
      {declineModalOpen && (
        <DeclinePurchaseOrderModal
          handleClose={() => setDeclineModalOpen(false)}
          handleDecline={handleDecline}
        />
      )}
      {currentPurchaseOrder.status === "draft" && (
        <div tw="flex gap-2">
          {hasPurchasingAgents && (
            <StyledButton
              cta
              onClick={() => handleUpdateStatus("ready-to-purchase")}
            >
              Notify Purchase Agent
            </StyledButton>
          )}
          <StyledButton cta onClick={() => handleUpdateStatus("submit")}>
            Notify Supplier
          </StyledButton>
        </div>
      )}
      {currentPurchaseOrder.status === "ready-to-purchase" && (
        <>
          {!roleIs("purchasing-agent") && (
            <>
              <Typography className={classes.headerText}>
                {`This purchase order has been sent to a Purchasing Agent and is awaiting a response.`}
              </Typography>

              {/* Allow non-purchasing agents to move it along if required */}
              <StyledButton
                tw="mt-2"
                outlined
                onClick={async () =>
                  (await confirm(
                    "This action will notify the Supplier directly that the Purchase Order is ready to review."
                  )) && handleUpdateStatus("submit")
                }
              >
                FORCE SUBMIT
              </StyledButton>
            </>
          )}

          {roleIs("purchasing-agent") && (
            <StyledButton cta onClick={() => handleUpdateStatus("submit")}>
              SUBMIT
            </StyledButton>
          )}
        </>
      )}
      {currentPurchaseOrder.status === "submitted" && !isSupplierRole && (
        <>
          <StyledButton cta onClick={() => setCancelModalOpen(true)}>
            CANCEL
          </StyledButton>
          <Paper elevation={6} className={classes.paperWrapper}>
            <Typography className={classes.headerText}>
              {`This purchase order has been submitted, and is waiting on a response from ${currentPurchaseOrder.supplier}.`}
            </Typography>
          </Paper>
        </>
      )}
      {currentPurchaseOrder.status === "submitted" && isSupplierRole && (
        <div className={classes.buttonRow}>
          <StyledButton
            cta
            style={{ marginRight: "20px" }}
            onClick={() => handleUpdateStatus("accept")}
          >
            ACCEPT
          </StyledButton>
          <StyledButton cta onClick={() => setDeclineModalOpen(true)}>
            DECLINE
          </StyledButton>
        </div>
      )}
      {currentPurchaseOrder.status === "in-progress" && (
        <PurchaseOrderSupplierForm
          handleUpdateStatus={handleUpdateStatus}
          handleCancelOpen={() => setCancelModalOpen(true)}
        />
      )}
      {currentPurchaseOrder.status === "received" && !isSupplierRole && (
        <StyledButton cta onClick={() => handleUpdateStatus("complete")}>
          COMPLETE
        </StyledButton>
      )}
      {currentPurchaseOrder.status === "complete" && (
        <Paper elevation={6} className={classes.paperWrapper}>
          <Typography className={classes.headerText}>
            {currentPurchaseOrder.type === "fulfillment"
              ? "This Inventory Fulfillment Is Complete"
              : "This purchase order has been completed"}
          </Typography>
        </Paper>
      )}
      {currentPurchaseOrder.status === "canceled" && (
        <Paper elevation={6} className={classes.paperWrapper}>
          <Typography className={classes.headerText}>
            {`This purchase order has been canceled`}
          </Typography>
        </Paper>
      )}
    </section>
  );
};

export default memo(PurchaseOrderControl);
