import tw from "twin.macro";

import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";

import { Close } from "@mui/icons-material";
import {
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
} from "@mui/material";

import { addDays } from "date-fns";
import _ from "lodash";
import { utcDate } from "src/utility/utilityFunctions";

import ReactQueryTable from "@components/Table/ReactQueryTable";
import { variantName } from "@features/items";
import { useOrderVariantsListQuery } from "@features/orderVariants";
import { ItemRollup } from "@models/ItemRollup";
import { OrderVariant } from "@models/OrderVariant";
import { createFulfillmentPurchaseOrder } from "@redux/slices/purchaseOrders/purchaseOrderSlice";
import { DatePicker } from "@utils/forms";

import { StyledButton } from "../StyledComponents";

const OrderVariantList = ({
  singleItemRollup,
  orderVariantIds,
  selectedOvs,
  setSelectedOvs,
  setValid,
}: {
  singleItemRollup: ItemRollup;
  orderVariantIds: number[];
  selectedOvs: OrderVariant[];
  setSelectedOvs: React.Dispatch<React.SetStateAction<OrderVariant[]>>;
  setValid: (val: boolean) => void;
}) => {
  const { data = [], ...tableProps } = useOrderVariantsListQuery({
    filter: { ids: orderVariantIds },
    skipPagination: true,
  });

  const orderVariants = _.sortBy(data, "order.id");
  const intersect = _.intersectionBy(orderVariants, selectedOvs, "id");
  const qtySelected = _.sumBy(intersect, "qty");
  const over = qtySelected > singleItemRollup.globalAvailableToOrderQty;

  useEffect(() => {
    setValid(!over);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [over]);

  return (
    <div>
      <div
        css={[
          tw`flex items-center justify-between px-2 py-1 -mx-2 text-sm font-medium border rounded bg-neutral-50 border-neutral-200`,
          qtySelected > 0 && tw`bg-primary-50 border-primary-100`,
          over && tw`border-red-100 bg-red-50`,
        ]}
      >
        <h3>
          {singleItemRollup.itemNumber} /{" "}
          {variantName(singleItemRollup.variant) || "Default variant"}
        </h3>
        <div>
          <span
            css={[
              qtySelected > singleItemRollup.globalAvailableToOrderQty &&
                tw`text-red-700`,
            ]}
          >
            {qtySelected}
          </span>
          <span> / {singleItemRollup.globalAvailableToOrderQty}</span>
        </div>
      </div>
      <ReactQueryTable
        rows={orderVariants}
        columns={[
          {
            id: "id",
            label: (
              <Checkbox
                tw="-m-4"
                size="small"
                checked={intersect.length === orderVariants.length}
                indeterminate={
                  intersect.length > 0 &&
                  intersect.length < orderVariantIds.length
                }
                onChange={() =>
                  setSelectedOvs((ovs) =>
                    intersect.length === orderVariants.length
                      ? _.differenceBy(ovs, orderVariants, "id")
                      : _.unionBy(ovs, orderVariants, "id")
                  )
                }
              />
            ),
            render: (id, ov) => (
              <Checkbox
                tw="-m-4"
                size="small"
                checked={selectedOvs.some((ov) => ov.id === id)}
                onChange={() =>
                  setSelectedOvs((ovs) => _.xorBy(ovs, [ov], "id"))
                }
              />
            ),
          },
          { id: "order.id", label: "Order #" },
          {
            id: "_address",
            label: "Address",
            render: (_, ov) => [ov.addressName, ov.state].join(", "),
          },
          { id: "qty", label: "Qty", align: "right" },
        ]}
        {...tableProps}
        maxHeight={300}
      />
    </div>
  );
};

const FulfillFromInventoryModal = ({
  handleClose,
  itemRollups,
  onCreateCallback,
}: {
  handleClose: () => void;
  itemRollups: ItemRollup[];
  onCreateCallback: (po: any) => void;
}) => {
  const dispatch = useDispatch();

  const { inMarketDate: dateFromItemRollup, itemNumber } = itemRollups[0];
  const orderVariantIdsByVariant = _(itemRollups)
    .groupBy("itemNumber")
    .mapValues((irs) => irs.flatMap((ir) => ir.orderVariantIds))
    .value();

  const [valid, setValid] = useState<boolean[]>(
    new Array(Object.keys(orderVariantIdsByVariant).length).fill(true)
  );
  const [loading, setLoading] = useState(false);
  const [selectedOvs, setSelectedOvs] = useState<OrderVariant[]>([]);
  const [inMarketDate, setInMarketDate] = useState<Date | null>(
    _.isDate(utcDate(dateFromItemRollup))
      ? utcDate(dateFromItemRollup)
      : addDays(new Date(), 5)
  );

  const handleCreateFulfillmentPurchaseOrder = async () => {
    setLoading(true);
    const ovIds = _.map(selectedOvs, "id");
    dispatch(
      createFulfillmentPurchaseOrder(
        {
          orderVariantIds: ovIds,
          inMarketDate: inMarketDate,
          type: "fulfillment",
        },
        (po) => {
          setLoading(false);
          onCreateCallback(po);
          handleClose();
        }
      )
    );
  };

  return (
    <Dialog open onClose={handleClose} fullWidth>
      <DialogTitle tw="flex justify-between items-center">
        Creating Fulfillment PO for #{itemNumber.split("-")[0]}
        <IconButton onClick={handleClose} edge="end">
          <Close />
        </IconButton>
      </DialogTitle>
      <DialogContent tw="space-y-6">
        {Object.entries(orderVariantIdsByVariant).map(
          ([itemNumber, orderVariantIds], i) => (
            <OrderVariantList
              key={itemNumber}
              singleItemRollup={
                itemRollups.find((ir) => ir.itemNumber === itemNumber)!
              }
              orderVariantIds={orderVariantIds}
              selectedOvs={selectedOvs}
              setSelectedOvs={setSelectedOvs}
              setValid={(val) =>
                setValid((valid) =>
                  valid.map((v, idx) => (idx === i ? val : v))
                )
              }
            />
          )
        )}
      </DialogContent>
      <DialogActions tw="justify-between px-6">
        <DatePicker
          label="In Market Date"
          disablePast
          value={inMarketDate}
          onChange={setInMarketDate}
        />
        <StyledButton
          cta
          onClick={handleCreateFulfillmentPurchaseOrder}
          loading={loading}
          disabled={selectedOvs.length === 0 || valid.some((v) => !v)}
        >
          Create Fulfillment PO
        </StyledButton>
      </DialogActions>
    </Dialog>
  );
};

export default FulfillFromInventoryModal;
