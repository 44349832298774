import "twin.macro";

import { Tab, Tabs } from "@mui/material";

import {
  FixedHeightScrollLastChild,
  OpaqueCard,
  PageTitle,
} from "@components/StyledComponents";
import { Filters, useFilterParams } from "@features/filters";
import { QuotesTable, usePaginatedQuoteQuery } from "@features/quotes";
import { RequestReportButton, useReport } from "@features/reports";
import {
  NewRfqButton,
  QuotedItemsTable,
  usePaginatedQuotedItemsQuery,
} from "@features/rfqs";
import { useSetLocation } from "@services/reactRouterDom";
import DocTitle from "@utility/DocTitle";

const RfqItemView = () => {
  const [filterParams] = useFilterParams();

  const { data, ...tableProps } = usePaginatedQuotedItemsQuery({
    filter: {
      programIds: filterParams.programs,
      searchTerm: filterParams.q,
      supplierIds: filterParams.suppliers,
      quoteStatus: filterParams.status?.split(","),
    },
  });

  return (
    <QuotedItemsTable
      {...tableProps}
      query={filterParams.q}
      rows={data ?? []}
    />
  );
};

const RfqQuoteView = () => {
  const [filterParams] = useFilterParams();

  const params = {
    filter: {
      programIds: filterParams.programs,
      searchTerm: filterParams.q,
      supplierIds: filterParams.suppliers,
      status: filterParams.status?.split(","),
    },
    sort: "-updated-at",
  };

  const { data, ...tableProps } = usePaginatedQuoteQuery(params);
  useReport("quotes", ["quotes", params]);

  return <QuotesTable {...tableProps} rows={data ?? []} />;
};

const QuotedItems = ({ view }: { view: "items" | "quotes" }) => {
  const setLocation = useSetLocation();

  return (
    <FixedHeightScrollLastChild>
      <DocTitle title={"Quote Requests"} />
      <div tw="flex justify-between items-center">
        <div tw="flex items-center gap-4">
          <PageTitle>Quote Requests</PageTitle>
          <Tabs
            value={view ?? ""}
            onChange={(_e, v) => setLocation({ pathname: `/rfqs/${v}` })}
            tw="min-h-0"
            indicatorColor="secondary"
          >
            <Tab
              label="Items"
              value={"items"}
              tw="py-2 min-h-0 hover:text-neutral-600 tracking-wider"
            />
            <Tab
              label="Quotes"
              value={"quotes"}
              tw="py-2 min-h-0 hover:text-neutral-600 tracking-wider"
            />
          </Tabs>
        </div>
        <div tw="flex items-center gap-4">
          {view === "quotes" && <RequestReportButton reportName="quotes" />}
          <NewRfqButton />
        </div>
      </div>
      <Filters
        searchTitle="Search Item"
        slots={["programs", "suppliers", "quoteStatus"]}
      />
      <OpaqueCard tw="p-0 overflow-hidden relative">
        {view === "items" ? <RfqItemView /> : <RfqQuoteView />}
      </OpaqueCard>
    </FixedHeightScrollLastChild>
  );
};

export default QuotedItems;
