import "twin.macro";

import BlockCard from "../../ui/BlockCard";
import EditableTextList from "./EditableTextList";

const Strategies = () => {
  return (
    <BlockCard title="Strategies">
      <EditableTextList listName="strategies" />
    </BlockCard>
  );
};

export default Strategies;
