import "twin.macro";

import React from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router";

import { OpaqueCard } from "@components/StyledComponents";
import ReactQueryTable, {
  PaginatedResourceProps,
} from "@components/Table/ReactQueryTable";
import { OrderWindow } from "@models/OrderWindow";
import { formatDateString } from "@utility/utilityFunctions";

export type TableProps = PaginatedResourceProps & {
  rows: OrderWindow[];
};

const OrderWindowAdminTable = ({ rows, ...props }: TableProps) => {
  const hasChannels = useSelector(
    (state: any) => !!state.currentUser.currentChannelId
  );
  return (
    <OpaqueCard tw="p-0 overflow-hidden relative">
      <ReactQueryTable
        columns={[
          {
            id: "name",
            label: "Name",
            render: (name, row) => (
              <Link
                tw="underline font-medium"
                to={`/admin/order-windows/edit/${row.id}`}
              >
                {name}
              </Link>
            ),
          },
          ...(hasChannels ? [{ id: "channel.name", label: "Channel" }] : []),
          {
            id: "openDate",
            label: "Open Date",
            sort: "open-date",
            render: formatDateString,
          },
          {
            id: "closeDate",
            label: "Close Date",
            sort: "close-date",
            render: formatDateString,
          },
          {
            id: "reviewCloseDate",
            label: "Review Close Date",
            sort: "review-close-date",
            render: formatDateString,
          },
          {
            id: "inMarketDate",
            label: "In Market Date",
            sort: "in-market-date",
            render: formatDateString,
          },
        ]}
        rows={rows}
        {...props}
      />
    </OpaqueCard>
  );
};

export default OrderWindowAdminTable;
