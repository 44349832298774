import _ from "lodash";

import { useCurrentOrderSet } from "../CurrentOrderSetContext";

// a hook to determine which items on the order set require customization, and if the requirements have been met for submission.
export default function useCustomizationRequirements() {
  const { orderSetVariants } = useCurrentOrderSet();
  const customizableOrderSetVariants = _(orderSetVariants)
    .filter((osv) => osv.variant.item.isCustomizable && !!osv.totalQty)
    .groupBy((osv) => osv.variant.item.id)
    .value();

  const requiresCustomization = _(customizableOrderSetVariants)
    .entries()
    .some(([_itemId, osvs]) => osvs.some((osv) => !osv.customization));

  return {
    requiresCustomization,
    customizableOrderSetVariants,
  };
}
