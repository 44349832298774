import tw, { styled } from "twin.macro";

import { memo } from "react";

import { Button, Chip, CircularProgress, Typography } from "@mui/material";

import clsx from "clsx";
import _ from "lodash";
import PropTypes from "prop-types";

import { Filters, useFilterParams } from "@features/filters";
import {
  ItemCatalogView,
  ItemViewButtons,
  usePaginatedItemsQuery,
} from "@features/items";

import { StyledButton } from "../../StyledComponents";

const ScrollContainer = styled.div({
  "&": tw`flex flex-col gap-3 overflow-hidden max-h-[calc(100vh - 228px)] w-full! pb-3`,
  "& > *": tw`flex-none`,
  "& > *:last-child": tw`flex-auto flex-grow-0 h-screen overflow-hidden`,
});
const ItemPanel = ({
  classes,
  type,
  currentItems,
  setCurrentItems,
  formData,
  handleSubmit,
  promotion,
}) => {
  const [filterParams] = useFilterParams();
  const params = {
    filter: {
      isActive: true,
      query: filterParams.q,
      favorites: filterParams.favorites,
      orderType: filterParams.orderType,
      programIds: filterParams.programs,
      groupIds:
        filterParams.groups && Object.values(filterParams.groups).flat(),
    },
    sort: filterParams.sort,
  };

  const { data: items, ...tableProps } = usePaginatedItemsQuery(params);

  const handleRemoveItem = (id) => {
    setCurrentItems(currentItems.filter((i) => i.id !== id));
  };

  if (type === "edit" && !promotion) {
    return (
      <>
        <br />
        <br />
        <CircularProgress color="secondary" />
      </>
    );
  }

  return (
    <>
      {formData.type !== "item" && (
        <>
          <Typography
            className={clsx(classes.bodyText, classes.settingsMargin)}
          >
            Items are not assigned to Order type promotions, if you would like
            to assign specific items to the promotion, switch the promotion type
            to Item.
          </Typography>
          {type === "edit" && (
            <>
              <Button
                className={clsx(classes.button, classes.settingsMargin)}
                variant="contained"
                color="secondary"
                onClick={handleSubmit}
                style={{ minWidth: "62px" }}
              >
                SAVE
              </Button>
            </>
          )}
        </>
      )}
      {formData.type === "item" && (
        <ScrollContainer>
          <header className={classes.titleBar}>
            <Typography className={classes.headerText}>
              Select Items to add to the Promotion
            </Typography>
            <div className={classes.innerConfigDiv}>
              {type === "edit" && (
                <Button
                  className={classes.button}
                  variant="contained"
                  color="secondary"
                  onClick={handleSubmit}
                >
                  SAVE
                </Button>
              )}
            </div>
          </header>
          <div className={classes.chipDiv}>
            {currentItems.length > 0 &&
              currentItems.map((item, i) => (
                <Chip
                  style={{ margin: "2.5px" }}
                  size="small"
                  color="primary"
                  key={`${i}-${item.id}`}
                  label={item.defaultExternalWarehouseId ?? item.sku}
                  onDelete={() => handleRemoveItem(item.id)}
                />
              ))}
          </div>

          <div tw="flex gap-2 items-start justify-between">
            <Filters
              searchTitle="Search Items"
              slots={[
                "favorites",
                "orderType",
                "groups",
                "territories",
                "programs",
              ]}
            />
            <ItemViewButtons />
          </div>
          <ItemCatalogView
            rows={items}
            query={filterParams.q}
            {...tableProps}
            rowActions={(item) => (
              <StyledButton
                outlined
                size="small"
                onClick={() =>
                  setCurrentItems((items) => _.xorBy(items, [item], "id"))
                }
              >
                {currentItems.some((i) => i.id === item.id) ? "Remove" : "Add"}
              </StyledButton>
            )}
          />
        </ScrollContainer>
      )}
    </>
  );
};

ItemPanel.propTypes = {
  classes: PropTypes.object.isRequired,
  type: PropTypes.string,
  currentItems: PropTypes.array,
  setCurrentItems: PropTypes.func.isRequired,
  formData: PropTypes.object.isRequired,

  handleSubmit: PropTypes.func.isRequired,
  promotion: PropTypes.object,
};

export default memo(ItemPanel);
