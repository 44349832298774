import "twin.macro";

import { useWatch } from "react-hook-form";
import { Link } from "react-router";

import { ResourceError } from "@features/errors";
import { QuoteStatus } from "@features/quotes";
import { NewRfqButton } from "@features/rfqs";
import { useRfqListQuery } from "@features/rfqs";
import { DateFromNow } from "@features/ui";
import { utcToLocalDate } from "@utility/utilityFunctions";

import BlockCard from "../../ui/BlockCard";

const Quotes = () => {
  const id = useWatch({ name: "id" });
  const { data, error } = useRfqListQuery({
    filter: { programIds: id },
  });
  const rfqs =
    data?.filter((rfq) => !["draft", "canceled"].includes(rfq.status)) ?? [];

  return (
    <BlockCard
      title="Quotes"
      titleElement={
        <NewRfqButton
          tw="normal-case py-1 shadow-none hover:(shadow-none bg-neutral-100!)"
          programId={id}
          label="New"
          cta={false}
        />
      }
    >
      {error && <ResourceError error={error} />}
      {data && rfqs.length === 0 && (
        <p tw="text-neutral-600 font-light">
          No quotes in progress for this program.
        </p>
      )}
      <div tw="-mt-2 -mx-6 px-3 max-h-48 overflow-y-auto">
        {rfqs.map((rfq) => (
          <div
            key={rfq.id}
            tw="px-3 py-2 rounded hover:bg-neutral-100 transition-colors"
          >
            <Link
              to={`/rfqs/${rfq.id}`}
              key={rfq.id}
              tw="text-sm text-neutral-600 hover:underline"
            >
              {rfq.itemName} • round {rfq.round}
            </Link>
            {rfq.quotes.map((quote) => (
              <Link
                to={
                  quote.isComplete
                    ? `/quotes/${quote.id}`
                    : `/rfqs/${quote.requestForQuote.id}`
                }
                key={quote.id}
                tw="block py-1 rounded transition-colors text-neutral-700 hover:(text-neutral-900)"
              >
                <div tw="flex gap-3 justify-between text-sm">
                  <div>
                    <QuoteStatus status={quote.status} />
                  </div>
                  <div tw="max-w-[10em] text-right whitespace-nowrap overflow-hidden text-ellipsis">
                    {quote.supplierName}
                  </div>
                </div>
                <div tw="text-neutral-500 text-xs">
                  <DateFromNow date={utcToLocalDate(quote.updatedAt)} />
                </div>
              </Link>
            ))}
          </div>
        ))}
      </div>
    </BlockCard>
  );
};

export default Quotes;
