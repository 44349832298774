import "twin.macro";
import { css } from "twin.macro";

import React, { useMemo } from "react";
import { useFormContext } from "react-hook-form";

import _ from "lodash";

import { OpaqueCard } from "@components/StyledComponents";
import ReactQueryTable from "@components/Table/ReactQueryTable";
import { PricingTierQuote } from "@models/Quote";
import { formatMoneyString, formatNumber } from "@utility/utilityFunctions";
import {
  ControlledTextInput,
  moneyAdornment,
  moneyValidation,
} from "@utils/forms";

import { useQuote } from "../context";

type PricingTierQuoteWithMaxQuantity = PricingTierQuote & {
  maxQuantity: string;
};

const VariablePricingTable = () => {
  const { quote } = useQuote();
  const { control } = useFormContext();
  const rows = useMemo(() => {
    const res: PricingTierQuoteWithMaxQuantity[] = [];
    const ptqs = _.sortBy(quote.pricingTierQuotes, "pricingTierQty");
    for (let i = 0; i < ptqs.length; i++) {
      res.push({
        ...ptqs[i],
        maxQuantity:
          i === ptqs.length - 1
            ? `Over ${formatNumber(ptqs[i].pricingTierQty)}`
            : `${formatNumber(ptqs[i].pricingTierQty)} - ${formatNumber(
                ptqs[i + 1].pricingTierQty - 1
              )}`,
      });
    }
    return _.sortBy(res, "maxQuantity");
  }, [quote.pricingTierQuotes]);
  return (
    <OpaqueCard tw="p-0 border-2 border-neutral-200">
      <ReactQueryTable
        tableSize="small"
        css={css`
          td {
            vertical-align: middle;
            font-size: 1rem;
          }
        `}
        columns={[
          {
            id: "maxQuantity",
            label: "Qty",
          },
          {
            id: "_price",
            label: "Price",
            align: "right",
            render: (_v, ptq) => (
              <ControlledTextInput
                control={control}
                tw="w-36"
                name={`variablePricingPrices.${ptq.id}`}
                rules={{ ...moneyValidation, required: true }}
                InputProps={moneyAdornment}
                onFocus={(e) => e.target.select()}
              />
            ),
          },

          {
            id: "cost",
            label: "Cost",
            align: "right",
            render: formatMoneyString,
          },
        ]}
        rows={rows}
      />
    </OpaqueCard>
  );
};

export default VariablePricingTable;
