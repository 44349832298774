import "twin.macro";

import React, { ReactNode } from "react";

import { PageTitle } from "@components/StyledComponents";

import BackButton from "./BackButton";

const Title: React.FC<{
  backUrl?: string | boolean;
  override?: string | false | null;
  children: ReactNode;
  [key: string]: any;
}> = ({ backUrl, override, children, ...props }) => {
  return (
    <div tw="flex gap-2 items-center" {...props}>
      {backUrl && (
        <BackButton
          defaultUrl={typeof backUrl === "string" ? backUrl : "/"}
          override={override || undefined}
        />
      )}
      <PageTitle>{children}</PageTitle>
    </div>
  );
};

export default Title;
