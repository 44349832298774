import tw from "twin.macro";

import { useState } from "react";
import { Control, Controller } from "react-hook-form";

import { Autocomplete, Chip } from "@mui/material";

import { formatNumber } from "@utility/utilityFunctions";
import { StyledTextInput } from "@utils/forms/ControlledInputs";

const RfqQuantitiesInput = ({
  control,
  name,
  onChange,
  ...props
}: {
  control: Control<any>;
  name: string;
  placeholder?: string;
  helperText?: string;
  label?: string;
  onChange?: (value: any) => void;
}) => {
  const [value, setValue] = useState("");

  return (
    <Controller
      name={name}
      control={control}
      rules={{
        required:
          "You must add at least one pricing tier to send the quote request.",
      }}
      render={({ field, fieldState }) => (
        <Autocomplete
          multiple
          options={[]}
          freeSolo // allow user to type in values
          autoSelect // auto-add the typed value on blur
          inputValue={value}
          css={{ input: field.value.length > 0 && tw`ml-2` }}
          onInputChange={(e, value) => {
            if (value.at(-1) === "," && value.length > 1) {
              field.onChange([...field.value, value.slice(0, -1)]);
              setValue("");
            } else {
              setValue(value.replace(/\D/g, ""));
            }
          }}
          renderTags={(value: readonly string[], getTagProps) =>
            value.map((option: string, index: number) => (
              <Chip
                variant="outlined"
                tw="flex last:mr-6! bg-neutral-100 border-neutral-200"
                label={formatNumber(+option)}
                {...getTagProps({ index })}
              />
            ))
          }
          renderInput={(params) => (
            <StyledTextInput
              {...params}
              {...props}
              error={!!fieldState.error}
              {...(fieldState.error && {
                helperText: fieldState.error?.message,
              })}
            />
          )}
          {...field}
          onChange={(e, value) => {
            field.onChange(value);
            onChange?.(value);
          }}
        />
      )}
    />
  );
};

export default RfqQuantitiesInput;
