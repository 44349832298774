import "twin.macro";

import { useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router";

import { ExitToAppTwoTone } from "@mui/icons-material";
import { Alert, Button } from "@mui/material";

import { Contained } from "@components/StyledComponents";
import { ItemCatalogView } from "@features/items";
import { useIsOrderWindowOrderable } from "@features/orderWindows";
import {
  AddToCartButton,
  VariantSelectionModal,
  useAddToCart,
  useCreateOrderSetMutation,
  useCurrentPreOrderOrderSetQuery,
} from "@features/ordering";
import {
  ProgramDetails,
  useProgramItems,
  useProgramQuery,
} from "@features/programs";
import { LoadingButton } from "@features/ui";
import { useMutateError } from "@services/api";
import DocTitle from "@utility/DocTitle";

/*
The program view displays all information for the selected program, and also gives access to view all items
in the program. From there the user can also create a pdf based on the items in the program.
*/

const ProgramOrder = () => {
  const setError = useMutateError();
  const navigate = useNavigate();
  const { programId, orderWindowId } = useParams() as {
    programId: string;
    orderWindowId: string | undefined;
  };

  const { currentChannelId, currentTerritoryId } = useSelector(
    (state: any) => state.currentUser
  );

  const { orderSet, isLoading: isOrderSetLoading } =
    useCurrentPreOrderOrderSetQuery(programId, orderWindowId);

  const createOrderSetMutation = useCreateOrderSetMutation();

  const handleStartOrderSet = () => {
    if (!orderWindowId) return;
    createOrderSetMutation.mutate(
      {
        orderSetType: "pre-order",
        preOrderOptions: {
          orderWindowId: orderWindowId,
          programId: programId,
        },
      },
      {
        onSuccess: (os) => {
          navigate(`/order-sets/${os.id}`);
        },
        onError: (err) => {
          setError(err, "Create Order Set");
        },
      }
    );
  };

  const {
    variantSelectionItem,
    setVariantSelectionItem,
    addItemToOrderSet,
    addVariantsToOrderSet,
  } = useAddToCart("pre-order", orderSet?.id ?? null);

  const { data: program } = useProgramQuery(programId);
  const { data, ...tableProps } = useProgramItems(programId, {
    isPublic: true,
  });
  const items = data || [];

  const selectedOrderWindow =
    orderWindowId &&
    program?.orderWindows.find((ow) => ow.id === orderWindowId);

  const isOrderWindowOrderable = useIsOrderWindowOrderable();
  const isProgramOrderable =
    selectedOrderWindow && isOrderWindowOrderable(selectedOrderWindow);

  const orderableForChannelTerritory =
    selectedOrderWindow &&
    program &&
    (!program.isTerritoryExclusive ||
      program.territories.some((t) => t.id === currentTerritoryId)) &&
    (program.channel?.id ?? null) === currentChannelId &&
    selectedOrderWindow.territories.some((t) => t.id === currentTerritoryId);

  const { role } = useSelector((state: any) => state.currentUser);

  return (
    <>
      <DocTitle title={"Program Order"} />
      {variantSelectionItem && (
        <VariantSelectionModal
          item={variantSelectionItem}
          onClose={() => setVariantSelectionItem(null)}
          orderType="pre-order"
          {...{
            orderSetVariants: orderSet?.orderSetVariants ?? [],
            createOrUpdateOrderSet: addVariantsToOrderSet,
          }}
        />
      )}
      <Contained tw="space-y-3 pb-3">
        <ProgramDetails
          ctaComponent={
            !isProgramOrderable ? (
              <Button disabled variant="outlined">
                Program outside orderablity window
              </Button>
            ) : (
              orderableForChannelTerritory &&
              role !== "read-only" &&
              (orderSet ? (
                <LoadingButton
                  variant="contained"
                  startIcon={<ExitToAppTwoTone />}
                  component={Link}
                  to={`/order-sets/${orderSet.id}`}
                >
                  View Order
                </LoadingButton>
              ) : (
                <LoadingButton
                  variant="contained"
                  startIcon={<ExitToAppTwoTone />}
                  onClick={handleStartOrderSet}
                  loading={isOrderSetLoading}
                >
                  Start Order
                </LoadingButton>
              ))
            )
          }
          programId={programId}
          items={items}
        />

        <div tw="flex gap-4 items-center justify-between">
          {orderWindowId && program && !orderableForChannelTerritory && (
            <Alert severity="warning">
              This program is not available for your current{" "}
              {currentChannelId && "channel and "}territory.
            </Alert>
          )}

          {!orderWindowId && (
            <h3 tw="text-lg text-neutral-600">
              Preview Only, select an Order Window for orderability.
            </h3>
          )}
        </div>

        <ItemCatalogView
          rows={items}
          noResultsText="No active items on this program."
          rowActions={
            orderSet
              ? (item) => (
                  <AddToCartButton
                    item={item}
                    orderSetVariants={orderSet.orderSetVariants}
                    addToOrderSet={addItemToOrderSet}
                  />
                )
              : undefined
          }
          {...tableProps}
        />
      </Contained>
    </>
  );
};
export default ProgramOrder;
