import "twin.macro";

import { Skeleton } from "@mui/material";

import { StyledSelectInput } from "@utils/forms";

import { useUserCostCentersQuery } from "./data";

const CostCenterSelector = ({
  value,
  onChange,
  hideLabel,
}: {
  value: string;
  onChange: (value: string) => void;
  hideLabel?: boolean;
}) => {
  const { data } = useUserCostCentersQuery();

  if (!data) return <Skeleton width={120} height={48} />;

  if (data.length === 1) {
    return (
      <div>
        {!hideLabel && <p tw="text-sm text-neutral-600 pb-0.5">Cost Center</p>}
        <p tw="text-neutral-800">{data[0].name}</p>
      </div>
    );
  }
  return (
    <StyledSelectInput
      tw="w-full max-w-md"
      label={hideLabel ? undefined : "Cost center"}
      options={data}
      value={value}
      onChange={(e) => onChange(e.target.value)}
    />
  );
};

export default CostCenterSelector;
