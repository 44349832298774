import "twin.macro";

import { useEffect, useRef } from "react";
import { useSelector } from "react-redux";

import MenuOpenRoundedIcon from "@mui/icons-material/MenuOpenRounded";
import MenuRoundedIcon from "@mui/icons-material/MenuRounded";
import { IconButton } from "@mui/material";

import _ from "lodash";

import permissions from "@utils/permissions";
import { useBreakpoint } from "@utils/tailwind";
import useRoleIs from "@utils/useRoleIs";

import ChannelSelector from "../Utility/Selectors/ChannelSelector";
import OrganizationSelector from "../Utility/Selectors/OrganizationSelector";
import TerritorySelector from "../Utility/Selectors/TerritorySelector";
import WithPermission from "../Utility/WithPermission";
import BreadcrumbTrail from "./BreadcrumbTrail";
import DraftOrderMenu from "./DraftOrderMenu";
import UserSection from "./UserSection";

const Header = ({ open, setOpen }) => {
  const isMobile = useBreakpoint("md");
  const { channels, territories, organization } = useSelector(
    (state) => state.currentUser
  );
  const roleIs = useRoleIs();
  const headerRef = useRef(null);

  const internalAccess = roleIs(permissions.internalRoles);
  const selectorList = [
    organization.id && organization.availableOrgs.length > 1 && (
      <OrganizationSelector key="org" />
    ),
    internalAccess && channels && channels.length > 1 && (
      <ChannelSelector key="channel" />
    ),
    internalAccess && territories && territories.length > 1 && (
      <TerritorySelector key="territory" />
    ),
  ].filter(Boolean);

  const displaySelectorsAbove = isMobile && selectorList.length > 1;

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([e]) =>
        e.target.classList.toggle(
          "header__container--pinned",
          e.intersectionRatio < 1
        ),
      { threshold: [1] }
    );

    const observed = headerRef.current;

    observer.observe(observed);
    return () => {
      observer.unobserve(observed);
    };
  }, []);
  return (
    <div className="header__container" ref={headerRef} tw="z-[60] mb-6">
      <div className="header" tw="pt-1">
        {displaySelectorsAbove && (
          <div
            tw="flex justify-between mt-3 -mb-2"
            id="tutorial-channel-territory"
          >
            {selectorList}
          </div>
        )}
        <div tw="flex items-center justify-between">
          <div tw="flex items-center">
            <IconButton tw="m-2" onClick={() => setOpen(!open)}>
              {!open ? <MenuRoundedIcon /> : <MenuOpenRoundedIcon />}
            </IconButton>
            {(!isMobile || displaySelectorsAbove) && <BreadcrumbTrail />}
          </div>
          <div tw="flex items-center lg:(gap-x-6 mt-3) mb-1">
            <WithPermission allow={permissions.orders}>
              <DraftOrderMenu />
            </WithPermission>
            {!displaySelectorsAbove && (
              <div
                id="tutorial-channel-territory"
                tw="flex items-center lg:gap-x-6"
              >
                {selectorList}
              </div>
            )}
            <UserSection />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Header;
