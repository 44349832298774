import "twin.macro";

import { capitalize } from "lodash";

import ReactQueryTable, {
  Column,
  PaginatedResourceProps,
} from "@components/Table/ReactQueryTable";
import SearchableText from "@components/Table/SearchableText";
import { HistoricalOrder } from "@models/HistoricalOrder";
import { formatDateString, formatMoneyString } from "@utility/utilityFunctions";

type HistoricalOrderTableProps = PaginatedResourceProps & {
  data: HistoricalOrder[];
  query?: string;
};

const cost = {
  align: "right" as const,
  render: (v) => v && formatMoneyString(v),
};

const HistoricalOrderTable = ({
  data,
  query,
  ...props
}: HistoricalOrderTableProps) => {
  const columns: Column<HistoricalOrder>[] = [
    {
      id: "orderId",
      label: "Order #",
      sort: "order-id",
      render: (id) => <SearchableText text={id} query={query} />,
    },
    {
      id: "accountCode",
      label: "Acc. Code",
      render: (code) => <SearchableText text={code} query={query} />,
    },
    {
      id: "_item",
      label: "Item",
      render: (_, row) => (
        <div tw="min-w-max">
          <div tw="flex gap-2 text-primary-600">
            <SearchableText text={row.externalWarehouseId} query={query} />
          </div>

          <div tw="inline-block text-base leading-tight mt-2">
            <SearchableText text={row.itemName} query={query} />

            <div tw="text-sm text-neutral-500">
              {[row.variant1, row.variant2].filter(Boolean).join(" / ")}
            </div>
          </div>
        </div>
      ),
    },
    {
      id: "_orderer",
      label: "Ordered By",
      render: (_, row) => (
        <div>
          <p>{row.orderedBy}</p>
          <p tw="text-neutral-500">{row.email}</p>
        </div>
      ),
    },
    { id: "program", label: "Program" },
    { id: "state", label: "State" },
    {
      id: "orderType",
      label: "Order Type",
      sort: "order-type",
      render: capitalize,
    },
    {
      id: "orderDate",
      label: "Order Date",
      sort: "order-date",
      render: formatDateString,
    },
    { id: "totalQty", label: "Total Qty", align: "right" },
    { id: "price", label: "Price", ...cost },
    { id: "actualFreight", label: "Freight", ...cost },
    { id: "actualTax", label: "Tax", ...cost },
    { id: "budget", label: "Budget" },
    { id: "billingInfo", label: "Billing Info" },
    { id: "supplier", label: "supplier" },
    { id: "poNumber", label: "PO #" },
    { id: "status", label: "Status", render: capitalize },
  ];
  return <ReactQueryTable columns={columns} rows={data} {...props} />;
};

export default HistoricalOrderTable;
