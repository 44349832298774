import "twin.macro";

import { OpaqueCard } from "@components/StyledComponents";
import ReactQueryTable, {
  PaginatedResourceProps,
} from "@components/Table/ReactQueryTable";
import SearchableText from "@components/Table/SearchableText";
import { Address } from "@models";

const insertIf = (condition, val) => (condition ? [val] : []);

export type AddressBookTableProps = PaginatedResourceProps & {
  rows: Address[];
  query?: string;
  onRowClick?: (row: Address) => void;
  showUserColumn?: boolean;
};

const AddressBookTable = ({
  query,
  rows,
  onRowClick,
  showUserColumn,
  isLoading,
  ...tableProps
}: AddressBookTableProps) => {
  const renderQuery = (text) => <SearchableText query={query} text={text} />;

  return (
    <OpaqueCard tw="p-0 overflow-hidden relative">
      <ReactQueryTable
        isLoading={isLoading}
        rows={rows}
        onRowClick={onRowClick}
        columns={[
          { id: "id", label: "ID" },
          ...insertIf(showUserColumn, {
            id: "userName",
            label: "User",
          }),
          { id: "name", label: "Name", sort: "name", render: renderQuery },
          {
            id: "streetAddress1",
            label: "Street Address One",
            render: renderQuery,
          },
          { id: "streetAddress2", label: "Street Address Two" },
          { id: "defaultAttn", label: "Attention", render: renderQuery },
          { id: "city", label: "City", render: renderQuery },
          {
            id: "state.code",
            label: "State/Region",
            render: (stateCode, row) => stateCode ?? row.region,
          },
          { id: "zip", label: "Zip Code" },
          { id: "phoneNumber", label: "Phone Number" },
          { id: "country.name", label: "Country" },
        ]}
        {...tableProps}
      />
    </OpaqueCard>
  );
};

export default AddressBookTable;
