import tw from "twin.macro";

import { useMemo } from "react";
import { useFormContext, useWatch } from "react-hook-form";
import { Link } from "react-router";

import { Skeleton } from "@mui/material";

import { StyledButton } from "@components/StyledComponents";
import { isOrderWindowActive } from "@features/orderWindows";
import { Item } from "@models/Item";
import { utcDate } from "@utility/utilityFunctions";

import BlockCard from "../../../ui/BlockCard";
import { useItemPrograms } from "../../helpers";

type StatusProps = {
  item: Item;
  handleSave: () => void;
};

const PreOrderStatusText = ({ isVisible }) => {
  const id = useWatch({ name: "id" });
  const { data, isLoading } = useItemPrograms(id);
  const activeProgram = data.find(
    (p) => p.status === "complete" && p.orderWindows.some(isOrderWindowActive)
  );
  const activeOrderWindow =
    activeProgram?.orderWindows.find(isOrderWindowActive);

  if (isLoading) return <Skeleton height="48px" width="100%" />;

  return (
    <>
      {isVisible ? (
        "Item is visible"
      ) : (
        <>
          Item is <b>not</b> visible
        </>
      )}
      {" and "}
      {activeProgram ? (
        <>
          can be ordered on{" "}
          <Link
            to={`/programs/${activeProgram.id}/order/${activeOrderWindow!.id}`}
            target="_blank"
          >
            {activeProgram.name}
          </Link>
        </>
      ) : (
        <>
          is <b>not</b> orderable.
        </>
      )}
    </>
  );
};

const Status = ({ handleSave, item }: StatusProps) => {
  const {
    setValue,
    formState: { errors },
  } = useFormContext();
  const status = useWatch({ name: "status" });
  const isActive = useWatch({ name: "isActive" });

  const verboseStatusMessage = useMemo(() => {
    if (item.status === "draft")
      return "Item is draft and won't be visible to users until it is published.";

    if (!item.isActive)
      return "Item is inactive and won't be visible to users until it is activated.";

    const today = new Date();
    today.setHours(0, 0, 0, 0);

    const isVisible =
      utcDate(item.visibleStartDate) <= today &&
      (!item.visibleEndDate || today <= utcDate(item.visibleEndDate));

    const isOrderable =
      utcDate(item.orderableStartDate) <= today &&
      (!item.orderableEndDate || today <= utcDate(item.orderableEndDate));

    if (item.orderType === "pre-order")
      return <PreOrderStatusText isVisible={isVisible} />;

    if (isVisible && isOrderable) {
      return (
        <>
          Item is visible in the{" "}
          <Link to="/items" target="_blank">
            catalog
          </Link>{" "}
          and orderable.
        </>
      );
    }

    if (!isOrderable && isVisible)
      return "Item is visible in the catalog but not orderable.";

    if (!isVisible && isOrderable)
      return "Item is orderable but not visible in the catalog.";

    if (!isVisible && !isOrderable)
      return "Item is not visible in the catalog and not orderable.";

    return "";
  }, [item]);

  const handleMarkAsComplete = () => {
    setValue("status", "complete");
    handleSave();
  };

  return (
    <BlockCard
      title="Item Status"
      titleElement={
        <h3 tw="text-neutral-600">
          {status === "complete" ? "Complete" : "Draft"}
        </h3>
      }
      tw="flex flex-col"
    >
      <div
        css={{
          "&": tw`text-lg font-light text-neutral-600`,
          b: tw`font-normal`,
          a: tw`underline`,
        }}
      >
        {verboseStatusMessage}
      </div>
      {status === "complete" ? (
        <div>
          {isActive && (
            <button
              tw="mt-2 text-sm text-neutral-500 hover:text-red-600 hover:underline"
              onClick={() => setValue("isActive", false, { shouldDirty: true })}
            >
              Deactivate Item
            </button>
          )}
          {!isActive && (
            <button
              tw="mt-2 text-base text-blue-700 hover:underline"
              onClick={() => setValue("isActive", true, { shouldDirty: true })}
            >
              Activate Item
            </button>
          )}
        </div>
      ) : (
        <StyledButton tw="mt-2" fullWidth cta onClick={handleMarkAsComplete}>
          Mark as complete
        </StyledButton>
      )}
      {Object.keys(errors).length > 0 && (
        <div tw="mt-4 text-red-600">
          * Some required fields are missing or invalid.
        </div>
      )}
    </BlockCard>
  );
};

export default Status;
