import "twin.macro";

import { useRef, useState } from "react";
import { CSVReader } from "react-papaparse";
import { useDispatch, useSelector } from "react-redux";

import { GetApp, Publish } from "@mui/icons-material";
import {
  Dialog,
  DialogActions,
  DialogContent,
  Typography,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

import { useQueryClient } from "@tanstack/react-query";

import { StyledButton } from "@components/StyledComponents";
import { useUserListQuery } from "@features/users/";
import {
  setHasUpdated,
  uploadBulkBudgets,
} from "@redux/slices/budgets/budgetSlice";
import { setError } from "@redux/slices/errorSlice";
import { downloadAsCsv } from "@utils/csv";

import BulkBudgetUploadEmailErrorModal from "./BulkBudgetUploadEmailErrorModal";
import { budgetsKeyFactory } from "./data";

const useStyles = makeStyles((theme: any) => ({
  ...theme.global,
}));

interface EmailError {
  budgetName: string;
  email: string;
}

const BudgetBulkUpload = () => {
  const classes = useStyles() as any;
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  const csvRef = useRef<any>(null);

  const [isUploadLoading, setUploadLoading] = useState(false);
  const [emailErrorModalOpen, setEmailErrorModalOpen] = useState(false);
  const [uploadEmailErrors, setUploadEmailErrors] = useState<EmailError[]>([]);

  const { hasUpdated, error } = useSelector((state: any) => state.budgets);

  const { data: userData = [], isLoading } = useUserListQuery();

  const {
    organization: { budgetLocation },
  } = useSelector((state: any) => state.currentUser);

  const csvHeaders = [
    "Name",
    "Start Date MM/DD/YYYY",
    "Expiration Date MM/DD/YYYY",
    "Total Budget",
    budgetLocation === "user" && "User emails separated by a semicolon ;",
  ];

  const handleOpenDialog = (evt) => {
    if (csvRef.current) {
      csvRef.current.open(evt);
    }
  };

  const handleFileSelect = (data) => {
    if (budgetLocation !== "user") {
      alert(`not implemented for budgetLocation ${budgetLocation}`);
      return;
    }

    let emailErrors: EmailError[] = [];

    const newArr = data.map((row) => {
      const userEmails = row.data["User emails separated by a semicolon ;"]
        ? row.data["User emails separated by a semicolon ;"]
            .split(";")
            .map((email) => email.trim())
        : [];

      const userIds = userEmails
        .map((email) => {
          const user = userData.find((user) => user.email === email);
          if (!user) {
            emailErrors.push({ budgetName: row.data["Name"], email });
            return null;
          }
          return user.id;
        })
        .filter((id) => id !== null);

      return {
        expiration_date: row.data["Expiration Date MM/DD/YYYY"],
        name: row.data["Name"],
        start_date: row.data["Start Date MM/DD/YYYY"],
        total_budget: row.data["Total Budget"],
        users: userIds,
      };
    });

    if (emailErrors.length > 0) {
      setUploadEmailErrors(emailErrors);
      setEmailErrorModalOpen(true);
      setUploadLoading(false);
    } else {
      dispatch(
        uploadBulkBudgets(newArr, () => {
          setUploadLoading(false);
          queryClient.invalidateQueries({
            queryKey: budgetsKeyFactory.paginated._def,
          });
        })
      );
    }
  };

  const handleFileUploadError = (err, file, inputElem, reason) => {
    dispatch(
      setError({ error: err.toString(), source: "Bulk Budget Upload CSV File" })
    );
    console.log(err, file, inputElem, reason);
  };

  return (
    <>
      {hasUpdated && !error && (
        <Dialog
          open={hasUpdated && !error}
          onClose={() => dispatch(setError(null))}
        >
          <DialogContent>Budget upload successful!</DialogContent>
          <DialogActions>
            <StyledButton
              cta
              onClick={() => {
                dispatch(setHasUpdated(false));
              }}
            >
              Done
            </StyledButton>
          </DialogActions>
        </Dialog>
      )}
      <BulkBudgetUploadEmailErrorModal
        emailErrorModalOpen={emailErrorModalOpen}
        setEmailErrorModalOpen={setEmailErrorModalOpen}
        uploadEmailErrors={uploadEmailErrors}
      />
      <CSVReader
        ref={csvRef}
        onFileLoad={handleFileSelect}
        onError={handleFileUploadError}
        noClick
        noDrag
        config={{
          header: true,
          beforeFirstChunk: (_chunk) => {
            setUploadLoading(true);
          },
        }}
        noProgressBar
      >
        {({ error }) => (
          <div style={{ position: "relative" }}>
            <StyledButton
              outlined
              startIcon={<Publish />}
              onClick={handleOpenDialog}
              loading={isUploadLoading}
              id="tutorial-bulk-upload"
              disabled={isLoading || isUploadLoading}
            >
              UPLOAD BULK BUDGETS
            </StyledButton>
            {!isUploadLoading && error && (
              <Typography className={classes.bodyText} color="textSecondary">
                {`Error uploading file: ${error}`}
              </Typography>
            )}
          </div>
        )}
      </CSVReader>

      <StyledButton
        outlined
        startIcon={<GetApp />}
        onClick={() => downloadAsCsv([csvHeaders], "bulk-budget-form.csv")}
      >
        BULK BUDGET FORM
      </StyledButton>
    </>
  );
};

export default BudgetBulkUpload;
