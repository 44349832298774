import "twin.macro";

import React, { memo, useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";

import AddToPhotosIcon from "@mui/icons-material/AddToPhotos";
import CancelIcon from "@mui/icons-material/CloseRounded";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  IconButton,
  Popper,
  Switch,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";

import PropTypes from "prop-types";

import {
  useCreateTerritoryMutation,
  useUpdateTerritoryMutation,
} from "@features/territories";
import { Country } from "@models/Country";
import { State } from "@models/State";
import { Territory } from "@models/Territory";

import { useInput } from "../../../hooks/inputs/useInput";
import { StyledButton } from "../../StyledComponents";

const TopPopper = (props) => <Popper {...props} placement="top" />;

const CountrySelector = React.memo(
  ({
    handleCountries,
    countries,
    territoryCountries,
  }: {
    handleCountries: any;
    countries: any[];
    territoryCountries: Country[];
  }) => {
    const [currentCountries, setCurrentCountries] =
      useState(territoryCountries);

    useEffect(() => {
      if (currentCountries.length !== territoryCountries.length) {
        setCurrentCountries(territoryCountries);
      }
    }, [currentCountries, territoryCountries]);

    return (
      <Autocomplete
        multiple
        fullWidth
        autoHighlight
        freeSolo
        id="country-selector"
        options={countries}
        getOptionLabel={(option) => option.name}
        isOptionEqualToValue={(option, value) => option.id === value.id}
        onChange={(_evt, value) => handleCountries(value)}
        PopperComponent={TopPopper}
        value={currentCountries}
        renderInput={(params) => (
          <TextField
            {...params}
            label="Countries"
            id="countries"
            variant="outlined"
            size="small"
            autoComplete="new-password"
            InputProps={{
              ...params.InputProps,
              autoComplete: "off",
              spellCheck: "false",
              autoCorrect: "off",
              endAdornment: <>{params.InputProps.endAdornment}</>,
            }}
          />
        )}
      />
    );
  }
);

const StateSelector = React.memo(
  ({
    handleStates,
    states,
    territoryStates,
  }: {
    handleStates: any;
    states: State[];
    territoryStates: State[];
  }) => {
    const [currentStates, setCurrentStates] = useState<State[] | []>(
      territoryStates
    );

    useEffect(() => {
      if (currentStates.length !== territoryStates.length) {
        setCurrentStates(territoryStates);
      }
    }, [currentStates, territoryStates]);

    return (
      <Autocomplete
        multiple
        fullWidth
        autoHighlight
        freeSolo
        id="state-selector"
        options={states}
        getOptionLabel={(option: string | State) =>
          typeof option === "string" ? option : option.code
        }
        isOptionEqualToValue={(option: string | State, value: string | State) =>
          typeof option === "object" &&
          typeof value === "object" &&
          option.id === value.id
        }
        onChange={(_evt, value) => handleStates(value)}
        PopperComponent={TopPopper}
        value={currentStates}
        renderInput={(params) => (
          <TextField
            {...params}
            label="State"
            id="state"
            variant="outlined"
            size="small"
            autoComplete="new-password"
            InputProps={{
              ...params.InputProps,
              autoComplete: "off",
              spellCheck: "false",
              autoCorrect: "off",
              endAdornment: <>{params.InputProps.endAdornment}</>,
            }}
          />
        )}
      />
    );
  }
);

const TerritoryModal = ({
  open,
  handleClose,
  type,
  territory,
  isLoading,
  isValidating,
}: {
  open: boolean;
  handleClose: () => void;
  type: string;
  territory: Territory;
  isLoading: boolean;
  isValidating: boolean;
}) => {
  const {
    value: name,
    bind: bindName,
    setValue: setName,
    reset: resetName,
  } = useInput("");

  const [currentCountries, setCurrentCountries] = useState<Country[]>([]);
  const [currentStates, setCurrentStates] = useState<State[] | []>([]);
  const [budgetCheckout, setBudgetCheckout] = useState<boolean>(false);
  const [standardCheckout, setStandardCheckout] = useState<boolean>(false);
  const [stripeCheckout, setStripeCheckout] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);

  const {
    mutate: createTerritory,
    isError: isCreateTerritoryError,
    isSuccess: isCreateTerritorySuccess,
    error: createTerritoryError,
  } = useCreateTerritoryMutation();
  const {
    mutate: updateTerritories,
    isError: isUpdateTerritoriesError,
    isSuccess: isUpdateTerritoriesSuccess,
    error: updateTerritoriesError,
  } = useUpdateTerritoryMutation();

  const orgCountries = useSelector(
    (state: any) => state.currentUser.organization.countries
  );

  const { organization } = useSelector((state: any) => state.currentUser);

  const stateOptions = currentCountries
    .flatMap(({ states }) => states)
    .filter(Boolean);

  const handleSubmit = async () => {
    if (
      name.length === 0 ||
      currentCountries.length === 0 ||
      (currentCountries.length > 0 &&
        currentCountries
          .map((c) => c.states)
          .reduce((acc, s) => acc + (s ? s.length : 0), 0) > 0 &&
        currentStates.length === 0)
    ) {
      setError("You must complete form before submitting");
    } else {
      if (type === "edit") {
        updateTerritories({
          ...territory,
          name,
          countries: currentCountries,
          states: currentStates,
          allowBudgetCheckout: budgetCheckout,
          allowStandardCheckout: standardCheckout,
          allowStripeCheckout: stripeCheckout,
        });
      } else {
        createTerritory({
          name,
          countries: currentCountries,
          states: currentStates,
          allowBudgetCheckout: budgetCheckout,
          allowStandardCheckout: standardCheckout,
          allowStripeCheckout: stripeCheckout,
        });
      }
    }
  };

  const handleCountries = useCallback(
    (value) => {
      setError(null);
      setCurrentCountries(value);
    },
    [setCurrentCountries]
  );

  const handleAllCountries = () => {
    setError(null);
    setCurrentCountries([...orgCountries]);
  };

  const handleStates = useCallback(
    (value) => {
      setError(null);
      setCurrentStates(value);
    },
    [setCurrentStates]
  );

  const handleAllStates = () => {
    let states = currentCountries.reduce(
      (acc: State[], c: Country) => (c.states ? acc.concat(c.states) : acc),
      []
    );
    setError(null);
    setCurrentStates(states);
  };

  useEffect(() => {
    if (territory) {
      setCurrentStates(territory.states as State[]);
      setCurrentCountries(territory.countries);
      setName(territory.name);

      setBudgetCheckout(
        !!organization.budgetLocation && territory.allowBudgetCheckout
      );
      setStandardCheckout(
        organization.usesStandardCheckout && territory.allowStandardCheckout
      );
      setStripeCheckout(
        organization.usesStripe && territory.allowStripeCheckout
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [territory]);

  const hasError = isCreateTerritoryError || isUpdateTerritoriesError || error;
  const errorMessage =
    createTerritoryError?.message || updateTerritoriesError?.message || error;

  return (
    <div>
      <Dialog
        open={open}
        onClose={() => {
          resetName();
          handleClose();
        }}
        fullWidth
        maxWidth="md"
        disableScrollLock
      >
        <DialogTitle>
          <Typography tw="font-semibold text-2xl">
            {type === "edit" ? `Editing ${name}` : "New Territory"}
          </Typography>
        </DialogTitle>
        <DialogContent>
          <IconButton
            tw="absolute top-0 right-1"
            onClick={() => {
              resetName();
              handleClose();
            }}
            size="large"
          >
            <CancelIcon fontSize="large" color="secondary" />
          </IconButton>

          <div tw="flex flex-col items-center p-4">
            <Typography tw="font-semibold text-xl mb-4">
              Territory Name
            </Typography>

            <div tw="mb-8 w-full">
              <TextField
                size="small"
                variant="outlined"
                color="secondary"
                name="name"
                type="text"
                label="Name"
                {...bindName}
                fullWidth
              />
            </div>

            <Typography tw="font-semibold text-xl mb-4">
              Territory Country Assignment
            </Typography>

            <div tw="mb-8 flex">
              <CountrySelector
                handleCountries={handleCountries}
                countries={orgCountries}
                territoryCountries={currentCountries}
              />
              <Tooltip title="Assign All">
                <span>
                  <IconButton
                    disabled={currentCountries.length === orgCountries.length}
                    onClick={handleAllCountries}
                    size="large"
                  >
                    <AddToPhotosIcon color="secondary" />
                  </IconButton>
                </span>
              </Tooltip>
            </div>

            {currentCountries.reduce(
              (acc: State[], c: Country) =>
                acc.concat(c.states ? c.states : []),
              []
            ).length > 0 && (
              <div tw="mb-8">
                <Typography tw="flex justify-center font-semibold text-xl mb-4">
                  Territory State Assignment
                </Typography>

                <div tw="flex items-start">
                  <StateSelector
                    handleStates={handleStates}
                    states={stateOptions}
                    territoryStates={currentStates}
                  />
                  <Tooltip title="Assign All">
                    <span>
                      <IconButton
                        disabled={
                          currentStates.length ===
                          currentCountries.reduce(
                            (acc: State[], c: Country) => acc.concat(c.states),
                            []
                          ).length
                        }
                        onClick={handleAllStates}
                        size="large"
                      >
                        <AddToPhotosIcon color="secondary" />
                      </IconButton>
                    </span>
                  </Tooltip>
                </div>
              </div>
            )}

            <Typography tw="font-semibold text-xl">
              Territory Checkout Options
            </Typography>
            <div tw="mb-6">
              {organization.usesStandardCheckout && (
                <FormControlLabel
                  control={
                    <Switch
                      checked={Boolean(standardCheckout)}
                      onChange={(event) =>
                        setStandardCheckout(event.target.checked)
                      }
                      name="standardCheckout"
                    />
                  }
                  label="Standard"
                />
              )}
              {organization.budgetLocation && (
                <FormControlLabel
                  control={
                    <Switch
                      checked={Boolean(budgetCheckout)}
                      onChange={(event) =>
                        setBudgetCheckout(event.target.checked)
                      }
                      name="budgetCheckout"
                    />
                  }
                  label="Budgets"
                />
              )}
              {organization.usesStripe && (
                <FormControlLabel
                  control={
                    <Switch
                      checked={Boolean(stripeCheckout)}
                      onChange={(event) =>
                        setStripeCheckout(event.target.checked)
                      }
                      name="stripeCheckout"
                    />
                  }
                  label="Credit Card"
                />
              )}
            </div>
            <div tw="mb-4">
              <StyledButton
                cta
                onClick={handleSubmit}
                loading={isLoading || isValidating}
                tw="mr-2"
              >
                Submit
              </StyledButton>
              <StyledButton
                outlined
                onClick={() => {
                  resetName();
                  handleClose();
                }}
              >
                CLOSE
              </StyledButton>
            </div>
            {!isValidating && !isLoading && (
              <div>
                {hasError && (
                  <Typography style={{ color: "#920000" }}>
                    Error: {errorMessage}
                  </Typography>
                )}
                {isCreateTerritorySuccess ||
                  (isUpdateTerritoriesSuccess && (
                    <Typography>
                      {type === "edit"
                        ? "Update Successful!"
                        : "New Territory Created Successfully!"}
                    </Typography>
                  ))}
              </div>
            )}
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
};

TerritoryModal.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  type: PropTypes.string.isRequired,
  id: PropTypes.string,
  territoryList: PropTypes.arrayOf(Object),
};

export default memo(TerritoryModal);
