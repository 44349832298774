import "twin.macro";

import { useEffect, useState } from "react";

import { OpaqueCard, SectionTitle } from "@components/StyledComponents";
import { MinimalItemCard } from "@features/items";
import { FaIcon } from "@features/ui";
import { Item } from "@models/Item";
import { StyledTextInput } from "@utils/forms/ControlledInputs";
import useDebouncedValue from "@utils/useDebouncedValue";

import { useUpdateOrderVariantCustomizationMutation } from "../data";
import useCustomizationRequirements from "./useCustomizationRequirements";

const ItemCustomization = ({
  item,
  savedCustomization,
}: {
  item: Item;
  savedCustomization: string;
}) => {
  const {
    mutate: updateOrderVariantCustomization,
    isPending,
    isSuccess,
  } = useUpdateOrderVariantCustomizationMutation();
  const [customization, setCustomization] = useState(savedCustomization);
  const debouncedCustomization = useDebouncedValue(customization, 1000);

  useEffect(() => {
    if (debouncedCustomization !== savedCustomization) {
      updateOrderVariantCustomization({
        itemId: item.id,
        customization: debouncedCustomization,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedCustomization]);

  let helperText = "";
  if (customization.length === 0) helperText = "Required";
  else if (isPending) helperText = "Saving...";
  else if (isSuccess && customization === debouncedCustomization)
    helperText = "Saved!";

  return (
    <div tw="flex flex-col lg:flex-row gap-2 gap-x-6 py-3">
      <MinimalItemCard
        tw="flex flex-1 gap-4 items-start"
        item={item}
        disabledInteractive
        imageProps={{ style: { width: "5em", flexShrink: 0 } }}
      />
      <div tw="flex-1">
        <p tw="text-neutral-800 leading-tight mb-1">
          {item.customizationDescription}
        </p>
        <StyledTextInput
          value={customization}
          onChange={(e) => setCustomization(e.target.value)}
          endAdornment={
            isPending && <FaIcon icon="spinner-third" tw="animate-spin" />
          }
          helperText={helperText}
          error={customization.length === 0}
        />
      </div>
    </div>
  );
};

const OrderSetItemCustomization = () => {
  const { customizableOrderSetVariants } = useCustomizationRequirements();

  const items = Object.values(customizableOrderSetVariants).map(
    (osvs) => osvs[0].variant.item
  );

  if (items.length === 0) return null;

  return (
    <OpaqueCard>
      <SectionTitle tw="mb-0">Item Customization</SectionTitle>
      <p tw="text-neutral-500 text-sm">
        Customizations apply to all variants of an item.
      </p>
      <div tw="divide-y-2 divide-neutral-100">
        {items.map((item) => (
          <ItemCustomization
            item={item}
            savedCustomization={
              customizableOrderSetVariants[item.id][0].customization ?? ""
            }
          />
        ))}
      </div>
    </OpaqueCard>
  );
};

export default OrderSetItemCustomization;
