import "twin.macro";
import tw from "twin.macro";

import { useDispatch, useSelector } from "react-redux";

import { CancelRounded } from "@mui/icons-material";
import { Checkbox, IconButton, Tooltip } from "@mui/material";

import { format } from "date-fns";

import { OpaqueCard } from "@components/StyledComponents";
import ReactQueryTable, {
  Column,
  PaginatedResourceProps,
} from "@components/Table/ReactQueryTable";
import {
  ItemIdentifiers,
  getItemPreviewImage,
  variantName,
} from "@features/items";
import { ItemRollup } from "@models";
import { setItemPreview } from "@redux/slices/items/itemSlice";
import { CldImage } from "@services/cloudinary";
import {
  formatDateString,
  formatMoneyString,
  utcDate,
} from "@utility/utilityFunctions";
import permissions from "@utils/permissions";

const insertIf = (cond, val) => (cond ? [val] : []);

const PurchaseOrderRollupTable = ({
  query,
  onCancelClick,
  isRowSelected,
  singleItemId,
  onCheckRow,
  ...tableProps
}: PaginatedResourceProps & {
  rows: ItemRollup[];
  query?: string;
  onCancelClick: (row) => void;
  isRowSelected: (row) => boolean;
  singleItemId?: null | number;
  onCheckRow: (row) => void;
}) => {
  const dispatch = useDispatch();
  const {
    role,
    organization: { usesItemCustomization },
  } = useSelector((state: any) => state.currentUser);

  const canCreatePurchaseOrder = permissions.createPurchaseOrder.includes(role);

  const columns: Column<ItemRollup>[] = [
    {
      id: "checkbox",
      label: "",
      render: (_, row) => (
        <Checkbox
          css={[
            tw`-m-4 -ml-2`,
            singleItemId &&
              (row.itemId === singleItemId
                ? tw`text-primary-800`
                : tw`opacity-50`),
          ]}
          checked={isRowSelected(row)}
          onClick={(e) => e.stopPropagation()}
          onChange={() => onCheckRow(row)}
          disabled={!canCreatePurchaseOrder}
        />
      ),
    },
    {
      id: "preview",
      label: "Preview",
      render: (_, row) => (
        <div
          tw="flex-shrink-0 w-20 relative rounded-md cursor-pointer overflow-hidden
                after:(absolute inset-0 bg-neutral-900 opacity-0 transition-opacity)
                hover:after:opacity-20"
          role="button"
          tabIndex={-1}
          onClick={(e) => {
            e.stopPropagation();

            dispatch(setItemPreview({ id: row.variant.item.id }));
          }}
        >
          <CldImage
            tw="w-full aspect-square object-contain
                  border-2 border-neutral-200
                bg-white rounded-md"
            image={row.variant.image || getItemPreviewImage(row.variant.item)}
            size="thumbnail"
          />
        </div>
      ),
    },
    {
      id: "_item",
      label: "Item",
      sort: "item-number",
      render: (_, row) => (
        <div>
          <ItemIdentifiers variant={row.variant} query={query} />
          <div tw="text-base! text-neutral-700 leading-tight mt-1">
            {row.variant.item.name}
          </div>
          {row.variant.selectedVariantOptions.length > 0 && (
            <div tw="text-neutral-500">{variantName(row.variant)}</div>
          )}
        </div>
      ),
    },
    {
      id: "supplierName",
      label: "Supplier",
    },
    {
      id: "orderProgramName",
      label: "Program",
      sort: "order-program-name",
    },
    {
      id: "orderWindowName",
      label: "Order Window",
    },
    {
      id: "totalOrdered",
      label: "Total Ordered",
    },
    {
      id: "minimumOrderQuantity",
      label: "MOQ",
    },
    {
      id: "cost",
      label: "Cost",
      align: "right",
      render: formatMoneyString,
    },
    {
      id: "estimatedTotal",
      label: "Total Cost",
      align: "right",
      render: formatMoneyString,
    },
    ...insertIf(usesItemCustomization, {
      id: "customization",
      label: "Customization",
    }),
    {
      id: "orderApprovedAt",
      label: "Approved At",
      sort: "order-approved-at",
      render: (t) => format(utcDate(t), "MM/dd/yyyy"),
    },
    {
      id: "inMarketDate",
      label: "In Market Date",
      sort: "in-market-date",
      render: formatDateString,
    },
    {
      id: "_cancel",
      label: "",
      render: (_, row) => (
        <Tooltip title="Cancel Purchase Order">
          <IconButton
            size="small"
            tw="-my-2 text-neutral-500 hover:text-red-700"
            onClick={() => onCancelClick(row)}
          >
            <CancelRounded />
          </IconButton>
        </Tooltip>
      ),
    },
  ];

  return (
    <OpaqueCard tw="p-0 overflow-hidden relative">
      <ReactQueryTable {...tableProps} columns={columns} />
    </OpaqueCard>
  );
};

export default PurchaseOrderRollupTable;
