import "twin.macro";

import { ReactNode, useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";

import {
  Autocomplete,
  AutocompleteProps,
  CircularProgress,
  InputAdornment,
  LinearProgress,
  Paper,
  PaperProps,
  Typography,
} from "@mui/material";

import { debounce, uniqBy } from "lodash";

import { PaperTooltip } from "@features/ui";
import { Address } from "@models";
import { TextInput } from "@utils/forms";
import permissions from "@utils/permissions";

import { usePaginatedAddressesQuery } from "./addressQueries";
import { formatLongAddress } from "./helpers";

const insureArray = <T extends any>(value: T | T[] | null | undefined) =>
  Array.isArray(value)
    ? value
    : value === null || value === undefined
      ? []
      : [value];

const addressSearchableString = (address: Address) =>
  `${address.name} ${address.streetAddress1} ${address.city} ${address.zip}`;

const filterAddresses = (addresses: Address[], searchTerm: string) =>
  addresses.filter((address) => {
    const searchable = addressSearchableString(address).toLowerCase();
    return searchable.includes(searchTerm.toLowerCase());
  });

const invalidStateWarning = (
  <PaperTooltip
    placement="right"
    title={
      "The state of this address is not an approved state within the territory you are ordering. Please ensure you are in the correct territory, review the address and try again. If you think this is an error, please contact help@selectbrandhub.com"
    }
  >
    <div tw="cursor-pointer absolute top-1/2 -translate-y-1/2 right-2 text-xs uppercase tracking-wider pointer-events-auto text-primary-900 font-medium">
      Invalid state
    </div>
  </PaperTooltip>
);

type PaperComponentProps = {
  isLoading: boolean;
  optionCount: number;
  children?: ReactNode;
} & PaperProps;

const PaperComponent = ({
  isLoading,
  optionCount,
  children,
  ...props
}: PaperComponentProps) => (
  <Paper {...props} tw="relative">
    {children}
    {optionCount === 20 && !isLoading && (
      <div tw="text-sm text-primary-300 px-3 py-1">Showing top 20 results</div>
    )}
    {isLoading && <LinearProgress tw="absolute bottom-0 left-0 right-0" />}
  </Paper>
);
type AddressSearchProps<T = false> = {
  multiple?: T;
  onChange: (a: T extends false ? Address | null : Address[]) => void;
  reset?: boolean;
  setReset?: (b: boolean) => void;
  autoFocus?: boolean;
  validateTerritory?: boolean | string;
} & Partial<AutocompleteProps<Address, any, false, false, any>>;

const AddressSearch = <M extends boolean = false>({
  onChange,
  reset,
  setReset,
  multiple,
  autoFocus = false,
  validateTerritory,
  getOptionDisabled,
  ...props
}: AddressSearchProps<M>) => {
  const [address, setAddress] = useState<
    typeof multiple extends true ? Address[] : Address | null
  >(multiple ? [] : null);
  const [searchTerm, setSearchTerm] = useState("");

  const {
    currentTerritoryId,
    id: userId,
    organization: { addressBookType },
    role,
  } = useSelector((state: any) => state.currentUser);

  const validTerritoryId = !validateTerritory
    ? undefined
    : validateTerritory === true
      ? (currentTerritoryId as string)
      : String(validateTerritory);

  const territory = useSelector((state: any) =>
    state.currentUser.territories.find((t) => t.id === validTerritoryId)
  );

  const isAdmin = permissions.admin.includes(role);

  const { data, isLoading } = usePaginatedAddressesQuery({
    filter: {
      searchTerm,
      ...(addressBookType === "user" && !isAdmin && { userIds: [userId] }),
      ...(addressBookType !== "user" && {
        territoryId: validTerritoryId,
      }),
    },
  });

  const addresses = data ?? [];
  const options = uniqBy(
    [...insureArray(props.value), ...insureArray(addresses)],
    "id"
  );

  const handleInvalidAddress = useCallback(
    (address: Address) => {
      if (!territory) return false;
      const isValid = address.state
        ? territory.states.some((state) => state.id === address.state!.id)
        : territory.countries.some(
            (country) => country.id === address.country.id
          );
      return !isValid;
    },
    [territory]
  );

  const handleChange = debounce((e) => setSearchTerm(e.target.value), 300);

  useEffect(() => {
    if (reset) {
      setAddress(null);
      setReset?.(false);
      setSearchTerm("");
    }
  }, [reset, setReset]);

  return (
    <Autocomplete
      fullWidth
      size="small"
      autoHighlight
      multiple={multiple}
      options={options}
      filterOptions={(options, { inputValue }) =>
        filterAddresses(options, inputValue)
      }
      isOptionEqualToValue={(option, value) => option.id === value.id}
      getOptionLabel={(opt) => opt.name}
      getOptionDisabled={(opt) =>
        handleInvalidAddress(opt) || (getOptionDisabled?.(opt) ?? false)
      }
      renderOption={({ key: _key, ...props }, option) => {
        const disabled = props["aria-disabled"];
        const invalid = disabled && handleInvalidAddress(option);
        return (
          <div tw="relative" key={option.id}>
            <li
              {...props}
              className={props.className + (!invalid ? " group" : "")}
            >
              <Typography noWrap>
                <span tw="text-neutral-800 text-base">{option.name}</span>
                <span tw="text-neutral-400 text-sm pl-2 group-hover:text-neutral-700">
                  {formatLongAddress(option)}
                </span>
              </Typography>
              {invalid && <div tw="w-24" />}
            </li>
            {invalid && invalidStateWarning}
          </div>
        );
      }}
      value={address}
      onChange={(_, value) => {
        setAddress(value);
        onChange?.(value as any);
      }}
      componentsProps={{
        popper: {
          style: { width: "auto", maxWidth: "100%" },
          // disablePortal: true,
          placement: "bottom-start",
        },
      }}
      renderInput={(params) => {
        return (
          <form autoComplete="off" onSubmit={(e) => e.preventDefault()}>
            <TextInput
              {...params}
              ref={params.InputProps.ref}
              tw="z-[1450]"
              placeholder="Search for an address"
              onChange={handleChange}
              autoFocus={autoFocus}
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <InputAdornment position="end">
                    {isLoading && <CircularProgress size={20} />}
                  </InputAdornment>
                ),
              }}
            />
          </form>
        );
      }}
      PaperComponent={(props) => (
        <PaperComponent
          isLoading={isLoading}
          optionCount={options.length}
          {...props}
        />
      )}
      {...props}
    />
  );
};

export default AddressSearch;
