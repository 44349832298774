import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";

import { saveAs } from "file-saver";

import { useApiResourcePaginated } from "@services/api";

import ReportRow from "./ReportRow";
import { setReportStatus } from "./globalReportSlice";
import { RStatus, Report, ReportStatus, TReportState } from "./types";

type JsonApiReportDownloadProps = {
  report: TReportState;
  reportDefinition: Report;
};

const JsonApiReportDownload = ({
  report,
  reportDefinition,
}: JsonApiReportDownloadProps) => {
  const fullFileName = report.filename + "." + reportDefinition.fileExtension;
  const dispatch = useDispatch();
  const [reportData, setReportData] = useState<null | any[]>(null);
  const [runTimeError, setRunTimeError] = useState<null | string>(null);
  const { isLastPage, nextPage, data, percentLoaded, error } =
    useApiResourcePaginated(report.url as any, {
      revalidate: false,
    });

  const setStatus = (status: RStatus) =>
    dispatch(setReportStatus({ id: report.id, status }));

  const onDownload = async () => {
    try {
      const blob = await reportDefinition.onDownload(reportData);
      saveAs(blob, fullFileName);
      setStatus(ReportStatus.DOWNLOADED);
    } catch (error: any) {
      console.error(error);
      setRunTimeError(error.message);
      setStatus(ReportStatus.FAILED);
    }
  };

  useEffect(() => {
    if (!data || report.status !== ReportStatus.LOADING) return;
    if (!isLastPage) {
      nextPage();
    } else {
      setStatus(ReportStatus.COMPLETE);
      setReportData(
        reportDefinition.onComplete ? reportDefinition.onComplete(data) : data
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data?.length, isLastPage, report.status, reportDefinition]);

  useEffect(() => {
    if (error) setStatus(ReportStatus.FAILED);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error]);

  return (
    <ReportRow
      filename={fullFileName}
      report={report}
      error={error ?? runTimeError}
      percentLoaded={percentLoaded}
      onDownload={onDownload}
    />
  );
};

export default JsonApiReportDownload;
