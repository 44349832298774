import "twin.macro";

import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

import GroupCategoryModal from "@components/Settings/Products/GroupCategoryModal";
import GroupCategoryTable from "@components/Settings/Products/GroupCategoryTable";
import VariantCategoryModal from "@components/Settings/Products/VariantCategoryModal";
import VariantCategoryTable from "@components/Settings/Products/VariantCategoryTable";
import {
  Contained,
  OpaqueCard,
  StyledButton,
} from "@components/StyledComponents";
import { clearErrors as clearGroupErrors } from "@redux/slices/groupCategories/groupCategorySlice";
import { clearErrors as clearVariantErrors } from "@redux/slices/variantCategories/variantCategorySlice";
import DocTitle from "@utility/DocTitle";

const useStyles = makeStyles((theme) => ({
  ...theme.global,
}));

const AdminItemSettings = () => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [groupModal, handleGroupModal] = useState(false);
  const [variantModal, handleVariantModal] = useState(false);
  const [modalType, setModalType] = useState("edit");
  const [currentCategoryId, setCurrentCategoryId] = useState(null);

  const { isLoading: isGroupsLoading, categories: groupCategories } =
    useSelector((state) => state.groupCategories);
  const { isLoading: isVariantsLoading, categories: variantCategories } =
    useSelector((state) => state.variantCategories);

  const handleModalClose = () => {
    dispatch(clearGroupErrors());
    dispatch(clearVariantErrors());
    handleGroupModal(false);
    handleVariantModal(false);
    setCurrentCategoryId(null);
  };

  const handleModalOpen = (id, type, modal) => {
    setCurrentCategoryId(id);
    setModalType(type);
    if (modal === "group") {
      handleGroupModal(true);
    } else {
      handleVariantModal(true);
    }
  };

  return (
    <>
      <DocTitle title={"Item Settings Admin"} />
      <Contained tw="space-y-4 pb-3">
        {groupModal && (
          <GroupCategoryModal
            open={groupModal}
            handleClose={handleModalClose}
            type={modalType}
            setType={setModalType}
            id={currentCategoryId}
          />
        )}
        {variantModal && (
          <VariantCategoryModal
            open={variantModal}
            handleClose={handleModalClose}
            type={modalType}
            setType={setModalType}
            id={currentCategoryId}
          />
        )}
        <div className={classes.titleBar}>
          <Typography className={classes.titleText}>
            Item Settings - Categories
          </Typography>
          <div className={classes.innerConfigDiv}>
            <StyledButton
              cta
              onClick={() => handleModalOpen(null, "new", "group")}
            >
              NEW CATEGORY
            </StyledButton>
          </div>
        </div>

        <Typography className={classes.bodyText} color="textSecondary">
          <span tw="text-sm">
            Categories are descriptors for your items that will control filters
            and visibility. You will be able to create and assign multiple
            values for each category. For example, if you are a multi brand
            organization, you might have Brand, Business Unit, and Item Type as
            categories. Each Category will have a type, Item or Organization,
            and a Priority Level. Organizational Categories are your highest
            priority delinations of goods. In our current example, in a multi
            brand organization, Brand and Business Unit would be an
            organizational type category while Item Type would be an item type
            category. The priority level within those types will determine the
            order in which the categories are displayed in reports, and other
            sections of the UI, as well as handle what data is shown in some
            reports and data visualizations.
          </span>
        </Typography>

        <OpaqueCard tw="p-0">
          <GroupCategoryTable
            handleCategoryClick={handleModalOpen}
            categories={groupCategories}
            isLoading={isGroupsLoading}
          />
        </OpaqueCard>

        <br />
        <div className={classes.titleBar}>
          <Typography className={classes.titleText}>
            Item Settings - Variants
          </Typography>
          <div className={classes.innerConfigDiv}>
            <StyledButton
              cta
              onClick={() => handleModalOpen(null, "new", "variant")}
            >
              NEW VARIANT
            </StyledButton>
          </div>
        </div>

        <Typography className={classes.bodyText} color="textSecondary">
          <span tw="text-sm">
            Variants are similar to Categories, but will allow you to create
            different versions of the same item. For instance, if your
            organization sells apparel, these might be size and color.
          </span>
        </Typography>
        <OpaqueCard tw="p-0">
          <VariantCategoryTable
            handleCategoryClick={handleModalOpen}
            categories={variantCategories}
            isLoading={isVariantsLoading}
          />
        </OpaqueCard>
      </Contained>
    </>
  );
};

export default AdminItemSettings;
