import { ResourceError } from "@features/errors";
import { useQuoteQuery } from "@features/quotes";

import { QuoteContext } from "./QuoteContext";
import { QuoteSkeleton } from "./QuoteSkeleton";

const QuoteContextProvider = ({
  quoteId,
  children,
}: {
  quoteId: string;
  children: React.ReactNode;
}) => {
  const { data: quote, error, isPending } = useQuoteQuery(quoteId);

  if (error) {
    return <ResourceError error={error} />;
  }

  if (isPending) {
    return <QuoteSkeleton />;
  }

  return (
    <QuoteContext.Provider value={{ quote }}>{children}</QuoteContext.Provider>
  );
};

export default QuoteContextProvider;
