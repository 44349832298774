import "twin.macro";

import { Suspense } from "react";

import { CircularProgress, Dialog, DialogContent } from "@mui/material";

import Carousel from "@components/Carousel";
import { CldImage } from "@services/cloudinary";

// const ImageSlide = ({ url, page, pageCount }) => {
//   const { src } = useImage({ srcList: [url, "/images/NotFound.png"] });
//   return (
//     <div tw="relative">
//       {/* {pageCount > 1 && (
//         <Typography tw="absolute top-0 left-0 text-xs text-neutral-500 bg-white pr-2 pb-2 rounded uppercase tracking-wider">
//           PDF page {page} of {pageCount}
//         </Typography>
//       )} */}
//       <Suspense fallback={<CircularProgress />}>
//         <img tw="h-full object-contain object-center" src={src} />
//       </Suspense>
//     </div>
//   );
// };

type MinimumImage = {
  cloudinaryId: string;
  [key: string]: any;
};
const ImageCarousel = ({
  images,
  open,
  handleClose,
  startIndex,
}: {
  images: MinimumImage[];
  open: boolean;
  handleClose: () => void;
  startIndex?: number;
}) => {
  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogContent tw="p-6">
        <Suspense fallback={<CircularProgress />}>
          <Carousel startIndex={startIndex}>
            {images.map(({ cloudinaryId }) => (
              <div
                key={cloudinaryId}
                tw="h-full flex items-center justify-center"
              >
                <CldImage publicId={cloudinaryId} size="large" />
              </div>
            ))}
          </Carousel>
        </Suspense>
      </DialogContent>
    </Dialog>
  );
};

export default ImageCarousel;
