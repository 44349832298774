import { InputAdornment } from "@mui/material";

import _ from "lodash";

type UnknownObject = Record<string, unknown>;
type UnknownArrayOrObject = unknown[] | UnknownObject;

export const dirtyValues = (
  dirtyFields: UnknownArrayOrObject | boolean,
  allValues: UnknownArrayOrObject
): UnknownArrayOrObject => {
  if (dirtyFields === true) {
    return allValues;
  }

  return Object.fromEntries(
    Object.keys(dirtyFields).map((key) => [
      key,
      dirtyValues(dirtyFields[key], allValues[key]),
    ])
  );
};

export const sorted = (arr) => {
  return _.sortBy(arr, (v) => v);
};

// Use with react-hook-form's useForm() to handle errors as you register the input
export const resolveObjPath = (path, obj, separator = ".") => {
  const properties = Array.isArray(path) ? path : path.split(separator);
  return properties.reduce((prev, curr) => prev && prev[curr], obj);
};

// Defaults null values to "", keep booleans, convert numbers to strings
export const defaultFormValues = (obj) =>
  Object.entries(obj).reduce((o, [key, value]) => {
    o[key] =
      value === null || value === undefined
        ? ""
        : typeof value === "number"
          ? String(value)
          : _.isArray(value)
            ? sorted(value)
            : value;
    return o;
  }, {});

export const transformEventValue = (e, fn) => {
  const _e = { ...e };
  _e.target.value = fn(_e.target.value);
  return _e;
};

export const moneyValidation = {
  pattern: {
    value: /^\d+\.?(\d+)?$/,
    message: "Must be a valid dollar value",
  },
  transform: (v) => v.replace(/[^0-9.]/g, ""),
  transformOnBlur: (v) => (!v || isNaN(v) ? v : Number(v).toFixed(2)),
};

export const intValidation = {
  pattern: {
    value: /^\d+$/,
    message: "Must be an integer",
  },
  transform: (v) => v.replace(/[^0-9]/g, ""),
};
export const numberValidation = {
  pattern: {
    value: /^\d+\.?(\d+)?$/,
    message: "Must be an number",
  },
  transform: (v) => v.replace(/[^0-9.]/g, ""),
  transformOnBlur: (v) => (!v || isNaN(v) ? 0 : Number(v)),
};

export const dateValidation = {
  pattern: {
    value: /\d{4}-\d{2}-\d{2}/,
    message: "Invalid date",
  },
};

export const externalIdValidation = {
  pattern: {
    value: /^[a-zA-Z0-9._-]*$/,
    message: "Only numbers, letters, and ._- allowed",
  },
  transform: (v) => v.replace(/—/g, "-"),
};

export const moneyAdornment = {
  startAdornment: <InputAdornment position="start">$</InputAdornment>,
};
