import { useSelector } from "react-redux";

import warehouseLabel from "./warehouseLabel";

export default function useWarehouseOptions() {
  const { warehouses } = useSelector(
    (state: any) => state.currentUser.organization
  );

  return warehouses.map((warehouseId) => ({
    id: warehouseId,
    name: warehouseLabel(warehouseId),
  }));
}
