import tw from "twin.macro";

import { useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";

import { CloseRounded, ExpandMoreRounded } from "@mui/icons-material";
import { Collapse, IconButton } from "@mui/material";

import CsvReportDownload from "./CsvReportDownload";
import JsonApiReportDownload from "./JsonApiReportDownload";
import { clearAllReports, setReportTrayOpen } from "./globalReportSlice";
import buildReportDefinitions from "./reportDefinitions";
import { ReportSource, ReportStatus, TGlobalReports } from "./types";

const GlobalReportManager = ({ children }) => {
  const dispatch = useDispatch();
  const { organization, role } = useSelector((state: any) => state.currentUser);
  const categories = useSelector(
    (state: any) => state.groupCategories.categories
  );

  const reportDefinitions = useMemo(
    () => buildReportDefinitions(organization, categories, role),
    [organization, categories, role]
  );

  const { reportQueue, reportTrayOpen } = useSelector(
    (state: any) => state.globalReports as TGlobalReports
  );

  const canClearAll = !reportQueue.some(
    (report) => report.status === ReportStatus.LOADING
  );
  return (
    <div tw="fixed inset-0">
      {children}
      {reportQueue.length > 0 && (
        <div
          tw="
          w-full max-w-sm
          absolute right-3 bottom-0 z-[9999]
          drop-shadow-xl shadow-sm bg-white rounded-t-lg
          overflow-hidden"
        >
          <div tw="flex items-center justify-between px-3 py-2 border-b-2 bg-neutral-800 text-white">
            <span>Report downloads</span>
            <div>
              <IconButton
                size="small"
                edge="end"
                onClick={() => dispatch(setReportTrayOpen(!reportTrayOpen))}
              >
                <ExpandMoreRounded
                  tw="text-white"
                  css={!reportTrayOpen && tw`rotate-180`}
                />
              </IconButton>
              {canClearAll && (
                <IconButton
                  size="small"
                  edge="end"
                  onClick={() => dispatch(clearAllReports())}
                >
                  <CloseRounded tw="text-white" />
                </IconButton>
              )}
            </div>
          </div>
          <Collapse in={reportTrayOpen} tw="max-h-60 overflow-auto">
            <div tw="px-3 mb-2 divide-y">
              {reportQueue.map((report) => {
                if (report.source === ReportSource.CSV) {
                  return <CsvReportDownload key={report.id} report={report} />;
                }
                if (report.source === ReportSource.JSON_API) {
                  return (
                    <JsonApiReportDownload
                      key={report.id}
                      report={report}
                      reportDefinition={reportDefinitions[report.name]}
                    />
                  );
                }
                return null;
              })}
            </div>
          </Collapse>
        </div>
      )}
    </div>
  );
};

export default GlobalReportManager;
