import "twin.macro";

import { useState } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";

import { CircularProgress, Switch } from "@mui/material";

import {
  updateChannelUser,
  updateCurrentUser,
} from "@redux/slices/user/currentUserSlice";

import { OpaqueCard } from "../../StyledComponents";

const Threshold = () => {
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const { channelUsers, receivesThresholdEmails } = useSelector(
    (state) => state.currentUser
  );

  const handleChangeUserChannel = (channelUser) => {
    setLoading(true);
    const receivesThresholdEmails = !channelUser.receivesThresholdEmails;

    dispatch(
      updateChannelUser(channelUser.id, { receivesThresholdEmails }, () =>
        setLoading(false)
      )
    );
  };

  const handleChangeUser = (rte) => {
    setLoading(true);
    dispatch(
      updateCurrentUser({ receivesThresholdEmails: rte }, () =>
        setLoading(false)
      )
    );
  };
  return (
    <OpaqueCard tw="relative">
      {loading && <CircularProgress tw="absolute bottom-4 right-4" size={20} />}
      <h2 tw="text-xl font-bold">Low Stock emails</h2>
      {channelUsers.length > 0 && (
        <>
          <p tw="text-neutral-500">
            Control for which channels you receive low stock email alerts.
          </p>
          <div tw="mt-4">
            {channelUsers.map((c) => (
              <label tw="flex items-center gap-4" key={c.id}>
                <Switch
                  checked={c.receivesThresholdEmails}
                  onChange={() => handleChangeUserChannel(c)}
                />
                <div>{c.channelName}</div>
              </label>
            ))}
          </div>
        </>
      )}
      {channelUsers.length === 0 && (
        <>
          <p tw="text-neutral-500">
            Control whether or not you receive email notifications for low stock
            items.
          </p>
          <div tw="mt-4">
            <div tw="flex items-center gap-4">
              <Switch
                disabled={loading}
                checked={receivesThresholdEmails}
                onChange={() => handleChangeUser(!receivesThresholdEmails)}
              />
            </div>
          </div>
        </>
      )}
    </OpaqueCard>
  );
};

export default Threshold;
