import React, { useCallback, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import Autocomplete from "@mui/material/Autocomplete";

import PropTypes from "prop-types";

import { fetchTerritoriesByName } from "@redux/slices/territories/territorySlice";
import { StyledTextInput } from "@utils/forms/ControlledInputs";

const TerritoryAutoComplete = ({
  handleChange,
  reset,
  setReset,
  filterType,
}) => {
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [territory, setTerritory] = useState("");
  const [currentTerritories, setCurrentTerritories] = useState([]);

  const isLoading = useSelector((state) => state.territories.isLoading);
  const options = useSelector(
    (state) => state.territories.filteredTerritoryList
  );
  const currentFiltersTerritory = useSelector(
    (state) => state.filters.territoryIds
  );
  const isGlobalLoading = useSelector((state) => state.globalState.isLoading);

  const loading = open && isLoading;

  const debounce = useRef(null);

  const handleTerritories = (value) => {
    setCurrentTerritories(value);
  };

  const handleQuery = useCallback(() => {
    clearTimeout(debounce.current);

    debounce.current = setTimeout(() => {
      dispatch(fetchTerritoriesByName(territory));
    }, 250);
  }, [territory, dispatch]);

  useEffect(() => {
    if (territory.length >= 1) {
      handleQuery();
    }
  }, [territory, handleQuery, dispatch]);

  useEffect(() => {
    if (currentFiltersTerritory.length !== currentTerritories.length) {
      setCurrentTerritories(currentFiltersTerritory);
    }
  }, [currentFiltersTerritory, currentTerritories.length]);

  useEffect(() => {
    if (reset && setReset) {
      if (reset) {
        setTerritory("");
        setCurrentTerritories([]);
        setReset(false);
      }
    }
  }, [reset, setTerritory, setReset]);

  return (
    <>
      <Autocomplete
        multiple
        autoHighlight
        renderTags={() => null}
        fullWidth
        freeSolo
        id="territory-auto-complete"
        open={open}
        onOpen={() => setOpen(true)}
        onClose={() => setOpen(false)}
        inputValue={territory}
        onInputChange={(_evt, value) => setTerritory(value)}
        onChange={(_evt, value) => {
          handleChange(value, "territoryIds", filterType);
          handleTerritories(value);
        }}
        isOptionEqualToValue={(option, value) => option.name === value.name}
        getOptionLabel={(option) => option.name}
        options={options}
        loading={loading}
        value={currentTerritories}
        disabled={isGlobalLoading}
        renderInput={(params) => (
          <StyledTextInput {...params} label="Territory" />
        )}
      />
    </>
  );
};

TerritoryAutoComplete.propTypes = {
  handleChange: PropTypes.func.isRequired,
  reset: PropTypes.bool,
  setReset: PropTypes.func,
};

export default TerritoryAutoComplete;
