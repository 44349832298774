import "twin.macro";

import { useSelector } from "react-redux";

import { useQueryClient } from "@tanstack/react-query";

import { OpaqueCard } from "../../components/StyledComponents";
import ReactQueryTable, {
  PaginatedResourceProps,
} from "../../components/Table/ReactQueryTable";
import SearchableText from "../../components/Table/SearchableText";
import { Program } from "../../models";
import { CldImage } from "../../services/cloudinary";
import { programsKeyFactory } from "./data";

export type ProgramTableProps = PaginatedResourceProps & {
  rows: Program[];
  query?: string;
  onRowClick?: (row: Program) => void;
};

const ProgramTable = ({ query, ...tableProps }: ProgramTableProps) => {
  const queryClient = useQueryClient();
  const { usesChannels } = useSelector(
    (state: any) => state.currentUser.organization
  );
  return (
    <OpaqueCard tw="p-0 overflow-hidden relative">
      <ReactQueryTable
        onRowClick={(program) =>
          queryClient.setQueryData<Program>(
            programsKeyFactory.detail(program.id).queryKey,
            program
          )
        }
        columns={[
          {
            id: "cloudinaryId",
            label: "Image",
            to: (row) => `/admin/programs/${row.id}`,
            render: (cloudinaryId) => (
              <div tw="flex-shrink-0 w-20 relative rounded-md overflow-hidden">
                <CldImage
                  tw="w-full aspect-square object-contain 
                  border-2 border-neutral-200
                bg-white rounded-md"
                  publicId={cloudinaryId}
                  size="thumbnail"
                />
              </div>
            ),
          },
          {
            id: "name",
            label: "Name",
            sort: "name",
            to: (row) => `/admin/programs/${row.id}`,
            render: (name) => (
              <div tw="text-base! text-neutral-800 leading-tight mt-2">
                <SearchableText text={name} query={query} />
              </div>
            ),
          },
          {
            id: "description",
            label: "Description",
            to: (row) => `/admin/programs/${row.id}`,
            render: (description) => (
              <SearchableText text={description} query={""} maxLength={200} />
            ),
          },
          ...(usesChannels
            ? [
                {
                  id: "channel.name",
                  label: "Channel",
                },
              ]
            : []),
          {
            id: "status",
            label: "Status",
            sort: "status",
            render: (status, p) =>
              !p.isActive
                ? "Inactive"
                : status === "complete"
                  ? "Active"
                  : "Draft",
          },
        ]}
        {...tableProps}
      />
    </OpaqueCard>
  );
};

export default ProgramTable;
