import "twin.macro";

import { useState } from "react";
import { useNavigate } from "react-router";

import { StyledButton } from "@components/StyledComponents";
import { RequestForQuote } from "@models/RequestForQuote";
import { useMutateError } from "@services/api";

import ItemSelectionModal from "./ItemSelectionModal";
import { useCopyRfqMutation, useCreateRfqMutation } from "./data";

const NewRfqButton = ({
  itemId,
  programId,
  label = "New Quote Request",
  baseOffOf: rfq,
  ...props
}: {
  itemId?: string;
  programId?: string;
  label?: string;
  baseOffOf?: RequestForQuote;
  [key: string]: any;
}) => {
  const navigate = useNavigate();
  const setMutateError = useMutateError();
  const [itemSelectionModalOpen, setItemSelectionModalOpen] = useState(false);

  const createMutation = useCreateRfqMutation();
  const copyMutation = useCopyRfqMutation();

  const createNewRFQ = (itemId: string) =>
    createMutation.mutate(itemId, {
      onSuccess: (rfq) => navigate(`/rfqs/${rfq.id}`),
      onError: setMutateError,
    });

  const copyRfq = (rfqId: string) =>
    copyMutation.mutate(rfqId, {
      onSuccess: (rfq) => navigate(`/rfqs/${rfq.id}`),
      onError: setMutateError,
    });

  const handleClick = () => {
    if (rfq) {
      copyRfq(rfq.id);
    } else if (itemId) {
      createNewRFQ(itemId);
    } else {
      setItemSelectionModalOpen(true);
    }
  };

  return (
    <>
      {itemSelectionModalOpen && (
        <ItemSelectionModal
          onClose={() => setItemSelectionModalOpen(false)}
          onSelect={(itemId) => createNewRFQ(itemId)}
          programId={programId}
        />
      )}
      <StyledButton cta onClick={handleClick} {...props}>
        {label}
      </StyledButton>
    </>
  );
};

export default NewRfqButton;
