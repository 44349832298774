import tw from "twin.macro";

import React from "react";
import { Link } from "react-router";

import { ArrowForwardRounded, DeleteRounded } from "@mui/icons-material";
import {
  Checkbox,
  Chip,
  CircularProgress,
  IconButton,
  Tooltip,
} from "@mui/material";

import { format } from "date-fns";
import _ from "lodash";

import { OpaqueCard, StyledButton } from "@components/StyledComponents";
import { QuoteStatus } from "@features/quotes";
import { DateFromNow, FaIcon } from "@features/ui";
import { Quote } from "@models/Quote";
import { RequestForQuote } from "@models/RequestForQuote";
import {
  formatDistanceFromNow,
  formatMoneyString,
  utcToLocalDate,
} from "@utility/utilityFunctions";

import NewRfqButton from "./NewRfqButton";
import { useCancelRfqMutation, useRfqListQuery } from "./data";

const QuoteRow = ({
  quote,
  selectedQuotes,
  toggleSelection,
}: {
  quote: Quote;
  selectedQuotes: Quote[];
  toggleSelection: () => void;
}) => {
  const isComplete = ["complete", "awarded"].includes(quote.status);

  return (
    <div tw="flex items-center gap-4 px-4 py-3 text-neutral-800 text-sm">
      <Tooltip
        enterDelay={500}
        title={
          quote.isComplete
            ? "Select for comparison"
            : "Quote must be completed to select for comparison"
        }
        placement="top"
      >
        <div>
          <Checkbox
            size="small"
            css={[
              tw`-mx-1.5 transition-opacity opacity-0 group-hover:opacity-100`,
              selectedQuotes.length > 0 && tw`opacity-100`,
            ]}
            checked={selectedQuotes.some((q) => q.id === quote.id)}
            onChange={toggleSelection}
            onClick={(e) => e.stopPropagation()}
            disabled={!quote.isComplete}
          />
        </div>
      </Tooltip>
      <div tw="flex-1 text-base">{quote.supplierName}</div>
      <div tw="flex-1 flex flex-wrap gap-x-3 items-center">
        <QuoteStatus status={quote.status} />
        <div tw=" text-neutral-500">
          <DateFromNow date={utcToLocalDate(quote.updatedAt)} />
        </div>
      </div>
      {quote.status !== "declined" && (
        <>
          <div tw="flex-[0.5]">
            {isComplete && (
              <>
                {formatMoneyString(
                  _(quote.pricingTierQuotes).map("cost").minBy(Number)
                )}
                <div tw="text-xs text-neutral-500">Lowest unit-price</div>
              </>
            )}
          </div>
          <div tw="flex-1 flex justify-end">
            {isComplete && (
              <StyledButton
                tw="rounded-full whitespace-nowrap"
                outlined
                endIcon={<ArrowForwardRounded />}
                to={`/quotes/${quote.id}`}
              >
                View Quote
              </StyledButton>
            )}
          </div>
        </>
      )}
      {quote.status === "declined" && (
        <div tw="flex-[2]">
          <div tw="text-orange-900">Reason: {quote.declineReason}</div>
        </div>
      )}
    </div>
  );
};

const DraftRfq = ({
  rfq,
  onCancel,
}: {
  rfq: RequestForQuote;
  onCancel: (id: string) => any;
}) => {
  return (
    <Link to={`/rfqs/${rfq.id}`}>
      <OpaqueCard className="group" tw="px-4 relative w-72 h-full">
        <h3 tw=" text-neutral-700 group-hover:underline">
          Round {rfq.round} - draft
        </h3>
        <Tooltip title="Cancel RFQ">
          <IconButton
            tw="hidden group-hover:flex absolute top-1.5 right-1.5"
            size="small"
            onClick={(e) => {
              e.stopPropagation();
              e.preventDefault();
              onCancel(rfq.id);
            }}
          >
            <DeleteRounded tw="text-lg" />
          </IconButton>
        </Tooltip>
        <p tw="text-sm text-neutral-500 line-clamp-1">{rfq.note}</p>
        {rfq.pricingTiers.length > 0 && (
          <div tw="text-sm text-primary-800 mt-1">
            Tiers {rfq.pricingTiers.map((t) => t.qty).join(", ")}
          </div>
        )}
      </OpaqueCard>
    </Link>
  );
};

type ItemQuotesListProps = {
  itemId: string;
  selectedQuotes: Quote[];
  toggleSelection: (quote: Quote) => void;
};

const ItemQuotesList: React.FC<ItemQuotesListProps> = ({
  itemId,
  selectedQuotes,
  toggleSelection,
}) => {
  const { data, isLoading } = useRfqListQuery({ filter: { itemId } });
  const cancelRfqMutation = useCancelRfqMutation();

  const rfqs = data ?? [];

  const draftRfqs = _(rfqs)
    .filter((rfq) => rfq.status === "draft")
    .sortBy("round")
    .value();

  const sentRfqs = _(rfqs)
    .filter((rfq) => rfq.quotes.length > 0)
    .value();

  const handleCancelRfq = (rfqId: string) => cancelRfqMutation.mutate(rfqId);

  if (isLoading) return <CircularProgress />;

  return (
    <div>
      {draftRfqs.length > 0 && (
        <div tw="flex gap-4 mb-6 overflow-x-auto max-w-full">
          {draftRfqs.map((rfq) => (
            <DraftRfq key={rfq.id} rfq={rfq} onCancel={handleCancelRfq} />
          ))}
        </div>
      )}
      {sentRfqs.length === 0 && (
        <OpaqueCard tw="p-6 text-neutral-700 bg-neutral-200 max-w-prose">
          <h2 tw="text-xl text-neutral-800 mb-2">
            No quotes have been sent for this item.
          </h2>
          <p>
            Quotes sent to suppliers will show up here once you submit a{" "}
            <b tw="font-medium">Request For Quotes</b>.
          </p>
          <NewRfqButton
            tw="mt-6"
            itemId={itemId}
            label="Create Request For Quote"
          />
        </OpaqueCard>
      )}
      <div tw="space-y-6">
        {sentRfqs.map((rfq) => (
          <div key={rfq.id} tw="bg-white rounded-lg">
            <div tw="flex justify-between items-center mx-4 pt-4">
              <div tw="flex gap-6 items-baseline">
                <Link
                  tw="block text-neutral-700 hover:underline underline-offset-2 font-medium tracking-wide"
                  to={`/rfqs/${rfq.id}`}
                >
                  <span
                    css={[
                      rfq.status === "canceled" &&
                        tw`line-through text-neutral-500`,
                    ]}
                  >
                    RFQ #{rfq.id} - Round {rfq.round}
                  </span>{" "}
                </Link>
                {rfq.status === "canceled" && <QuoteStatus status="canceled" />}
              </div>
              <div tw="flex justify-end gap-2 -mr-3">
                {rfq.requestedDueDate && (
                  <Chip
                    label={`Requested by ${format(
                      new Date(rfq.requestedDueDate),
                      "PP"
                    )}`}
                    size="small"
                    icon={
                      <FaIcon icon={"clock"} tw="text-sm! text-primary-800!" />
                    }
                    tw="bg-transparent text-primary-800"
                  />
                )}
              </div>
            </div>
            <div tw="flex justify-between items-center mx-4">
              <p tw="text-sm text-neutral-500 max-w-prose line-clamp-1 mb-2">
                {/* Tiers:{" "}
                  {_(rfq.pricingTiers)
                    .sortBy("qty")
                    .map((t) => formatNumber(t.qty))
                    .join(", ")} */}
                {rfq.note}
              </p>
              <div tw="text-sm text-neutral-500">
                sent {formatDistanceFromNow(utcToLocalDate(rfq.insertedAt))}
              </div>
            </div>
            <div
              className="group"
              tw="divide-y divide-neutral-200 p-0 border-t border-neutral-200 mt-1"
            >
              {rfq.quotes.map((quote) => (
                <QuoteRow
                  key={quote.id}
                  quote={quote}
                  selectedQuotes={selectedQuotes}
                  toggleSelection={() => toggleSelection(quote)}
                />
              ))}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ItemQuotesList;
