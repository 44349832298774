import "twin.macro";

import { Dialog, DialogContent } from "@mui/material";

import Carousel from "@components/Carousel";
import { CloudinaryFile } from "@models/CloudinaryFile";

import CloudinaryFileSlide from "./CloudinaryFileSlide";

const ImageCarousel = ({
  files,
  open,
  handleClose,
  startIndex,
}: {
  files: CloudinaryFile[];
  open: boolean;
  handleClose: () => void;
  startIndex?: number;
}) => {
  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogContent tw="p-6">
        <Carousel startIndex={startIndex}>
          {files.map((file) => (
            <CloudinaryFileSlide file={file} key={file.cloudinaryId} />
          ))}
        </Carousel>
      </DialogContent>
    </Dialog>
  );
};

export default ImageCarousel;
