import "twin.macro";

import { useDispatch } from "react-redux";

import { createPurchaseOrderVariant } from "@redux/slices/purchaseOrders/purchaseOrderSlice";

import ItemVariantInventoryQtyModal, {
  TVariantQty,
} from "./ItemVariantInventoryQtyModal";

const CreateInventoryPOVModal = ({
  itemId,
  handleClose,
  disabledSkus,
}: {
  itemId: string;
  handleClose: () => void;
  disabledSkus: string[];
}) => {
  const dispatch = useDispatch();
  const handleCreatePOV = async (
    variantQtys: TVariantQty[],
    warehouse: string
  ) =>
    dispatch(
      createPurchaseOrderVariant(
        {
          variantQtys: variantQtys,
          warehouse,
        },
        handleClose
      )
    );

  return (
    <ItemVariantInventoryQtyModal
      modalTitle="Order Additional Inventory"
      itemId={itemId}
      onClose={handleClose}
      onSubmit={handleCreatePOV}
      disabledSkus={disabledSkus}
    />
  );
};

export default CreateInventoryPOVModal;
