import "twin.macro";

import React, { useState } from "react";
import { CSVReader } from "react-papaparse";
import { useDispatch } from "react-redux";

import { FileDownload, MoreVert, UploadFile } from "@mui/icons-material";
import {
  CircularProgress,
  Dialog,
  DialogActions,
  DialogTitle,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material";

import { saveAs } from "file-saver";
import { z } from "zod";

import { StyledButton } from "@components/StyledComponents";
import OptionsMenu from "@components/Utility/OptionsMenu";
import { setError } from "@redux/slices/errorSlice";
import client from "@services/api";
import { kebabCaseKeys } from "@utility/utilityFunctions";
import { formatCsvBlob } from "@utils/csv";

const zDollarAmount = z.preprocess(
  (val) =>
    val && typeof val === "string"
      ? Number(val.replace(/[$,]/g, ""))
      : undefined,
  z.number().nonnegative().optional()
);

const headers = [
  "Order Number",
  "Total Actual Tax",
  "Total Actual Shipping Cost",
] as const;

const schema = z.array(
  z.object({
    [headers[0]]: z.string().nonempty(), // id
    [headers[1]]: zDollarAmount.optional(), // tax
    [headers[2]]: zDollarAmount.optional(), // freight
  })
);

const OrderUploadTaxAndFreight = () => {
  const dispatch = useDispatch();
  const csvRef = React.useRef<CSVReader<any>>(null);
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(0);

  const handleDownloadTemplate = () => {
    const csvBlob = formatCsvBlob(headers.join(","));
    saveAs(csvBlob, "tax-and-freight-template.csv");
  };

  const handleFileUpload = (data: any[]) => {
    const lines = data
      .map(({ data }) => data)
      .filter((row) => Object.values(row).some(Boolean));
    const res = schema.safeParse(lines);
    if (!res.success) {
      const err = res.error.errors[0];
      dispatch(
        setError({
          error: `Error on line ${(err.path[0] as number) + 2}: ${
            err.path[1]
          } ${
            err.message
          }\n\nThe file has not been uploaded, please address the error and attempt the upload again.`,
          source: "Parse Order Bulk Upload Tax and Freight",
        })
      );
      setLoading(false);
      console.error(res.error.errors);
      return;
    }
    const orders = res.data.map((data) =>
      kebabCaseKeys({
        id: data[headers[0]],
        totalActualTax: data[headers[1]],
        totalActualShippingCost: data[headers[2]],
      })
    );

    client
      .post(
        "orders/bulk-add-freight-and-tax",
        { orders: orders },
        { serializeBody: false }
      )
      .then(() => setSuccess(orders.length))
      .catch((err) =>
        dispatch(
          setError({
            error:
              err.data.error +
              "\n\nThe file has not been uploaded, please address the error and attempt the upload again.",
            source: "Order Bulk Upload Tax and Freight",
          })
        )
      )
      .finally(() => setLoading(false));
  };

  return (
    <div>
      {success > 0 && (
        <Dialog open fullWidth maxWidth="xs">
          <DialogTitle>Succesfully updated {success} orders!</DialogTitle>
          <DialogActions>
            <StyledButton cta onClick={() => setSuccess(0)}>
              Dismiss
            </StyledButton>
          </DialogActions>
        </Dialog>
      )}
      <CSVReader
        ref={csvRef}
        onFileLoad={handleFileUpload}
        noProgressBar
        config={{
          header: true,
          beforeFirstChunk: () => setLoading(true),
        }}
        // prevent default appearance
        children={() => null}
      />
      <OptionsMenu
        title="More Options"
        component={
          React.memo((props) => (
            <StyledButton outlined endIcon={<MoreVert />} {...props}>
              More
            </StyledButton>
          )) as any
        }
      >
        <ListItemButton onClick={() => handleDownloadTemplate()}>
          <ListItemIcon>
            <FileDownload />
          </ListItemIcon>
          <ListItemText primary="Download tax & freight template" />
        </ListItemButton>
        <ListItemButton
          onClick={(e) => {
            csvRef.current?.open(e);
          }}
          disabled={loading}
        >
          <ListItemIcon>
            <UploadFile />
          </ListItemIcon>
          <ListItemText primary="Bulk upload tax & freight" />
          {loading && <CircularProgress size={24} />}
        </ListItemButton>
      </OptionsMenu>
    </div>
  );
};

export default OrderUploadTaxAndFreight;
