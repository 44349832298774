import "twin.macro";

import React, { useState } from "react";

import { Close } from "@mui/icons-material";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
} from "@mui/material";

import { StyledButton } from "@components/StyledComponents";
import { StyledTextInput } from "@utils/forms/ControlledInputs";

type BudgetUpdateNoteModalProps = {
  handleClose: () => void;
  handleSave: (note: string) => Promise<void>;
};

const BudgetUpdateNoteModal: React.FC<BudgetUpdateNoteModalProps> = ({
  handleClose,
  handleSave,
}) => {
  const [loading, setLoading] = useState(false);
  const [note, setNote] = useState("");

  const canSubmit = note.trim().length > 0;

  const handleSubmit = async () => {
    if (!canSubmit || loading) return;
    setLoading(true);
    await handleSave(note.trim());
    setLoading(false);
    handleClose();
  };
  return (
    <Dialog open onClose={handleClose} fullWidth maxWidth="sm">
      <DialogTitle tw="flex justify-between items-start">
        Confirm Budget Changes
        <IconButton onClick={handleClose} edge="end">
          <Close />
        </IconButton>
      </DialogTitle>
      <DialogContent tw="max-w-prose">
        <h3 tw="text-lg font-medium">
          Please provide a note explaining the changes made to this budget.
        </h3>
        <p tw="text-neutral-500 mb-4">
          Notes are used to help describe events in the budget history and are
          required
        </p>
        <StyledTextInput
          label="Note"
          autoFocus
          multiline
          minRows={3}
          value={note}
          onChange={(e) => setNote(e.target.value)}
        />
      </DialogContent>
      <DialogActions>
        <StyledButton
          role="button"
          outlined
          disabled={loading}
          onClick={handleClose}
        >
          Cancel
        </StyledButton>
        <StyledButton
          cta
          role="submit"
          disabled={!canSubmit}
          onClick={handleSubmit}
          loading={loading}
        >
          Save
        </StyledButton>
      </DialogActions>
    </Dialog>
  );
};

export default BudgetUpdateNoteModal;
