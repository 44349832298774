import React, { memo } from "react";

import CancelIcon from "@mui/icons-material/CloseRounded";
import {
  Button,
  Dialog,
  DialogContent,
  IconButton,
  Typography,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

import PropTypes from "prop-types";

const useStyles = makeStyles((theme) => ({
  ...theme.global,
}));

const WindowSelectionModal = ({
  open,
  handleClose,
  windows,
  handleOrderWindowClick,
}) => {
  const classes = useStyles();

  return (
    <div className={classes.relativeContainer}>
      <Dialog
        open={open}
        disableScrollLock
        onClose={handleClose}
        fullWidth
        maxWidth="sm"
      >
        <DialogContent>
          <IconButton
            className={classes.closeButton}
            onClick={handleClose}
            size="large"
          >
            <CancelIcon fontSize="large" color="secondary" />
          </IconButton>
          <br />
          <div className={classes.centeredModal}>
            <Typography className={classes.headerText}>
              There are multiple pre-order windows open, please select which
              you'd like to view.
            </Typography>
            <br />
            {windows.map((w, i) => (
              <div className={classes.fullWidthCenter} key={i}>
                <Button
                  className={classes.button}
                  variant="contained"
                  color="secondary"
                  onClick={() => {
                    handleClose();
                    handleOrderWindowClick(w.id);
                  }}
                >
                  {w.name}
                </Button>
                <br />
                <br />
              </div>
            ))}
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
};

WindowSelectionModal.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  windows: PropTypes.array.isRequired,
  handleOrderWindowClick: PropTypes.func.isRequired,
};

export default memo(WindowSelectionModal);
