import "twin.macro";

import { useQueryClient } from "@tanstack/react-query";

import ReactQueryTable, {
  Column,
  PaginatedResourceProps,
} from "@components/Table/ReactQueryTable";
import SearchableText from "@components/Table/SearchableText";
import { orderTypeLabel } from "@features/ordering";
import { DateFromNow } from "@features/ui";
import { Quote } from "@models/Quote";
import { formatUtcDate, utcToLocalDate } from "@utility/utilityFunctions";

import QuoteStatus from "./QuoteStatus";
import { quotesKeyFactory } from "./data";

const quoteUrl = (quote: Quote) => `/quotes/${quote.id}`;

const SupplierQuotesTable = ({
  query,
  ...tableProps
}: PaginatedResourceProps & {
  rows: Quote[];
  query?: string;
}) => {
  const queryClient = useQueryClient();
  const columns: Column<Quote>[] = [
    {
      id: "supplierQuoteNumber",
      label: "Quote Number",
      render: (v) =>
        v ? (
          <span tw="text-neutral-800 leading-tight mt-2 underline underline-offset-2">
            <SearchableText text={v} query={query} />
          </span>
        ) : (
          <span tw="text-neutral-500">---</span>
        ),
      to: quoteUrl,
    },
    {
      id: "requestForQuote.itemName",
      label: "Item Name",
      render: (name) => (
        <span tw="text-neutral-800 leading-tight mt-2 underline underline-offset-2">
          <SearchableText text={name} query={query} />
        </span>
      ),
      to: quoteUrl,
    },
    {
      id: "requestForQuote.orderType",
      label: "Order Type",
      render: orderTypeLabel,
    },
    {
      id: "requestForQuote.requestedDueDate",
      label: "Quote Due Date",
      sort: "due-date",
      render: (v) => formatUtcDate(v),
    },
    {
      id: "requestForQuote.estimatedOrderDate",
      label: "Order Date",
      sort: "order-date",
      render: (v) => formatUtcDate(v),
    },
    {
      id: "status",
      label: "Status",
      to: quoteUrl,
      render: (status) => <QuoteStatus status={status} />,
    },
    {
      id: "updatedAt",
      label: "Last Modified",
      render: (d, { status }) =>
        status === "sent" ? "" : <DateFromNow date={utcToLocalDate(d)} />,
    },
  ];

  return (
    <ReactQueryTable
      onRowClick={(quote) => {
        queryClient.setQueryData(
          quotesKeyFactory.detail(quote.id).queryKey,
          quote
        );
      }}
      {...tableProps}
      columns={columns}
    />
  );
};

export default SupplierQuotesTable;
