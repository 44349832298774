import "twin.macro";

import { useDispatch } from "react-redux";
import { useNavigate } from "react-router";

import { createPurchaseOrder } from "@redux/slices/purchaseOrders/purchaseOrderSlice";

import ItemVariantInventoryQtyModal, {
  TVariantQty,
} from "./ItemVariantInventoryQtyModal";

const CreateInventoryPurchaseOrderModal = ({
  itemId,
  handleClose,
}: {
  itemId: string;
  handleClose: () => void;
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const handleCreateInventoryPO = async (
    variantQtys: TVariantQty[],
    warehouse: string
  ) =>
    dispatch(
      createPurchaseOrder(
        {
          type: "inventory",
          variantQtys: variantQtys,
          warehouse,
        },
        (po) => {
          navigate(`/purchase-orders/new/${po.id}`);
          handleClose();
        }
      )
    );

  return (
    <ItemVariantInventoryQtyModal
      modalTitle="Create an Inventory Purchase Order"
      itemId={itemId}
      onClose={handleClose}
      onSubmit={handleCreateInventoryPO}
    />
  );
};

export default CreateInventoryPurchaseOrderModal;
