import "twin.macro";

import { useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";

import { Button, Typography } from "@mui/material";

import { StyledButton } from "@components/StyledComponents";
import { updateOrder } from "@redux/slices/orders/orderHistorySlice";
import { formatMoney } from "@utility/utilityFunctions";
import {
  ControlledTextInput,
  moneyAdornment,
  moneyValidation,
} from "@utils/forms";
import useRoleIs from "@utils/useRoleIs";

type SingleOrderFreightAndTaxProps = {
  order: {
    id: string;
    estFreight: number;
    estTax: number;
    actFreight: number;
    actTax: number | null;
  };
};

const TaxAndFreightForm = ({
  id,
  tax,
  freight,
  handleClose,
}: {
  id: string;
  tax: number;
  freight: number;
  handleClose: () => void;
}) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const { control, handleSubmit } = useForm({
    defaultValues: {
      totalActualShippingCost: (freight ?? 0).toFixed(2),
      totalActualTax: (tax ?? 0).toFixed(2),
    },
  });

  const handleSave = (data: {
    totalActualShippingCost: string;
    totalActualTax: string;
  }) => {
    setLoading(true);
    dispatch(
      updateOrder(id, data, () => {
        setLoading(false);
        handleClose();
      })
    );
  };
  return (
    <form tw="space-y-2">
      <ControlledTextInput
        control={control}
        name="totalActualShippingCost"
        label="Actual Freight"
        rules={moneyValidation}
        {...moneyAdornment}
      />
      <ControlledTextInput
        control={control}
        name="totalActualTax"
        label="Actual Tax"
        rules={moneyValidation}
        {...moneyAdornment}
      />
      <div tw="flex gap-2">
        <StyledButton outlined onClick={() => handleClose()}>
          Cancel
        </StyledButton>
        <StyledButton cta onClick={handleSubmit(handleSave)} loading={loading}>
          Save
        </StyledButton>
      </div>
    </form>
  );
};

const SingleOrderFreightAndTax = ({ order }: SingleOrderFreightAndTaxProps) => {
  const [editing, setEditing] = useState(false);
  const { allowShippingCostsAtOrderLevel } = useSelector(
    (state: any) => state.currentUser.organization
  );
  const roleIs = useRoleIs();
  return editing ? (
    <TaxAndFreightForm
      id={order.id}
      handleClose={() => setEditing(false)}
      tax={(order.actTax ?? order.estTax) / 100}
      freight={(order.actFreight || order.estFreight) / 100}
    />
  ) : (
    <div tw="space-y-2 relative">
      {allowShippingCostsAtOrderLevel &&
        roleIs("super", "admin", "purchasing-agent") && (
          <Button
            tw="absolute -top-2 right-0"
            variant="text"
            onClick={() => setEditing(true)}
          >
            Edit
          </Button>
        )}
      {order.actFreight ? (
        <Typography>{`Actual Freight: ${formatMoney(
          order.actFreight
        )}`}</Typography>
      ) : (
        <Typography>
          {`Estimated Freight: ${formatMoney(order.estFreight)}`}
        </Typography>
      )}

      {order.actTax ? (
        <Typography>{`Actual Tax: ${formatMoney(order.actTax)}`}</Typography>
      ) : (
        <Typography>{`Estimated Tax: ${formatMoney(order.estTax)}`}</Typography>
      )}
    </div>
  );
};

export default SingleOrderFreightAndTax;
