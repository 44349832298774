import "twin.macro";
import tw, { styled } from "twin.macro";

import React from "react";

import { Tooltip, TooltipProps, tooltipClasses } from "@mui/material";

const PaperTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))({
  [`& .${tooltipClasses.tooltip}`]: tw`p-3 mt-3 text-sm font-normal bg-white shadow-md drop-shadow text-neutral-700 [text-wrap:pretty]`,
  [`& .${tooltipClasses.arrow}`]: tw`text-white`,
});

export default PaperTooltip;
