import "twin.macro";

import { useSelector } from "react-redux";

import { PictureAsPdfRounded } from "@mui/icons-material";

import { FixedHeightScrollLastChild } from "@components/StyledComponents";
import { Filters, useFilterParams } from "@features/filters";
import {
  ItemCatalogView,
  ItemViewButtons,
  usePaginatedItemsQuery,
} from "@features/items";
import { RequestReportButton, useReport } from "@features/reports";
import { Item } from "@models/Item";
import DocTitle from "@utility/DocTitle";
import permissions from "@utils/permissions";

import FourOhFour from "./FourOhFour";

type ItemCatalogProps = {
  isArchive?: boolean;
};
const ItemCatalog = ({ isArchive }: ItemCatalogProps) => {
  const [filterParams] = useFilterParams();
  const {
    currentTerritoryId,
    territories: userAssignedTerritories,
    currentChannelId,
    role,
    organization: { restrictItemArchiveToAdmins },
  } = useSelector((state: any) => state.currentUser);

  const params = {
    filter: {
      ...(!isArchive && { isActive: true, isVisible: true }),
      ...(isArchive && { isArchived: true }),
      isDraft: false,
      territoryId: currentTerritoryId,
      channelId: currentChannelId,
      query: filterParams.q,
      favorites: filterParams.favorites,
      orderType: filterParams.orderType,
      territoryIds:
        filterParams.territories ??
        userAssignedTerritories.map((t: any) => t.id),
      programIds: filterParams.programs,
      groupIds:
        filterParams.groups && Object.values(filterParams.groups).flat(),
    },
    sort: filterParams.sort,
  };

  const { data, ...tableProps } = usePaginatedItemsQuery(params);

  useReport("items-pdf", ["items", params]);

  if (
    isArchive &&
    restrictItemArchiveToAdmins &&
    !permissions.admin.includes(role)
  ) {
    return <FourOhFour />;
  }

  return (
    <>
      <DocTitle title={isArchive ? "Catalog Archive" : "Item Catalog"} />
      <FixedHeightScrollLastChild>
        <div tw="flex flex-wrap justify-between items-center">
          <h2 tw="text-2xl text-neutral-600 font-bold mb-2">
            {isArchive ? "Catalog Archive" : "Item Catalog"}
          </h2>
          <RequestReportButton
            title="Export PDF"
            startIcon={<PictureAsPdfRounded />}
            reportName={"items-pdf"}
          />
        </div>
        <div tw="flex gap-2 items-start justify-between">
          <Filters
            searchTitle="Search Items"
            slots={[
              "favorites",
              "orderType",
              "groups",
              "territories",
              "programs",
            ]}
          />
          <ItemViewButtons />
        </div>
        <ItemCatalogView
          rows={data as Item[]}
          query={filterParams.q}
          {...tableProps}
        />
      </FixedHeightScrollLastChild>
    </>
  );
};

export default ItemCatalog;
