import tw from "twin.macro";

const ToggleButtons = ({
  leftTitle,
  rightTitle,
  onLeftSelect,
  onRightSelect,
  selectedValue,
}: {
  leftTitle: string;
  rightTitle: string;
  onLeftSelect: () => void;
  onRightSelect: () => void;
  selectedValue: "left" | "right";
}) => {
  return (
    <div tw="inline-flex h-10 transition-colors bg-white border rounded-md border-neutral-300 hover:border-neutral-400">
      <div
        css={[
          tw`flex items-center px-3 transition rounded-md text-filter text-neutral-600`,
          selectedValue === "left" && tw`text-white bg-neutral-800`,
        ]}
        onClick={onLeftSelect}
      >
        {leftTitle}
      </div>
      <div
        css={[
          tw`flex items-center px-3 transition rounded-md text-filter text-neutral-600`,
          selectedValue === "right" && tw`text-white bg-neutral-800`,
        ]}
        onClick={onRightSelect}
      >
        {rightTitle}
      </div>
    </div>
  );
};

export default ToggleButtons;
