import "twin.macro";

import { useDispatch } from "react-redux";

import CancelRoundedIcon from "@mui/icons-material/CancelRounded";

import { updateHasViewed } from "@redux/slices/user/currentUserSlice";

import Tutorial from "./Tutorial";

const OrderSetTutorial = () => {
  const dispatch = useDispatch();
  const handleDismiss = () => dispatch(updateHasViewed("has-viewed-order-set"));

  return (
    <Tutorial
      handleDismiss={handleDismiss}
      steps={[
        {
          position: "center",
          content: (
            <p>
              <span tw="text-2xl">Welcome to your first Order Set!</span>
              <br />
              Order Sets allow you to ship to different addresses at once, for
              all items added to your cart.
            </p>
          ),
        },

        {
          position: "center",
          content: `First, you must add at least 1 address. Adding an address will create an individual order for that address, 
          with all the items of the order set.
`,
        },
        {
          anchor: "#tutorial-address-selection",
          content: (
            <>
              You can use an existing address from your address book by using
              the <b>address search</b> or a pre-created <b>favorites</b> list.
              Or you can add a <b>new address</b> if it's not yet in your
              address book.
            </>
          ),
        },
        {
          position: "center",
          content: (
            <>
              Once you have added an address, you can delegate quantities of
              items to each address on your form. The order set will behave much
              like an Excel or Google Docs spreadsheet. Add the quantity of the
              item in the cell that coincides with the address you are Ordering
              that item too.
            </>
          ),
        },
        {
          position: "center",
          content: (
            <>
              At any time, you can delete an address from the set, or delete an
              item from every order by clicking the small <CancelRoundedIcon />{" "}
              icon.
            </>
          ),
        },
        {
          anchor: "#tutorial-delete-order-set",
          position: "top",
          content: (
            <>
              You can also delete the entire draft order set itself by clicking
              here.
            </>
          ),
        },
        {
          anchor: "#tutorial-order-set-overview",
          position: "top",
          content: (
            <>
              Clicking this will take you to the order overview, and is only
              active once you have delegated an item quantity to an order. Once
              in the Order Overview, you can <b>review</b> your order,{" "}
              <b>check out</b> with a budget or credit card if applicable, or{" "}
              <b>return here</b> to continue editing the order.
            </>
          ),
        },
      ]}
    />
  );
};

export default OrderSetTutorial;
