import "twin.macro";

import React from "react";
import { useDispatch } from "react-redux";

import CancelIcon from "@mui/icons-material/CloseRounded";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
} from "@mui/material";

import PropTypes from "prop-types";

import { StyledButton } from "@components/StyledComponents";
import { createPurchaseOrder } from "@redux/slices/purchaseOrders/purchaseOrderSlice";

const AdditionalRollupItemModal = ({
  open,
  originalIds,
  additionalRollupItems,
  orderType,
  handleClose,
  onCreateCallback,
}) => {
  const dispatch = useDispatch();
  const allIds = [
    ...new Set(
      additionalRollupItems
        .reduce((acc, ri) => acc.concat(ri.orderVariantIds), [])
        .concat(originalIds)
    ),
  ];

  const createPO = (orderVariantIds) => {
    dispatch(
      createPurchaseOrder(
        { orderVariantIds, orderType: orderType },
        onCreateCallback
      )
    );
    handleClose();
  };

  return (
    <Dialog
      open={open}
      disableScrollLock
      onClose={handleClose}
      fullWidth
      maxWidth="sm"
    >
      <IconButton
        onClick={handleClose}
        size="small"
        tw="absolute top-1 right-1"
      >
        <CancelIcon />
      </IconButton>
      <DialogTitle>Additional Variants</DialogTitle>
      <DialogContent>
        <p tw="text-neutral-700 text-lg">
          It looks like there are additional variants for the items selected
          that can be added to the purchase order you are creating. Would you
          like to create this purchase order with just the selected variants, or
          all available variants?
        </p>
        <br />
        <p tw="text-neutral-600 mb-2">
          Additional Variants that will be added to Purchase Order:
        </p>
        <div tw="grid grid-cols-2">
          {additionalRollupItems.map((ri) => (
            <React.Fragment key={ri.id}>
              <div>Sku: {ri.itemNumber}</div>
              <div>Qty: {ri.totalOrdered}</div>
            </React.Fragment>
          ))}
        </div>
      </DialogContent>
      <DialogActions>
        <StyledButton outlined onClick={() => createPO(originalIds)}>
          USE SELECTED VARIANTS
        </StyledButton>
        <StyledButton outlined onClick={() => createPO(allIds)}>
          USE ALL VARIANTS
        </StyledButton>
      </DialogActions>
    </Dialog>
  );
};

AdditionalRollupItemModal.propTypes = {
  open: PropTypes.bool.isRequired,
  originalIds: PropTypes.array.isRequired,
  additionalRollupItems: PropTypes.array.isRequired,
  orderType: PropTypes.string.isRequired,
  handleClose: PropTypes.func.isRequired,
};

export default AdditionalRollupItemModal;
