import { addDays, getHours, isBefore, isSameDay } from "date-fns";
import { utcToZonedTime } from "date-fns-tz";

import { useCurrentOrderSet } from "@features/ordering";
import { utcDate } from "@utility/utilityFunctions";

export const isDeliveryDateValid = (date: Date, nowEST: Date) => {
  if (isBefore(date, nowEST)) return false;

  const weekday = date.getDay();
  // disable weekends
  if (weekday === 0 || weekday === 6) return false;

  const daysUntilNextBusinessDay = weekday >= 5 ? 8 - weekday : 1;
  const isNextBusinessDay = isSameDay(
    addDays(nowEST, daysUntilNextBusinessDay),
    date
  );
  if (isNextBusinessDay) {
    // allow if before 2pm EST
    return getHours(nowEST) < 14;
  }
  return true;
};

export const useAreOrderDeliveryDatesValid = () => {
  const { orderSet, orders } = useCurrentOrderSet();
  if (orderSet.type !== "inventory") return true;

  const nowEST = utcToZonedTime(new Date(), "America/New_York");
  const allValid = orders.every(
    (order) =>
      !order.expeditedDate ||
      isDeliveryDateValid(utcDate(order.expeditedDate), nowEST)
  );
  return allValid;
};
