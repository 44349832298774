import "twin.macro";

import { useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router";

import { format } from "date-fns";

import ItemColumn from "@components/OrderHistory/ItemColumn";
import ReactQueryTable, {
  Column,
  PaginatedResourceProps,
} from "@components/Table/ReactQueryTable";
import SearchableText from "@components/Table/SearchableText";
import TrackingModal from "@components/Utility/Modals/TrackingModal";
import { OrderVariant } from "@models";
import { orderVariantDatePropertyMap } from "@models/OrderVariant";
import {
  formatDateString,
  formatMoneyString,
  upCase,
  utcDate,
} from "@utility/utilityFunctions";

import OrderStatus from "./OrderStatus";

const insertIf = (cond, val) => (cond ? [val] : []);

const OrderHistoryItemTable = ({
  query,
  ...tableProps
}: PaginatedResourceProps & {
  rows: OrderVariant[];
  query?: string;
}) => {
  const [trackingId, setTrackingId] = useState<string | null>(null);
  const { usesCostCenters, budgetLocation } = useSelector(
    (state: any) => state.currentUser.organization
  );

  const columns: Column<OrderVariant>[] = [
    {
      id: "item",
      label: "Item",
      sort: "sku",
      to: (row) => `/orders/history/${row.order.id}`,
      render: (_, row) => <ItemColumn row={row} query={query} />,
    },
    {
      id: "order.id",
      label: "Order #",
      sort: "order-id",
      to: (row) => `/orders/history/${row.order.id}`,
      render: (id) => (
        <span tw="block hover:underline font-semibold">
          <SearchableText text={id} query={query} />
        </span>
      ),
    },
    {
      id: "poId",
      label: "PO #",
      render: (_, row) => {
        return (
          row.poId && (
            <Link
              to={`/purchase-orders/history/${row.poId}`}
              target="_blank"
              tw="block hover:underline"
            >
              <div>{row.poId}</div>
            </Link>
          )
        );
      },
    },
    {
      id: "orderStatus",
      label: "Status",
      to: (row) => `/orders/history/${row.order.id}`,
      render: (_, row) => (
        <OrderStatus
          status={row.status === "canceled" ? row.status : row.orderStatus}
        />
      ),
    },
    {
      id: "orderType",
      label: "Order Type",
      render: (ot) => upCase(ot, "-"),
    },
    { id: "orderUserName", label: "Ordered By" },
    { id: "addressName", label: "Address Name", sort: "address-name" },
    {
      id: "state",
      label: "State / Region",
      render: (_, row) => row.state ?? row.region,
    },
    {
      id: "programName",
      label: "Program",
      render: (program) => <div tw="w-max max-w-xs">{program}</div>,
    },
    { id: "qty", label: "QTY", align: "right" },
    {
      id: "promotionDiscount",
      label: "Promotion Discount",
      align: "right",
      render: formatMoneyString,
    },
    {
      id: "price",
      label: "Price",
      align: "right",
      render: formatMoneyString,
    },
    {
      id: "totalPrice",
      label: "Extended Price",
      align: "right",
      render: formatMoneyString,
    },
    { id: "orderCheckoutType", label: "Payment Type", render: upCase },
    ...insertIf(budgetLocation, {
      id: "budgetName",
      label: "Budget",
    }),
    ...insertIf(usesCostCenters, {
      id: "costCenterName",
      label: "Cost Center",
    }),
    {
      id: "inMarketDate",
      label: "In-Market Date",
      render: (_, row) => {
        const dateProperty = orderVariantDatePropertyMap[row.orderType];
        return dateProperty && row[dateProperty]
          ? format(utcDate(row[dateProperty]), "MM/dd/yyyy")
          : "";
      },
    },
    {
      id: "orderSubmittedAt",
      label: "Order Date",
      sort: "order-submitted-at",
      render: (date) => (date ? format(utcDate(date), "MM/dd/yyyy") : ""),
    },
    {
      id: "orderApprovedAt",
      label: "Approved Date",
      render: (date) => (date ? format(utcDate(date), "MM/dd/yyyy") : ""),
    },
    {
      id: "actualShipDate",
      label: "Act. Ship Date",
      sort: "address-shipped-at",
      render: (d) => (d ? formatDateString(d) : ""),
    },
    {
      id: "orderExpeditedDate",
      label: "Requested Date",
      render: (d) => (d ? formatDateString(d) : ""),
    },
    {
      id: "trackingData.carrier",
      label: "Carrier",
    },
    {
      id: "trackingData.number",
      label: "Tracking",
      render: (tracking, row) =>
        tracking && (
          <div tw="mr-4">
            {tracking}
            <div
              role="link"
              tw="text-primary-600 underline mt-1 cursor-pointer"
              onClick={() => setTrackingId(row.id)}
            >
              View tracking info
            </div>
          </div>
        ),
    },
  ];

  return (
    <>
      {trackingId && (
        <TrackingModal
          id={trackingId}
          open={true}
          handleClose={() => setTrackingId(null)}
        />
      )}
      <ReactQueryTable {...tableProps} columns={columns} />
    </>
  );
};

export default OrderHistoryItemTable;
