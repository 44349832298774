import { useMemo } from "react";
import { useSelector } from "react-redux";

import _ from "lodash";

import { useCurrentOrderSet } from "../CurrentOrderSetContext";

const useCalculateOrderSetBudgetImpact = () => {
  const { includeShippingInBudgets, includeTaxesInBudgets } = useSelector(
    (state: any) => state.currentUser.organization
  );

  const { orders } = useCurrentOrderSet();

  return useMemo(() => {
    const totalPrice = _.sumBy(orders, "totalPrice");
    const totalTax = _.sumBy(orders, "totalEstimatedTax");
    const totalFreight = _.sumBy(orders, "totalEstimatedShippingCost");

    let impact = totalPrice;
    if (includeShippingInBudgets) impact += totalFreight;
    if (includeTaxesInBudgets) impact += totalTax;

    return impact;
  }, [includeShippingInBudgets, includeTaxesInBudgets, orders]);
};

export default useCalculateOrderSetBudgetImpact;
