import "twin.macro";

import { useWatch } from "react-hook-form";

import { Alert, Button } from "@mui/material";

import { Item } from "@models/Item";
import client from "@services/api";

const DuplicateVariants = ({ item }: { item: Item }) => {
  const formVariants: Record<string, any> = useWatch({ name: "variants" });
  const formVariantSkus = Object.values(formVariants).map((v) => v.id);
  const hiddenVariants = item.variants.filter(
    (v) => v.isActive && !formVariantSkus.includes(v.id)
  );

  const handleDeactivateAll = async () => {
    const promises = hiddenVariants.map((v) =>
      client.update(`variants/${v.id}`, {
        isActive: false,
        selectedVariantOptions: v.selectedVariantOptions,
        relationshipNames: ["selectedVariantOptions"],
      })
    );
    await Promise.all(promises);
    window.location.reload();
  };

  if (hiddenVariants.length === 0) {
    return null;
  }

  return (
    <Alert severity="warning" tw="mt-3">
      <h2 tw="text-lg font-bold">Duplicate Active Variants</h2>
      <p tw="text-sm">{hiddenVariants.map((v) => v.id).join(", ")}</p>
      <p tw="text-sm">{hiddenVariants.map((v) => v.variantSku).join(", ")}</p>
      <Button variant="text" onClick={handleDeactivateAll}>
        Deactivate All
      </Button>
    </Alert>
  );
};

export default DuplicateVariants;
