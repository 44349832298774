import "twin.macro";

import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router";

import { useQueryClient } from "@tanstack/react-query";
import _ from "lodash";

import AdditionalRollupItemModal from "@components/Purchasing/AdditionalRollupItemModal";
import CancelRollupItemModal from "@components/Purchasing/CancelRollupItemModal";
import FulfillFromInventoryModal from "@components/Purchasing/FulfillFromInventoryModal";
import {
  FixedHeightScrollLastChild,
  StyledButton,
} from "@components/StyledComponents";
import useConfirm from "@features/confirm";
import { Filters, useFilterParams } from "@features/filters";
import { usePaginatedItemRollupsQuery } from "@features/itemRollups";
import { itemRollupsKeyFactory } from "@features/itemRollups/";
import {
  PurchaseOrderRollupTable,
  getRelatedItemRollups,
} from "@features/purchaseOrders";
import { RequestReportButton, useReport } from "@features/reports";
import { ItemRollup } from "@models/ItemRollup";
import { setError } from "@redux/slices/errorSlice";
import { cancelMultipleOrderVariants } from "@redux/slices/purchaseOrders/purchaseOrderRollupSlice";
import { createPurchaseOrder } from "@redux/slices/purchaseOrders/purchaseOrderSlice";
import DocTitle from "@utility/DocTitle";

const defaultFilters = {
  orderType: "pre-order",
};

const PurchaseOrderRollupNew = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const [filters] = useFilterParams();
  const confirm = useConfirm();

  const queryClient = useQueryClient();

  const { usesInventory } = useSelector(
    (state: any) => state.currentUser.organization
  );

  const [additionalRollupItems, setAdditionalRollupItems] = useState<
    ItemRollup[]
  >([]);
  const [selectedItemRollups, setSelectedItemRollups] = useState<ItemRollup[]>(
    []
  );

  const [fulfillmentModalOpen, setFulfillmentModalOpen] = useState(false);
  const [cancelItemRollupIds, setCancelItemRollupIds] = useState<
    null | string[]
  >(null);
  const [loading, setLoading] = useState(false);

  const params = {
    filter: {
      searchTerm: filters.q,
      orderSetType: filters.orderType ?? "pre-order",
      territoryIds: filters.territories,
      programIds: filters.programs,
      groupIds: filters.groups && Object.values(filters.groups).flat(),
      supplierIds: filters.suppliers,
    },
    sort: filters.sort,
  };
  const { data, ...tableProps } = usePaginatedItemRollupsQuery(params);

  useReport("purchase-order-rollup", ["item-rollups", params]);

  const canFullfillWithInventory =
    _.sumBy(selectedItemRollups, "globalAvailableToOrderQty") > 0;

  const firstSelectedItemId = selectedItemRollups[0]?.itemId ?? null;

  const multipleItemsSelected =
    !!firstSelectedItemId &&
    selectedItemRollups.some((ir) => firstSelectedItemId !== ir.itemId);

  const multipleItemWarning = async (itemId: number) => {
    if (
      !firstSelectedItemId ||
      multipleItemsSelected ||
      itemId === firstSelectedItemId
    )
      return true;
    return confirm(
      `Purchase orders should only be created for a single item and its variants at a time,
       unless otherwise discussed with the brandhub service team.
       Would you like to continue and create a Purchase Order for multiple items?`,
      {
        title: "Multiple Items Selected",
        confirmButtonText: "Yes",
        cancelButtonText: "Go Back",
      }
    );
  };

  const onCreateCallback = (po) => {
    navigate(`/purchase-orders/new/${po.id}`, {
      state: { search: location.search },
    });
    queryClient.invalidateQueries({
      queryKey: itemRollupsKeyFactory.paginated._def,
    });
  };

  const handleCreatePurchaseOrder = async () => {
    let supplierCount = _.uniqBy(selectedItemRollups, "supplierName").length;

    if (supplierCount > 1) {
      dispatch(
        setError({
          error:
            "All product suppliers must match when creating a purchase order.",
          source: "Purchase Order Rollup",
        })
      );
      return null;
    }
    setLoading(true);
    const additionalRollups = await getRelatedItemRollups(
      selectedItemRollups,
      filters.orderType
    );

    if (additionalRollups.length > 0) {
      // Set Modal
      setAdditionalRollupItems(additionalRollups);
      return;
    }
    const orderVariantIds = _.flatMap(selectedItemRollups, "orderVariantIds");

    dispatch(
      createPurchaseOrder(
        {
          orderVariantIds,
          orderType: filters.orderType,
        },
        onCreateCallback
      )
    );
  };

  const handleCancelRollupItem = async (
    orderVariantIds: string[],
    cancelationNote: string
  ) => {
    await new Promise((res) =>
      dispatch(
        cancelMultipleOrderVariants(orderVariantIds, cancelationNote, res)
      )
    );
    setCancelItemRollupIds(null);
  };

  return (
    <>
      <DocTitle title={"Purchase Order Rollup"} />
      <AdditionalRollupItemModal
        open={additionalRollupItems.length > 0}
        originalIds={_.flatMap(selectedItemRollups, "orderVariantIds")}
        additionalRollupItems={additionalRollupItems}
        handleClose={() => setAdditionalRollupItems([])}
        orderType={filters.orderType}
        onCreateCallback={onCreateCallback}
      />
      {fulfillmentModalOpen && (
        <FulfillFromInventoryModal
          handleClose={() => setFulfillmentModalOpen(false)}
          itemRollups={selectedItemRollups}
          onCreateCallback={onCreateCallback}
        />
      )}
      {cancelItemRollupIds && (
        <CancelRollupItemModal
          open
          ids={cancelItemRollupIds}
          handleCancel={handleCancelRollupItem}
          handleClose={() => setCancelItemRollupIds(null)}
        />
      )}
      <FixedHeightScrollLastChild>
        <div tw="flex flex-wrap justify-between items-center">
          <h2 tw="text-2xl text-neutral-600 font-bold mb-2">
            Purchase Order Rollup
          </h2>
          <div tw="flex items-center space-x-2">
            <StyledButton
              outlined
              onClick={handleCreatePurchaseOrder}
              disabled={selectedItemRollups.length === 0}
              loading={loading}
            >
              Create Purchase Order
            </StyledButton>

            {usesInventory && (
              <StyledButton
                outlined
                disabled={!canFullfillWithInventory}
                onClick={() => setFulfillmentModalOpen(true)}
              >
                Fulfill w/ Inventory
              </StyledButton>
            )}
            <RequestReportButton reportName={`purchase-order-rollup`} />
          </div>
        </div>
        <Filters
          searchTitle="Search Items"
          slots={[
            "orderType",
            "groups",
            "territories",
            "programs",
            "suppliers",
          ]}
          slotProps={{
            orderType: {
              showCloseButton: false,
              hideOptions: ["inventory"],
            },
          }}
          defaultValues={defaultFilters}
          alwaysShow={["orderType"]}
        />
        <PurchaseOrderRollupTable
          rows={data ?? []}
          query={filters.q}
          onCancelClick={(row) => setCancelItemRollupIds(row.orderVariantIds)}
          singleItemId={multipleItemsSelected ? null : firstSelectedItemId}
          isRowSelected={(row) =>
            selectedItemRollups.some((r) => r.id === row.id)
          }
          onCheckRow={async (row) =>
            (await multipleItemWarning(row.itemId)) &&
            setSelectedItemRollups(_.xorBy(selectedItemRollups, [row], "id"))
          }
          {...tableProps}
        />
      </FixedHeightScrollLastChild>
    </>
  );
};

export default PurchaseOrderRollupNew;
