import tw from "twin.macro";

import { useSelector } from "react-redux";
import { Link } from "react-router";

import { ArrowForwardRounded } from "@mui/icons-material";
import { Button, DialogContent, Typography } from "@mui/material";

import _, { startCase } from "lodash";

import Carousel from "@components/Carousel";
import { SectionTitle } from "@components/StyledComponents";
import { ResourceError } from "@features/errors";
import { CldImage } from "@services/cloudinary";
import { formatMoneyString, formatNumber } from "@utility/utilityFunctions";
import permissions from "@utils/permissions";
import useRoleIs from "@utils/useRoleIs";

import { useItemPrograms } from "../../helpers";
import useGetAllocationValues from "../../useGetAllocationValues";
import { useItem } from "./ItemContext";

const Tag = tw.div`px-3 py-1 rounded bg-neutral-50 text-neutral-700 text-base border border-neutral-300 border-solid`;

const ItemProgramTags = () => {
  const {
    item: { id: itemId },
  } = useItem();
  const { data: programs, error } = useItemPrograms(itemId, { isDraft: false });
  if (error) return <ResourceError error={error} />;
  if (programs.length === 0) return null;
  return (
    <>
      <SectionTitle tw="mt-6">Programs</SectionTitle>
      <div tw="flex gap-2 flex-wrap">
        {programs.map((program) => (
          <Link key={program.id} to={`/programs/${program.id}`} target="_blank">
            <Tag>{program.name}</Tag>
          </Link>
        ))}
      </div>
    </>
  );
};

const ItemModalContent = () => {
  const roleIs = useRoleIs();
  const { itemPreviewFocusImageId } = useSelector((state: any) => state.items);
  const userIsAdmin = roleIs(permissions.admin);
  const isInternalRole = roleIs(permissions.internalRoles);

  const { item } = useItem();

  const { totalUserOnHand, variantAllocationLookup, usesAllocations } =
    useGetAllocationValues(item);
  // Shipstation items are always "in-stock" but will never have inventory values.
  const isShipstation = item.warehouse === "shipstation";

  const activeVariants = _(item.variants)
    .filter("isActive")
    .sortBy("orderPosition")
    .value();

  const variantCategories = _(activeVariants)
    .flatMap((v) => v.selectedVariantOptions.map((vo) => vo.variantCategory))
    .uniqBy("id")
    .sortBy("id")
    .value();
  const activeDefaultVariant = item.variants.find(
    (v) => v.isDefault && v.isActive
  );

  const defaultUserOnHand =
    activeDefaultVariant &&
    variantAllocationLookup?.get(activeDefaultVariant.id)?.userOnHand;

  const defaultAllocationOnHand = activeDefaultVariant
    ? variantAllocationLookup?.get(activeDefaultVariant.id)?.allocationOnHand!
    : null;

  const carouselStartIndex = itemPreviewFocusImageId
    ? item.images.findIndex((img) => img.id === itemPreviewFocusImageId)
    : 0;

  return (
    <DialogContent tw="overflow-hidden grid grid-cols-2 gap-4 p-0">
      <div tw="flex items-center justify-center p-4">
        <Carousel startIndex={carouselStartIndex}>
          {item.images.length > 0 ? (
            item.images.map((img) => (
              <div key={img.id}>
                <CldImage image={img} size="large" alt={`${item.name}`} />
              </div>
            ))
          ) : (
            <CldImage
              src="https://res.cloudinary.com/brandhub/image/upload/v1685722984/prod/Brandhub/no-image-square_iefxuz.png"
              alt="No Image"
            />
          )}
        </Carousel>
      </div>
      <div tw="overflow-y-auto text-base text-neutral-800">
        <Typography component="div" tw="max-w-prose mb-12 p-4">
          <div tw="grid grid-cols-3 space-x-2 -m-2 p-2 pt-8 sticky top-0 bg-white">
            <div>
              {item.sku}
              <div tw="text-xs text-neutral-400">Sku</div>
            </div>
            <div>
              {item.defaultExternalWarehouseId ?? "---"}
              <div tw="text-xs text-neutral-400">External ID</div>
            </div>
            {userIsAdmin && (
              <Button
                component={Link}
                to={`/admin/items/${item.id}`}
                target="_blank"
                tw="normal-case place-self-start self-end text-sm text-primary-600 hover:text-primary-800"
                endIcon={<ArrowForwardRounded />}
              >
                Item Admin
              </Button>
            )}
          </div>

          <h1 tw="text-3xl mt-4">{item.name}</h1>
          {isInternalRole && (
            <div tw="mt-3 flex items-center text-neutral-400">
              <div tw=" text-xl font-bold tracking-wider text-neutral-600">
                {formatMoneyString(item.price)}
              </div>
              &nbsp; / &nbsp;
              <div tw="">
                {totalUserOnHand !== null
                  ? isShipstation
                    ? "On Hand"
                    : activeDefaultVariant
                      ? `${formatNumber(defaultUserOnHand!)} available`
                      : `${formatNumber(totalUserOnHand)} available across variants`
                  : startCase(item.orderType)}
              </div>
            </div>
          )}
          <SectionTitle tw="mt-6">Description</SectionTitle>
          <div tw="text-neutral-600 whitespace-pre-line">
            {item.description}
          </div>

          <SectionTitle tw="mt-6">Categories</SectionTitle>
          <div tw="flex flex-wrap gap-2">
            {item.groups?.length > 0 &&
              item.groups.map((group, i) => (
                <Tag key={i}>
                  {group.name}
                  <div tw="text-xs text-neutral-500">
                    {group.groupCategory.name}
                  </div>
                </Tag>
              ))}
          </div>

          <SectionTitle tw="mt-6">Item info</SectionTitle>
          <div tw=" text-neutral-700">
            <div>Unit of measurement: {item.unitOfMeasure}</div>
            <div>Pack size: {item.packSize}</div>
            <div>Order type: {_.startCase(item.orderType)}</div>
            {item.orderType !== "on-demand" &&
              usesAllocations &&
              !isShipstation && (
                <>
                  {userIsAdmin && (
                    <div tw="flex items-center gap-4">
                      Total Inventory:{" "}
                      {formatNumber(
                        activeDefaultVariant
                          ? activeDefaultVariant.quantityOnHand!
                          : _.sumBy(
                              activeVariants,
                              (v) => v.quantityOnHand ?? 0
                            )
                      )}
                      <Link
                        target="_blank"
                        to={`/allocations/${item.id}`}
                        tw="text-sm underline text-primary-700"
                      >
                        Manage Allocations
                      </Link>
                    </div>
                  )}
                  {isInternalRole && defaultAllocationOnHand !== null && (
                    <div>
                      On Hand (Allocation):{" "}
                      {formatNumber(defaultAllocationOnHand)}
                    </div>
                  )}
                </>
              )}
          </div>
          {item.specification && Object.keys(item.specification).length > 0 && (
            <>
              <SectionTitle tw="mt-6">Specifications</SectionTitle>
              <div tw="text-neutral-700">
                {Object.keys(item.specification).map((k, i) => (
                  <div key={i}>
                    <b>{k}:</b>
                    {` ${item.specification[k]}`}
                  </div>
                ))}
              </div>
            </>
          )}

          {activeVariants.length > 0 && (
            <>
              <SectionTitle tw="mt-6">Variants</SectionTitle>
              <div tw="max-h-[300px] overflow-auto shadow-inner bg-neutral-50 rounded-md">
                <table tw="w-full border-collapse  text-sm">
                  <thead tw="font-bold rounded overflow-hidden">
                    <tr>
                      {variantCategories.map((vc) => (
                        <td key={vc.id} tw="py-2 px-4 bg-gray-200">
                          {vc.name}
                        </td>
                      )) || null}
                      {totalUserOnHand !== null && !isShipstation && (
                        <td tw="py-2 px-4 bg-gray-200">
                          Qty Available to Order
                        </td>
                      )}
                      <td tw="py-2 px-4 bg-gray-200">SKU</td>
                    </tr>
                  </thead>
                  <tbody tw="divide-y">
                    {activeVariants.map((variant, i) => (
                      <tr tw="hover:bg-neutral-100" key={i}>
                        {variantCategories.map(({ id }) => {
                          const variantOption =
                            variant.selectedVariantOptions.find(
                              (vo) => vo.variantCategory.id === id
                            );
                          return (
                            <td tw="py-2 px-4" key={`${i}-${id}`}>
                              {variantOption?.name ?? (
                                <span tw="text-neutral-400">---</span>
                              )}
                            </td>
                          );
                        })}
                        {totalUserOnHand !== null && !isShipstation && (
                          <td tw="py-2 px-4">
                            {Math.max(
                              0,
                              variantAllocationLookup.get(variant.id)
                                ?.userOnHand ?? 0
                            ) || (
                              <span tw="text-neutral-500">
                                {variant.globalAvailableToOrderQty! > 0
                                  ? "Unavailable"
                                  : "Out of stock"}
                              </span>
                            )}
                          </td>
                        )}
                        <td tw="py-2 px-4 text-neutral-500">
                          {variant.externalWarehouseId ?? variant.variantSku}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </>
          )}
          {isInternalRole && <ItemProgramTags />}
        </Typography>
      </div>
    </DialogContent>
  );
};

export default ItemModalContent;
