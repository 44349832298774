import "twin.macro";

import { useState } from "react";
import { useSelector } from "react-redux";

import {
  FixedHeightScrollLastChild,
  PageTitle,
} from "@components/StyledComponents";
import {
  BudgetModal,
  BudgetTable,
  usePaginatedBudgetsQuery,
} from "@features/budgets";
import { useFilterParams } from "@features/filters";
import { Filters } from "@features/filters";
import { RequestReportButton, useReport } from "@features/reports";
import { BudgetWithCalcs } from "@models/Budget";
import DocTitle from "@utility/DocTitle";

const Budgets = () => {
  const [filterParams] = useFilterParams();

  const { budgetLocation } = useSelector(
    (state: any) => state.currentUser.organization
  );

  const params = {
    filter: {
      isActive: filterParams.isActive,
      userIds: filterParams.users,
      name: filterParams.q,
    },
    include: "budget_calculations",
  };
  useReport("budgets", ["budgets", params]);

  const { data, ...tableProps } = usePaginatedBudgetsQuery(params);

  const [selectedBudget, setSelectedBudget] = useState<BudgetWithCalcs | null>(
    null
  );

  return (
    <>
      <DocTitle title={"Budgets"} />
      {selectedBudget && (
        <BudgetModal
          budget={selectedBudget}
          handleClose={() => setSelectedBudget(null)}
        />
      )}
      <FixedHeightScrollLastChild>
        <div tw="flex items-center justify-between">
          <PageTitle>Budgets</PageTitle>
          <RequestReportButton reportName="budgets" />
        </div>
        <Filters
          slots={[
            "isActive",
            budgetLocation === "user" ? "users" : "territories",
          ]}
          defaultValues={{ isActive: true }}
          searchTitle="Search Budget Name"
        />
        <BudgetTable
          {...tableProps}
          rows={data}
          query={filterParams.q}
          onRowClick={(row) => setSelectedBudget(row)}
        />
      </FixedHeightScrollLastChild>
    </>
  );
};

export default Budgets;
