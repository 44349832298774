import { useRef, useState } from "react";

import { useCreateOrderSetVariantsMutation } from "@features/ordering";
import { Item, OrderType } from "@models";
import { useMutateError } from "@services/api";

export const useAddToCart = (
  orderType: OrderType,
  orderSetId?: string | null
) => {
  const setError = useMutateError();
  const createOrderSetVariantsMutation = useCreateOrderSetVariantsMutation();
  const [variantSelectionItem, setVariantSelectionItem] = useState<null | Item>(
    null
  );

  // Initialize a promise queue
  const mutationQueue = useRef<Promise<any>>(Promise.resolve());

  const addVariantsToOrderSet = async (variantIds: string[]) => {
    return new Promise<void>((resolve) => {
      mutationQueue.current = mutationQueue.current.then(() => {
        return createOrderSetVariantsMutation
          .mutateAsync({
            orderSetId,
            variantIds,
            orderType,
          })
          .catch((err) => setError(err, "Add Order Set Variants"))
          .finally(() => resolve());
      });
    });
  };

  const addItemToOrderSet = async (item: Item) => {
    const defaultVariant = item.variants.find(
      (v) => v.isDefault || v.selectedVariantOptions.length === 0
    );

    if (defaultVariant?.isActive) {
      await addVariantsToOrderSet([defaultVariant.id]);
    } else {
      setVariantSelectionItem(item);
    }
  };

  return {
    variantSelectionItem,
    setVariantSelectionItem,
    addItemToOrderSet,
    addVariantsToOrderSet,
  };
};
