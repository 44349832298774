import { useEffect } from "react";
import { useFormContext, useWatch } from "react-hook-form";

import _, { filter, sortBy, uniqBy } from "lodash";

import { getItemPreviewImage, usePaginatedItemsQuery } from "@features/items";
import { usePaginatedProgramsQuery } from "@features/programs";
import { Item } from "@models/Item";
import { Variant } from "@models/Variant";
import { VariantOption } from "@models/VariantOption";
import cloudinary, { imageSizeOptions } from "@services/cloudinary";
import useDebouncedValue from "@utils/useDebouncedValue";

import { FormVariant } from "./types";

export const useIsPublished = () => {
  const status = useWatch({ name: "status" });
  const isPublished = status !== "draft";
  return isPublished;
};

export const variantOptionIdsFromHash = (hash: string) =>
  hash === "default" ? [] : hash.split("-").filter(Boolean);

type VariantLike =
  | Variant
  | {
      selectedVariantOptions: VariantOption[];
    };

export const variantHash = (variant: VariantLike) =>
  _(variant.selectedVariantOptions)
    .sortBy("variantCategory.id")
    .map((vo) => +vo.id)
    .join("-") || "default";

export const variantName = (variant: VariantLike) =>
  sortBy([...variant.selectedVariantOptions], "variantCategory.id")
    .map((vo) => vo.name)
    .join(" / ");

export const countVariantOptions = (item: Item) =>
  sortBy(filter(item.variants, "isActive"), "orderPosition")
    .flatMap((v) => v.selectedVariantOptions)
    .reduce<Record<string, string[]>>((acc, vo) => {
      const key = vo.variantCategory.name;
      if (!acc[key]) acc[key] = [];
      if (!acc[key].includes(vo.name)) acc[key].push(vo.name);
      return acc;
    }, {});

export const optionsFromVariants = (variants: Variant[]) => {
  const uniqueOptions = uniqBy(
    variants.flatMap((variant) => variant.selectedVariantOptions),
    "id"
  );
  const groupedByCategory = uniqueOptions.reduce<Record<string, string[]>>(
    (acc, vo) => {
      if (!acc[vo.variantCategory.id]) acc[vo.variantCategory.id] = [];
      acc[vo.variantCategory.id].push(vo.id);
      return acc;
    },
    {}
  );

  return groupedByCategory;
};

// [1, 2] + [3, 4] =
// [[1, 3], [2, 3], [1, 4], [2, 4]]
const insureArray = (a: any) => (Array.isArray(a) ? a : [a]);
const mergeArrays = (ar1: any[], ar2: any[]) =>
  ar1.flatMap((a1) =>
    ar2.map((a2) => [...insureArray(a1), ...insureArray(a2)])
  );
export const variantCombinationsFromOptions = (
  options: Record<string, string[]>,
  allVariantOptions: VariantOption[]
): FormVariant[] =>
  Object.values(options)
    .filter((opts) => opts.length > 0)
    .reduce<string[][]>((acc, opts) => mergeArrays(acc, opts), [[]])
    .map((voIds: string[], i) => {
      const selectedVariantOptions = voIds
        .map((id) => allVariantOptions.find((vo) => vo.id === id))
        .filter((vo): vo is VariantOption => Boolean(vo));
      return {
        hash: variantHash({ selectedVariantOptions }),
        name: variantName({ selectedVariantOptions }),
        upcharge: "0.00",
        externalWarehouseId: "",
        isActive: true,
        isDefault: false,
        imageId: null,
        cachedWarehouseQty: 0,
        quantityOnHand: 0,
        orderPosition: i,
        reorderThreshold: 0,
      };
    });

export const itemPreviewUrl = (
  item: Item,
  size?: keyof typeof imageSizeOptions
) => {
  const image = getItemPreviewImage(item);
  const options = size ? imageSizeOptions[size] : {};

  return image
    ? cloudinary.url(image.cloudinaryId, options)
    : "https://res.cloudinary.com/brandhub/image/upload/v1685722984/prod/Brandhub/no-image-square_iefxuz.png";
};

export const useValidateUniqueName = (): void => {
  const { setError, clearErrors } = useFormContext();

  const name = useWatch({ name: "name" });
  const id = useWatch({ name: "id" });

  const debouncedName = useDebouncedValue(name, 300);
  const { data: items } = usePaginatedItemsQuery({
    filter: { exactName: debouncedName },
  });

  useEffect(() => {
    if (items && items.length === 1 && items[0].id !== id) {
      setError("name", {
        type: "manual",
        message: "There is already an item with this name.",
      });
    } else {
      clearErrors("name");
    }
  }, [items, id, setError, clearErrors]);
};

export const useItemPrograms = (
  itemId: string,
  filters?: { isDraft: boolean }
) => {
  return usePaginatedProgramsQuery({
    filter: { itemId, isActive: true, ...filters },
  });
};
