import "twin.macro";

import { Close } from "@mui/icons-material";
import { Dialog, DialogContent, DialogTitle, IconButton } from "@mui/material";

import BudgetHistoryTable from "./BudgetHistoryTable";
import BudgetTable from "./BudgetTable";
import { usePaginatedBudgetHistoryQuery } from "./data";

const BudgetModal = ({ budget, handleClose, hideOverview = false }) => {
  const { data, ...tableProps } = usePaginatedBudgetHistoryQuery({
    filter: { budgetId: budget.id },
  });
  return (
    <Dialog open onClose={handleClose} fullWidth maxWidth="lg">
      <DialogTitle tw="flex justify-between items-start">
        {budget.name} History
        <IconButton onClick={handleClose} edge="end">
          <Close />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        {!hideOverview && (
          <>
            <BudgetTable rows={[budget]} error={null} />
            <h3 tw="mt-6 mb-3">Budget History</h3>
          </>
        )}
        <BudgetHistoryTable {...tableProps} rows={data ?? []} />
      </DialogContent>
    </Dialog>
  );
};

export default BudgetModal;
