import "twin.macro";

import { useCallback, useState } from "react";
import { useParams } from "react-router";

import { Skeleton } from "@mui/material";

import _ from "lodash";

import { Contained } from "@components/StyledComponents";
import { useItemQuery } from "@features/items";
import { CompareQuotes, ItemQuotesList, NewRfqButton } from "@features/rfqs";
import { Title } from "@features/ui";
import { Quote } from "@models/Quote";
import DocTitle from "@utility/DocTitle";

import ErrorPage from "./ErrorPage";

const ItemQuotes = () => {
  const [selectedQuotes, setSelectedQuotes] = useState<Quote[]>([]);
  const { itemId } = useParams<{ itemId: string }>();

  const { data: item, error } = useItemQuery(itemId);

  const handleToggleSelection = useCallback(
    (quote: Quote) => setSelectedQuotes((ids) => _.xorBy(ids, [quote], "id")),
    []
  );

  if (error) return <ErrorPage error={error} />;

  return (
    <Contained tw="space-y-6 pb-6 max-w-screen-xl xl:mx-auto">
      <CompareQuotes quotes={selectedQuotes} />
      <DocTitle title={`Quotes for ${item?.name ?? "item"}`} />
      <div tw="flex items-center justify-between">
        <Title backUrl="/rfqs">
          Quotes for{" "}
          {item ? item.name : <Skeleton variant="text" height={40} />}
        </Title>
        <NewRfqButton label="New Round" itemId={itemId} />
      </div>

      <ItemQuotesList
        itemId={itemId as string}
        selectedQuotes={selectedQuotes}
        toggleSelection={handleToggleSelection}
      />
    </Contained>
  );
};

export default ItemQuotes;
