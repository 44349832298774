import { useEffect } from "react";
import { useSelector } from "react-redux";

import MenuItem from "@mui/material/MenuItem";
import Typography from "@mui/material/Typography";

import PropTypes from "prop-types";

import { StyledSelect } from "@utils/forms";

const StatusSelector = ({
  handleStatus,
  status,
  setStatus,
  filterType,
  role,
}) => {
  const orderHistoryStatuses = [
    { id: "submitted,approved,canceled", label: "All" },
    { id: "submitted", label: "Submitted" },
    { id: "approved", label: "Approved" },
    { id: "canceled", label: "Canceled" },
    { id: "draft", label: "Draft" },
  ]
    .filter((status) => {
      if (["super", "admin"].includes(role)) return status;
      return status.id !== "draft";
    })
    .map((status, index) => (
      <MenuItem value={status.id} key={index}>
        <Typography variant="body2">{status.label}</Typography>
      </MenuItem>
    ));

  const purchaseOrderHistoryStatuses = [
    { id: "draft,submitted,in-progress,complete,canceled", label: "All" },
    { id: "draft", label: "Draft" },
    { id: "submitted", label: "Submitted" },
    { id: "in-progress", label: "In Progress" },
    { id: "complete", label: "Complete" },
    { id: "canceled", label: "Canceled" },
  ].map((status, index) => (
    <MenuItem value={status.id} key={index}>
      <Typography variant="body2">{status.label}</Typography>
    </MenuItem>
  ));

  const supplierPurchaseOrderHistoryStatuses = [
    { id: "submitted,in-progress,complete,canceled", label: "All" },
    { id: "submitted", label: "New" },
    { id: "in-progress", label: "Active" },
    { id: "complete", label: "Complete" },
    { id: "canceled", label: "Canceled" },
  ].map((status, index) => (
    <MenuItem value={status.id} key={index}>
      <Typography variant="body2">{status.label}</Typography>
    </MenuItem>
  ));

  const reportOrderStatuses = [
    { id: "draft,submitted,approved", label: "All" },
    { id: "approved", label: "Approved" },
    { id: "submitted", label: "Submitted" },
    { id: "submitted,approved", label: "Submitted / Approved" },
    { id: "draft", label: "Draft" },
  ].map((status, index) => (
    <MenuItem value={status.id} key={index}>
      <Typography variant="body2">{status.label}</Typography>
    </MenuItem>
  ));

  const currentStatus = useSelector((state) => state.filters.status);

  const handleChangeSelect = (evt) => {
    setStatus(evt.target.value);
    handleStatus(evt.target.value, "status", filterType);
  };

  useEffect(() => {
    if (status !== currentStatus) {
      setStatus(currentStatus);
    }
  });

  return (
    <StyledSelect
      label={"Status"}
      name="status"
      value={status || ""}
      onChange={handleChangeSelect}
      MenuProps={{
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "left",
        },
        transformOrigin: {
          vertical: "top",
          horizontal: "left",
        },
      }}
    >
      {filterType === "order-history" && [...orderHistoryStatuses]}
      {role !== "supplier" &&
        filterType === "purchase-order-history" && [
          ...purchaseOrderHistoryStatuses,
        ]}
      {role === "supplier" &&
        filterType === "purchase-order-history" && [
          ...supplierPurchaseOrderHistoryStatuses,
        ]}
      {filterType.includes("reports-order-window") && [...reportOrderStatuses]}
    </StyledSelect>
  );
};

StatusSelector.propTypes = {
  handleStatus: PropTypes.func.isRequired,
  status: PropTypes.string,
  setStatus: PropTypes.func.isRequired,
  filterType: PropTypes.string,
};

export default StatusSelector;
