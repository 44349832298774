import "twin.macro";

import React from "react";

import { IconButton, Tooltip } from "@mui/material";

import { FaIcon } from "@features/ui";

import { useCurrentOrderSet } from "./orderSets/CurrentOrderSetContext";

const beaconArticleMap = {
  "on-demand": "65dfae85cbe4ec20ccc9f15d",
  inventory: "65f301f69b1ce3620e7d3cbf",
  "pre-order": "65dfb08dcbe4ec20ccc9f164",
};

const OrderingHelpIcon = () => {
  const { orderSet } = useCurrentOrderSet();
  const handleOpenOrderHelp = () => {
    const articleId = beaconArticleMap[orderSet.type];
    if (articleId) {
      window.Beacon("article", articleId, { type: "modal" });
    }
  };

  return (
    <Tooltip title="Help">
      <IconButton
        tw="-my-2"
        size="small"
        edge="end"
        onClick={handleOpenOrderHelp}
      >
        <FaIcon icon="question-circle" tw="text-primary-600" />
      </IconButton>
    </Tooltip>
  );
};

export default OrderingHelpIcon;
