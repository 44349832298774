import { AxiosError } from "axios";
import { isAxiosError } from "axios";
import _ from "lodash";

export default function parseApiErrorMessage(error: Error | AxiosError) {
  if (isAxiosError(error)) {
    if (!error.response?.data?.errors) return error.message;
    const errors = _.castArray(error.response.data.errors);
    return errors.map((e) => (e.detail ?? e).replace(/^base /i, "")).join("\n");
  }
  return error.message;
}
