import "twin.macro";

import { useSelector } from "react-redux";
import { Navigate } from "react-router";

import { Contained } from "src/components/StyledComponents";

import OrderSetTutorial from "@components/tutorial/OrderSetTutorial";
import {
  OrderSetActionBar,
  OrderSetNavigation,
  OrderSetTable,
  useCurrentOrderSet,
} from "@features/ordering";
import permissions from "@utils/permissions";
import useRoleIs from "@utils/useRoleIs";

const OrderSet = () => {
  const { orderSet } = useCurrentOrderSet();
  const canViewOnceSubmitted = useRoleIs()(permissions.orderReview);
  const { hasViewedOrderSet } = useSelector((state: any) => state.currentUser);

  if (
    (orderSet.status !== "draft" && !canViewOnceSubmitted) ||
    orderSet.status === "approved"
  )
    return <Navigate to="./overview" replace />;

  return (
    <div tw="min-h-[calc(100vh - var(--header-height))]">
      {!hasViewedOrderSet && <OrderSetTutorial />}
      <Contained tw="space-y-6">
        <OrderSetNavigation />
        <OrderSetTable />
        <OrderSetActionBar />
      </Contained>
    </div>
  );
};

export default OrderSet;
