import "twin.macro";
import tw, { styled } from "twin.macro";

import { useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router";

import {
  IconButton,
  LinearProgress,
  ListItemButton,
  ListItemText,
  Menu,
  Tooltip,
  Typography,
} from "@mui/material";

import { useDraftOrderSets } from "@features/ordering";
import { OrderSet } from "@models";
import { formatMoneyString, upCase } from "@utility/utilityFunctions";

import { SectionTitle } from "../StyledComponents";

const Badge = styled.div([
  tw`relative flex items-center justify-center w-5 h-5 mb-1 ml-1 text-sm font-bold border-2 rounded-sm border-neutral-700 text-primary-600`,
  tw`after:(absolute w-5 h-5 top-0.5 -left-[6px] block  border-2 rounded-sm border-neutral-700 border-t-0 border-r-0)`,
]);

type orderSetMenuItem = OrderSet & { primary: string; secondary?: string };
type orderSetMenuType = Record<string, orderSetMenuItem[]>;

const DraftOrderMenu = (props) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const { territories } = useSelector((state: any) => state.currentUser);

  let {
    data,
    mutate: mutateOrderSets,
    isValidating: orderSetsValidating,
  } = useDraftOrderSets();
  const orderSets = data ?? [];

  const hasTerritories = territories ? territories.length > 1 : false;

  const handleOpen = (evt) => {
    setAnchorEl(evt.target);
    mutateOrderSets();
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const orderSetMenu: orderSetMenuType = orderSets.reduce((acc, os) => {
    if (!acc[os.territory.name]) acc[os.territory.name] = [];
    acc[os.territory.name].push({
      ...os,
      primary:
        os.type === "pre-order"
          ? `${os.programName}`
          : `${upCase(os.type, "-")} Order`,
      secondary: os.orderWindowName,
    });
    return acc;
  }, {});

  return (
    orderSets.length > 0 && (
      <>
        <Tooltip title="Draft Orders">
          <IconButton
            {...props}
            tw="lg:(-mx-4 -mt-3)"
            aria-haspopup="true"
            onClick={(evt) => {
              handleOpen(evt);
            }}
          >
            <Badge>{orderSets.length}</Badge>
          </IconButton>
        </Tooltip>
        <Menu
          tw="mt-3"
          disableScrollLock
          anchorEl={anchorEl}
          open={!!anchorEl}
          onClose={handleClose}
        >
          <div tw="relative pb-2">
            <h3 tw="mx-3 mt-2 text-neutral-700 font-bold">Draft Orders</h3>
            {Object.entries(orderSetMenu).map(([territory, orders]) => (
              <div key={territory}>
                {hasTerritories && (
                  <SectionTitle tw="mx-3 mb-1 mt-4">{territory}</SectionTitle>
                )}
                {orders.map((os) => (
                  <ListItemButton
                    tw="flex gap-3 justify-between items-baseline"
                    key={os.id}
                    component={Link}
                    to={`/order-sets/${os.id}`}
                    onClick={() => handleClose()}
                  >
                    <ListItemText
                      tw="whitespace-normal"
                      primary={os.primary}
                      secondary={os.secondary}
                    />
                    <Typography>
                      {os.totalPrice === 0 ? (
                        <span tw="text-neutral-400">New Order</span>
                      ) : (
                        formatMoneyString(os.totalPrice)
                      )}
                    </Typography>
                  </ListItemButton>
                ))}
              </div>
            ))}
            {hasTerritories && (
              <Typography tw="text-neutral-500 text-sm px-3 mt-3">
                * Clicking an order will switch your current territory to that
                order's territory.
              </Typography>
            )}
            {orderSetsValidating && (
              <LinearProgress tw="absolute -bottom-2 left-0 right-0" />
            )}
          </div>
        </Menu>
      </>
    )
  );
};

export default DraftOrderMenu;
