const updateEntityCacheArray = (cache, update) => {
  const dataArray: any[] = Array.isArray(update.data)
    ? update.data
    : [update.data];
  // filter the list, and return it with the updated item
  let newData = [...cache.data];

  dataArray.forEach((data) => {
    const index = newData.findIndex((e) => e.id === data.id);
    if (index !== -1) {
      newData[index] = data;
    } else {
      newData.push(data);
    }
  });
  return { ...cache, data: newData };
};

const updateSwrCache = (mutate, update) =>
  new Promise(async (resolve, reject) => {
    try {
      const cache = await mutate(update, {
        populateCache: (response, cache) => {
          if (!cache) throw new Error("Update to cache before initialization");
          if (Array.isArray(cache.data)) {
            return updateEntityCacheArray(cache, response);
          }
          return response;
        },
        // Since the API already gives us the updated information,
        // we don't need to revalidate here.
        revalidate: false,
      });
      return resolve(cache);
    } catch (E) {
      reject(E);
    }
  });

export default updateSwrCache;
