import "twin.macro";

import { useDispatch, useSelector } from "react-redux";

import HelpDash from "@components/Dashboard/HelpDash";
import { FixedHeightScrollLastChild } from "@components/StyledComponents";
import DocTitle from "@utility/DocTitle";

import { useNoFetch } from "../hooks/useNoFetch";

/*
Utilizes the dashboard cards to display a variety of help documents. The help dash component also
has some instructional videos available to the user.
*/

const Help = () => {
  const dispatch = useDispatch();

  const { firstName } = useSelector((state) => state.currentUser);

  useNoFetch(dispatch);

  return (
    <>
      <DocTitle title={"Help"} />
      <FixedHeightScrollLastChild>
        <h2 tw="text-2xl font-bold mb-2">{`How can we help you ${firstName}?`}</h2>

        <div>
          <HelpDash />
        </div>

        <div>
          <div tw="mt-4 mb-4">
            If you have any immediate questions, please contact Select Design.
            We are happy to help!
          </div>

          <div tw="text-lg font-bold">General Contact Information</div>
          <div>Phone: 802-864-9075</div>
          <div>Email: help@selectbrandhub.com</div>
          <div tw="mb-4">
            Please allow up to 12 hours to receive a response from us.
          </div>
        </div>
      </FixedHeightScrollLastChild>
    </>
  );
};

export default Help;
