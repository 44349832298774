import "twin.macro";

import { Contained } from "@components/StyledComponents";
import { ProgramList } from "@features/programs";
import DocTitle from "@utility/DocTitle";

const Programs = () => {
  return (
    <>
      <DocTitle title={"Programs"} />
      <Contained
        tw="space-y-6 pb-3 flex-col flex"
        className="body-height-after-header"
      >
        <h2 tw="font-medium text-neutral-700 text-xl">Programs</h2>

        <ProgramList />
      </Contained>
    </>
  );
};

export default Programs;
