import { Link } from "react-router";

import Beacons from "src/pages/Beacons";

import permissions from "@utils/permissions";

import { protectedRoute } from "../types";

export default {
  path: "beacons",
  handle: { crumb: () => <Link to="/beacons">Beacons</Link> },
  allowedRoles: permissions.beacons,
  children: [
    { path: "", element: <Beacons /> },
    { path: ":view", element: <Beacons /> },
  ],
} as protectedRoute;
