import { useLocation } from "react-router";

import { useSetLocation } from "@services/reactRouterDom";

const useNavigateToUser = () => {
  const { hash, pathname } = useLocation();
  const setLocation = useSetLocation();

  return function navigateToUser(userId: string | null) {
    const fromUserId = hash ? hash.slice(1) : pathname.split("/").at(-1);

    // If the user is coming from the new user form, or switching views for the same user,
    // replace the location instead of pushing a new history entry
    const navOptions =
      fromUserId === "new" || userId === fromUserId
        ? { replace: true }
        : undefined;

    if (hash) setLocation({ hash: userId ? `#${userId}` : "" }, navOptions);
    else {
      setLocation({ pathname: `/admin/users/${userId ?? ""}` }, navOptions);
    }
  };
};

export default useNavigateToUser;
