import "twin.macro";

import React from "react";

import { CircularProgress } from "@mui/material";

import { usePaginatedAddressesQuery } from "@features/addresses";
import { WarehouseID } from "@models/Address";

const PurchasingAgentWarehouseAddress = ({
  warehouse,
}: {
  warehouse: WarehouseID | null;
}) => {
  // At the moment, the only purchasing-agents warehouse is Vernon
  const warehouseId = warehouse ?? "vernon";
  const { data: warehouses = [] } = usePaginatedAddressesQuery({
    filter: {
      warehouse: warehouseId,
    },
  });

  const w = warehouses[0];
  if (!w) return <CircularProgress />;
  return (
    <div tw="p-6 text-neutral-800">
      <div tw="text-lg">{w.name}</div>
      <div>{w.streetAddress1}</div>
      <div>{w.streetAddress2}</div>
      <div>
        {w.city}, {w.state?.code ?? w.region} {w.zip}
      </div>
    </div>
  );
};

export default PurchasingAgentWarehouseAddress;
