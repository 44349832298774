import "twin.macro";

import { OpaqueCard } from "../StyledComponents";
import FilterChipList from "./FilterChipList";
import FilterDrawer from "./FilterDrawer";

const FilterPanel = () => {
  // const [showMore, setShowMore] = useState(false)
  return (
    <OpaqueCard tw="relative! py-4">
      <FilterDrawer />
      <FilterChipList tw="mt-2" />
    </OpaqueCard>
  );
};

export default FilterPanel;
