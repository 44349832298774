import { ReactNode, createContext } from "react";

type PromiseResolve = { resolve: (x: boolean) => void } | null;

export type ConfirmContentProps = {
  message: ReactNode;
  title: ReactNode;
  confirmButtonText?: string;
  cancelButtonText?: string;
};

export type GlobalConfirmContextProps = {
  promise: PromiseResolve;
  setPromise: (x: PromiseResolve) => void;
  confirmContent: ConfirmContentProps | null;
  setConfirmContent: (x: ConfirmContentProps | null) => void;
};

export const GlobalConfirmContext = createContext<GlobalConfirmContextProps>({
  promise: null,
  setPromise: () => {},
  confirmContent: null,
  setConfirmContent: () => {},
});
