import tw from "twin.macro";

import { useFilterContext } from "../filterContext";
import Chip from "./Chip";
import ControlledResourceFilterAutocomplete from "./ControlledResourceFilterAutocomplete";
import useSelectedResources from "./useSelectedResources";

/*
 * api/purchase-users endpoint gets all users that have made purchases in
 * the organization regardless of what organization they belong to.
 */

const PurchasersPopper = () => {
  const { control } = useFilterContext();
  return (
    <ControlledResourceFilterAutocomplete
      name="purchasers"
      resource="purchase-users"
      control={control}
      inputPlaceholder="Filter Purchasers"
    />
  );
};

const PurchaserFilterChip = (props) => {
  const { filterValues } = useFilterContext();
  const selectedPurchasers = filterValues.purchasers ?? [];
  const { data: purchasers, isLoading } = useSelectedResources(
    "purchase-users",
    selectedPurchasers
  );

  return (
    <Chip {...props}>
      <span tw="text-neutral-600">User is&nbsp;</span>
      <span
        css={[
          tw`font-medium transition-opacity text-neutral-800`,
          isLoading && tw`opacity-75`,
        ]}
      >
        {[...purchasers]
          .splice(0, 2)
          .map((purchaser) => purchaser.name)
          .join(", ")}
        {selectedPurchasers.length > 2 && (
          <span>, + {selectedPurchasers.length - 2}</span>
        )}
      </span>
    </Chip>
  );
};

const PurchasersFilter = {
  Chip: PurchaserFilterChip,
  Popper: PurchasersPopper,
};

export default PurchasersFilter;
