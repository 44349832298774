import "twin.macro";
import tw from "twin.macro";

import { useCallback, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";

import { endOfDay, isBefore } from "date-fns";

import {
  OpaqueCard,
  SectionTitle,
  StyledButton,
} from "@components/StyledComponents";
import { SaveFormBanner } from "@features/ui";
import { BudgetWithCalcs } from "@models/Budget";
import { Organization } from "@models/Organization";
import { utcDate } from "@utility/utilityFunctions";
import {
  ControlledDateInput,
  ControlledSelectInput,
  ControlledTextInput,
  moneyAdornment,
  moneyValidation,
} from "@utils/forms";

import BudgetAssignment from "./BudgetAssignment";
import BudgetUpdateNoteModal from "./BudgetUpdateNoteModal";
import { useCreateBudgetMutation, useUpdateBudgetMutation } from "./data";
import formValuesFromBudget, { BudgetFormValues } from "./formValuesFromBudget";
import useBudgetAssignedResource from "./useBudgetAssignedResource";

const TwoCol = tw.div`grid gap-6 md:grid-cols-2`;

const BudgetForm = ({
  budget,
  id,
}: {
  budget?: BudgetWithCalcs;
  id: string | null;
}) => {
  const navigate = useNavigate();
  const budgetLocation: Organization["budgetLocation"] = useSelector(
    (state: any) => state.currentUser.organization.budgetLocation
  );
  const assignedResource = useBudgetAssignedResource();
  const createBudgetMutation = useCreateBudgetMutation();
  const updateBudgetMutation = useUpdateBudgetMutation();
  const isLoading =
    createBudgetMutation.isPending || updateBudgetMutation.isPending;
  const [budgetNoteModalOpen, setBudgetNoteModalOpen] = useState(false);
  const {
    control,
    handleSubmit,
    getValues,
    setValue,
    reset,
    watch,
    formState: { isDirty },
  } = useForm({
    defaultValues: formValuesFromBudget(budget),
  });

  const now = new Date();
  const isExpired = (dateString: Date | string | null) =>
    Boolean(dateString && !isBefore(now, endOfDay(new Date(dateString))));
  const [startDate, expirationDate] = watch(["startDate", "expirationDate"]);
  const pastExpiration = isExpired(expirationDate);

  const reg = (name: keyof BudgetFormValues, rules?: any) => ({
    name,
    control,
    rules: {
      required: true, // all fields are required
      ...rules,
    },
  });

  const handleSave = async (data: BudgetFormValues) => {
    if (id) {
      setBudgetNoteModalOpen(true);
    } else {
      createBudgetMutation.mutate(data, {
        onSuccess: (res) => {
          navigate(`/admin/budgets/${res.id}`, { replace: true });
        },
      });
    }
  };

  const handleUpdateWithNote = async (note: string) => {
    updateBudgetMutation.mutate({
      id: id!,
      note,
      ...getValues(),
    });
    setBudgetNoteModalOpen(false);
  };

  const handleExpirationDateChange = (date: Date | null) => {
    setValue("isActive", !isExpired(date), { shouldDirty: true });
  };

  const handleReset = useCallback(() => {
    reset(formValuesFromBudget(budget));
  }, [budget, reset]);

  useEffect(() => {
    handleReset();
  }, [handleReset]);

  return (
    <>
      {id && isDirty && (
        <SaveFormBanner
          handleReset={handleReset}
          handleSave={handleSubmit(handleSave)}
          isLoading={isLoading}
        />
      )}
      {budgetNoteModalOpen && (
        <BudgetUpdateNoteModal
          handleClose={() => setBudgetNoteModalOpen(false)}
          handleSave={handleUpdateWithNote}
        />
      )}
      <form tw="space-y-6">
        <OpaqueCard tw="space-y-6">
          <ControlledTextInput label="Name" {...reg("name")} />
          <TwoCol>
            <ControlledDateInput label="Start Date" {...reg("startDate")} />
            <ControlledDateInput
              label="Expiration Date"
              minDate={utcDate(startDate)}
              onChange={(val) => handleExpirationDateChange(val)}
              {...reg("expirationDate")}
            />
          </TwoCol>
          <TwoCol>
            <ControlledTextInput
              label="Total Amount"
              {...reg("totalAmount", moneyValidation)}
              {...moneyAdornment}
              placeholder="0.00"
            />
            <ControlledSelectInput
              {...reg("isActive", { required: false })}
              label="Status"
              disabled={pastExpiration}
              options={[
                { name: "Active", id: true },
                {
                  name: `Inactive${pastExpiration ? " - Expired" : ""}`,
                  id: false,
                },
              ]}
            />
          </TwoCol>
        </OpaqueCard>
        <OpaqueCard>
          <SectionTitle tw="mb-4">{budgetLocation} Assignments</SectionTitle>
          <BudgetAssignment
            resource={assignedResource}
            control={control}
            budgetAssignment={budget?.[assignedResource] ?? []}
            setValue={setValue}
          />
        </OpaqueCard>
        {!id && (
          <StyledButton
            cta
            onClick={handleSubmit(handleSave)}
            disabled={isLoading}
          >
            Create Budget
          </StyledButton>
        )}
      </form>
    </>
  );
};

export default BudgetForm;
