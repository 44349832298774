import "twin.macro";

import { useSelector } from "react-redux";

import { FaIcon, LoadingButton } from "@features/ui";

import useDownloadPackoutPdfMutation from "./useDownloadPackoutPdfMutation";

const WarehouseLabelDownloadButton = () => {
  const isLoading = useSelector((state: any) => state.purchaseOrder.isLoading);
  const { mutateAsync: downloadPackoutPdf, isPending } =
    useDownloadPackoutPdfMutation();
  return (
    <LoadingButton
      onClick={() => downloadPackoutPdf(undefined)}
      disabled={isLoading}
      loading={isPending}
      startIcon={<FaIcon icon="arrow-down-to-line" tw="text-base!" />}
      // variant="outlined"
      // tw="bg-white"
    >
      Warehouse Labeling
    </LoadingButton>
  );
};

export default WarehouseLabelDownloadButton;
