import "twin.macro";

import React from "react";
import { useFormContext } from "react-hook-form";

import { ControlledTextInput } from "../../../utils/forms/ControlledInputs";
import BlockCard from "../../ui/BlockCard";

const General = () => {
  const { control } = useFormContext();

  return (
    <BlockCard tw="space-y-4">
      <ControlledTextInput control={control} label="Name" name="name" />
      <ControlledTextInput
        control={control}
        multiline
        minRows={2}
        maxRows={8}
        label="Description"
        name="description"
      />
    </BlockCard>
  );
};

export default General;
