import "twin.macro";

import { useFilterContext } from "../filterContext";
import Chip from "./Chip";
import ControlledResourceFilterAutocomplete from "./ControlledResourceFilterAutocomplete";
import useSelectedResources from "./useSelectedResources";

const OrderWindowPopper = () => {
  const { control } = useFilterContext();

  return (
    <ControlledResourceFilterAutocomplete
      name="orderWindows"
      resource="order-windows"
      control={control}
      inputPlaceholder="Filter Order Windows"
    />
  );
};

const OrderWindowFilterChip = (props) => {
  const { filterValues } = useFilterContext();
  const owIds = filterValues["orderWindows"] ?? [];
  const { data: selectedOrderWindows } = useSelectedResources(
    "order-windows",
    owIds
  );

  return (
    <Chip {...props}>
      <span tw="text-neutral-600">Order Window is&nbsp;</span>
      <span tw="text-neutral-800 font-medium">
        {selectedOrderWindows
          .slice(0, 1)
          .map((ow) => ow.name)
          .join(", ")}
        {selectedOrderWindows.length > 1 && (
          <span>, + {selectedOrderWindows.length - 1}</span>
        )}
      </span>
    </Chip>
  );
};

const OrderWindowFilter = {
  Chip: OrderWindowFilterChip,
  Popper: OrderWindowPopper,
};

export default OrderWindowFilter;
