import { sortBy } from "lodash";

import { createSlice } from "@reduxjs/toolkit";

import { axiosGet, axiosPatch, axiosPost } from "../../../api/axiosCalls";
import { setError } from "../errorSlice";
import {
  setFailure as patchFailure,
  setIsLoading as patchLoading,
  patchSuccess,
} from "../patchOrderSlice";
import { buildVariant, buildVariantCategory } from "./helpers";
import { mapVariantCategories, mapVariants } from "./maps";

let initialState = {
  isLoading: false,
  isUpdateLoading: false,
  isVariantsLoading: false,
  categories: [],
  variants: [],
  hasUpdated: false,
  error: null,
};

const variantCategorySlice = createSlice({
  name: "variantCategories",
  initialState,
  reducers: {
    setIsLoading(state) {
      state.isLoading = true;
    },
    setIsUpdateLoading(state) {
      state.isUpdateLoading = true;
    },
    setIsVariantsLoading(state) {
      state.isVariantsLoading = true;
    },
    getCategoriesSuccess(state, action) {
      const { categories } = action.payload;
      state.categories = categories;
      state.isLoading = false;
      state.error = null;
    },
    getVariantsSuccess(state, action) {
      const { variants } = action.payload;
      state.variants = variants;
      state.isVariantsLoading = false;
      state.error = null;
    },
    createCategorySuccess(state, action) {
      const { category } = action.payload;
      state.categories = [...state.categories, category].sort((a, b) =>
        a.name < b.name ? -1 : a.name > b.name ? 1 : 0
      );
      state.isUpdateLoading = false;
      state.hasUpdated = true;
      state.error = null;
    },
    updateCategorySuccess(state, action) {
      const { category } = action.payload;
      state.categories = state.categories.map((cat) =>
        cat.id === category.id
          ? { variants: cat.variants, ...category }
          : { ...cat }
      );
      state.isUpdateLoading = false;
      state.hasUpdated = true;
      state.error = null;
    },
    createOrUpdateVariantSuccess(state, action) {
      const { variant } = action.payload;
      state.categories = state.categories.map((cat) =>
        cat.id === variant.variantCategoryId
          ? {
              ...cat,
              variants: sortBy(
                [
                  variant,
                  ...(cat.variants?.filter((v) => v.id !== variant.id) ?? []),
                ],
                "name"
              ),
            }
          : cat
      );
      state.isUpdateLoading = false;
      state.hasUpdated = true;
      state.error = null;
    },
    setHasUpdated(state, action) {
      const { value } = action.payload;
      state.hasUpdated = value;
    },
    clearErrors(state) {
      state.error = null;
    },
    clearVariants(state) {
      state.variants = [];
    },
    setFailure(state, action) {
      const { error } = action.payload;
      state.isLoading = false;
      state.isUpdateLoading = false;
      state.isVariantsLoading = false;
      state.error = error;
    },
  },
});

export const {
  setIsLoading,
  setIsUpdateLoading,
  setIsVariantsLoading,
  getCategoriesSuccess,
  getVariantsSuccess,
  createCategorySuccess,
  updateCategorySuccess,
  createOrUpdateVariantSuccess,
  setHasUpdated,
  clearErrors,
  clearVariants,
  setFailure,
} = variantCategorySlice.actions;

export default variantCategorySlice.reducer;

export const fetchVariantCategories = () => async (dispatch) => {
  try {
    dispatch(setIsLoading());
    const categoryResp = await axiosGet("/api/variant-categories");
    if (categoryResp.error) {
      throw categoryResp.error;
    }
    let mappedData = mapVariantCategories(categoryResp.data);
    mappedData = await Promise.all(
      mappedData.map(async (cat) => {
        let variantResp = await axiosGet(
          `/api/variant-options?filter[variant-category-id]=${cat.id}`
        );
        if (variantResp.error) {
          throw variantResp.error;
        }
        let mappedVariants = mapVariants(variantResp.data).sort((a, b) =>
          a.name < b.name ? -1 : a.name > b.name ? 1 : 0
        );
        return {
          ...cat,
          variants: mappedVariants,
        };
      })
    );
    dispatch(getCategoriesSuccess({ categories: mappedData }));
  } catch (err) {
    dispatch(setFailure({ error: err.toString() }));
    dispatch(setError({ error: err.toString(), source: "Variant Categories" }));
  }
};

export const createVariantCategory = (attributes) => async (dispatch) => {
  try {
    dispatch(patchLoading());
    dispatch(setIsUpdateLoading());
    const postData = buildVariantCategory(attributes);
    const response = await axiosPost("/api/variant-categories", postData);
    if (response.error) {
      throw response.error;
    }
    const mappedData = mapVariantCategories([response.data])[0];
    if (attributes.variants.length > 0) {
      let variants = [];
      for (let i = 0; i < attributes.variants.length; i++) {
        let variantData = buildVariant({
          name: attributes.variants[i].name,
          abbreviation: attributes.variants[i].abbreviation,
          variantCategoryId: mappedData.id,
        });
        let variantResp = await axiosPost("/api/variant-options", variantData);
        if (variantResp.error) {
          throw variantResp.error;
        }
        let mappedVariant = mapVariants([variantResp.data])[0];
        variants.push(mappedVariant);
      }
      mappedData.variants = variants;
    }
    dispatch(createCategorySuccess({ category: mappedData }));
    dispatch(patchSuccess());
  } catch (err) {
    dispatch(setFailure({ error: err.toString() }));
    dispatch(patchFailure());
    dispatch(setError({ error: err.toString(), source: "Variant Categories" }));
  }
};

export const updateVariantCategory = (id, attributes) => async (dispatch) => {
  try {
    dispatch(patchLoading());
    dispatch(setIsUpdateLoading());
    const patchData = buildVariantCategory(attributes);
    const response = await axiosPatch(
      `/api/variant-categories/${id}`,
      patchData
    );
    if (response.error) {
      throw response.error;
    }
    const mappedData = mapVariantCategories([response.data])[0];
    dispatch(updateCategorySuccess({ category: mappedData }));
    dispatch(patchSuccess());
  } catch (err) {
    dispatch(setFailure({ error: err.toString() }));
    dispatch(patchFailure());
    dispatch(setError({ error: err.toString(), source: "Variant Categories" }));
  }
};

export const createVariant = (attributes) => async (dispatch) => {
  try {
    dispatch(patchLoading());
    dispatch(setIsUpdateLoading());
    const postData = buildVariant(attributes);
    const response = await axiosPost("/api/variant-options", postData);
    if (response.error) {
      throw response.error;
    }
    const mappedData = mapVariants([response.data])[0];
    dispatch(
      createOrUpdateVariantSuccess({
        variant: mappedData,
      })
    );
    dispatch(patchSuccess());
  } catch (err) {
    dispatch(setFailure({ error: err.toString() }));
    dispatch(patchFailure());
    dispatch(setError({ error: err.toString(), source: "Variant Categories" }));
  }
};

export const updateVariant = (id, attributes, callback) => async (dispatch) => {
  try {
    dispatch(patchLoading());
    dispatch(setIsUpdateLoading());
    const patchData = buildVariant(attributes);
    const response = await axiosPatch(`/api/variant-options/${id}`, patchData);
    if (response.error) {
      throw response.error;
    }
    const mappedData = mapVariants([response.data])[0];
    dispatch(
      createOrUpdateVariantSuccess({
        variant: mappedData,
      })
    );
    dispatch(patchSuccess());
  } catch (err) {
    dispatch(setFailure({ error: err.toString() }));
    dispatch(patchFailure());
    dispatch(setError({ error: err.toString(), source: "Variant Categories" }));
  } finally {
    callback?.();
  }
};

export const fetchVariants = (id, name) => async (dispatch) => {
  try {
    dispatch(setIsVariantsLoading());
    const response = await axiosGet(
      `/api/variant-options?filter[name]=${name}&filter[variant-category-id]=${id}`
    );
    if (response.error) {
      throw response.error;
    }
    const mappedData = mapVariants(response.data);
    dispatch(getVariantsSuccess({ variants: mappedData }));
  } catch (err) {
    dispatch(setFailure({ error: err.toString() }));
    dispatch(setError({ error: err.toString(), source: "Variant Categories" }));
  }
};
