import "twin.macro";

import { Skeleton } from "@mui/material";

import { Contained } from "src/components/StyledComponents";

import { Title } from "@features/ui";

const OrderSetSkeleton = () => {
  return (
    <Contained tw="space-y-6">
      <Title backUrl="/orders/history">
        <Skeleton variant="text" tw="w-64 h-16" />
      </Title>
      <div tw="mx-auto grid [grid-template-columns: 26em repeat(auto-fill, minmax(15em, 1fr))] grid-rows-[160px 120px repeat(4, 40px)] gap-4">
        {Array(25)
          .fill(null)
          .map((_, i) => (
            <Skeleton
              key={`skeleton-${i}`}
              variant="rectangular"
              tw="rounded-md h-full"
            />
          ))}
      </div>
    </Contained>
  );
};

export default OrderSetSkeleton;
