import tw from "twin.macro";

import { useFormContext, useWatch } from "react-hook-form";
import { useSelector } from "react-redux";

import _ from "lodash";

import { useSuppliersListQuery } from "@features/suppliers";
import { BlockCard } from "@features/ui";
import { useApiResource } from "@services/api";
import { ControlledSelectInput, ControlledTextInput } from "@utils/forms";
import { ControlledSelectInputProps } from "@utils/forms/ControlledInputs";
import useRoleIs from "@utils/useRoleIs";

import UserEmailInput from "./UserEmailInput";

const userRoles = [
  { id: "read-only", name: "Read Only" },
  { id: "admin", name: "Admin" },
  { id: "approver", name: "Approver" },
  { id: "orderer", name: "Orderer" },
  { id: "purchaser", name: "Purchaser" },
  { id: "purchasing-agent", name: "Purchasing Agent" },
  { id: "select-account-manager", name: "Select Account Manager" },
  { id: "super", name: "Super" },
  { id: "supplier", name: "Supplier" },
];

const specialRoles = [
  "supplier",
  "super",
  "select-account-manager",
  "purchasing-agent",
];
const Loading = tw.div`h-10 rounded-md bg-neutral-100 animate-pulse`;
const ResourceSelectInput = ({
  name,
  resource,
  required = false,
  thru,
  ...props
}: {
  resource: string;
  required?: boolean;
  thru?: (x: any[]) => any[];
} & Omit<ControlledSelectInputProps, "options">) => {
  const { control } = useFormContext();
  let { data: groupOptions } = useApiResource(resource as any, {
    revalidate: false,
  });

  if (!groupOptions) return <Loading />;

  if (thru) {
    groupOptions = thru(groupOptions);
  }

  return (
    <ControlledSelectInput
      options={_.sortBy(groupOptions, "name")}
      name={name}
      control={control}
      rules={{ required }}
      {...props}
    />
  );
};

const SupplierOrgSelectInput = () => {
  const supplierId = useWatch({ name: "supplierId" });
  const { data: suppliers = [] } = useSuppliersListQuery();
  const supplierOrgIds =
    suppliers
      .find((s) => s.id === supplierId)
      ?.organizations.map((org) => org.id) ?? [];

  return (
    <ResourceSelectInput
      label="Supplier Organizations *"
      name="supplierUserOrganizationIds"
      resource="organizations"
      thru={(orgs) => orgs.filter((o) => supplierOrgIds.includes(o.id))}
      required
      multiple
    />
  );
};

const UserFormGeneral = () => {
  const { control } = useFormContext();
  const { warehouses } = useSelector(
    (state: any) => state.currentUser.organization
  );
  const orgHasVernonWarehouse = warehouses.some(
    (warehouse) => warehouse === "vernon"
  );
  const role = useWatch({ name: "role" });

  const editorRoleIs = useRoleIs();

  const isUserRoleDisabled = (role: string) => {
    if (editorRoleIs("super")) return false; // All roles allowed for super

    if (editorRoleIs("admin")) {
      return ["super", "select-account-manager"].includes(role); // These roles, not allowed for admins
    }

    return specialRoles.includes(role); // Special roles not allowed for most users
  };

  const disableRoleInput = isUserRoleDisabled(role);

  return (
    <BlockCard tw="space-y-4">
      <UserEmailInput />
      <ControlledTextInput
        label="Name"
        name="name"
        control={control}
        rules={{ required: "Name is required" }}
      />
      {orgHasVernonWarehouse && (
        <ControlledTextInput
          label="Account Code (Vernon billing ID)"
          name="vernonBillingId"
          control={control}
        />
      )}
      <ControlledSelectInput
        label="Role"
        options={
          disableRoleInput
            ? userRoles
            : userRoles.filter((opt) => !isUserRoleDisabled(opt.id))
        }
        name="role"
        control={control}
        disabled={disableRoleInput}
      />
      {role === "supplier" && (
        <ResourceSelectInput
          label="Supplier *"
          name="supplierId"
          resource="suppliers"
          required
        />
      )}
      {role === "select-account-manager" && (
        <ResourceSelectInput
          label="Account Manager Organizations *"
          name="accountManagerOrganizationIds"
          resource="organizations"
          required
          multiple
        />
      )}
      {role === "purchasing-agent" && (
        <ResourceSelectInput
          label="Purchasing Agent Organizations *"
          name="purchasingAgentOrganizationIds"
          resource="organizations"
          required
          multiple
        />
      )}
      {editorRoleIs("super") && role === "supplier" && (
        <SupplierOrgSelectInput />
      )}
    </BlockCard>
  );
};

export default UserFormGeneral;
