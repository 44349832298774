import { createContext, useContext } from "react";

import { Item } from "@models/Item";

export const ItemContext = createContext<{ item: Item } | null>(null);

export const useItem = () => {
  const context = useContext(ItemContext);
  if (!context) {
    throw new Error("useItem must be used within an ItemContextProvider");
  }
  return context;
};
