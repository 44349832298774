import "twin.macro";

import { useSelector } from "react-redux";
import { Link } from "react-router";

import { ButtonBase, Divider, MenuItem } from "@mui/material";

import permissions from "@utils/permissions";
import useRoleIs from "@utils/useRoleIs";

import OptionsMenu from "../Utility/OptionsMenu";

const UserSection = () => {
  const roleIs = useRoleIs();
  const user = useSelector((state) => state.currentUser);
  return (
    <>
      <OptionsMenu
        title="User Profile"
        originRight
        component={(props) => (
          <ButtonBase
            id="tutorial-profile-button"
            tw="flex items-center text-neutral-700 text-base"
            {...props}
          >
            <div tw="hidden lg:block whitespace-nowrap">{user.name}</div>
            <div tw="w-8 h-8 mx-3 text-sm bg-neutral-700 tracking-wider text-primary-300 rounded-full flex items-center justify-center">
              {user.name
                .split(" ")
                .map((s) => s[0])
                .join("")}
            </div>
          </ButtonBase>
        )}
      >
        <MenuItem component={Link} to="/profile/general">
          Profile
        </MenuItem>
        {roleIs(permissions.userSettingsOrdering) && (
          <>
            <MenuItem component={Link} to="/profile/addressBook">
              Address Book
            </MenuItem>
            <MenuItem component={Link} to="/profile/favoriteItems">
              Favorite Items
            </MenuItem>
          </>
        )}
        <Divider />
        <MenuItem component={Link} to="/help">
          Help
        </MenuItem>
        <MenuItem component={Link} to="/logout">
          Logout
        </MenuItem>
      </OptionsMenu>
    </>
  );
};

export default UserSection;
