import { createContext, useContext } from "react";
import { FieldValues, UseFormReturn } from "react-hook-form";

export type FilterContextProps = UseFormReturn<FieldValues, any> & {
  filterValues: Record<string, any>;
  setFilterValues: (x: Record<string, any>) => void;
};

export const FilterContext = createContext<FilterContextProps>({} as any);

export const useFilterContext = () => useContext(FilterContext);
