import "twin.macro";

import { Chip } from "@mui/material";

import ReactQueryTable, {
  Column,
  PaginatedResourceProps,
} from "@components/Table/ReactQueryTable";
import SearchableText from "@components/Table/SearchableText";
import { ItemIdentifiers } from "@features/items";
import { DateFromNow } from "@features/ui";
import { Quote } from "@models/Quote";
import { utcToLocalDate } from "@utility/utilityFunctions";

import QuoteStatus from "./QuoteStatus";

const quoteUrl = (quote: Quote) =>
  ["complete", "awarded"].includes(quote.status) ? `/quotes/${quote.id}` : null;

const RfqQuotesTable = ({
  query,
  ...tableProps
}: PaginatedResourceProps & {
  rows: Quote[];
  query?: string;
}) => {
  const columns: Column<Quote>[] = [
    {
      id: "_item",
      label: "Quoted Item",
      to: (quote) => `/rfqs/item/${quote.requestForQuote.item.id}`,
      render: (_, quote) => (
        <div className="group">
          <ItemIdentifiers
            item={{
              sku: quote.requestForQuote.sku,
            }}
            query={query}
          />
          <div tw="inline-block text-base text-neutral-800 leading-tight mt-1 group-hover:underline">
            <SearchableText
              text={quote.requestForQuote.itemName}
              query={query}
            />
          </div>
        </div>
      ),
    },
    {
      id: "requestForQuote.id",
      label: "Quote Request",
      to: (quote) => `/rfqs/${quote.requestForQuote.id}`,
      render: (_, quote) => (
        <span tw="text-neutral-800 leading-tight mt-2 underline underline-offset-2 font-medium">
          #{quote.requestForQuote.id}
        </span>
      ),
    },
    {
      id: "supplierName",
      label: "Supplier",
      to: quoteUrl,
    },
    {
      id: "status",
      label: "Status",
      to: quoteUrl,
      render: (status) => <QuoteStatus status={status} />,
    },
    {
      id: "updatedAt",
      label: "Last Action Date",
      to: quoteUrl,
      render: (d, { status }) =>
        status === "sent" ? (
          <span tw="text-neutral-500">---</span>
        ) : (
          <DateFromNow date={utcToLocalDate(d)} />
        ),
    },
    {
      id: "_action",
      label: "",
      to: quoteUrl,
      render: (_, { status }) =>
        ["complete", "awarded"].includes(status) ? (
          <Chip label="View" variant="outlined" size="small" clickable />
        ) : null,
    },
  ];

  return <ReactQueryTable {...tableProps} columns={columns} />;
};

export default RfqQuotesTable;
