import "twin.macro";

import { useFilterContext } from "../filterContext";
import Chip from "./Chip";
import ControlledResourceFilterAutocomplete from "./ControlledResourceFilterAutocomplete";
import useSelectedResources from "./useSelectedResources";

const BudgetPopper = () => {
  const { control } = useFilterContext();

  return (
    <ControlledResourceFilterAutocomplete
      name="budgets"
      resource="budgets"
      control={control}
      inputPlaceholder="Filter Budgets"
    />
  );
};

const BudgetFilterChip = (props) => {
  const { filterValues } = useFilterContext();
  const budgetIds = filterValues["budgets"] ?? [];
  const { data: selectedBudgets } = useSelectedResources("budgets", budgetIds);

  return (
    <Chip {...props}>
      <span tw="text-neutral-600">
        {budgetIds.length === 0 ? "Select Budget" : <>Budget is&nbsp;</>}
      </span>
      <span tw="text-neutral-800 font-medium">
        {selectedBudgets
          .slice(0, 1)
          .map((budget) => budget.name)
          .join(", ")}
        {budgetIds.length > 1 && <span>, + {budgetIds.length - 1}</span>}
      </span>
    </Chip>
  );
};

const BudgetFilter = {
  Chip: BudgetFilterChip,
  Popper: BudgetPopper,
};

export default BudgetFilter;
