import React from "react";

import { Order } from "@models/Order";
import { OrderSet } from "@models/OrderSet";

import { ExtendedOrderSetVariant } from "./useOrderSet";

export type OrderSetContextType = {
  orderSet: OrderSet;
  orders: Order[];
  orderSetVariants: ExtendedOrderSetVariant[];
  orderSetId: string;
  isFetching: boolean;
  onOrderSetDelete: (orderSet: OrderSet) => void;
};

export const CurrentOrderSetContext =
  React.createContext<OrderSetContextType | null>(null);

export const useCurrentOrderSet = () => {
  const context = React.useContext(CurrentOrderSetContext);

  if (!context) {
    throw new Error(
      "useCurrentOrderSet must be used within a CurrentOrderSetContextProvider"
    );
  }

  return context;
};

export const useOptionalCurrentOrderSet = () => {
  return React.useContext(CurrentOrderSetContext);
};
