import "twin.macro";

import { useWatch } from "react-hook-form";
import { Link } from "react-router";

import { StarRounded } from "@mui/icons-material";
import { Button } from "@mui/material";

import { usePaginatedQuoteQuery } from "@features/quotes";

const AwardedQuoteButton = () => {
  const itemId = useWatch({ name: "id" });
  const { data } = usePaginatedQuoteQuery({
    filter: {
      itemId,
      status: ["awarded"],
    },
  });
  if (data.length === 0) return null;

  const [quote] = data;
  return (
    <Button
      component={Link}
      to={`/quotes/${quote.id}`}
      target="_blank"
      tw="normal-case bg-purple-50 text-purple-700 py-1 shadow-none hover:(shadow-none bg-purple-100! text-purple-900)"
      endIcon={<StarRounded />}
    >
      Awarded Quote Reference
    </Button>
  );
};

export default AwardedQuoteButton;
