import tw from "twin.macro";

import { useState } from "react";

import {
  Checkbox,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";

import { sortBy, uniqBy, xor } from "lodash";

import {
  isOrderWindowActive,
  isOrderWindowAssignableToProgram,
  useOrderWindowsListQuery,
} from ".";
import { StyledButton } from "../../components/StyledComponents";
import { Program } from "../../models";
import { formatUtcDate } from "../../utility/utilityFunctions";

type ProgramOrderWindowAssignmentModalProps = {
  program: Program;
  handleSave: (orderWindowIds: string[]) => Promise<void> | void;
  onClose: () => void;
};

export const ProgramOrderWindowAssignmentModal = ({
  program,
  onClose,
  handleSave,
}: ProgramOrderWindowAssignmentModalProps) => {
  const [loading, setLoading] = useState(false);
  const [showClosed, setShowClosed] = useState(false);
  const [orderWindowIds, setOrderWindowIds] = useState<string[]>(
    program.orderWindows.map((ow) => ow.id)
  );
  const { data, isLoading } = useOrderWindowsListQuery({
    filter: { isNotClosed: !showClosed },
  });

  const orderWindows = uniqBy(
    [...program.orderWindows, ...(data ?? [])],
    "id"
  ).map((orderWindow) => ({
    ...orderWindow,
    ...isOrderWindowAssignableToProgram(orderWindow, program),
    isActive: isOrderWindowActive(orderWindow),
  }));

  const sortedOrderWindows = sortBy(
    sortBy(orderWindows, "openDate").reverse(),
    (ow) => {
      if (!ow.isAssignable) {
        return 2;
      }
      if (!program.orderWindows.some((pow) => pow.id === ow.id)) {
        return 1;
      }
      return 0;
    }
  );

  const saveAndClose = async () => {
    setLoading(true);
    await handleSave(orderWindowIds);
    onClose();
  };

  const handleToggle = (id: string) => {
    setOrderWindowIds(xor(orderWindowIds, [id]));
  };

  return (
    <Dialog open onClose={onClose} fullWidth maxWidth="sm">
      <DialogTitle>Assign Order Windows</DialogTitle>
      <DialogContent
        css={[tw`transition-opacity`, isLoading && tw`opacity-50`]}
      >
        {!data && <CircularProgress />}
        {data && data.length === 0 && (
          <div tw="rounded bg-neutral-100 text-neutral-500 p-3">
            No upcoming Order Windows available.
          </div>
        )}
        {data && (
          <>
            <div tw="space-y-2">
              {sortedOrderWindows.map((orderWindow) => (
                <label
                  key={orderWindow.id}
                  css={[
                    tw`box-border flex items-start px-4 py-2 rounded bg-neutral-100`,
                    orderWindow.isActive &&
                      tw`border-2 border-primary-100 bg-primary-50`,
                  ]}
                >
                  <Checkbox
                    size="small"
                    edge="start"
                    disabled={loading || !orderWindow.isAssignable}
                    checked={orderWindowIds.includes(orderWindow.id)}
                    onChange={() =>
                      orderWindow.isAssignable && handleToggle(orderWindow.id)
                    }
                    tw="-my-1.5"
                  />
                  <div tw="flex-grow">
                    <div tw="w-full text-lg flex gap-3 items-baseline justify-between">
                      <div>{orderWindow.name}</div>
                      <div tw="text-base text-neutral-600">
                        In Market{" "}
                        {formatUtcDate(orderWindow.inMarketDate, "PP")}
                      </div>
                    </div>
                    {!orderWindow.isAssignable && (
                      <div tw="text-neutral-500">{orderWindow.reason}</div>
                    )}
                    {orderWindow.isAssignable && (
                      <div tw="mt-1 flex gap-3 items-baseline">
                        <div tw="text-sm text-neutral-700 tracking-wide">
                          {formatUtcDate(orderWindow.openDate, "PP")} &mdash;{" "}
                          {formatUtcDate(orderWindow.closeDate, "PP")}
                        </div>
                        {orderWindow.isActive && (
                          <span tw="text-xs tracking-wide bg-primary-100 rounded text-primary-900 py-0.5 px-2">
                            Active
                          </span>
                        )}
                      </div>
                    )}
                  </div>
                </label>
              ))}
            </div>
            {!showClosed && (
              <button
                tw="mt-3 text-neutral-600 underline"
                onClick={() => setShowClosed(true)}
              >
                Show Closed Order Windows
              </button>
            )}
          </>
        )}
      </DialogContent>
      <DialogActions>
        <StyledButton outlined onClick={onClose}>
          Cancel
        </StyledButton>
        <StyledButton cta onClick={saveAndClose} loading={loading}>
          Apply
        </StyledButton>
      </DialogActions>
    </Dialog>
  );
};
