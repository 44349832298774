import React from "react";
import { createPortal } from "react-dom";
import { useSelector } from "react-redux";

import { useItemQuery } from "@features/items";
import { Item } from "@models/Item";

const ItemModalPortal = ({
  children,
}: {
  children: (item: Item) => React.ReactNode;
}) => {
  const node = document.getElementById("item-modal-actions");
  const { itemPreviewId: itemId } = useSelector((state: any) => state.items);

  const { data: item } = useItemQuery(itemId);

  if (!node) console.warn("Item modal was unmounted, portal node not found");
  if (!node || !item) return null;
  return createPortal(children(item), node);
};

export default ItemModalPortal;
