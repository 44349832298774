import "twin.macro";

import { Skeleton } from "@mui/material";

import { Contained, OpaqueCard } from "@components/StyledComponents";
import { Title } from "@features/ui";
import permissions from "@utils/permissions";
import useRoleIs from "@utils/useRoleIs";

export const QuoteSkeleton = () => {
  const roleIs = useRoleIs();
  const isSupplier = roleIs(permissions.supplier);
  const defaultBackUrl = isSupplier ? "/quotes/" : "/rfqs";
  return (
    <Contained tw="pb-6 max-w-screen-xl">
      {/* Title Section */}
      <div tw="flex items-start justify-between mb-6">
        <div>
          <div tw="flex items-center gap-2 mb-2">
            <Title backUrl={defaultBackUrl}>
              <Skeleton variant="text" width={200} height={36} />
            </Title>
          </div>
        </div>
        <Skeleton variant="rectangular" width={120} height={36} />{" "}
        {/* Award button */}
      </div>

      {/* Two Column Layout */}
      <div tw="grid lg:grid-cols-[2fr 1fr] gap-6 max-w-screen-xl">
        {/* Left Column - Main Quote Content */}
        <OpaqueCard tw="p-6 bg-neutral-50 shadow-none">
          {/* RFQ Summary Skeleton */}
          <div tw="space-y-4">
            <Skeleton variant="text" width="40%" height={30} />
            <div tw="grid grid-cols-2 gap-4">
              <Skeleton variant="text" width="80%" height={24} />
              <Skeleton variant="text" width="70%" height={24} />
              <Skeleton variant="text" width="60%" height={24} />
              <Skeleton variant="text" width="75%" height={24} />
            </div>
          </div>

          {/* Quote Details Skeleton */}
          <div tw="mt-6">
            <Skeleton variant="text" width="30%" height={30} />
            <div tw="grid grid-cols-2 gap-4 mt-2">
              <Skeleton variant="text" width="80%" height={24} />
              <Skeleton variant="text" width="70%" height={24} />
            </div>
          </div>

          {/* Pricing Table Skeleton */}
          <div tw="mt-6">
            <Skeleton variant="text" width="25%" height={30} />
            <div tw="mt-2">
              <Skeleton variant="rounded" width="100%" height={120} />
            </div>
          </div>
        </OpaqueCard>

        {/* Right Column - RFQ Reference */}
        <div tw="flex flex-col gap-6 relative">
          <OpaqueCard tw="p-4 bg-neutral-100 shadow-none">
            <Skeleton variant="text" width="60%" height={24} />
            <div tw="mt-3 space-y-3">
              <Skeleton variant="text" width="100%" height={20} />
              <Skeleton variant="text" width="80%" height={20} />
              <Skeleton variant="text" width="90%" height={20} />
            </div>
            <div tw="mt-4">
              <Skeleton variant="rounded" width={120} height={120} />
            </div>
          </OpaqueCard>
        </div>
      </div>
    </Contained>
  );
};
