import "twin.macro";

import { useDispatch, useSelector } from "react-redux";

import { Close } from "@mui/icons-material";
import { Button, DialogActions } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import IconButton from "@mui/material/IconButton";

import { clearError } from "@redux/slices/errorSlice";

const ErrorModal = () => {
  const dispatch = useDispatch();

  const error = useSelector((state) => state.error.currentError);
  const source = useSelector((state) => state.error.errorSource);

  const handleClose = () => dispatch(clearError());

  if (!error) return null;

  return (
    <Dialog open onClose={handleClose} fullWidth maxWidth="sm">
      <DialogContent>
        <div tw="flex items-start justify-between mb-2">
          <div>
            <h2 tw="text-2xl font-medium">Something went wrong</h2>
            <div tw="text-base text-neutral-500">
              The following error has occured:
            </div>
          </div>
          <IconButton edge="end" onClick={handleClose}>
            <Close />
          </IconButton>
        </div>
        <p tw="text-lg text-neutral-800 whitespace-pre-wrap bg-red-50 p-4 rounded border-l-4 border-red-200">
          {error}
        </p>
        {source && (
          <p tw="text-sm text-neutral-500 mt-2">{`Source: ${source}`}</p>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} variant="outlined">
          GO BACK HOME
        </Button>

        <Button
          variant="contained"
          onClick={handleClose}
          style={{ marginLeft: "12px" }}
        >
          DISMISS ERROR
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ErrorModal;
