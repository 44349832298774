import "twin.macro";

import { OpaqueCard, StyledButton } from "@components/StyledComponents";

type SaveFormBannerProps = {
  handleSave: () => void;
  handleReset: () => void;
  isLoading?: boolean;
  className?: string;
};

export const SaveFormBanner = ({
  handleSave,
  handleReset,
  isLoading,
  className,
}: SaveFormBannerProps) => {
  return (
    <div tw="absolute inset-0 h-full bottom-auto pointer-events-none">
      <OpaqueCard
        tw="
          sticky top-3 z-[70] 
          flex items-center justify-between border-2 border-primary-600
          px-6 h-20 pointer-events-auto font-bold text-lg"
        className={className}
      >
        <div>You have unsaved changes.</div>
        <div tw="flex gap-4">
          <StyledButton cta onClick={handleSave} loading={isLoading}>
            Save
          </StyledButton>
          <StyledButton outlined onClick={handleReset}>
            Reset
          </StyledButton>
        </div>
      </OpaqueCard>
    </div>
  );
};
