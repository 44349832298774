import tw from "twin.macro";

import { memo, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";

import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";

import { useQueryClient } from "@tanstack/react-query";

import { changeOrganization } from "@redux/slices/user/currentUserSlice";
import { useClearSwrCache } from "@services/api";

const OrganizationSelector = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const clearCache = useClearSwrCache();
  const queryClient = useQueryClient();

  const [org, setOrg] = useState("");
  const { id, availableOrgs } = useSelector(
    (state) => state.currentUser.organization
  );
  const { isUpdating, isLoading } = useSelector((state) => state.globalState);

  const handleChange = (evt) => {
    clearCache();
    queryClient.clear();
    setOrg(evt.target.value);
    dispatch(changeOrganization(evt.target.value));
    navigate("/");
  };

  useEffect(() => {
    if (availableOrgs.length > 0) {
      setOrg(availableOrgs.find((org) => org.id === id).id);
    }
  }, [availableOrgs, id]);

  return (
    <FormControl
      {...props}
      size="small"
      css={{
        ".MuiOutlinedInput-root fieldset": tw`border-none`,
      }}
    >
      <InputLabel id="organization-select" color="secondary" tw="max-w-none">
        Organization
      </InputLabel>
      <Select
        name="organizations"
        labelId="organization-select"
        id="organizations"
        value={org}
        onChange={handleChange}
        disabled={isLoading || isUpdating}
      >
        {availableOrgs.map((org, index) => (
          <MenuItem value={org.id} key={index} id={org.id}>
            {org.name}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default memo(OrganizationSelector);
