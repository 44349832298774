import React, { useCallback, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import Autocomplete from "@mui/material/Autocomplete";

import PropTypes from "prop-types";

import { fetchPromotions } from "@redux/slices/promotions/promotionSlice";
import { StyledTextInput } from "@utils/forms/ControlledInputs";

const PromotionAutoComplete = ({
  handleChange,
  reset,
  setReset,
  filterType,
}) => {
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [promotion, setPromotion] = useState("");
  const [currentPromotions, setCurrentPromotions] = useState([]);

  const isLoading = useSelector((state) => state.promotions.isLoading);
  const options = useSelector((state) => state.promotions.promotionList);
  const currentFilterPrograms = useSelector(
    (state) => state.filters.promotionIds
  );
  const isGlobalLoading = useSelector((state) => state.globalState.isLoading);

  const loading = open && isLoading;

  const debounce = useRef(null);

  const handlePrograms = (value) => {
    setCurrentPromotions(value);
  };

  const handleQuery = useCallback(() => {
    clearTimeout(debounce.current);

    debounce.current = setTimeout(() => {
      dispatch(
        fetchPromotions({ description: promotion, isAutoComplete: true })
      );
    }, 250);
  }, [promotion, dispatch]);

  useEffect(() => {
    if (promotion.length >= 1) {
      handleQuery();
    }
  }, [promotion, handleQuery, dispatch]);

  useEffect(() => {
    if (currentFilterPrograms.length !== currentPromotions.length) {
      setCurrentPromotions(currentFilterPrograms);
    }
  }, [currentFilterPrograms, currentPromotions.length]);

  useEffect(() => {
    if (reset) {
      setPromotion("");
      setCurrentPromotions([]);
      setReset(false);
    }
  }, [reset, setPromotion, setReset]);

  return (
    <>
      <Autocomplete
        multiple
        autoHighlight
        renderTags={() => null}
        fullWidth
        freeSolo
        id="promotion-auto-complete"
        open={open}
        onOpen={() => setOpen(true)}
        onClose={() => setOpen(false)}
        inputValue={promotion}
        onInputChange={(_evt, value) => setPromotion(value)}
        onChange={(evt, value) => {
          handleChange(value, "promotionIds", filterType);
          handlePrograms(value);
        }}
        isOptionEqualToValue={(option, value) =>
          option.description === value.description
        }
        getOptionLabel={(option) => option.description}
        options={options}
        loading={loading}
        value={currentPromotions}
        disabled={isGlobalLoading}
        renderInput={(params) => (
          <StyledTextInput {...params} label="Promotion Description" />
        )}
      />
    </>
  );
};

PromotionAutoComplete.propTypes = {
  handleChange: PropTypes.func.isRequired,
  reset: PropTypes.bool.isRequired,
  setReset: PropTypes.func.isRequired,
};

export default PromotionAutoComplete;
