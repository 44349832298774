import { useSelector } from "react-redux";

import { useMutation } from "@tanstack/react-query";
import saveFile from "file-saver";
import _ from "lodash";

import { useMutateError } from "@services/api";

export default function useDownloadPackoutPdfMutation() {
  const setError = useMutateError();
  const orgName = useSelector(
    (state: any) => state.currentUser.organization.name
  );
  const {
    currentPurchaseOrder: { purchaseOrderVariants, id },
  } = useSelector((state: any) => state.purchaseOrder);

  const povItems = _(purchaseOrderVariants)
    .filter((pov) => !pov.isSetupFee)
    .uniqBy("sku")
    .sortBy("sku")
    .value();

  return useMutation({
    mutationFn: async () => {
      // Lazy load for chunking
      const { default: getPurchaseOrderPackoutPdf } = await import(
        "./getPurchaseOrderPackoutPdf"
      );
      const pdf = await getPurchaseOrderPackoutPdf(id, orgName, povItems);
      saveFile(pdf, `warehouse-labels-${id}.pdf`);
    },
    onError: (err) => setError(err, "downloadPackoutPdf"),
  });
}
