import "twin.macro";

import { upCase } from "@utility/utilityFunctions";
import permissions from "@utils/permissions";

import { useFilterContext } from "../filterContext";
import Chip from "./Chip";
import ControlledFilterAutocomplete from "./ControlledFilterAutocomplete";

/*
 * api/purchase-users endpoint gets all users that have made purchases in
 * the organization regardless of what organization they belong to.
 */

const UserRolesPopper = () => {
  const { control } = useFilterContext();

  return (
    <>
      <ControlledFilterAutocomplete
        name={`userRoles`}
        control={control}
        options={permissions.userRoles.map((role) => ({
          id: role,
          name: upCase(role),
        }))}
        inputPlaceholder={`Filter user roles`}
        isLoading={false}
      />
    </>
  );
};

const UserRolesChip = (props) => {
  const { filterValues } = useFilterContext();
  const selectedRoles = filterValues["userRoles"] ?? [];

  return (
    <Chip {...props}>
      <span tw="text-neutral-600">Role is&nbsp;</span>
      <span tw="text-neutral-800 font-medium">
        {[...selectedRoles]
          .splice(0, 2)
          .filter(Boolean)
          .map((role) => upCase(role))
          .join(", ")}
        {selectedRoles.length > 2 && (
          <span>, + {selectedRoles.length - 2}</span>
        )}
      </span>
    </Chip>
  );
};

const UserRolesFilter = {
  Chip: UserRolesChip,
  Popper: UserRolesPopper,
};

export default UserRolesFilter;
