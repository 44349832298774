import "twin.macro";

import { useMemo } from "react";
import { Link } from "react-router";

import { Button } from "@mui/material";

import _ from "lodash";

import { FixedHeightScrollLastChild } from "@components/StyledComponents";
import Filters, { splitSort, useFilterParams } from "@features/filters";
import OrderWindowAdminTable from "@features/orderWindows/OrderWindowAdminTable";
import { useOrderWindowsListQuery } from "@features/orderWindows/orderWindowsQueries";
import { Title } from "@features/ui";
import DocTitle from "@utility/DocTitle";

const AdminOrderWindows = () => {
  const [filters] = useFilterParams();
  const search: string = filters.q ?? "";
  const sort = filters.sort ? splitSort(filters.sort) : null;
  const { data: orderWindows = [], ...tableProps } = useOrderWindowsListQuery();

  const filteredOrderWindows = useMemo(() => {
    const terms = search.toLowerCase().split(" ");
    const filtered = orderWindows.filter((ow) => {
      const owName = ow.name.toLowerCase();
      return terms.some((term) => owName.includes(term));
    });

    if (!sort) {
      // show newest first
      filtered.reverse();
      return filtered;
    }

    return _.orderBy(filtered, [_.camelCase(sort[0])], [sort[1]]);
  }, [orderWindows, search, sort]);

  return (
    <>
      <DocTitle title={"Order Window Admin"} />
      <FixedHeightScrollLastChild>
        <div tw="flex justify-between items-center">
          <Title>Order Window Admin</Title>
          <Button
            variant="contained"
            component={Link}
            to="/admin/order-windows/new"
          >
            NEW ORDER WINDOW
          </Button>
        </div>
        <Filters slots={[]} />
        <OrderWindowAdminTable rows={filteredOrderWindows} {...tableProps} />
      </FixedHeightScrollLastChild>
    </>
  );
};

export default AdminOrderWindows;
