import "twin.macro";

import { useDispatch, useSelector } from "react-redux";

import { usePaginatedAddressesQuery } from "@features/addresses/addressQueries";
import { updateHasViewed } from "@redux/slices/user/currentUserSlice";

import Tutorial from "./Tutorial";

const AddressBookTutorial = () => {
  const dispatch = useDispatch();

  const { currentTerritoryId } = useSelector((state) => state.currentUser);
  const { data: addressses } = usePaginatedAddressesQuery(
    {
      filter: {
        territoryId: currentTerritoryId,
      },
    },
    {
      page: { size: 1 },
    }
  );
  const hasAddresses = addressses.length > 0;

  const handleDismiss = () =>
    dispatch(updateHasViewed("has-viewed-address-book"));

  return (
    <Tutorial
      handleDismiss={handleDismiss}
      steps={[
        {
          position: "center",
          content: <p tw="text-2xl">Welcome to your Address Book!</p>,
        },
        ...(hasAddresses
          ? [
              {
                anchor: "#tutorial-address-table",
                position: "top",
                content: `Your organization preloaded some addresses for you! Check them
                out here to make sure they are correct`,
              },
            ]
          : []),
        {
          anchor: "#tutorial-new-address",
          content: `Add more addresses here to use as shipping options.`,
        },
        {
          anchor: "#tutorial-bulk-upload",
          content: `You can also use this button to upload multiple addresses at once.`,
        },
        {
          anchor: "#tutorial-favorite-addresses",
          content: `Once you've created addresses, you can build favorites
            lists here for quick adds to order forms later.
`,
        },
      ]}
    />
  );
};

export default AddressBookTutorial;
