import { useEffect } from "react";
import { useSelector } from "react-redux";

import MenuItem from "@mui/material/MenuItem";
import Typography from "@mui/material/Typography";

import PropTypes from "prop-types";

import { StyledSelect } from "@utils/forms";

const OrderTypeSelector = ({
  handleOrderType,
  orderType,
  setType,
  filterType,
}) => {
  const {
    organization: { usesOnDemand, usesInventory },
  } = useSelector((state) => state.currentUser);
  const currentType = useSelector((state) => state.filters.orderType);

  const orderTypes = [
    { id: "pre-order", label: "Pre-Order" },
    usesOnDemand ? { id: "on-demand", label: "On Demand" } : null,
    usesInventory ? { id: "inventory", label: "Inventory" } : null,
    filterType === "purchase-order-history"
      ? { id: "fulfillment", label: "Fulfillment" }
      : null,
  ]
    .filter((status) => status)
    .map((orderType, index) => (
      <MenuItem value={orderType.id} key={index}>
        <Typography variant="body2">{orderType.label}</Typography>
      </MenuItem>
    ));

  const handleChangeSelect = (evt) => {
    setType(evt.target.value);
    handleOrderType(evt.target.value, "orderType", filterType);
  };

  useEffect(() => {
    if (orderType !== currentType) {
      setType(currentType);
    }
  });

  return (
    <StyledSelect
      label={
        filterType === "purchase-order-history"
          ? "Purchase Order Type"
          : "Order Type"
      }
      name="orderType"
      labelId="orderType-select"
      id="orderType"
      value={orderType || ""}
      onChange={handleChangeSelect}
      MenuProps={{
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "left",
        },
        transformOrigin: {
          vertical: "top",
          horizontal: "left",
        },
      }}
    >
      {[...orderTypes]}
    </StyledSelect>
  );
};

OrderTypeSelector.propTypes = {
  handleOrderType: PropTypes.func.isRequired,
  orderType: PropTypes.string,
  setType: PropTypes.func.isRequired,
  filterType: PropTypes.string,
};

export default OrderTypeSelector;
