import "twin.macro";

import { useSelector } from "react-redux";

import stateCodeMap from "@utility/stateCodeMap";

import { useFilterContext } from "../filterContext";
import Chip from "./Chip";
import ControlledFilterAutocomplete from "./ControlledFilterAutocomplete";

const useStates = (): { id: string; code: string; name: string }[] => {
  const orgCountries = useSelector(
    (state: any) => state.currentUser.organization.countries
  );
  return orgCountries
    .flatMap((country: any) => country.states)
    .filter(Boolean)
    .map((state) => ({
      id: state.id,
      code: state.code,
      name: `${state.code} - ${stateCodeMap[state.code]}`,
    }));
};
const StatePopper = () => {
  const { control } = useFilterContext();
  const states = useStates();

  return (
    <ControlledFilterAutocomplete
      name="states"
      options={states}
      control={control}
      inputPlaceholder="Filter States"
      isLoading={false}
    />
  );
};

const StateFilterChip = (props) => {
  const states = useStates();
  const { filterValues } = useFilterContext();
  const selectedStates = filterValues["states"] ?? [];

  return (
    <Chip {...props}>
      <span tw="text-neutral-600">State is&nbsp;</span>
      <span tw="text-neutral-800 font-medium">
        {[...selectedStates]
          .splice(0, 3)
          .map((id) => states.find((state) => state.id === id)?.code)
          .filter(Boolean)
          .join(", ")}
        {selectedStates.length > 3 && (
          <span>, + {selectedStates.length - 3}</span>
        )}
      </span>
    </Chip>
  );
};

const StateFilter = {
  Chip: StateFilterChip,
  Popper: StatePopper,
};

export default StateFilter;
