import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";

import { setRedirect } from "@redux/slices/globalStateSlice";

/*
useRedirect lives top level, and checks for any needed redirects in the globalState
slice. If a redirect is present, it gets pushed into history and the redirect is cleared
from state.
*/

export const useRedirect = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { willRedirect, redirectUrl } = useSelector(
    (state) => state.globalState
  );

  useEffect(() => {
    if (willRedirect && redirectUrl) {
      navigate(redirectUrl);
      dispatch(setRedirect({ redirectBool: false, url: null }));
    }
  }, [willRedirect, redirectUrl, dispatch, navigate]);
};
