import "twin.macro";

import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";

import CloseIcon from "@mui/icons-material/CloseRounded";
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  Stack,
  Typography,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

import clsx from "clsx";
import { sortBy } from "lodash";
import PropTypes from "prop-types";

import { LoadingButton } from "@features/ui";
import { ControlledAutocompleteInput, ControlledTextInput } from "@utils/forms";

import SingleTerritoryAutoComplete from "../../components/Utility/AutoCompleteFields/SingleTerritoryAutoComplete";
import SingleUserAutoComplete from "../../components/Utility/AutoCompleteFields/SingleUserAutoComplete";
import {
  useAddressQuery,
  useCreateAddressMutation,
  usePaginatedAddressesQuery,
  useUpdateAddressMutation,
} from "./addressQueries";

const StateSelector = ({ country, disabled, control }) => {
  const [currentStates, setCurrentStates] = useState([]);

  const { territoryList } = useSelector((state) => state.territories);
  const currentTerritoryId = useSelector(
    (state) => state.currentUser.currentTerritoryId
  );

  useEffect(() => {
    const states =
      country?.states?.length > 0
        ? country.states
        : territoryList.find((t) => t.id === currentTerritoryId)?.states;

    setCurrentStates(states ? sortBy(states, "code") : []);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [country]);

  return (
    <ControlledAutocompleteInput
      name="state"
      label="State"
      options={currentStates.map((s) => ({ id: s.id, name: s.code }))}
      disabled={disabled}
      control={control}
    />
  );
};

const useCheckUniqueName = (name, id) => {
  const { data, isLoading } = usePaginatedAddressesQuery(
    {
      filter: { name },
    },
    { enabled: name.length > 1 }
  );

  return {
    nameTaken: data?.some((a) => a.id !== id && a.name === name),
    nameCheckLoading: isLoading,
  };
};

const useStyles = makeStyles((theme) => ({
  ...theme.global,
}));

const AddressModal = ({
  open,
  handleClose,
  type: _type,
  id,
  onSuccess = (_) => {},
}) => {
  const classes = useStyles();

  const [type, setType] = useState(_type ?? "create");
  const [addressId, setAddressId] = useState(id ?? null);
  const [assignedUser, setAssignedUser] = useState(null);
  const [assignedTerritory, setAssignedTerritory] = useState(null);
  const [nameToCheck, setNameToCheck] = useState("");
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [country, setCountry] = useState("");
  const [state, setState] = useState("");
  const [validationError, setValidationError] = useState(null);
  const [formLoading, setFormLoading] = useState(false);

  const {
    data: address,
    isValidating,
    error: errorAddress,
  } = useAddressQuery(addressId);

  const createAddress = useCreateAddressMutation();
  const updateAddress = useUpdateAddressMutation();

  const {
    territories,
    currentTerritoryId,
    id: userId,
    role,
    organization: { addressBookType, countries: orgCountries },
  } = useSelector((state) => state.currentUser);

  const countryOptions = ["super", "admin"].includes(role)
    ? orgCountries
    : territories.find((t) => t.id === currentTerritoryId).countries;

  const { nameTaken, nameCheckLoading } = useCheckUniqueName(
    nameToCheck,
    addressId
  );

  const {
    handleSubmit,
    reset: resetForm,
    control,
    watch,
    getValues,
  } = useForm({
    defaultValues: {
      name: "",
      country: "",
      state: "",
    },
  });

  const watchCountry = watch("country");

  const onSubmit = async (data) => {
    if (
      !country ||
      (country.states && country.states.length > 0 && !getValues("state"))
    ) {
      setValidationError(new Error("Must choose country/state"));
    } else {
      setFormLoading(true);
      setValidationError(null);

      const territoryId = assignedTerritory
        ? assignedTerritory.id
        : currentTerritoryId;

      const payloadUserId = assignedUser
        ? +assignedUser.id
        : addressBookType === "user"
          ? +userId
          : null;

      if (type === "edit") {
        await updateAddress.mutateAsync({
          id: address.id,
          ...data,
          territoryId,
          userId: payloadUserId,
        });
      } else {
        const cache = await createAddress.mutateAsync({
          ...data,
          territoryId,
          userId: payloadUserId,
        });

        setAddressId(cache.id);
        setType("edit");
        onSuccess(cache);
      }
      setFormSubmitted(true);
      setFormLoading(false);
    }
  };

  useEffect(() => {
    setCountry(countryOptions.find((c) => c.id === watchCountry));
  }, [watchCountry, countryOptions]);

  // Listen for address to update
  useEffect(() => {
    if (address) {
      resetForm({
        name: address.name ?? "",
        country: address.country.id ?? "",
        streetAddress1: address.streetAddress1 ?? "",
        streetAddress2: address.streetAddress2 ?? "",
        defaultAttn: address.defaultAttn ?? "",
        city: address.city ?? "",
        state: address.state?.id ?? "",
        region: address.region ?? "",
        zip: address.zip ?? "",
        phoneNumber: address.phoneNumber ?? "",
      });
      setState(address.state);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [address]);

  const formError =
    errorAddress ??
    createAddress.error ??
    updateAddress.error ??
    validationError;

  return (
    <div className={classes.relativeContainer}>
      <Dialog
        open={open}
        onClose={() => {
          setValidationError(null);
          handleClose();
        }}
        fullWidth
        maxWidth="sm"
      >
        <DialogTitle>
          <Typography className={classes.headerText}>
            {type === "edit" ? `Editing ${address?.name}` : "New Address"}
          </Typography>
        </DialogTitle>
        <DialogContent>
          <IconButton
            className={classes.closeButton}
            onClick={() => {
              setValidationError(null);
              handleClose();
            }}
            size="large"
          >
            <CloseIcon />
          </IconButton>
          <div tw="p-4">
            <form>
              <Stack spacing={2}>
                <ControlledTextInput
                  label="Business Name / Reference Id"
                  name="name"
                  onBlur={(e) => setNameToCheck(e.target.value)}
                  error={nameTaken}
                  helperText={nameTaken ? "Address name already in use" : null}
                  control={control}
                  rules={{ required: true }}
                />
                <ControlledAutocompleteInput
                  name="country"
                  label="Country"
                  options={countryOptions}
                  disabled={address?.name.length === 0}
                  control={control}
                />
                <ControlledTextInput
                  label="Address Line One"
                  disabled={address?.name.length === 0}
                  name="streetAddress1"
                  control={control}
                  rules={{ required: true }}
                />
                <ControlledTextInput
                  label="Address Line Two"
                  disabled={address?.name.length === 0}
                  name="streetAddress2"
                  control={control}
                />
                <ControlledTextInput
                  label="Attention"
                  disabled={address?.name.length === 0}
                  name="defaultAttn"
                  control={control}
                />
                <ControlledTextInput
                  label="City"
                  disabled={address?.name.length === 0}
                  name="city"
                  control={control}
                  rules={{ required: true }}
                />
                {country?.states?.length > 0 &&
                  ((type === "edit" && state && typeof state === "object") ||
                    type === "new") && (
                    <StateSelector
                      country={country}
                      disabled={address?.name.length === 0}
                      control={control}
                    />
                  )}
                {(!country?.states || country?.states.length === 0) && (
                  <ControlledTextInput
                    label="Region"
                    disabled={address?.name.length === 0}
                    name="region"
                    control={control}
                  />
                )}
                <ControlledTextInput
                  label="Zip Code"
                  disabled={address?.name.length === 0}
                  name="zip"
                  control={control}
                  rules={{ required: true }}
                />
                <ControlledTextInput
                  label="Phone Number"
                  disabled={address?.name.length === 0}
                  name="phoneNumber"
                  control={control}
                  rules={{ required: true }}
                />
                {["super", "admin"].includes(role) && type === "new" && (
                  <>
                    <br />
                    <Divider className={classes.fullWidth} />
                    <br />
                    <Typography
                      className={clsx(
                        classes.headerText,
                        classes.settingsMargin
                      )}
                    >
                      {addressBookType === "user"
                        ? "User and Territory Assignment"
                        : "Territory Assignment"}
                    </Typography>
                    <Typography
                      className={classes.bodyText}
                      color="textSecondary"
                    >
                      {addressBookType === "user"
                        ? "* By default the address will be assigned you as a user, and your current territory.  If you would like to assign a different user or territory where the address will be visible, you can do so here"
                        : "* By default the address will be assigned your current territory, but if you would like to assign the territory that this address will be visible in, you can do so here"}
                    </Typography>
                    <br />
                    {addressBookType === "user" && (
                      <SingleUserAutoComplete
                        classes={classes}
                        handleChange={setAssignedUser}
                        disabled={address?.name.length === 0}
                        roles={null}
                        noMargin={false}
                      />
                    )}
                    <SingleTerritoryAutoComplete
                      classes={classes}
                      handleChange={setAssignedTerritory}
                      disabled={address?.name.length === 0}
                      noMargin={false}
                    />
                    <Button
                      className={classes.button}
                      variant="contained"
                      color="secondary"
                      onClick={() => {
                        setAssignedUser(null);
                        setAssignedTerritory(null);
                      }}
                      disabled={address?.name.length === 0}
                    >
                      RESET ASSIGNMENT
                    </Button>
                    <br />
                    <Divider className={classes.fullWidth} />
                    <br />
                  </>
                )}
                <br />
                {updateAddress.isSuccess ||
                  (createAddress.isSuccess && (
                    <Typography
                      color="success.main"
                      className={classes.bodyText}
                    >
                      {`Address ${
                        updateAddress.isSuccess ? "updated" : "created"
                      } successfully`}
                    </Typography>
                  ))}
                {!!formError && (
                  <Typography
                    color="error.main"
                    className={clsx(classes.settingsMargin, classes.bodyText)}
                  >
                    {formError.toString()}
                  </Typography>
                )}
                {!isValidating && (
                  <LoadingButton
                    variant="contained"
                    onClick={handleSubmit(onSubmit)}
                    loading={formLoading || isValidating || nameCheckLoading}
                  >
                    {type === "edit" ? "Update" : "Submit"}
                  </LoadingButton>
                )}
                {formSubmitted && (
                  <LoadingButton
                    style={{ marginLeft: "4px" }}
                    variant="contained"
                    onClick={handleClose}
                  >
                    CLOSE
                  </LoadingButton>
                )}
                <br />
              </Stack>
            </form>
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
};

AddressModal.propTypes = {
  id: PropTypes.string,
  type: PropTypes.string.isRequired,
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
};

export default React.memo(AddressModal);
