import "twin.macro";

import { useEffect } from "react";

import { ErrorRounded } from "@mui/icons-material";

import { OpaqueCard } from "@components/StyledComponents";

const ResourceError = ({ error, ...props }) => {
  useEffect(() => {
    console.error(error);
    // eslint-disable-next-line
  }, []);
  return (
    <OpaqueCard
      tw="flex gap-3 items-start bg-white border border-red-700 p-4 max-w-md"
      {...props}
    >
      <ErrorRounded tw="text-red-700 mt-0.5" />
      <div>
        <h3 tw="text-lg text-red-900">Error fetching resource:</h3>
        <p tw="text-neutral-700">{error?.message}</p>
      </div>
    </OpaqueCard>
  );
};

export default ResourceError;
