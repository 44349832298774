import { useDispatch } from "react-redux";
import { useNavigate } from "react-router";

import { useMutation, useQueryClient } from "@tanstack/react-query";

import { OrderSet } from "@models/OrderSet";
import { setDraftOrderId } from "@redux/slices/globalStateSlice";
import client, { useMutateError } from "@services/api";

import useOrderSetId from "../../useOrderSetId";
import { orderSetsKeyFactory } from "../orderSetQueries";

export default function useSubmitOrderSetMutation() {
  const setError = useMutateError();
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  const orderSetId = useOrderSetId();
  const navigate = useNavigate();

  return useMutation({
    mutationFn: (submittedForId: string | void) => {
      const body = submittedForId
        ? { "submitted-for-id": +submittedForId }
        : {};
      return client.post<OrderSet>(`order-sets/${orderSetId}/submit`, body, {
        axiosOptions: { timeout: 10 * 60_1000 },
        serializeBody: false,
      });
    },
    onSuccess: () => {
      // navigate first, otherwise the component re-renders first and its navigate clause gets triggered first
      navigate(`/order-sets/${orderSetId}/overview?action=submit`, {
        replace: true,
      });

      let orderSet;
      queryClient.setQueryData(
        orderSetsKeyFactory.detail(orderSetId).queryKey,
        (os: OrderSet) => {
          orderSet = os;
          return { ...os, status: "submitted" };
        }
      );
      dispatch(setDraftOrderId({ orderType: orderSet.type, id: null }));
      queryClient.invalidateQueries({
        queryKey: orderSetsKeyFactory.paginated._def,
      });
    },
    onError: (err) => {
      setError(err, "Submit Order Set");
      queryClient.invalidateQueries({
        queryKey: orderSetsKeyFactory.detail(orderSetId).queryKey,
      });
    },
  });
}
