import { axiosGet } from "src/api/axiosCalls";

import { createSlice } from "@reduxjs/toolkit";

import { setError } from "../errorSlice";
import { mapCountries } from "../user/mapCountries";
import { mapTerritories } from "./maps";

let initialState = {
  isLoading: false,
  isUpdateLoading: false,
  isStatesLoading: false,
  territoryList: [],
  filteredTerritoryList: [],
  countries: [],
  updateStatus: false,
  error: null,
};

const territorySlice = createSlice({
  name: "territories",
  initialState,
  reducers: {
    setIsLoading(state) {
      state.isLoading = true;
    },
    getTerritoriesSuccess(state, action) {
      const { territories } = action.payload;
      state.filteredTerritoryList = territories;
      state.isLoading = false;
      state.error = null;
    },
    getAllTerritoriesSuccess(state, action) {
      const { territories, countries } = action.payload;
      state.territoryList = territories;
      if (countries) {
        state.countries = countries;
      }
      state.isLoading = false;
      // state.error = null;
    },
    clearTerritories(state) {
      state.isLoading = false;
      state.isUpdateLoading = false;
      state.territoryList = [];
      state.filteredTerritoryList = [];
      state.updateStatus = false;
      state.error = null;
    },
    setFailure(state, action) {
      const { error } = action.payload;
      state.isLoading = false;
      state.isUpdateLoading = false;
      state.error = error;
    },
  },
});

export const {
  setIsLoading,
  getTerritoriesSuccess,
  getAllTerritoriesSuccess,
  clearTerritories,
  setFailure,
} = territorySlice.actions;

export default territorySlice.reducer;

export const fetchTerritories = () => async (dispatch) => {
  try {
    dispatch(setIsLoading());
    const response = await axiosGet("/api/territories");
    if (response.error) throw response.error;
    const mappedData = mapTerritories(response.data);
    const countryResponse = await axiosGet("/api/countries");
    if (countryResponse.error) throw countryResponse.error;
    const mappedCountries = mapCountries(countryResponse.data);
    dispatch(
      getAllTerritoriesSuccess({
        territories: mappedData,
        countries: mappedCountries,
      })
    );
  } catch (err) {
    dispatch(setFailure({ error: err.toString() }));
    dispatch(setError({ error: err.toString(), source: "Territories" }));
  }
};

export const fetchTerritoriesByName = (name) => async (dispatch) => {
  try {
    dispatch(setIsLoading());
    const response = await axiosGet(`/api/territories?filter[name]=${name}`);
    if (response.error) {
      throw response.error;
    }
    dispatch(
      getTerritoriesSuccess({
        territories: response.data.map((t) => ({ id: t.id, name: t.name })),
      })
    );
  } catch (err) {
    dispatch(setFailure({ error: err.toString() }));
    dispatch(setError({ error: err.toString(), source: "Territories" }));
  }
};
