import "twin.macro";

import { useSuppliersListQuery } from "@features/suppliers";

import { useFilterContext } from "../filterContext";
import Chip from "./Chip";
import ControlledFilterAutocomplete from "./ControlledFilterAutocomplete";

const useSuppliersQuery = () => {
  return useSuppliersListQuery({
    sort: "name",
    filter: { assignedToOrg: true },
  });
};

const SupplierPopper = () => {
  const { control } = useFilterContext();
  const { data: suppliers = [], isLoading } = useSuppliersQuery();

  return (
    <ControlledFilterAutocomplete
      name="suppliers"
      options={suppliers}
      control={control}
      inputPlaceholder="Filter Suppliers"
      isLoading={isLoading}
    />
  );
};

const SupplierFilterChip = (props) => {
  const { data: suppliers = [] } = useSuppliersQuery();
  const { filterValues } = useFilterContext();

  const selectedSuppliers = filterValues["suppliers"] ?? [];

  return (
    <Chip {...props}>
      <span tw="text-neutral-600">Supplier is&nbsp;</span>
      <span tw="text-neutral-800 font-medium">
        {[...selectedSuppliers]
          .splice(0, 2)
          .map((id) => suppliers.find((supp) => supp.id === id)?.name)
          .filter(Boolean)
          .join(", ")}
        {selectedSuppliers.length > 2 && (
          <span>, + {selectedSuppliers.length - 2}</span>
        )}
      </span>
    </Chip>
  );
};

const SupplierFilter = {
  Chip: SupplierFilterChip,
  Popper: SupplierPopper,
};

export default SupplierFilter;
