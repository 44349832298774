import "twin.macro";

import { useFormContext, useWatch } from "react-hook-form";

import { Close } from "@mui/icons-material";
import { Button, IconButton, Typography } from "@mui/material";

import { StyledButton } from "../../../components/StyledComponents";
import {
  CldImage,
  getResourceUrl,
  useOpenCloudinaryWidget,
} from "../../../services/cloudinary";
import BlockCard from "../../ui/BlockCard";

const dirty = {
  shouldDirty: true,
};

const Image = () => {
  const { setValue } = useFormContext();
  const openCloudinaryWidget = useOpenCloudinaryWidget();
  const cloudinaryId = useWatch({ name: "cloudinaryId" });
  const additionalFileCloudinaryId = useWatch({
    name: "additionalFileCloudinaryId",
  });
  const additionalFileResourceType = useWatch({
    name: "additionalFileResourceType",
  });
  const additionalFileName = useWatch({ name: "additionalFileName" });

  const handleUpload = (type) =>
    openCloudinaryWidget({
      onSuccess: (file) => {
        if (type === "image") {
          setValue("cloudinaryId", file.info.public_id, { shouldDirty: true });
        } else {
          setValue("additionalFileCloudinaryId", file.info.public_id, dirty);
          setValue(
            "additionalFileName",
            file.info.original_filename +
              "." +
              file.info.path.split(".").at(-1),
            dirty
          );
          setValue(
            "additionalFileResourceType",
            file.info.resource_type,
            dirty
          );
        }
      },
    });

  const handleResetAdditionalFile = () => {
    setValue("additionalFileCloudinaryId", null, dirty);
    setValue("additionalFileName", null, dirty);
    setValue("additionalFileResourceType", null, dirty);
  };

  return (
    <BlockCard title="Image" tw="flex flex-col items-start gap-3">
      {cloudinaryId && (
        <>
          <div
            tw="relative w-full max-w-[220px] aspect-square"
            className="group"
          >
            <CldImage
              tw="w-full h-full object-cover object-center rounded-lg border-2 border-neutral-900/10"
              size="medium"
              publicId={cloudinaryId}
            />
            <IconButton
              title="Remove Image"
              tw="absolute top-1 right-1 opacity-0 group-hover:opacity-100"
              size="small"
              onClick={() =>
                setValue("cloudinaryId", null, { shouldDirty: true })
              }
            >
              <Close />
            </IconButton>
          </div>
          <Button
            variant="text"
            tw="normal-case font-normal text-neutral-600"
            onClick={() => handleUpload("image")}
          >
            Change Image
          </Button>
        </>
      )}
      {!cloudinaryId && (
        <div>
          <h3 tw="text-neutral-600 pb-2">No image uploaded</h3>
          <StyledButton cta onClick={() => handleUpload("image")}>
            Upload Image
          </StyledButton>
        </div>
      )}
      <div tw="max-w-full flex flex-wrap gap-x-3 items-baseline">
        {additionalFileName && <h3 tw="text-neutral-600">Additional File:</h3>}

        {additionalFileName ? (
          <div tw="flex items-center max-w-full" className="group">
            <Typography noWrap>
              <a
                tw="hover:underline"
                href={getResourceUrl(additionalFileCloudinaryId, {
                  resourceType: additionalFileResourceType,
                })}
                download={additionalFileName}
                target="_blank"
                rel="noreferrer"
              >
                {additionalFileName}
              </a>
            </Typography>
            <IconButton
              title="Remove File"
              tw="opacity-0 group-hover:opacity-100 shrink-0"
              size="small"
              onClick={handleResetAdditionalFile}
            >
              <Close tw="text-lg" />
            </IconButton>
          </div>
        ) : (
          <StyledButton
            outlined
            tw="normal-case font-normal text-neutral-600"
            onClick={() => handleUpload("file")}
          >
            Upload Additional File
          </StyledButton>
        )}
      </div>
    </BlockCard>
  );
};

export default Image;
