import "twin.macro";

import React from "react";

import { Skeleton } from "@mui/material";

import { Contained } from "@components/StyledComponents";
import { Title } from "@features/ui";

const OrderSetOverviewSkeleton = () => {
  return (
    <Contained tw="pb-6 max-w-screen-xl">
      <Title backUrl="../">
        <Skeleton variant="text" tw="w-64 h-16" />
      </Title>
      <div tw="grid lg:grid-cols-[2fr 1fr] gap-6 max-w-screen-xl">
        <div tw="space-y-6">
          {Array(3)
            .fill(null)
            .map((_, i) => (
              <Skeleton key={`skeleton-${i}`} variant="rounded" height={80} />
            ))}
        </div>
        <div tw="flex flex-col gap-6 relative">
          <Skeleton variant="rounded" height={320} />
        </div>
      </div>
    </Contained>
  );
};

export default OrderSetOverviewSkeleton;
