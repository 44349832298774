import { PropsWithChildren } from "react";

import { FilterContext, FilterContextProps } from "./filterContext";

const FilterContextProvider: React.FC<
  PropsWithChildren<FilterContextProps>
> = ({ children, ...props }) => {
  return (
    <FilterContext.Provider value={props}>{children}</FilterContext.Provider>
  );
};

export default FilterContextProvider;
