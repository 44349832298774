import "twin.macro";

import { useDispatch, useSelector } from "react-redux";

import { kebabCase } from "lodash";

import useConfirm from "@features/confirm";
import {
  sendChangeNotification,
  updatePurchaseOrder,
} from "@redux/slices/purchaseOrders/purchaseOrderSlice";

import { OpaqueCard, StyledButton } from "../StyledComponents";
import DebounceTextField from "../Utility/DebounceTextField";

const TextOnlyField = ({ label, value }) => (
  // Display newlines in the value
  <p tw="whitespace-pre-line">
    <b>{label}: </b>
    {value ?? "---"}
  </p>
);

const Field = ({ canEdit, ...props }) => {
  if (!canEdit)
    return <TextOnlyField label={props.label} value={props.value} />;

  return <DebounceTextField {...props} />;
};

const PurchaseOrderSupplierForm = ({
  handleUpdateStatus,
  handleCancelOpen,
}) => {
  const dispatch = useDispatch();
  const confirm = useConfirm();

  const { role } = useSelector((state) => state.currentUser);
  const { currentPurchaseOrder: purchaseOrder, isUpdateLoading } = useSelector(
    (state) => state.purchaseOrder
  );

  const markAsReceived = async () => {
    const confirmed = await confirm(
      "Are you sure you want to mark this order as received?"
    );
    if (!confirmed) return;
    handleUpdateStatus("received");
  };

  const { requiresPurchasingAgent, type } = purchaseOrder;

  const roleIs = (...args) => args.includes(role);
  const isSupplierRole = roleIs("supplier", "select-account-manager");

  const register = (name) => ({
    value: purchaseOrder[name],
    id: purchaseOrder.id,
    setter: updatePurchaseOrder,
    attr: kebabCase(name),
    fullWidth: true,
  });

  return (
    <div tw="flex flex-col lg:flex-row gap-6">
      <OpaqueCard tw="p-6 space-y-3 flex-1">
        <DebounceTextField
          {...register("changeNote")}
          label="Change Note"
          multiline
          minRows={4}
        />
        <p tw="text-sm">
          <em>
            * Keep track of any changes made to the purchase order here, both
            the purchaser and supplier see the change notes.
          </em>
        </p>

        <div tw="flex gap-4">
          <StyledButton
            cta
            onClick={() => dispatch(sendChangeNotification(purchaseOrder.id))}
            disabled={isUpdateLoading}
          >
            SEND CHANGE UPDATE
          </StyledButton>
          {!requiresPurchasingAgent && (
            <>
              <StyledButton cta onClick={() => handleUpdateStatus("complete")}>
                COMPLETE
              </StyledButton>
              {!isSupplierRole && (
                <StyledButton cta onClick={() => handleCancelOpen()}>
                  CANCEL
                </StyledButton>
              )}
            </>
          )}
        </div>
      </OpaqueCard>
      {(requiresPurchasingAgent || type === "inventory") && (
        <OpaqueCard tw="p-6 space-y-3 flex-1">
          <Field
            canEdit={isSupplierRole}
            {...register("inboundTrackingNumber")}
            label="Inbound Tracking Number"
          />
          <Field
            canEdit={isSupplierRole}
            {...register("inboundCarrier")}
            label="Inbound Carrier"
          />
          {requiresPurchasingAgent && (
            <>
              {isSupplierRole && (
                <p tw="text-sm text-primary-800">
                  The Purchasing Agent will be notified when you enter the
                  tracking information.
                </p>
              )}

              {!isSupplierRole && (
                <div tw="flex gap-4">
                  <StyledButton cta onClick={markAsReceived}>
                    Mark As Received
                  </StyledButton>
                  <StyledButton cta onClick={() => handleCancelOpen()}>
                    CANCEL
                  </StyledButton>
                </div>
              )}
            </>
          )}
        </OpaqueCard>
      )}
    </div>
  );
};

export default PurchaseOrderSupplierForm;
