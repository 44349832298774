import tw from "twin.macro";

import { useState } from "react";
import { useFormContext, useWatch } from "react-hook-form";

import { ArrowDropDown } from "@mui/icons-material";
import {
  Button,
  Checkbox,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Menu,
} from "@mui/material";

import _ from "lodash";

import { useVariantCategoriesListQuery } from "@features/variantCategories";
import { useVariantOptionsListQuery } from "@features/variantOptions";

import { variantOptionIdsFromHash } from "../../../helpers";

const LinkButton = tw(
  Button
)`underline normal-case font-normal text-base text-primary-800 hover:(underline bg-transparent) min-w-0 px-2`;

const CategoryOptionsDropdown = ({
  variantCategory,
  selectedVariants,
  setSelectedVariants,
}) => {
  const { data: allVariantOptions } = useVariantOptionsListQuery({
    filter: { "variant-category-id": variantCategory.id },
  });
  const [anchorEl, setAnchorEl] = useState<Element | null>(null);
  const variants = useWatch({ name: "variants" });
  const variantCategoryOptionIds = useWatch({
    name: `variantOptions.${variantCategory.id}`,
  });
  const allVariantHashes = Object.keys(variants);

  const hashesWithOption = (optionId: string) =>
    allVariantHashes.filter((hash) =>
      variantOptionIdsFromHash(hash).includes(optionId)
    );

  const isChecked = (optionId: string, hashes = hashesWithOption(optionId)) => {
    return _.intersection(selectedVariants, hashes).length === hashes.length;
  };

  const handleToggle = (optionId: string) => {
    const hashes = hashesWithOption(optionId);
    const checked = isChecked(optionId, hashes);
    if (checked) {
      setSelectedVariants(_.xor(selectedVariants, hashes));
    } else {
      setSelectedVariants(_.uniq([...selectedVariants, ...hashes]));
    }
  };

  return (
    <div>
      <LinkButton
        endIcon={<ArrowDropDown tw="-mx-1" />}
        size="small"
        onClick={(e) => setAnchorEl(e.target as any)}
      >
        {variantCategory.name}
      </LinkButton>
      {anchorEl && (
        <Menu open anchorEl={anchorEl} onClose={() => setAnchorEl(null)}>
          {allVariantOptions
            ?.filter(({ id }) => variantCategoryOptionIds.includes(id))
            .map((option) => (
              <ListItem key={option.id} disablePadding>
                <ListItemButton dense onClick={() => handleToggle(option.id)}>
                  <ListItemIcon tw="min-w-0">
                    <Checkbox
                      tw="-my-2"
                      size="small"
                      edge="start"
                      checked={isChecked(option.id)}
                    />
                  </ListItemIcon>
                  <ListItemText primary={option.name} />
                </ListItemButton>
              </ListItem>
            ))}
        </Menu>
      )}
    </div>
  );
};

const BulkVariantSelection = ({ selectedVariants, setSelectedVariants }) => {
  const { getValues } = useFormContext();
  const variantOptions = useWatch({ name: "variantOptions" });
  const variantCategoryIds = Object.keys(variantOptions);
  const { data: variantCategories } = useVariantCategoriesListQuery();

  const handleSelectNone = () => setSelectedVariants([]);
  const handleSelectAll = () => {
    const variants = getValues().variants;
    setSelectedVariants(
      Object.keys(variants).filter((vId) => !variants[vId].isDefault)
    );
  };
  if (!variantCategories) return null;

  return (
    <div tw="flex items-center gap-2">
      <div tw="text-neutral-700">Select:</div>
      <LinkButton size="small" onClick={handleSelectAll}>
        All
      </LinkButton>
      <LinkButton size="small" onClick={handleSelectNone}>
        None
      </LinkButton>
      {variantCategoryIds.map((vcId) => {
        const variantCategory = variantCategories.find(({ id }) => id === vcId);
        return (
          <CategoryOptionsDropdown
            key={vcId}
            {...{ variantCategory, selectedVariants, setSelectedVariants }}
          />
        );
      })}
    </div>
  );
};

export default BulkVariantSelection;
