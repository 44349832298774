import "twin.macro";

import { useState } from "react";
import { useSelector } from "react-redux";

import { MenuItem } from "@mui/material";
import TextField from "@mui/material/TextField";

import { AddressSearch } from "@features/addresses";
import {
  useRemoveFavoriteAddressesMutation,
  useUpdateFavoriteAddressesMutation,
} from "@features/addresses/";

import { useInput } from "../../../hooks/inputs/useInput";
import MoreOptions from "../../Utility/OptionsMenu";
import AddressTable from "./AddressTable";

const MemoNameInput = ({ addressList, currentTerritoryId }) => {
  const {
    value: name,
    bind: bindName,
    setChange: setNameChange,
    change: nameChange,
  } = useInput(addressList.name);

  const updateFavoriteAddressesSet = useUpdateFavoriteAddressesMutation();

  const handleUpdateName = (newName, faveListId) => {
    updateFavoriteAddressesSet.mutate({
      id: faveListId,
      name: newName,
      addresses: addressList.addresses.map((address) => address.id),
      territoryId: currentTerritoryId,
    });
  };

  return (
    <TextField
      style={{ width: "45%" }}
      size="small"
      variant="outlined"
      color="secondary"
      name="listName"
      type="text"
      label="List Name"
      value={name}
      {...bindName}
      onBlur={() => {
        if (nameChange) {
          handleUpdateName(name, addressList.id);
          setNameChange(false);
        }
      }}
    />
  );
};

const FavoriteAddressList = ({ addressList }) => {
  const currentTerritoryId = useSelector(
    (state) => state.currentUser.currentTerritoryId
  );

  const [reset, setReset] = useState(false);

  const updateFavoriteAddressesSet = useUpdateFavoriteAddressesMutation();
  const removeFavoriteAddressesSet = useRemoveFavoriteAddressesMutation();

  const updateFaveListAddresses = (faveListId, newAddressArray) => {
    updateFavoriteAddressesSet.mutate({
      id: faveListId,
      addresses: newAddressArray,
      territoryId: currentTerritoryId,
    });
  };

  const handleUpdate = (value, faveListId) => {
    const newAddressArray = [
      ...addressList.addresses.map((address) => address.id),
      ...value.map((address) => address.id),
    ];
    updateFaveListAddresses(faveListId, newAddressArray);
  };

  const handleDelete = (faveListId) => {
    removeFavoriteAddressesSet.mutate({ id: faveListId });
  };
  return (
    <div>
      <div tw="flex items-center gap-2 mb-4">
        <MemoNameInput
          addressList={addressList}
          currentTerritoryId={currentTerritoryId}
        />
        <AddressSearch
          multiple
          validateTerritory
          renderTags={() => null}
          reset={reset}
          setReset={setReset}
          getOptionDisabled={(option) =>
            addressList.addresses.some((a) => a.id === option.id)
          }
          value={addressList.addresses}
          onChange={(e) => {
            handleUpdate(e.slice(-1), addressList.id);
          }}
        />
        <MoreOptions tw="text-2xl ml-auto flex-none">
          <MenuItem
            onClick={() => {
              handleDelete(addressList.id);
            }}
          >
            Delete List
          </MenuItem>
        </MoreOptions>
      </div>
      <AddressTable
        addressList={addressList}
        updateFaveListAddresses={updateFaveListAddresses}
        isValidating={updateFavoriteAddressesSet.isPending}
      />
    </div>
  );
};

export default FavoriteAddressList;
