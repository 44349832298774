import "twin.macro";

import { useEffect, useState } from "react";
import { useTransition } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router";

import makeStyles from "@mui/styles/makeStyles";

import FavoriteAddresses from "@components/Profile/Addresses/FavoriteAddresses";
import EmailPreferences from "@components/Profile/Emails/EmailPreferences";
import General from "@components/Profile/General/General";
import FavoriteItems from "@components/Profile/Items/FavoriteItems";
import { Contained, StyledTab, StyledTabs } from "@components/StyledComponents";
import OrderPatchLoading from "@components/Utility/OrderPatchLoading";
import { AddressBook } from "@features/addresses";
import DocTitle from "@utility/DocTitle";
import permissions from "@utils/permissions";

import { useNoFetch } from "../hooks/useNoFetch";

/*
The profile view has multiple tabs that control what is currently being viewed. The user has access
to their general account info, address information, and their favorite items.
*/

const useStyles = makeStyles((theme) => ({
  ...theme.global,
}));

const indexMap = [
  "general",
  "addressBook",
  "favoriteAddresses",
  "favoriteItems",
  "emailPreferences",
];
const Profile = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { type } = useParams();
  const [_isPending, startTransition] = useTransition();

  const [selectedIndex, setSelectedIndex] = useState(0);

  const handleChangeTab = (index) =>
    startTransition(() => {
      setSelectedIndex(index);
    });

  useEffect(() => {
    setSelectedIndex(indexMap.indexOf(type));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [type]);

  useNoFetch(dispatch);

  return (
    <div style={{ "--space-after-header": "58px" }}>
      <DocTitle title={"Profile"} />
      <OrderPatchLoading />
      <Contained tw="mb-4">
        <StyledTabs
          value={selectedIndex}
          onChange={(_e, val) => handleChangeTab(val)}
        >
          <StyledTab label="General" to="/profile/general" />
          <StyledTab
            label="Address Book"
            to="/profile/addressBook"
            allow={permissions.userSettingsOrdering}
          />
          <StyledTab
            id="tutorial-favorite-addresses"
            className="tutorial-highlight"
            label="Favorite Addresses"
            to="/profile/favoriteAddresses"
            allow={permissions.userSettingsOrdering}
          />
          <StyledTab
            label="Favorite Items"
            to="/profile/favoriteItems"
            allow={permissions.userSettingsOrdering}
          />
          <StyledTab
            label="Email Preferences"
            to="/profile/emailPreferences"
            allow={[...permissions.admin, "approver"]}
          />
        </StyledTabs>
      </Contained>
      {selectedIndex === 0 && <General />}
      {selectedIndex === 1 && <AddressBook />}
      {selectedIndex === 2 && <FavoriteAddresses />}
      {selectedIndex === 3 && <FavoriteItems classes={classes} />}
      {selectedIndex === 4 && <EmailPreferences />}
    </div>
  );
};

Profile.propTypes = {};

export default Profile;
