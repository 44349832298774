import tw from "twin.macro";

import { useState } from "react";
import { useDispatch } from "react-redux";

import {
  CheckRounded,
  CloseRounded,
  DownloadRounded,
  SummarizeRounded,
  WarningRounded,
} from "@mui/icons-material";
import { CircularProgress, IconButton, Tooltip } from "@mui/material";

import { StyledButton } from "@components/StyledComponents";

import { setReportStatus } from "./globalReportSlice";
import { ReportStatus, TReportState } from "./types";

const ReportRow = ({
  filename,
  report,
  error,
  percentLoaded,
  onDownload,
}: {
  filename: string;
  report: TReportState;
  error?: any;
  percentLoaded: number;
  onDownload: () => Promise<void>;
}) => {
  const dispatch = useDispatch();
  const [downloadLoading, setDownloadLoading] = useState(false);
  const handleDownload = async () => {
    setDownloadLoading(true);
    await onDownload();
    setDownloadLoading(false);
  };
  return (
    <div css={report.status === ReportStatus.ABORTED && tw`opacity-50`}>
      <div tw="flex gap-4 py-3 items-start justify-between">
        <div tw="flex gap-2 items-start overflow-hidden">
          <SummarizeRounded tw="text-primary-900" />
          <div tw="text-neutral-600 leading-tight" title={filename}>
            {filename}
          </div>
        </div>
        <div tw="flex items-center">
          {report.status === ReportStatus.LOADING && (
            <>
              <div tw="bg-neutral-100 rounded-full py-1 px-3 flex items-center gap-2 text-sm font-medium text-neutral-600">
                <div>{Math.ceil(percentLoaded * 100)}%</div>
                <CircularProgress size={16} />
              </div>

              <Tooltip title="Cancel">
                <IconButton
                  size="small"
                  onClick={() =>
                    dispatch(
                      setReportStatus({
                        id: report.id,
                        status: ReportStatus.ABORTED,
                      })
                    )
                  }
                >
                  <CloseRounded tw="text-base" />
                </IconButton>
              </Tooltip>
            </>
          )}
          {(report.status === ReportStatus.COMPLETE ||
            report.status === ReportStatus.DOWNLOADED) && (
            <StyledButton
              outlined
              endIcon={
                report.status === ReportStatus.COMPLETE ? (
                  <DownloadRounded />
                ) : (
                  <CheckRounded />
                )
              }
              tw="rounded-full text-xs mb-1 py-1 px-3"
              onClick={handleDownload}
              loading={downloadLoading}
            >
              {report.status === ReportStatus.COMPLETE
                ? "Download"
                : "Downloaded"}
            </StyledButton>
          )}
          {report.status === ReportStatus.FAILED && (
            <div tw="flex gap-1 items-center px-3">
              <WarningRounded tw="text-red-600 text-base" />
              <div tw="text-red-900 text-sm">ERROR</div>
            </div>
          )}
          {report.status === ReportStatus.ABORTED && (
            <div tw="px-3">
              <div tw="text-neutral-700 text-sm">ABORTED</div>
            </div>
          )}
        </div>
      </div>
      {report.status === ReportStatus.FAILED && (
        <div tw="bg-red-50 text-sm text-red-900 p-4 whitespace-pre-wrap">
          {error?.message || error}
        </div>
      )}
    </div>
  );
};

export default ReportRow;
