import "twin.macro";

import React from "react";

import { Tooltip, Typography } from "@mui/material";

import { Item } from "@models/Item";
import { Variant } from "@models/Variant";

import SearchableText from "../../../components/Table/SearchableText";

type WithItem = {
  item: Pick<Item, "sku" | "defaultExternalWarehouseId">;
  variant?: never;
};
type WithVariant = {
  item?: never;
  variant: Pick<Variant, "variantSku" | "externalWarehouseId">;
};

type ItemIdentifiersProps = (WithItem | WithVariant) & {
  query?: string;
};

const DottedInfoText = ({ title, children }) => {
  return (
    <Tooltip title={title}>
      <span tw="decoration-dashed underline underline-offset-4 decoration-neutral-300">
        {children}
      </span>
    </Tooltip>
  );
};

const ItemIdentifiers = ({ item, variant, query }: ItemIdentifiersProps) => {
  const externalIdentifier = item
    ? item.defaultExternalWarehouseId
    : variant.externalWarehouseId;
  return (
    <Typography
      tw="space-x-2 items-center text-sm text-primary-600 w-full"
      noWrap
    >
      <DottedInfoText title="brandhub generated SKU">
        <SearchableText
          text={item ? item.sku : variant.variantSku}
          query={query}
        />
      </DottedInfoText>
      {externalIdentifier && <span tw="text-neutral-600">/</span>}
      {externalIdentifier && (
        <DottedInfoText title="Customer provided identifier">
          <SearchableText text={externalIdentifier} query={query} />
        </DottedInfoText>
      )}
    </Typography>
  );
};

export default ItemIdentifiers;
