import React, { useMemo, useState } from "react";
import type { ReactNode } from "react";

import { Table, TableBody, TableContainer } from "@mui/material";

import {
  DndContext,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import type { Active, UniqueIdentifier } from "@dnd-kit/core";
import { restrictToVerticalAxis } from "@dnd-kit/modifiers";
import {
  SortableContext,
  arrayMove,
  sortableKeyboardCoordinates,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";

import { DragHandle, SortableItem, SortableOverlay } from "./components";

interface BaseItem {
  id: UniqueIdentifier;
  [key: string]: any;
}

interface Props<T extends BaseItem> {
  items: T[];
  onChange(items: T[]): void;
  renderItem(item: T, isDragging: boolean): ReactNode;
  head(): ReactNode;
  className?: string;
}

export function SortableTable<T extends BaseItem>({
  items,
  onChange,
  renderItem,
  head,
  className,
}: Props<T>) {
  const [active, setActive] = useState<Active | null>(null);
  const activeItem = useMemo(
    () => items.find((item) => item.id === active?.id),
    [active, items]
  );
  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );

  return (
    <DndContext
      sensors={sensors}
      modifiers={[restrictToVerticalAxis]}
      onDragStart={({ active }) => {
        setActive(active);
      }}
      onDragEnd={({ active, over }) => {
        if (over && active.id !== over?.id) {
          const activeIndex = items.findIndex(({ id }) => id === active.id);
          const overIndex = items.findIndex(({ id }) => id === over.id);

          onChange(arrayMove(items, activeIndex, overIndex));
        }
        setActive(null);
      }}
      onDragCancel={() => {
        setActive(null);
      }}
    >
      <SortableContext items={items} strategy={verticalListSortingStrategy}>
        <TableContainer className={className}>
          <Table size="small" tw="border-separate">
            {head()}
            <TableBody>
              {items.map((item) => (
                <React.Fragment key={item.id}>
                  {renderItem(item, !!active)}
                </React.Fragment>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </SortableContext>
      <SortableOverlay>
        <Table size="small">
          <TableBody tw="cursor-grabbing!">
            {activeItem ? renderItem(activeItem, !!active) : null}
          </TableBody>
        </Table>
      </SortableOverlay>
    </DndContext>
  );
}

SortableTable.Item = SortableItem;
SortableTable.DragHandle = DragHandle;
