import tw from "twin.macro";

import { useFormContext, useWatch } from "react-hook-form";
import { useSelector } from "react-redux";

import { AddToPhotosRounded } from "@mui/icons-material";
import { Chip, IconButton, Tooltip } from "@mui/material";

import _ from "lodash";

import { useGroupCategoriesListQuery } from "@features/groupCategories";
import { BlockCard } from "@features/ui";
import {
  RequestParams,
  routeResourceTypes,
  useApiResource,
} from "@services/api";
import { ControlledAutocompleteInput } from "@utils/forms";

const Loading = tw.div`h-10 rounded-md bg-neutral-100 animate-pulse`;

type SelectResourceProps = {
  name: string;
  resource: keyof routeResourceTypes;
  requestParams: RequestParams;
  label: string;
  required?: boolean | string;
};

const SelectResource = ({
  name,
  resource,
  requestParams,
  label,
  required,
}: SelectResourceProps) => {
  const { control, setValue } = useFormContext();
  const { data } = useApiResource(resource, {
    ...requestParams,
    revalidate: false,
  });
  const entities = useWatch({ name });
  const displayEntities: any[] = _.intersectionBy(data, entities, "id");

  if (!data) return <Loading />;

  const options = _.sortBy(data, "name").map(({ id, name }) => ({
    id,
    name,
  }));

  const handleRemove = (id: string) => {
    setValue(
      name,
      entities.filter((g) => g.id !== id),
      { shouldDirty: true }
    );
  };

  return (
    <div tw="space-y-3">
      <div tw="flex items-center gap-2">
        <ControlledAutocompleteInput
          tw="flex-1"
          multiple
          label={label + (!!required ? " *" : "")}
          name={name}
          control={control}
          options={options}
          renderTags={() => null}
          rules={{ required }}
        />
        <Tooltip title={`Add all ${label}`}>
          <IconButton
            onClick={() =>
              setValue(name, options, {
                shouldValidate: true,
                shouldDirty: true,
              })
            }
          >
            <AddToPhotosRounded />
          </IconButton>
        </Tooltip>
      </div>
      <div tw="flex flex-wrap gap-2">
        {displayEntities?.map((ent) => (
          <Chip
            size="small"
            key={ent.id}
            label={ent.name}
            onDelete={() => handleRemove(ent.id)}
          />
        ))}
        {displayEntities.length === 0 && (
          <div tw="text-sm text-neutral-700">No {label} selected</div>
        )}
      </div>
    </div>
  );
};

const UserFormVisibility = () => {
  const {
    organization: { usesChannels },
  } = useSelector((state: any) => state.currentUser);
  const { data: groupCategories } = useGroupCategoriesListQuery();

  const groupCategoryThatLimitsAvailability = groupCategories?.filter(
    (gc) => gc.limitsAvailability
  );
  const showGroupCategories = !!groupCategoryThatLimitsAvailability?.length;

  return (
    <BlockCard title="Visibility" tw="space-y-6">
      {usesChannels && (
        <SelectResource
          name="channels"
          resource="channels"
          requestParams={{}}
          label="Channels"
          required="At least one channel is required."
        />
      )}
      <SelectResource
        name="territories"
        resource="territories"
        requestParams={{}}
        label="Territories"
        required="At least one territory is required."
      />
      {showGroupCategories && (
        <>
          {groupCategoryThatLimitsAvailability.map((gc) => (
            <SelectResource
              name="groups"
              resource="groups"
              requestParams={{ filter: { "group-category-id": gc.id } }}
              label={gc.name}
              key={gc.id}
            />
          ))}
        </>
      )}
    </BlockCard>
  );
};

export default UserFormVisibility;
