import "twin.macro";

import { useState } from "react";
import { useSelector } from "react-redux";

import { Alert } from "@mui/material";

import { CardElement, useElements, useStripe } from "@stripe/react-stripe-js";

import { LoadingButton } from "@features/ui";

const StripeForm = ({
  onSuccess,
}: {
  onSuccess: (paymentId: string) => Promise<void>;
}) => {
  const userName = useSelector((state: any) => state.currentUser.name);
  const stripe = useStripe();
  const elements = useElements();

  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [loading, setLoading] = useState(false);

  const handleSubmit = async (event) => {
    try {
      event.preventDefault();
      if (!stripe) return;

      if (elements == null) {
        return;
      }

      setLoading(true);

      const result = await stripe.createPaymentMethod({
        type: "card",
        card: elements.getElement(CardElement)!,
        billing_details: {
          name: userName,
        },
      });

      if (result.error) {
        // This point will only be reached if there is an immediate error when
        // confirming the payment. Show error to your customer (for example, payment
        // details incomplete)
        setErrorMessage(result.error.message ?? null);
      } else {
        await onSuccess(result.paymentMethod.id);
      }
    } catch (err) {
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <div tw="border border-neutral-300 shadow-sm rounded p-3">
        <CardElement />
      </div>
      <div tw="flex mt-4 items-start gap-3">
        <LoadingButton
          tw="flex-none"
          size="large"
          type="submit"
          variant="contained"
          disabled={!stripe || !elements}
          loading={loading}
        >
          Pay Now
        </LoadingButton>
        {/* Show error message to your customers */}
        {errorMessage && <Alert severity="error">{errorMessage}</Alert>}
      </div>
    </form>
  );
};

export default StripeForm;
