import "twin.macro";

import { useState } from "react";
import { Link, useParams } from "react-router";

import { HistoryRounded } from "@mui/icons-material";
import { Divider, Skeleton } from "@mui/material";

import {
  Contained,
  OpaqueCard,
  SectionTitle,
  StyledButton,
} from "@components/StyledComponents";
import { BudgetForm, BudgetModal, useBudgetQuery } from "@features/budgets";
import { Title } from "@features/ui";
import DocTitle from "@utility/DocTitle";
import { formatMoneyString } from "@utility/utilityFunctions";

import ErrorPage from "./ErrorPage";

const BudgetSkeleton = () => {
  return (
    <div tw="grid gap-x-4 gap-y-6 lg:grid-cols-[2fr 1fr] ">
      <Skeleton variant="rounded" height={200} tw="rounded-xl" />
      <Skeleton variant="rounded" height={200} tw="rounded-xl" />
      <Skeleton variant="rounded" height={300} tw="rounded-xl" />
    </div>
  );
};

const BudgetValue = ({
  name,
  value,
  className,
}: {
  name: string;
  value: string | number;
  className?: string;
}) => (
  <div tw="flex items-baseline justify-between" className={className}>
    <div>{name}</div>
    <div>{formatMoneyString(value)}</div>
  </div>
);

const Budget = () => {
  const { id } = useParams();
  const isNew = !id || id === "new";
  const { data: budget, error } = useBudgetQuery(isNew ? null : id);

  const [historyModalOpen, setHistoryModalOpen] = useState(false);

  const isLoading = !isNew && !budget;

  if (error) return <ErrorPage error={error} />;

  return (
    <>
      {budget && historyModalOpen && (
        <BudgetModal
          budget={budget}
          handleClose={() => setHistoryModalOpen(false)}
          hideOverview
        />
      )}
      <Contained tw="pb-6 max-w-screen-xl">
        <DocTitle title={isNew ? "New Budget" : budget?.name || "Budget"} />
        <div tw="flex justify-between items-center">
          <Title backUrl="/admin/budgets" tw="mb-6">
            {isNew ? "New Budget" : budget?.name || <Skeleton tw="w-48" />}
          </Title>
          {!isNew && (
            <StyledButton cta component={Link} to="/admin/budgets/new">
              New Budget
            </StyledButton>
          )}
        </div>
        {isLoading && <BudgetSkeleton />}
        {!isLoading && (
          <div tw="grid lg:grid-cols-[2fr 1fr] items-start gap-4">
            <BudgetForm budget={budget} id={isNew ? null : id} />
            <div tw="order-first lg:order-none">
              {budget && (
                <div>
                  <OpaqueCard tw="p-6 bg-neutral-200">
                    <SectionTitle tw="text-primary-800">
                      Budget Calculations
                    </SectionTitle>
                    <div tw="space-y-2 mt-4 text-neutral-800">
                      <BudgetValue
                        tw="text-[15px] text-neutral-700"
                        name="Total Amount"
                        value={budget.totalAmount}
                      />
                      <BudgetValue
                        tw="text-[15px] text-neutral-700"
                        name="Amount Spent"
                        value={+budget.spend && "-" + budget.spend}
                      />
                      <BudgetValue
                        tw="font-medium"
                        name="Balance"
                        value={budget.currentLevel}
                      />
                      <Divider />
                      <BudgetValue
                        tw="text-[15px] text-neutral-700"
                        name="Pending Amount"
                        value={
                          +budget.pendingSpend && "-" + budget.pendingSpend
                        }
                      />
                      <BudgetValue
                        tw="font-medium"
                        name="Pending Balance"
                        value={budget.pendingBalance}
                      />
                    </div>
                    {/* <Button
                    tw="mt-3 -ml-2 normal-case text-sm text-primary-600 hover:text-primary-800"
                    startIcon={<HistoryRounded />}
                    onClick={() => setHistoryModalOpen(true)}
                  >
                    View Budget History
                  </Button> */}
                  </OpaqueCard>
                  <StyledButton
                    outlined
                    tw="mt-3"
                    startIcon={<HistoryRounded />}
                    onClick={() => setHistoryModalOpen(true)}
                  >
                    View Budget History
                  </StyledButton>
                </div>
              )}
            </div>
          </div>
        )}
      </Contained>
    </>
  );
};

export default Budget;
