import tw from "twin.macro";

import { memo, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";

import { setSorted, updateSingleFilter } from "@redux/slices/filterSlice";
import { updateCurrentTerritory } from "@redux/slices/user/currentUserSlice";

const TerritorySelector = () => {
  const dispatch = useDispatch();

  const [territory, updateTerritory] = useState("");

  const { territories, currentTerritoryId } = useSelector(
    (state) => state.currentUser
  );

  const isOrdering = useSelector((state) => state.globalState.isOrdering);
  const isStepperLoad = useSelector(
    (state) => state.globalState.isProgStepperLoad
  );

  const handleChangeSelect = (evt) => {
    let activeTerritory = territories.find(
      (terr) => terr.name === evt.target.value
    );
    updateTerritory(evt.target.value);
    dispatch(updateCurrentTerritory({ territory: activeTerritory.id }));
    dispatch(
      updateSingleFilter({
        filter: "currentTerritoryId",
        value: activeTerritory.id,
      })
    );
    dispatch(setSorted({ sorted: true }));
  };

  useEffect(() => {
    if (territories.length > 0) {
      const currentTerritory = territories.find(
        (reg) => reg.id === currentTerritoryId
      );
      if (currentTerritory) {
        updateTerritory(currentTerritory.name);
      } else {
        // Handle the case where currentTerritory is not found
        updateTerritory(territories[0].id);
      }
    }
  }, [territories, currentTerritoryId]);

  return (
    <FormControl
      size="small"
      css={{
        ".MuiOutlinedInput-root fieldset": tw`border-none`,
      }}
    >
      <InputLabel id="territory-select" color="secondary" tw="max-w-none">
        Territory
      </InputLabel>
      <Select
        name="territories"
        labelId="territory-select"
        id="territories"
        value={territory}
        onChange={handleChangeSelect}
        disabled={isOrdering || isStepperLoad}
      >
        {territories.map((territory, index) => (
          <MenuItem value={territory.name} key={index}>
            {territory.name}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default memo(TerritorySelector);
