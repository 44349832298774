import "twin.macro";

import { useEffect, useMemo, useRef, useState } from "react";
import { Navigate } from "react-router";

import { Button, Fade } from "@mui/material";

import { FaIcon } from "@features/ui";

import { useCurrentOrderSet } from "../CurrentOrderSetContext";
import OrderSetCostOverview from "../orderSetOverview/OrderSetCostOverview";
import Checkout from "./Checkout";
import OrderDeliveryReview from "./OrderDeliveryReview";
import OrderSetItemCustomization from "./OrderSetItemCustomization";
import OrderSetPromotions from "./OrderSetPromotions";

const OrderSetCheckoutContent = () => {
  const { orders, orderSet } = useCurrentOrderSet();
  const [showScrollButton, setShowScrollButton] = useState(orders.length > 3);
  const checkoutRef = useRef<HTMLDivElement>(null);
  const scrollParentRef = useRef(document.querySelector(".body"));

  const observer = useMemo(
    () =>
      new IntersectionObserver(
        (entries) => {
          entries.forEach((entry) => {
            setShowScrollButton(!entry.isIntersecting);
          });
        },
        { rootMargin: "0px", threshold: 0.6, root: scrollParentRef.current }
      ),
    []
  );

  const handleScrollToCheckout = () => {
    scrollParentRef.current?.scrollTo({
      top: checkoutRef.current?.offsetTop,
      behavior: "smooth",
    });
  };

  useEffect(() => {
    let el: Element;
    if (checkoutRef.current) {
      el = checkoutRef.current;
      observer.observe(checkoutRef.current);
    }
    return () => {
      if (el) {
        observer.unobserve(el);
      }
    };
  }, [observer]);

  if (orderSet.status !== "draft") {
    return <Navigate to="../overview" replace />;
  }
  return (
    <div tw="grid grid-flow-dense grid-cols-1 lg:grid-cols-[minmax(0, 1fr) 320px] gap-4 lg:gap-6 relative">
      <div tw="lg:col-start-2 relative">
        <div tw="lg:sticky lg:top-[var(--header-height)] space-y-6">
          <OrderSetCostOverview />
          <Fade in={showScrollButton}>
            <Button
              variant="contained"
              color="neutral"
              onClick={handleScrollToCheckout}
              endIcon={<FaIcon icon="arrow-down" />}
            >
              Skip to Checkout
            </Button>
          </Fade>
        </div>
      </div>
      <div tw="space-y-6 pb-8">
        <OrderSetItemCustomization />
        <div tw="space-y-2">
          {orders
            .filter((o) => o.totalQuantity > 0)
            .map((order) => (
              <OrderDeliveryReview order={order} key={order.id} />
            ))}
        </div>
        <OrderSetPromotions />
        <Checkout ref={checkoutRef} />
      </div>
    </div>
  );
};

export default OrderSetCheckoutContent;
