import "twin.macro";

import { OpaqueCard } from "@components/StyledComponents";
import { useQuote } from "@features/quotes";

import CompletedQuote from "./CompletedQuote";

const PurchaserQuote = () => {
  const { quote } = useQuote();
  const isComplete = ["complete", "awarded"].includes(quote.status);
  return (
    <div tw="space-y-6">
      <OpaqueCard tw="p-6">
        {isComplete && <CompletedQuote />}
      </OpaqueCard>
    </div>
  );
};

export default PurchaserQuote;
