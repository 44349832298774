import "twin.macro";

import { InfoOutlined } from "@mui/icons-material";
import { Tooltip } from "@mui/material";

const BaseInfoIcon = ({ title }) => {
  return (
    <Tooltip title={title}>
      <InfoOutlined tw="text-base ml-2 cursor-pointer" color="secondary" />
    </Tooltip>
  );
};

export default BaseInfoIcon;
