import { useMemo } from "react";
import { useSelector } from "react-redux";

import permissions from "@utils/permissions";
import useRoleIs from "@utils/useRoleIs";

import { useOrderWindowsListQuery } from "./orderWindowsQueries";

const useOrderableOrderWindowsQuery = () => {
  const roleIs = useRoleIs();
  const channelId = useSelector(
    (state: any) => state.currentUser.currentChannelId
  );
  const territoryId = useSelector(
    (state: any) => state.currentUser.currentTerritoryId
  );

  const { data = [], isPending } = useOrderWindowsListQuery(
    { filter: { isActive: true } },
    { enabled: roleIs(permissions.internalRoles) }
  );

  const filteredData = useMemo(
    () =>
      data.filter(
        (w) =>
          w.territories.some((t) => t.id === territoryId) &&
          (!channelId || w.channel?.id === channelId)
      ),
    [channelId, territoryId, data]
  );

  return {
    data: filteredData,
    isPending,
  };
};

export default useOrderableOrderWindowsQuery;
