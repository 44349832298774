import "twin.macro";

import _ from "lodash";

import {
  FixedHeightScrollLastChild,
  OpaqueCard,
} from "@components/StyledComponents";
import Filters, { useFilterParams } from "@features/filters";
import { HistoricalOrderTable } from "@features/orderHistory";
import { RequestReportButton, useReport } from "@features/reports";
import { Title } from "@features/ui";
import { useApiResourcePaginated } from "@services/api";
import DocTitle from "@utility/DocTitle";

const defaultFilters = {
  sort: "-order-date",
};
const OrderArchive = () => {
  const [filters] = useFilterParams();

  const params = {
    filter: {
      searchTerm: filters.q,
      orderType: filters.orderType,
      fromDate: filters.fromDate,
      toDate: filters.toDate,
    },
    sort: filters.sort,
  };

  const { data, ...tableProps } = useApiResourcePaginated(
    "historical-orders" as any,
    {
      params,
      keepPreviousData: true,
      revalidateOnFocus: false,
    }
  );

  useReport("historical-orders", [
    "historical-orders",
    // historical-orders' response is small so we can load a large page size
    _.set(params, "page.size", 500),
  ]);

  return (
    <>
      <DocTitle title={"Order Archive"} />
      <FixedHeightScrollLastChild>
        <div tw="flex justify-between items-center">
          <Title backUrl="/orders/history">Orders Archive</Title>
          <RequestReportButton reportName="historical-orders" />
        </div>
        <Filters
          searchTitle="Search Orders"
          slots={["dateRange", "orderType"]}
          slotProps={{
            dateRange: {
              title: "Order was placed",
              chipTextPrefix: "Placed ",
            },
          }}
          defaultValues={defaultFilters}
          alwaysShow={["dateRange"]}
        />
        <OpaqueCard tw="p-0 overflow-hidden relative">
          <HistoricalOrderTable
            data={data ?? []}
            query={filters.q}
            {...tableProps}
          />
        </OpaqueCard>
      </FixedHeightScrollLastChild>
    </>
  );
};

export default OrderArchive;
