import { Transformer } from "@services/api";

import { AsyncResource } from "./AsyncResource";
import { RequestForQuote } from "./RequestForQuote";

export type QuoteStatus =
  | "sent"
  | "accepted"
  | "declined"
  | "complete"
  | "awarded"
  | "canceled";

export interface Quote {
  id: string;
  brandingArea: string | null;
  colorOptions: string | null;
  decorationOptions: string | null;
  declineReason: string | null;
  description: string | null;
  ideas: string | null;
  insertedAt: Date;
  itemDimensions: null;
  isComplete: boolean;
  moq: number;
  preProCost: number | null;
  preProLeadTimeInDays: number | null;
  pricingBasedOn: string | null;
  status: QuoteStatus;
  supplierName: string;
  supplierQuoteNumber: string | null;
  updatedAt: Date;
  costs: QuoteCost[];
  supplier: AsyncResource<"supplier">;
  requestForQuote: RequestForQuote;
  pricingTierQuotes: PricingTierQuote[];
  relationshipNames: string[];
}

export interface QuoteCost {
  id: string;
  amount: number;
  name: string;
  quote: AsyncResource<"quote"> | Quote;
}

export interface PricingTierQuote {
  id: string;
  cost: string;
  leadTimeInDays: number;
  pricingTierQty: number;
  pricingTier: AsyncResource<"pricing-tier">;
  quote: Quote;
}

const quoteTransformer: Transformer = {
  type: ["quote", "quotes"],
  deserialize: (attr) => ({
    ...attr,
    isComplete: ["complete", "awarded"].includes(attr.status),
  }),
};

export default quoteTransformer;
