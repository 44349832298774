import "twin.macro";
import tw from "twin.macro";

import { Link } from "react-router";

import { format } from "date-fns";

import { OpaqueCard } from "@components/StyledComponents";
import ReactQueryTable, {
  PaginatedResourceProps,
} from "@components/Table/ReactQueryTable";
import { BudgetHistory } from "@models";
import { formatMoneyString, utcToLocalDate } from "@utility/utilityFunctions";

type BudgetHistoryTableProps = PaginatedResourceProps & {
  rows: BudgetHistory[];
};

const asDate = (date: string) => format(utcToLocalDate(date), "PPpp");

const BudgetHistoryTable = ({ ...tableProps }: BudgetHistoryTableProps) => {
  return (
    <OpaqueCard tw="p-0 overflow-hidden relative">
      <ReactQueryTable
        columns={[
          { id: "userName", label: "Updated By" },
          {
            id: "orderNumber",
            label: "Order Number",
            render: (orderNumber, _) =>
              typeof orderNumber === "number" ? (
                <Link
                  to={`/orders/history/${orderNumber}`}
                  target="_blank"
                  tw="underline text-blue-700"
                >
                  {orderNumber}
                </Link>
              ) : (
                "Adjust"
              ),
          },
          { id: "note", label: "Note" },
          { id: "updatedAt", label: "Date", render: asDate },
          {
            id: "adjustment",
            label: "Adjustment",
            align: "right",
            render: (v, row) => {
              const value = typeof row.orderNumber === "number" ? -v : +v;
              return (
                <span
                  css={[
                    value > 0 && tw`font-medium text-green-800`,
                    value < 0 &&
                      row.orderNumber === "Adjust" &&
                      tw`font-medium text-red-800`,
                  ]}
                >
                  {value > 0 && "+"}
                  {value !== 0 ? formatMoneyString(value) : "-"}
                </span>
              );
            },
          },
        ]}
        {...tableProps}
        maxHeight={600}
      />
    </OpaqueCard>
  );
};

export default BudgetHistoryTable;
