export const buildBulkBudgets = (bulkBudgets) => {
  const options = { day: "2-digit", month: "2-digit", year: "numeric" };
  let budgetDataArr = [];
  bulkBudgets.forEach((budget) => {
    const newBudget = {
      name: budget.name,
      "start-date": new Date(budget.start_date).toLocaleDateString(
        "en-US",
        options
      ),
      "expiration-date": new Date(budget.expiration_date).toLocaleDateString(
        "en-US",
        options
      ),
      "total-amount": budget.total_budget,
      users: budget.users,
    };
    budgetDataArr.push(newBudget);
  });

  return {
    data: {
      rows: budgetDataArr,
    },
  };
};
