import "twin.macro";

import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

import PropTypes from "prop-types";

import { useLocalPagination } from "../../hooks/useLocalPagination";
import StandardTableHead from "../Utility/StandardTableHead";

const useStyles = makeStyles((theme) => ({
  ...theme.global,
}));

const ReportTable = ({ type, report, headCells }) => {
  const classes = useStyles();

  const { scrollRef, reportData } = useLocalPagination(report);
  if (report.length === 0) {
    return (
      <div tw="bg-neutral-200 p-4 shadow-inner rounded-lg text-neutral-500">
        No data to show for this query.
      </div>
    );
  }

  return (
    <TableContainer
      // className={classes.tableContainer}
      tw="max-h-full"
      ref={scrollRef}
    >
      <Table
        size="small"
        // className={classes.table}
        aria-label={type}
        stickyHeader
      >
        <StandardTableHead classes={classes} headCells={headCells} />
        <TableBody>
          {reportData.map((item, index) => (
            <TableRow key={index}>
              {headCells.map((cell, i) => (
                <TableCell key={i} align="left" tw="whitespace-nowrap">
                  {item[cell.id]}
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

ReportTable.propTypes = {
  report: PropTypes.array,
  type: PropTypes.string.isRequired,
  headCells: PropTypes.array.isRequired,
};

export default ReportTable;
