const csvToJson = (csvData: string): Record<string, string>[] => {
  // Split into lines and filter out empty lines
  const lines = csvData.split("\n").filter((line) => line.trim());
  if (lines.length === 0) return [];

  // Get headers from first line
  const headers = lines[0].split(",").map((header) => header.trim());

  // Process remaining lines
  return lines.slice(1).map((line) => {
    const values = line.split(",").map((value) => value.trim());

    // Create object from headers and values
    return headers.reduce(
      (obj, header, index) => {
        obj[header] = values[index] || "";
        return obj;
      },
      {} as Record<string, string>
    );
  });
};

export default csvToJson;
