import { PropsWithChildren, useState } from "react";

import ConfirmModal from "./ConfirmModal";
import {
  ConfirmContentProps,
  GlobalConfirmContext,
  GlobalConfirmContextProps,
} from "./GlobalConfirmContext";

type PromiseResolve = GlobalConfirmContextProps["promise"];

export const GlobalConfirmProvider = ({ children }: PropsWithChildren) => {
  const [promise, setPromise] = useState<PromiseResolve>(null);
  const [confirmContent, setConfirmContent] =
    useState<ConfirmContentProps | null>(null);

  return (
    <GlobalConfirmContext.Provider
      value={{
        promise,
        setPromise,
        confirmContent,
        setConfirmContent,
      }}
    >
      {children}
      <ConfirmModal />
    </GlobalConfirmContext.Provider>
  );
};
