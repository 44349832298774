import React from "react";

import {
  Document,
  Font,
  Image,
  Page,
  StyleSheet,
  Text,
  View,
} from "@react-pdf/renderer";
import _ from "lodash";
// @ts-ignore
import logoUrl from "src/assets/Brandhub_Logo_RGB.png";
import colors from "tailwindcss/colors";

import { itemPreviewUrl } from "@features/items";
import { GroupCategory, Item } from "@models";
import { formatMoneyString } from "@utility/utilityFunctions";

Font.register({
  family: "Roboto",
  src: "/fonts/Roboto-Regular.ttf",
});

const styles = StyleSheet.create({
  page: {
    fontSize: 10,
    padding: "24px 4vw 32px",
    margin: 0,
    fontFamily: "Roboto",
    color: colors.slate[700],
  },
  flex: {
    display: "flex",
    flexDirection: "row",
  },
  footer: {
    fontSize: 10,
    color: colors.slate[700],
    position: "absolute",
    bottom: 12,
    left: `4vw`,
    right: `4vw`,
  },
});

type ProgramItemPDFProps = {
  items: Item[];
  programName: string;
  orgLogo: string;
  categories: GroupCategory[];
};

const KeyValue = ({ label, value }) => (
  <View style={[styles.flex, { marginBottom: 3 }]}>
    <Text style={{ width: `16vw`, marginRight: `2vw`, textAlign: "right" }}>
      {label}
    </Text>
    {!!value && (
      <Text style={{ width: `40vw`, lineHeight: 1.25 }}>{value}</Text>
    )}
    {!value && <Text style={{ color: colors.slate[400] }}>Not Provided</Text>}
  </View>
);

const ProgramItemPDF = ({
  items,
  programName,
  orgLogo,
  categories,
}: ProgramItemPDFProps) => {
  // Find the highest priority item category
  const primaryCategory = _.sortBy(categories, (c) => c.priorityLevel)[0];
  const groupedItems = _.groupBy(
    items,
    (item) =>
      item.groups.find(
        (group) => group.groupCategory.id === primaryCategory?.id
      )?.name ?? "Other"
  );
  const groupNames = _(groupedItems).keys().sort().value();
  return (
    <Document>
      {groupNames.map((groupName) => (
        // size: 7.5"Wx10"H
        <Page size={[540, 720]} style={styles.page} key={groupName}>
          <View
            style={[
              styles.flex,
              {
                borderBottom: `1px solid ${colors.slate[300]}`,
                padding: "4 0 8",
                justifyContent: "space-between",
                alignItems: "center",
                marginBottom: 16,
              },
            ]}
            fixed
          >
            <View>
              <Text
                style={{
                  marginBottom: 2,
                  color: colors.sky[500],
                  fontSize: 14,
                  fontWeight: "bold",
                  textTransform: "uppercase",
                  letterSpacing: 2,
                }}
              >
                {programName}
              </Text>
              <Text
                style={{
                  marginBottom: 2,
                  fontSize: 12,
                  color: colors.slate[600],
                }}
              >
                {groupName}
              </Text>
            </View>
            <Image src={orgLogo} style={{ width: 80 }} />
          </View>
          {groupedItems[groupName].map((item) => (
            <View
              key={item.id}
              style={[styles.flex, { marginBottom: 32 }]}
              wrap={false}
            >
              <View style={{ width: "30vw", marginRight: "6vw" }}>
                <Image
                  src={itemPreviewUrl(item, "medium")}
                  style={{
                    maxHeight: 158,
                    objectFit: "contain",
                    objectPosition: "center",
                  }}
                />
              </View>
              <View>
                <Text
                  style={{
                    fontSize: 14,
                    color: colors.sky[500],
                    marginBottom: 8,
                    maxWidth: "56vw",
                    fontWeight: "bold",
                    letterSpacing: 1,
                  }}
                >
                  {item.name}
                </Text>
                <KeyValue
                  label="SKU"
                  value={item.defaultExternalWarehouseId ?? item.sku}
                />
                {item.packSize > 1 ? (
                  <>
                    <KeyValue
                      label="Pack Price"
                      value={`${formatMoneyString(
                        item.price * item.packSize
                      )} (pack of ${item.packSize})`}
                    />
                    <KeyValue
                      label="Unit Price"
                      value={formatMoneyString(item.price)}
                    />
                  </>
                ) : (
                  <KeyValue
                    label="Price"
                    value={formatMoneyString(item.price)}
                  />
                )}

                <KeyValue label="Item Description" value={item.description} />
                {Object.entries(item.specification ?? {}).map(
                  ([key, value]) => (
                    <KeyValue key={key} label={key} value={value} />
                  )
                )}
              </View>
            </View>
          ))}
          <View
            fixed
            style={[
              styles.footer,
              styles.flex,
              {
                paddingTop: 4,
                justifyContent: "space-between",
                alignItems: "flex-end",
                borderTop: `1px solid ${colors.slate[300]}`,
              },
            ]}
          >
            <View style={[styles.flex, { alignItems: "center" }]}>
              <Image src={orgLogo} style={{ width: 80 }} />
              <Text
                style={{
                  margin: "0 12",
                  color: colors.slate[400],
                  fontSize: 14,
                }}
              >
                ×
              </Text>
              <Image src={logoUrl} style={{ width: 80, margin: "4 0" }} />
            </View>
            <Text
              render={({ pageNumber, totalPages }) =>
                `${pageNumber} / ${totalPages}`
              }
            />
          </View>
        </Page>
      ))}
    </Document>
  );
};

export default ProgramItemPDF;
