import { createContext, useContext } from "react";

import { Quote } from "@models/Quote";

export const QuoteContext = createContext<{ quote: Quote } | null>(null);

export const useQuote = () => {
  const context = useContext(QuoteContext);
  if (!context) {
    throw new Error("useQuote must be used within a QuoteContextProvider");
  }
  return context;
};
