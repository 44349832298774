export const mapStates = (states) => {
  if (!states || states.length === 0) return null;
  const mappedStates = states
    .map((s) => ({ id: s.id, code: s.code }))
    .sort((a, b) => {
      return a.code < b.code ? -1 : a.code > b.code ? 1 : 0;
    });

  return mappedStates;
};

export const mapCountries = (countries) => {
  const mappedCountries = countries.map((c) => ({
    id: c.id,
    name: c.name,
    code: c.code,
    states: mapStates(c.states),
  }));

  return mappedCountries;
};
