import React, { useState } from "react";
import { useDispatch } from "react-redux";

import {
  MutationCache,
  QueryCache,
  QueryClient,
  QueryClientProvider,
} from "@tanstack/react-query";

import { setExpires } from "@redux/slices/user/currentUserSlice";
import parseApiErrorMessage from "@services/api/parseApiErrorMessage";
import {
  captureMutationException,
  captureQueryException,
} from "@services/sentry";

const QueryClientProviderWrapper = (props: { children: React.ReactNode }) => {
  const dispatch = useDispatch();
  // make client persistent
  const [client] = useState(
    new QueryClient({
      queryCache: new QueryCache({
        onError: (error, query) => {
          if (!error) return;
          // Catch 401, invalid_token errors and set to expire
          if (error.status === 401) {
            dispatch(setExpires({ expires: 1 }));
            return;
          }
          captureQueryException(error, query);
        },
      }),
      mutationCache: new MutationCache({
        onError: (error, _variables, _context, mutation) => {
          if (!error) return;
          // Catch 401, invalid_token errors and set to expire
          if (error.status === 401) {
            dispatch(setExpires({ expires: 1 }));
            return;
          }
          if (
            parseApiErrorMessage(error).includes("exceeds available inventory")
          ) {
            // Variant exceeds available inventory error
            return;
          }
          captureMutationException(error, mutation);
        },
      }),
      defaultOptions: {
        queries: {
          // because most of our data contains circular data, we can't use this optimization
          structuralSharing: false,
          retry: false,
        },
        mutations: {},
      },
    })
  );
  return <QueryClientProvider client={client} {...props} />;
};

export default QueryClientProviderWrapper;
